import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { TestListColumns } from "components/course/test/TestListColumns";

export class TestDto implements Idto {
  id?: string;

  mintestscore?: number;
  maxtestscore?: number;
  name?: string;
  type?: number;
  status?: number;
  idcourse?: string;
  idlesson?: string | null;

  constructor(
    idcourse?: string,
    idlesson?: string | null,
    name?: string,
    mintestscore?: number,
    maxtestscore?: number,
    type?: number,
    status?: number
  ) {
    this.idcourse = idcourse || "";
    this.idlesson = idlesson || null;
    this.name = name || "";
    this.mintestscore = mintestscore || 0;
    this.maxtestscore = maxtestscore || 0;
    this.type = type || Types.TYPE_TEST_BEFORE_COURSE;
    this.status = status || Status.ACTIVE;
  }

  static rows: any = false;
  static setRows = (rows: any) => {
    TestDto.rows = rows;
  };
  static getRows = (): TestDto[] => {
    if (TestDto.rows) return TestDto.rows();
    return [];
  };
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "mintestscore",
        value: ["mintestscore"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "maxtestscore",
        value: ["maxtestscore"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_StandardColumn(LL, "mintestscore"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardColumn(LL, "maxtestscore"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    setObject: (value: TestDto | null) => void,

    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_StandardColumn(LL, "mintestscore"),
      ComponentCommonTools.columns_StandardColumn(LL, "maxtestscore"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      TestListColumns.columnQuestions(LL, setObject),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        "test"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idcourse";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idcourse") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}

export class PostTestDto implements Idto {
  idsquestion?: string[];
  mintestscore?: number;
  maxtestscore?: number;
  name?: string;
  type?: number;
  status?: number;
  idcourse?: string;
  idlesson?: string | null;

  constructor() {
    this.status = Status.ACTIVE;
    this.mintestscore = 0;
  }
}
