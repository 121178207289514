import React from "react";
import { Box } from "@mui/material";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  index,
  value,
  ...props
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...props}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

export { TabPanel };
