import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {FileRepository} from "repositories/system/file.repository";

export class FileService
  extends GeneralService<FileRepository>
  implements IService
{
  constructor() {
    super(new FileRepository(), [{ key: "type", type: "types" }], "name");
  }
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
// import { PostNomenclatureDto } from "dto/nomenclature/postnomenclature.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import FileRepository from "repositories/file/file.repository";
// import { Status } from "tools/types/status";
// import ResultListDTO from "dto/app/resultlist.dto";
// import GeneralService from "services/general.service";

// export default class FileService extends GeneralService implements IService {
//   FileRepository: FileRepository = new FileRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }

//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.FileRepository.update(id, this.handleUpdate, data, cbparameters);
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.FileRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: 'type', type: 'types' },
//     ]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.FileRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     // this.parseResultPopulate(result, rez, [
//     //   { key: 'type', type: 'types' },

//     // ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//         return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.FileRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.FileRepository.add(this.handleGetList, cbparameters, data);
//   }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "name";

//       obj.sortcriteria = [sobj];
//     }

//     return obj;
//   }
// }
