import { createContext, useState, useEffect, useCallback } from "react";

import { useCookies } from "react-cookie";

import { UserService } from "services/user/user.service";
import SignInDto from "dto/auth/signin.dto";
import SignInHashDto from "dto/auth/signinhash.dto";
import DateTools from "tools/utils/date.tools";
import GeneralRepository from "repositories/general.repository";
import { RouteTools } from "tools/utils/routetools";
import { Loading } from "components/elements/loading/Loading";
import { UserSettingsService } from "services/user/usersettings.service";
import { isTokenExpired } from "tools/utils/commontools";

import IProvider from "interfaces/provider.interface";
import { Config } from "tools/utils/config";
import { logger } from "tools/utils/logger";

type Props = {
  user: any;
  roles: any;
  usersettings: any;
  signIn: (signInDto: SignInDto, r?: boolean) => void;
  signOut: (onlyUser?: boolean) => void;
  verifyToken: () => void;
  updateUserSettings: (field: string, value: any) => void;
};
export const AuthContext = createContext<Props>({
  user: null,
  roles: null,
  usersettings: null,
  signIn: () => {},
  signOut: () => {},
  verifyToken: () => {},
  updateUserSettings: () => {},
});
var inRefresh = false;
const userService = new UserService();
const userSettingsService = new UserSettingsService();
export const AuthProvider: React.FC<IProvider> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const [token, setToken] = useState<any>(null);
  const [jwt, setJWT] = useState<any>(null);

  const [nextLocation, setNextLocation] = useState<any>(false);
  const [user, setUser] = useState<any>(-1);
  const [roles, setRoles] = useState(null);
  const [usersettings, setUserSettings] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  // ---------------------

  useEffect(() => {
    GeneralRepository.setUnauthorizedFunction(processUnauthorized);
  }, []);
  // ----------------------------------------
  useEffect(() => {
    checkIsLoading();
  }, [user, roles, usersettings, token, jwt]);

  const checkIsLoading = () => {
    if (!isLoading) return;
    if (user == -1) return;
    if (!token) return;
    if (!jwt) return;
    // if (roles == -1) return;

    setIsLoading(false);
  };

  // ----------------------------------------
  const generate = useCallback(() => {
    if (token === null || token === undefined) return;
    if (jwt && isTokenExpired(jwt))
      userService.generateToken(handleSetToken, {});
    else if (token == "") userService.generateToken(handleSetToken, {});
  }, [token, jwt]);

  useEffect(() => {
    generate();
  }, [generate]);

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    verifyToken();
    GeneralRepository.setToken(getToken);
  }, [token]);

  useEffect(() => {
    if (!jwt) return;
    if (!token) return;
    checkUser();
  }, [token, jwt]);

  useEffect(() => {
    if (user === null) return;

    if (user && window.location.pathname == "/login") {
      let u = "/";
      if (nextLocation) {
        u = nextLocation.pathname + nextLocation.search;
      }
      RouteTools.setHistory(u, {});
      setNextLocation(false);
    } else if (!user && window.location.pathname != "/login") {
      const t = {
        pathname: window.location.pathname,
        search: window.location.search,
      };
      setNextLocation(t);
      RouteTools.setHistory("/login", {});
    }
  }, [user]);

  // ---------------------
  const checkToken = () => {
    var t =
      cookies.token != undefined &&
      cookies.token != "undefined" &&
      cookies.token != "null"
        ? cookies.token
        : "";

    processToken(t);
  };

  const processToken = (accesstoken: any) => {
    setToken(accesstoken);
    setCookie("token", accesstoken, { path: "/" });

    if (accesstoken.accesstoken != undefined) {
      setCookie("jwt", accesstoken.accesstoken, { path: "/" });
      setJWT(accesstoken.accesstoken);
    }
  };

  const getToken = () => {
    verifyToken();
    return jwt;
  };

  const verifyToken = () => {
    if (!token) return;

    const expiresin = token.expiresin;
    const untildate = token.untildate;
    const diff = expiresin * 0.9;

    const c = DateTools.getTimeStamp();
    const rd = untildate - c;

    if (rd > diff || rd < 0) {
      if (rd < 0) {
        // signOut(true);
      }
      return;
    }
    if (isTokenExpired(jwt)) generate();
    else refreshToken();
  };

  const refreshToken = () => {
    if (inRefresh) return;
    inRefresh = true;

    const _r =
      cookies._r != undefined &&
      cookies._r != "undefined" &&
      cookies._r != "null"
        ? cookies._r
        : 0;

    const r = _r ? true : false;

    const cbparameters: any = {};
    cbparameters.remember = r;
    cbparameters.jwt = jwt;

    userService.refreshToken(handleSetToken, cbparameters);
  };
  // ---------------------

  // ---------------------

  // ---------------------
  const signOut = (onlyUser?: boolean) => {
    onlyUser = onlyUser ?? false;
    setUser(false);
    setRoles(null);
    setUserSettings(null);
    setToken(null);
    setJWT(null);

    if (!onlyUser) removeCookie("_r");
    if (!onlyUser) removeCookie("_uid");
    if (!onlyUser) removeCookie("_uhash");
    if (!onlyUser) removeCookie("jwt");
    if (!onlyUser) removeCookie("token");
  };

  const checkUser = () => {
    if (user != undefined && user != null && user != -1) return;

    setSavedUser();
  };

  const processUnauthorized = () => {
    removeCookie("_uid");
    removeCookie("_uhash");
    removeCookie("_r");
    removeCookie("jwt");
    removeCookie("token");
    setUser(false);
    setRoles(null);
    setUserSettings(null);
    setToken(null);
    setJWT(null);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  // ---------------------
  const setSavedUser = () => {
    const _uid =
      cookies._uid != undefined &&
      cookies._uid != "undefined" &&
      cookies._uid != "null"
        ? cookies._uid
        : "";

    const _uhash =
      cookies._uhash != undefined &&
      cookies._uhash != "undefined" &&
      cookies._uhash != "null"
        ? cookies._uhash
        : "";

    const _r =
      cookies._r != undefined &&
      cookies._r != "undefined" &&
      cookies._r != "null"
        ? cookies._r
        : 0;

    const r = _r ? true : false;

    if (!_uid || !_uhash) {
      setUser(false);
      return;
    }

    const signInHashDto = new SignInHashDto();
    signInHashDto.id = _uid;
    signInHashDto.hash = _uhash;

    const cbparameters: any = {};
    cbparameters.remember = r;
    cbparameters.signHash = true;
    userService.signInHash(signInHashDto, handleSetToken, cbparameters);
  };
  // ---------------------

  const processUser = (obj: any, cbparameters: any) => {
    if (
      cbparameters.remember != undefined &&
      cbparameters.remember != null &&
      cbparameters.remember
    ) {
      let exp = Config.COOKIE_EXPIRES;

      if (isNaN(exp)) exp = 0;
      setCookie("_r", "1", { path: "/", expires: DateTools.getDate(exp) });
      setCookie("_uid", obj.id, { path: "/", expires: DateTools.getDate(exp) });
      setCookie("_uhash", obj.hash, {
        path: "/",
        expires: DateTools.getDate(exp),
      });
    } else {
      setCookie("_r", "0", { path: "/" });
      setCookie("_uid", obj.id, { path: "/" });
      setCookie("_uhash", obj.hash, { path: "/" });
    }
  };

  const handleSetToken = (result?: any, cbparameters?: any, data?: any) => {
    if(!result) return;
    if (result.accesstoken != undefined && result.accesstoken != null) {
      processToken(result.accesstoken);
    }

    if (result.obj != undefined && result.obj != null) {
      setUser(result.obj);
      processUser(result.obj, cbparameters);
    }
    if (result.roles != undefined && result.roles != null) {
      setRoles(result.roles);
    }
    if (result.usersettings != undefined && result.usersettings != null) {
      setUserSettings(result.usersettings);
    }

    inRefresh = false;
  };

  const updateUserSettings = (field: string, value: any) => {
    if (usersettings == undefined) return;
    if (usersettings == null) return;

    const t: any = usersettings;

    t[field] = value;
    userSettingsService.update(t.id, null, {}, t);

    setUserSettings(t);
  };

  // ---------------------

  // ---------------------

  const signIn = (signInDto: SignInDto, r?: Boolean) => {
    if (userService == undefined) return false;
    if (userService == null) return false;

    r = r != undefined ? r : false;

    const cbp: any = {};
    cbp.remember = r;

    userService.signIn(signInDto, handleSetToken, cbp);
  };

  const value = {
    user,
    roles,
    usersettings,
    signIn,
    signOut,
    verifyToken,
    updateUserSettings,
  };

  return isLoading ? (
    <Loading />
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};
