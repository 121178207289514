import { Box, Stack, IconButton, Typography } from "@mui/material";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { useResource } from "hooks/useResource";
import { MyBreadcrumbs } from "../breadcrumbs/MyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";

type Props = {
  mainObject: string;
  mainUrl: string;
  listUrl: string;
  
};
const HeaderAdd: React.FC<Props> = ({
  mainObject,
  mainUrl,
  listUrl,
}) => {
  const { LL } = useResource();
  return (
    <React.Fragment>
      <MyBreadcrumbs
        childs={BreadcrumbDto.getBreadcrumbAdd(mainUrl, mainObject)}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",

          width: "100%",
        }}
        maxWidth="md"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <MyNavBt
              href={listUrl}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </MyNavBt>
          </Box>
          <Box>
            <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
              {LL("AddBlock")}
            </Typography>
          </Box>
        </Stack>
       
      </Box>
    </React.Fragment>
  );
};

export { HeaderAdd };
