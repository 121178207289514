import React from "react";
import { LinkCourseCategoryService } from "services/course/linkcoursecategory.service";

import { IPage } from "interfaces/page.interface";

import { LinkCourseCategoryDto } from "dto/course/linkcoursecategory.dto";

import { FormLinkCourseCategory } from "components/special/linkcoursecategory/FormLinkCourseCategory";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const service = new LinkCourseCategoryService();
const SpecialLinkCourseCategoryAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  const onSubmit = (obj: LinkCourseCategoryDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "linkcoursecategory",
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="linkcoursecategory"
      />
      <DataDisplayBox>
        <FormLinkCourseCategory
          defaultObject={new LinkCourseCategoryDto(parentId)}
          onSubmit={onSubmit}
          loading={loading}
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialLinkCourseCategoryAdd };
