// import React, { useState, useEffect } from "react";

// import {VideoService} from "services/system/video.service";
// import { logger } from "tools/utils/logger";

// import { VideoDto, PostVideoDto } from "dto/system/video.dto";
// import { useCookies } from "react-cookie";
// import { Loading } from "components/general/Loading";
// import { MyNavBt } from "components/general/MyNavBt";
// import { useResource } from "hook/useResource";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import { CommonTools } from "tools/utils/commontools";
// import { ComponentCommonTools } from "tools/utils/componentcommontools";
// import { Status } from "tools/types/status";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import MyTextField from "components/elements/form/mytextfield";
// import { LabelTypes } from "tools/types/labeltypes";
// import ValidateTools from "tools/utils/validate.tools";
// import RequiredValidator from "validators/required.validator";
// import { MyButton } from "components/general/MyButton";
// import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
// import { RouteTools } from "tools/utils/routetools";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import MyCheckboxField from "components/elements/form/mycheckboxfield";
// import IconButton from "@mui/material/IconButton";
// import { StatusSelect } from "components/elements/Select/StatusSelect";
// import { LanguageSelect } from "components/elements/Select/LanguageSelect";
// import MyFileField from "components/elements/form/myfilefield";
// import SpecialTopInfo from "../SpecialTopInfo";
// import UrlValidator from "validators/url.validator";
// const videoService = new VideoService();

// interface SpecialVideoAddProps {
//   children: any;
//   currentRoute: any;
//   _obj: VideoDto;
//   mainObject: any;
// }

// const SpecialVideoAdd: React.FC<SpecialVideoAddProps> = ({
//   children,
//   currentRoute,
//   _obj,
//   mainObject,
//   ...props
// }) => {
//   const [parentType, setParentType] = useState("");
//   const [parentId, setParentId] = useState("");

//   const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);
//   const addUrl = CommonTools.generateAddUrlSpecial(currentRoute);

//   const { LL } = useResource();

//   const [loading, setLoading] = useState<boolean>(false);
//   var [obj, setObj] = useState<PostVideoDto>(new PostVideoDto());

//   var [files, setFiles] = useState(null);

//   const [isFormDisabled, setIsFormDisabled] = useState(true);

//   const [validators, setValidators] = useState(false);
//   const [vresults, setVResults] = useState<any>(false);

//   // -----------------------------

//   const processParentData = () => {
//     // logger("SpecialVideoAddSpecialVideoAdd", mainUrl, addUrl);

//     if (currentRoute == undefined) return;
//     if (currentRoute._paths == undefined) return;

//     const path = currentRoute._paths;

//     if (path.length < 3) return;

//     setParentType(path[0]);
//     setParentId(path[1]);
//   };

//   useEffect(() => {
//     processParentData();
//   }, [currentRoute, mainObject]);
//   // -----------------------------

//   const validateAll = () => {
//     if (!obj) return;
//     if (!validators) return;
//     if (vresults) return;

//     obj.name = obj.name !== undefined ? obj.name : "";
//     obj.videolocation =
//       obj.videolocation !== undefined ? obj.videolocation : "";
//     obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
//     // obj.idfile = obj.idfile !== undefined ? obj.idfile : '';
//     obj.idparent = obj.idparent !== undefined ? obj.idparent : "";
//     obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : "";

//     setObj(obj);

//     // let t = validateField("name", obj.name);
//     let t = validateField("ordercriteria", obj.ordercriteria);
//     // t = validateField("idfile", obj.idfile);
//     t = validateField("idparent", obj.idparent);
//     t = validateField("idlanguage", obj.idlanguage);
//   };

//   const validateField = (field: any, value: any, v?: any) => {
//     v = v ? v : JSON.parse(JSON.stringify(vresults));
//     if (v === false) v = {};
//     v[field] = ValidateTools.validate(field, value, validators, vresults);
//     setVResults(v);
//     return v;
//   };

//   const processValidators = () => {
//     let v: any = {
//       name: [],
//       videolocation: [],
//       ordercriteria: [],
//     };

//     // v.name.push(
//     //   new RequiredValidator(
//     //     LL("Required field name", LabelTypes.LABEL_VALIDATION),
//     //     ""
//     //   )
//     // );

//     v.videolocation.push(
//       new RequiredValidator(
//         LL("Required field videolocation", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );
//     v.videolocation.push(
//       new UrlValidator(
//         LL("field must be a URL", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );

//     v.ordercriteria.push(
//       new RequiredValidator(
//         LL("Required field ordercriteria", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );
//     setValidators(v);
//   };

//   const checkLoading = () => {
//     if (!loading) return;
//     if (!obj) return;
//     if (!validators) return;
//     validateAll();
//     setLoading(false);
//   };

//   useEffect(() => {
//     processValidators();
//   }, []);

//   useEffect(() => {
//     checkLoading();
//   }, [obj, validators]);

//   useEffect(() => {
//     checkIsFormDisabled();
//   }, [vresults]);

//   const setObjFile = (field: any, event: any) => {
//     setFiles(event.target.files[0]);
//   };

//   const setObjField = (field: any, value: any) => {
//     // logger("setObjFieldsetObjFieldsetObjField", field, value);
//     let t = JSON.parse(JSON.stringify(obj));
//     t[field] = value;
//     setObj(t);

//     validateField(field, value);
//   };

//   const setCheckFixed = (value: any) => {
//     let t = JSON.parse(JSON.stringify(obj));

//     t["isdefault"] = value;
//     setObj(t);
//   };

//   const checkIsFormDisabled = () => {
//     var rez = ValidateTools.vForm(vresults, "videolocation");

//     // var rez = false;
//     // if (files == null) {
//     //   rez = true;
//     // }

//     setIsFormDisabled(rez);
//   };

//   const handleSubmit = (event: any) => {
//     event.preventDefault();
//     if (isFormDisabled) return false;
//     if (obj.isdefault === undefined) obj.isdefault = false;

//     obj.parent = parentType;
//     obj.idparent = parentId;

//     if (obj) obj.files = files;

//     videoService.add(
//       ComponentCommonTools.handleSuccessAddAndEditSpecial,
//       { mainObject, parentType, parentId, specialPart: "video" },
//       obj
//     );
//   };

//   const ChildsCrumbs = [
//     { url: "/", label: LL("crumb_homepage") },
//     {
//       url: "/" + parentType,
//       label: LL("crumb_" + parentType),
//     },
//     {
//       url: "/" + parentType + "/" + parentId + "/video",
//       label: LL("crumb_video"),
//     },
//     { label: LL("crumb_Addvideo") },
//   ];

//   return !loading ? (
//     <>
//       <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

//       <Box
//         sx={{
//           flexDirection: "row",
//           display: "flex",
//           alignContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <MyNavBt
//           tabIndex={6}
//           href={mainUrl}
//           children={
//             <IconButton color="primary">
//               <ArrowBackIcon />
//             </IconButton>
//           }
//           size="large"
//           _hstate={{}}
//           _mainurl={mainUrl}
//         />

//         <SpecialTopInfo
//           parentType={parentType}
//           parentId={parentId}
//           specialtype={"video"}
//           suplimentar={"add"}
//           variant="h4"
//           sx={{ my: 3, ml: 3 }}
//         />
//       </Box>

//       <Box
//         maxWidth="md"
//         sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
//       >
//         <form onSubmit={handleSubmit}>
//           <MyTextField
//             tabIndex={0}
//             fullWidth
//             id="name"
//             name="name"
//             variant="outlined"
//             color="primary"
//             _label={LL("Name")}
//             size="small"
//             value={obj.name}
//             setObj={setObjField}
//             _vresults={vresults && vresults.name ? vresults.name : false}
//           />
//           <Box sx={{ mt: 3 }}>
//             <MyTextField
//               tabIndex={1}
//               fullWidth
//               id="ordercriteria"
//               name="ordercriteria"
//               variant="outlined"
//               color="primary"
//               _label={LL("ordercriteria")}
//               size="small"
//               value={obj.ordercriteria}
//               setObj={setObjField}
//               _vresults={
//                 vresults && vresults.ordercriteria
//                   ? vresults.ordercriteria
//                   : false
//               }
//             />
//           </Box>
//           {/* <Box sx={{ mt: 3 }}>
//             <StatusSelect
//               tabIndex={2}
//               id="status"
//               name="status"
//               variant="outlined"
//               color="primary"
//               value={obj.status}
//               field="status"
//               size="small"
//               setObj={setObjField}
//             />
//           </Box> */}
//            <Box sx={{ mt: 3 }}>
//             <StatusSelect
//               value={obj.status}
//               setObjField={setObjField}
//             />
//           </Box>
//           <Box sx={{ mt: 3 }}>
//             <LanguageSelect
//               tabIndex={3}
//               id="idlanguage"
//               name="idlanguage"
//               variant="outlined"
//               color="primary"
//               value={obj.idlanguage}
//               field="idlanguage"
//               size="small"
//               setObj={setObjField}
//               obj={obj}
//               forAddForms={true}
//             />
//           </Box>

//           <Box sx={{ mt: 3 }}>
//             <MyCheckboxField
//               tabIndex={4}
//               id="isdefault"
//               name="isdefault"
//               _label={LL("isdefault")}
//               setObj={setCheckFixed}
//               checked={obj.isdefault}
//               color="primary"
//               size="small"
//               _vresults={undefined}
//             />
//           </Box>

//           <MyTextField

//             fullWidth
//             id="videolocation"
//             name="videolocation"
//             variant="outlined"
//             color="primary"
//             _label={LL("videolocation")}
//             size="small"
//             value={obj.videolocation}
//             setObj={setObjField}
//             _vresults={
//               vresults && vresults.videolocation
//                 ? vresults.videolocation
//                 : false
//             }
//           />

//           <MyButton
//             tabIndex={5}
//             disabled={isFormDisabled}
//             color="primary"
//             fullWidth
//             variant="contained"
//             size="large"
//             type="submit"
//             sx={{ mt: 4 }}
//             children={LL("Bt_Submit")}
//           />
//         </form>
//       </Box>
//     </>
//   ) : (
//     <Loading />
//   );
// };

// export default SpecialVideoAdd;

// import React, { useState, useEffect } from "react";

// import {VideoService} from "services/system/video.service";
// import { logger } from "tools/utils/logger";

// import { VideoDto, PostVideoDto } from "dto/system/video.dto";
// import { useCookies } from "react-cookie";
// import { Loading } from "components/general/Loading";
// import { MyNavBt } from "components/general/MyNavBt";
// import { useResource } from "hook/useResource";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import { CommonTools } from "tools/utils/commontools";
// import { ComponentCommonTools } from "tools/utils/componentcommontools";
// import { Status } from "tools/types/status";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import MyTextField from "components/elements/form/mytextfield";
// import { LabelTypes } from "tools/types/labeltypes";
// import ValidateTools from "tools/utils/validate.tools";
// import RequiredValidator from "validators/required.validator";
// import { MyButton } from "components/general/MyButton";
// import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
// import { RouteTools } from "tools/utils/routetools";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import MyCheckboxField from "components/elements/form/mycheckboxfield";
// import IconButton from "@mui/material/IconButton";
// import { StatusSelect } from "components/elements/Select/StatusSelect";
// import { LanguageSelect } from "components/elements/Select/LanguageSelect";
// import MyFileField from "components/elements/form/myfilefield";
// import SpecialTopInfo from "../SpecialTopInfo";
// const videoService = new VideoService();

// interface SpecialVideoAddProps {
//   children: any;
//   currentRoute: any;
//   _obj: VideoDto;
//   mainObject: any;
// }

// const SpecialVideoAdd: React.FC<SpecialVideoAddProps> = ({
//   children,
//   currentRoute,
//   _obj,
//   mainObject,
//   ...props
// }) => {
//   const [parentType, setParentType] = useState("");
//   const [parentId, setParentId] = useState("");

//   const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);
//   const addUrl = CommonTools.generateAddUrlSpecial(currentRoute);

//   const { LL } = useResource();

//   const [loading, setLoading] = useState<boolean>(false);
//   var [obj, setObj] = useState<PostVideoDto>(new PostVideoDto());

//   var [files, setFiles] = useState(null);

//   const [isFormDisabled, setIsFormDisabled] = useState(true);

//   const [validators, setValidators] = useState(false);
//   const [vresults, setVResults] = useState<any>(false);

//   // -----------------------------

//   const processParentData = () => {
//     // logger("SpecialVideoAddSpecialVideoAdd", mainUrl, addUrl);

//     if (currentRoute == undefined) return;
//     if (currentRoute._paths == undefined) return;

//     const path = currentRoute._paths;

//     if (path.length < 3) return;

//     setParentType(path[0]);
//     setParentId(path[1]);
//   };

//   useEffect(() => {
//     processParentData();
//   }, [currentRoute, mainObject]);
//   // -----------------------------

//   const validateAll = () => {
//     if (!obj) return;
//     if (!validators) return;
//     if (vresults) return;

//     obj.name = obj.name !== undefined ? obj.name : "";
//     obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;
//     // obj.idfile = obj.idfile !== undefined ? obj.idfile : '';
//     obj.idparent = obj.idparent !== undefined ? obj.idparent : "";
//     obj.idlanguage = obj.idlanguage !== undefined ? obj.idlanguage : "";

//     setObj(obj);

//     // let t = validateField("name", obj.name);
//     let t = validateField("ordercriteria", obj.ordercriteria);
//     // t = validateField("idfile", obj.idfile);
//     t = validateField("idparent", obj.idparent);
//     t = validateField("idlanguage", obj.idlanguage);
//   };

//   const validateField = (field: any, value: any, v?: any) => {
//     v = v ? v : JSON.parse(JSON.stringify(vresults));
//     if (v === false) v = {};
//     v[field] = ValidateTools.validate(field, value, validators, vresults);
//     setVResults(v);
//     return v;
//   };

//   const processValidators = () => {
//     let v: any = {
//       name: [],
//       ordercriteria: [],
//     };

//     // v.name.push(
//     //   new RequiredValidator(
//     //     LL("Required field name", LabelTypes.LABEL_VALIDATION),
//     //     ""
//     //   )
//     // );
//     v.ordercriteria.push(
//       new RequiredValidator(
//         LL("Required field ordercriteria", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );
//     setValidators(v);
//   };

//   const checkLoading = () => {
//     if (!loading) return;
//     if (!obj) return;
//     if (!validators) return;
//     validateAll();
//     setLoading(false);
//   };

//   useEffect(() => {
//     processValidators();
//   }, []);

//   useEffect(() => {
//     checkLoading();
//   }, [obj, validators]);

//   useEffect(() => {
//     checkIsFormDisabled();
//   }, [vresults]);

//   const setObjFile = (field: any, event: any) => {
//     setFiles(event.target.files[0]);
//   };

//   const setObjField = (field: any, value: any) => {
//     // logger("setObjFieldsetObjFieldsetObjField", field, value);
//     let t = JSON.parse(JSON.stringify(obj));
//     t[field] = value;
//     setObj(t);

//     validateField(field, value);
//   };

//   const setCheckFixed = (value: any) => {
//     let t = JSON.parse(JSON.stringify(obj));

//     t["isdefault"] = value;
//     setObj(t);
//   };

//   const checkIsFormDisabled = () => {
//     // var rez = ValidateTools.vForm(vresults, "name");

//     var rez = false;
//     if (files == null) {
//       rez = true;
//     }

//     setIsFormDisabled(rez);
//   };

//   const handleSubmit = (event: any) => {
//     event.preventDefault();
//     if (isFormDisabled) return false;
//     if (obj.isdefault === undefined) obj.isdefault = false;

//     obj.parent = parentType;
//     obj.idparent = parentId;

//     if (obj) obj.files = files;

//     videoService.add(
//       ComponentCommonTools.handleSuccessAddAndEditSpecial,
//       { mainObject, parentType, parentId, specialPart: "video" },
//       obj
//     );
//   };

//   const ChildsCrumbs = [
//     { url: "/", label: LL("crumb_homepage") },
//     {
//       url: "/" + parentType,
//       label: LL("crumb_" + parentType),
//     },
//     {
//       url: "/" + parentType + "/" + parentId + "/video",
//       label: LL("crumb_video"),
//     },
//     { label: LL("crumb_Addvideo") },
//   ];

//   return !loading ? (
//     <>
//       <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />

//       <Box
//         sx={{
//           flexDirection: "row",
//           display: "flex",
//           alignContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <MyNavBt
//           tabIndex={6}
//           href={mainUrl}
//           children={
//             <IconButton color="primary">
//               <ArrowBackIcon />
//             </IconButton>
//           }
//           size="large"
//           _hstate={{}}
//           _mainurl={mainUrl}
//         />

//         <SpecialTopInfo
//           parentType={parentType}
//           parentId={parentId}
//           specialtype={"video"}
//           suplimentar={"add"}
//           variant="h4"
//           sx={{ my: 3, ml: 3 }}
//         />
//       </Box>

//       <Box
//         maxWidth="md"
//         sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
//       >
//         <form onSubmit={handleSubmit}>
//           <MyTextField
//             tabIndex={0}
//             fullWidth
//             id="name"
//             name="name"
//             variant="outlined"
//             color="primary"
//             _label={LL("Name")}
//             size="small"
//             value={obj.name}
//             setObj={setObjField}
//             _vresults={vresults && vresults.name ? vresults.name : false}
//           />
//           <Box sx={{ mt: 3 }}>
//             <MyTextField
//               tabIndex={1}
//               fullWidth
//               id="ordercriteria"
//               name="ordercriteria"
//               variant="outlined"
//               color="primary"
//               _label={LL("ordercriteria")}
//               size="small"
//               value={obj.ordercriteria}
//               setObj={setObjField}
//               _vresults={
//                 vresults && vresults.ordercriteria
//                   ? vresults.ordercriteria
//                   : false
//               }
//             />
//           </Box>
//           {/* <Box sx={{ mt: 3 }}>
//             <StatusSelect
//               tabIndex={2}
//               id="status"
//               name="status"
//               variant="outlined"
//               color="primary"
//               value={obj.status}
//               field="status"
//               size="small"
//               setObj={setObjField}
//             />
//           </Box> */}
//            <Box sx={{ mt: 3 }}>
//             <StatusSelect
//               value={obj.status}
//               setObjField={setObjField}
//             />
//           </Box>
//           <Box sx={{ mt: 3 }}>
//             <LanguageSelect
//               tabIndex={3}
//               id="idlanguage"
//               name="idlanguage"
//               variant="outlined"
//               color="primary"
//               value={obj.idlanguage}
//               field="idlanguage"
//               size="small"
//               setObj={setObjField}
//               obj={obj}
//               forAddForms={true}
//             />
//           </Box>

//           <Box sx={{ mt: 3 }}>
//             <MyCheckboxField
//               tabIndex={4}
//               id="isdefault"
//               name="isdefault"
//               _label={LL("isdefault")}
//               setObj={setCheckFixed}
//               checked={obj.isdefault}
//               color="primary"
//               size="small"
//               _vresults={undefined}
//             />
//           </Box>

//           <Box sx={{ mt: 3 }}>
//             <MyFileField
//               tabIndex={1}
//               fullWidth
//               id="files"
//               name="files"
//               variant="outlined"
//               color="primary"
//               _label={LL("files")}
//               size="small"
//               setObj={setObjFile}
//               _vresults={vresults && vresults.files ? vresults.files : false}
//             />
//           </Box>

//           <MyButton
//             tabIndex={5}
//             disabled={isFormDisabled}
//             color="primary"
//             fullWidth
//             variant="contained"
//             size="large"
//             type="submit"
//             sx={{ mt: 4 }}
//             children={LL("Bt_Submit")}
//           />
//         </form>
//       </Box>
//     </>
//   ) : (
//     <Loading />
//   );
// };

// export default SpecialVideoAdd;

import React from "react";
import { VideoService } from "services/system/video.service";

import { IPage } from "interfaces/page.interface";

import { VideoDto } from "dto/system/video.dto";

import { FormVideo } from "components/special/video/FormVideo";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";
import { useResource } from "hooks/useResource";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const service = new VideoService();
const SpecialVideoAdd: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  const { _idlanguage } = useResource();
  const onSubmit = (obj: VideoDto) => {
    if (!obj) return;
    obj.idparent = parentId;
    obj.parent = parentType;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "video",
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["idlanguage"]),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="video"
      />
      <DataDisplayBox>
        <FormVideo
          defaultObject={new VideoDto(_idlanguage)}
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialVideoAdd };
