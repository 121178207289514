import { DetailGridItemProps } from "interfaces/detailobject.interface";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { Grid, Typography } from "@mui/material";

import { useResource } from "hooks/useResource";
import { HtmlTag } from "./HtmlTag";

const DetailGridHtml: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
  const { LL } = useResource();
  // const label = "Field_" + item.label;
  const mt = index === 0 ? 0 : 3;
  return (
    <React.Fragment>
      <Grid mt={mt}>
        <Typography color="text.disabled" variant="caption">
          {LL(item.label)}
        </Typography>
      </Grid>
      <Grid>
        <HtmlTag content={CommonTools.processObjectField(object, item.value)} />
      </Grid>
    </React.Fragment>
  );
};

export { DetailGridHtml };
