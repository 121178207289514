// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import ResultSignInDTO from "dto/auth/resultsignin.dto";
// import SignInDto from "dto/auth/signin.dto";
// import SignInHashDto from "dto/auth/signinhash.dto";
// import ModifyPasswordDto from "dto/user/modifypassword.dto";
// import { PostUserDto } from "dto/user/postuser.dto";

// import IService from "interfaces/iservice.interface";
// import GeneralService from "services/general.service";

// import { UserRepository } from "repositories/user/user.repository";

// export default class UserService extends GeneralService implements IService {
//   repository: UserRepository = new UserRepository();

//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }

//   handleSignIn(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultSignInDTO();
//     rez = result;
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async refreshToken(cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.repository.refreshToken(this.handleSignIn, cbparameters);
//   }

//   async generateToken(cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.repository.generateToken(this.handleSignIn, cbparameters);
//   }

//   async signIn(data: SignInDto, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.repository.signIn(data, this.handleSignIn, cbparameters);
//   }

//   async signInHash(
//     data: SignInHashDto,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.repository.signInHash(data, this.handleSignIn, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//       { key: "statusemail", type: "status" },
//       { key: "statuspassword", type: "status" },
//       { key: "registerdate", type: "timestamp" },
//       { key: "birthday", type: "date" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.repository.get(id, this.handleGet, cbparameters);
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     this.parseResultPopulate(result, rez, [
//       { key: "status", type: "status" },
//       { key: "statusemail", type: "status" },
//       { key: "statuspassword", type: "status" },
//       { key: "registerdate", type: "timestamp" },
//     ]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     const t = await this.repository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(cb?: any, cbparameters?: any, data?: PostUserDto): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.repository.add(this.handleAdd, cbparameters, data);
//   }

//   async addNewStudent(
//     cb?: any,
//     cbparameters?: any,
//     data?: PostUserDto
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.repository.addNewStudent(this.handleAdd, cbparameters, data);
//   }

//   async modifyPassword(
//     cb?: any,
//     cbparameters?: any,
//     data?: ModifyPasswordDto
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     if (
//       data?.email !== undefined &&
//       data.currentpassword !== undefined &&
//       data.newpassword !== undefined
//     ) {
//       this.repository.modifyPassword(this.handleAdd, cbparameters, data);
//     }
//   }

//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     // logger("Put rez", rez)
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   prepareUpdateData(data: PostUserDto): PostUserDto {
//     const rez = new PostUserDto();

//     rez.status = data.status;
//     rez.statusemail = data.statusemail;
//     rez.statuspassword = data.statuspassword;
//     return rez;
//   }
//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: PostUserDto
//   ): Promise<any> {
//     // logger("Put data", data)
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     if (data) {
//       this.repository.update(
//         id,
//         this.handleUpdate,
//         this.prepareUpdateData(data),
//         cbparameters
//       );
//     }
//   }

//   handlePatch(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async patch(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: PostUserDto
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.repository.patch(id, this.handlePatch, data, cbparameters);
//   }

//   async modifyPasswordByAdmin(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: PostUserDto
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.repository.modifyPasswordByAdmin(
//       id,
//       this.handlePatch,
//       data,
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.repository.delete(id, this.handleDelete, cbparameters);
//   }

//   getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
//     defaultRequestSortCriteria.asc = true;
//     defaultRequestSortCriteria.field = "email";
//     defaultRequestList.sortcriteria = [];
//     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
//   }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "email";

//       obj.sortcriteria = [sobj];
//     }

//     return obj;
//   }
// }

import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { UserRepository } from "repositories/user/user.repository";

import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { PostUserDto } from "dto/user/postuser.dto";
import ModifyPasswordDto from "dto/user/modifypassword.dto";
import ResultSignInDTO from "dto/auth/resultsignin.dto";
import SignInDto from "dto/auth/signin.dto";
import SignInHashDto from "dto/auth/signinhash.dto";

export class UserService
  extends GeneralService<UserRepository>
  implements IService
{
  constructor() {
    super(
      new UserRepository(),
      [
        { key: "status", type: "status" },
        { key: "statusemail", type: "status" },
        { key: "statuspassword", type: "status" },
        { key: "registerdate", type: "timestamp" },
        { key: "birthday", type: "date" },
      ],
      "email"
    );
  }

  addNewStudent = async (
    cb?: any,
    cbparameters?: any,
    data?: PostUserDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.addNewStudent(this.handleGet, cbparameters, data);
  };

  modifyPassword = async (
    cb?: any,
    cbparameters?: any,
    data?: ModifyPasswordDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    if (
      data?.email !== undefined &&
      data.currentpassword !== undefined &&
      data.newpassword !== undefined
    ) {
      this.repository.modifyPassword(this.handleGet, cbparameters, data);
    }
  };

  modifyPasswordByAdmin = async (
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: PostUserDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    await this.repository.modifyPasswordByAdmin(
      id,
      this.handleGet,
      data,
      cbparameters
    );
  };

  patch = async (
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: PostUserDto
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.patch(id, this.handleGet, data, cbparameters);
  };

  refreshToken = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.refreshToken(this.handleSignIn, cbparameters);
  };

  generateToken = async (cb?: any, cbparameters?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.generateToken(this.handleSignIn, cbparameters);
  };

  signIn = async (
    data: SignInDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.signIn(data, this.handleSignIn, cbparameters);
  };

  signInHash = async (
    data: SignInHashDto,
    cb?: any,
    cbparameters?: any
  ): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.signInHash(data, this.handleSignIn, cbparameters);
  };

  handleSignIn = (result?: any, cbparameters?: any, data?: any): any => {
    let rez = new ResultSignInDTO();
    rez = result;
    if (cbparameters && cbparameters._cb) {
      cbparameters._cb(rez, cbparameters, data);
    }
  };

  getDefaultRequestListSelect = (onPage?: number): RequestListDTO => {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "email";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  };
}
