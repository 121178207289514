import React, { useState } from "react";
import { UserSettingsTabProps } from "./UserSettingsTab";
import {UserSettingsService} from "services/user/usersettings.service";
import useObject from "hooks/useObject";
import { UserSettingsDto } from "dto/user/usersettings.dto";
import { Skeleton } from "@mui/material";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CommonTools } from "tools/utils/commontools";
import { FormUserSettings } from "./FormUserSettings";

const service = new UserSettingsService();
const UserSettingsTabEdit: React.FC<UserSettingsTabProps> = ({
  idUser,
  mainObject,
}) => {
  const get = (id: string, cb?: any, cbParams?: any) => {
    if (!id) return;
    service.getByUserId(id, cb, cbParams);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = (obj: UserSettingsDto) => {
    if (!obj) return;
    if(!obj.id) return;
    setLoading(true);
    service.update(
      obj.id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        idRoute: idUser,
        cb: CommonTools.processLoadingCallback(setLoading),
        needUpdate: true,
      },
      obj
    );
  };
  const [loadingObject, object] = useObject<UserSettingsDto>(get, idUser, [
    idUser,
  ]);
  if (loadingObject || loading) return <Skeleton />;
  if (!object) return null;
  return (
    <FormUserSettings
      defaultObject={object}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export { UserSettingsTabEdit };
