import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class PostPageDto implements Idto {
  type?: number;
  status?: number;
  idlanguage?: string;
  idpage?: string;
  name?: string;
  url?: string;
  title?: string;
  keymeta?: string;
  descriptionmeta?: string;
  content?: string;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  status_name?: string;
  type_name?: string;
  constructor() {
    this.status = Status.ACTIVE;
  }
}

export class PageDto implements Idto {
  id?: string;
  type?: number;
  status?: number;
  idlanguage?: string;
  idpage?: string;
  name?: string;
  url?: string;
  title?: string;
  keymeta?: string;
  descriptionmeta?: string;
  content?: string;

  language?: any;
  status_name?: string;
  type_name?: string;
  
  constructor(
    idlanguage?: string,
    type?: number,
    status?: number,
    name?: string,
    url?: string,
    title?: string,
    keymeta?: string,
    descriptionmeta?: string,
    content?: string
  ) {
    this.status = Status.ACTIVE || status;
    this.type = type || Types.PAGE_BLOCK;
    this.name = name || "";
    this.url = url || "";
    this.title = title || "";
    this.keymeta = keymeta || "";
    this.descriptionmeta = descriptionmeta || "";
    this.content = content || "";
    this.idlanguage = idlanguage || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "url",
        value: ["url"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "title",
        value: ["title"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "keymeta",
        value: ["keymeta"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "descriptionmeta",
        value: ["descriptionmeta"],
      },
      {
        typeField: Types.FIELD_TYPE_HTML,
        label: "content",
        value: ["content"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name", undefined, false),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "url", undefined, false),
      ComponentCommonTools.columns_StandardColumn(
        LL,
        "title",
        undefined,
        false
      ),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "gallery", "attachment", "video"],
        ["detail", "attachment", "video"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<PageDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["name"])
        )
      );
    });
    return result;
  }
}
