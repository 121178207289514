import { GeneralAutocomplete } from "components/elements/autocomplete/newAutocomplete/GeneralAutocomplete";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { StudentDto } from "dto/user/student.dto";
import { useResource } from "hooks/useResource";

import React from "react";
import {StudentService} from "services/user/student.service";

type Props = {
  setObjField: (field: string, value: any) => void;
  defaultObject?: StudentDto | null;
  value?: string | number;
  idCourse?: string;
};

const service = new StudentService();
const AutocompleteStudent: React.FC<Props> = ({
  setObjField,
  defaultObject,
  value,
  idCourse = "",
}) => {
  const { LL } = useResource();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    if (!req) req = new RequestListDTO();
    if (!req.filters) req.filters = [];
    if (idCourse)
      req.filters.push(RequestFilterDTO.prepareFilter("noenrollment", [idCourse]));
    service.getList(cb, cbParams, req);
  };
  let defaultObj: SelectOptions | null = null;
  if (!defaultObject) defaultObj = null;
  else defaultObj = StudentDto.parseOption(defaultObject);

  return (
    <GeneralAutocomplete
      field="idstudent"
      label={LL("field_student")}
      defaultObject={defaultObj}
      setObjectField={setObjField}
      parseOptions={StudentDto.parseToSelectOptions}
      getListFunction={getList}
      mainValue={value}
      filterField="emailsearch"
    />
  );
};

export { AutocompleteStudent };
