import { NomenclatureAdd } from "components/elements/nomenclature/NomenclatureAdd";
import { NomenclatureDetail } from "components/elements/nomenclature/NomenclatureDetail";
import { NomenclatureEdit } from "components/elements/nomenclature/NomenclatureEdit";
import { NomenclatureList } from "components/elements/nomenclature/NomenclatureList";
import { IPage } from "interfaces/page.interface";
import { TypeDisciplineService } from "services/nomenclature/typediscipline.service";

const service = new TypeDisciplineService();

const TypeDiscipline: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureList
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const DetailTypeDiscipline: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  return (
    <NomenclatureDetail
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const AddTypeDiscipline: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureAdd
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const EditTypeDiscipline: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureEdit
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};
export {
  TypeDiscipline,
  DetailTypeDiscipline,
  AddTypeDiscipline,
  EditTypeDiscipline,
};
