import React from "react";
import YouTube from "react-youtube";
import ReactPlayer from "react-player";
import { logger } from "tools/utils/logger";

interface VideoPlayerProps {
  videoId: string | undefined | null;
  platform: string | undefined | null;
  width?: number | undefined | null;
  height?: number | undefined | null;
  controls?: boolean | undefined | null;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoId,
  platform,
  width,
  height,
  controls,
  ...props
}) => {
  videoId = videoId ?? '';
  platform = platform ?? "";
  width = width ?? 640;
  height = height ?? 360;
  controls = controls ?? true;

  if (platform === "youtube") {
    // YouTube Player
    return <YouTube videoId={videoId} opts={{ width, height }} />;
  } else if (platform === "vimeo") {
    // Vimeo Player
    return (
      <>{" "}
        <ReactPlayer
          url={"https://vimeo.com/" + videoId}
          width={width + "px"}
          height={height + "px"}
          controls={controls}
        />{" "}
      </>
    );
  } else {
    return <div>Unsupported platform</div>;
  }
};

export default VideoPlayer;
