import { IPage } from "interfaces/page.interface";
import React from "react";
import { UserSettingsTabAdd } from "./UserSettingsTabAdd";
import { UserSettingsTabEdit } from "./UserSettingsTabEdit";

export type UserSettingsTabProps = IPage & {
  idUser: string;
  idUserSettings: string;
};

const UserSettingsTab: React.FC<UserSettingsTabProps> = ({
  idUser,
  idUserSettings,
  mainObject,
  currentRoute,
}) => {
  if (!idUserSettings)
    return (
      <UserSettingsTabAdd
        idUser={idUser}
        mainObject={mainObject}
        currentRoute={currentRoute}
        idUserSettings={idUserSettings}
      />
    );
  else
    return (
      <UserSettingsTabEdit
        idUser={idUser}
        mainObject={mainObject}
        currentRoute={currentRoute}
        idUserSettings={idUserSettings}
      />
    );
};

export { UserSettingsTab };
