import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {UserRoleRepository} from "repositories/user/userrole.repository";
import RequestListDTO from "dto/app/requestlist.dto";

export  class UserRoleService
  extends GeneralService<UserRoleRepository>
  implements IService
{
  constructor() {
    super(new UserRoleRepository(), [], "name");
  }
  async getUserRolesByUserId(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.getUserRolesByUserId(id, this.handleGet, cbparameters);
  }
  
  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    return defaultRequestList;
  }
}
// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
// import { PostNomenclatureDto } from "dto/nomenclature/postnomenclature.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import UserRoleRepository from "repositories/user/userrole.repository";
// import { Status } from "tools/types/status";

// import GeneralService from "services/general.service";
// export default class UserRoleService extends GeneralService implements IService {
//   userRoleRepository: UserRoleRepository = new UserRoleRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }

//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRoleRepository.update(id, this.handleUpdate, data, cbparameters);
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.userRoleRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.userRoleRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//   }
  // async getUserRolesByUserId(id: string, cb?: any, cbparameters?: any): Promise<any> {
  //   cbparameters = cbparameters ? cbparameters : {};
  //   cbparameters._cb = cb;
  //   this.userRoleRepository.getUserRolesByUserId(id, this.handleGet, cbparameters);
  // }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//         return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.userRoleRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.userRoleRepository.add(this.handleGetList, cbparameters, data);
//   }

  // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
  //   onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
  //   if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
  //   var defaultRequestList = new RequestListDTO();
  //   defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
  //   defaultRequestList.onpage = onPage;
  //   // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
  //   // defaultRequestSortCriteria.asc = true;
  //   // defaultRequestSortCriteria.field = 'name';
  //   // defaultRequestList.sortcriteria = []
  //   // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
  //   return defaultRequestList;
  // }
//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "name";

//       obj.sortcriteria = [sobj];
//     }

//     return obj;
//   }
// }
