// import React, { useEffect, useState } from "react";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { logger } from "tools/utils/logger";
// import { useResource } from "hook/useResource";
// import {PricePolicyService} from "services/finance/pricepolicy.service";

// import { MySelect } from "components/elements/Select/MySelect";
// import RequestListDTO from 'dto/app/requestlist.dto';
// import SelectOptions from "dto/app/selectoptions.dto";
// import ResultListDTO from 'dto/app/resultlist.dto';
// import { PricePolicyDto } from 'dto/finance/pricepolicy.dto';
// import { CommonTools } from 'tools/utils/commontools';
// import { Skeleton } from "@mui/material";
// import RequestFilterDTO from "dto/app/requestfilter.dto";
// const pricePolicyService = new PricePolicyService();

// type PricePolicySelectProps = {
//     setObj: ((field: any, value: any) => void);
//     field: any;
//     obj: any;
//     idTypeCourse: string;
//     _defaultValue?: string;
//     [key: string]: any;
// }

// const PricePolicySelect: React.FC<PricePolicySelectProps> = ({
//     setObj,
//     field,
//     obj,
//     _defaultValue,
//     idTypeCourse,
//     ...props
// }) => {
//     const { LL } = useResource();

//     const label = "PricePolicy";
//     const defaultValue = _defaultValue ? _defaultValue : '';
//     const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
//     const [loading, setLoading] = useState<boolean>(true);
//     const [value, setValue] = useState<string>(defaultValue);
//     const [data, setData] = useState<Array<PricePolicyDto> | undefined>();

//     const onPage = 2000;
//     const getReqList = () => {
//         const reqList = pricePolicyService.getDefaultRequestListSelect(onPage);
//         if(!idTypeCourse) return reqList;
//         reqList.filters = []
//         let filter = new RequestFilterDTO();
//         filter.field = 'idtypecourse';
//         filter.values = [idTypeCourse]
//         reqList.filters.push(filter);

//         return reqList;
//     };

//     const [reqList, setReqList] = useState<RequestListDTO>(getReqList());

//     const loadObjects = (data: ResultListDTO) => {
//         setData(data.objects);
//     };

//     useEffect(() => {
//         if(!idTypeCourse) return;
//         setReqList(getReqList());
//     }, [idTypeCourse]);

//     const getList = () => {
//         setLoading(true);
//         pricePolicyService.getList(loadObjects, {}, reqList);
//     };

//     const processOptions = () => {

//         if (data !== undefined) {
//             const options: Array<SelectOptions> = data.map((item) => {
//                 const obj = {
//                     label: item.name,
//                     value: item.id
//                 }
//                 return obj;
//             })
//             setOptions(options);
//         }

//     }

//     // useEffect(() => {
//     //     getReqList();
//     // }, []);

//     useEffect(() => {
//         setLoading(true);
//         if (CommonTools.atLeastOneFieldDefined(reqList)) {
//             getList();
//         }
//     }, [reqList]);

//     useEffect(() => {
//         if (data !== undefined) processOptions()
//         checkIsLoading();
//     }, [options, data]);

//     useEffect(() => {
//         if (loading) return;
//         setObj(field, value);
//     }, [value]);

//     const checkIsLoading = () => {
//         let load: boolean = false;
//         if (data === undefined) load = true;
//         if (options === undefined) load = true;
//         // if (value === undefined) load = true;
//         setLoading(load);
//     };

//     return !loading ? (
//         <MySelect
//             options={options}
//             _label={label}
//             setValue={setValue}
//             value={value}
//             {...props}
//         />
//     ) : (<Skeleton variant="rectangular" height={50} />);
// };

// export { PricePolicySelect };

import React from "react";
import RequestListDTO from "dto/app/requestlist.dto";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import { ServerSelect } from "./newSelect/ServerSelect";
import { PricePolicyService } from "services/finance/pricepolicy.service";

import { PricePolicyDto } from "dto/finance/pricepolicy.dto";

const service = new PricePolicyService();

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
  idTypeCourse?: string;
};

const PricePolicySelect: React.FC<Props> = ({
  field = "idpricepolicy",
  setObjectField,
  value,
  idTypeCourse = "",
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    if (!data) data = new RequestListDTO();
    data.filters = [
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    if (idTypeCourse) {
      data.filters.push(
        RequestFilterDTO.prepareFilter("idtypecourse", [idTypeCourse])
      );
    }
    service.getList(cb, cbParams, data);
  };
  const deps = idTypeCourse ? [idTypeCourse] : [];
  return (
    <ServerSelect
      processOptions={PricePolicyDto.parseToSelectOptions}
      getList={getList}
      field={field}
      setObjectField={setObjectField}
      value={value}
      label="PricePolicy"
      dependencies={deps}
    />
  );
};

export { PricePolicySelect };
