import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Types } from "tools/types/types";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class LinkCourseCategoryDto implements Idto {
  id?: string;
  idcourse?: string;
  idcoursecategory?: string;

  course?: any;
  coursecategory?: any;

  constructor(idcourse?: string, idcoursecategory?: string, id?: string) {
    this.idcourse = idcourse || "";
    this.idcoursecategory = idcoursecategory || "";
    this.id = id || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "coursecategory",
        value: ["coursecategory", "fullname"],
      },
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "coursecategory",
        "fullname"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        "linkcoursecategory"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    return obj;
  }

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idcourse";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idcourse") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}

export class PostLinkCourseCategoryDto implements Idto {
  idcourse?: string;

  idcoursecategory?: string;
}
