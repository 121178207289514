import { Close } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import MyTextField from "components/elements/form/mytextfield";
import { HeaderListSpecial } from "components/elements/list/HeaderListSpecial";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { GroupPermissionList } from "components/user/permission/GroupPermissionList";
import { PermissionActionAll } from "components/user/permission/PermissionActionAll";
import { PermissionMenu } from "components/user/permission/PermissionMenu";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { PermissionDto } from "dto/user/permission.dto";
import { useList } from "hooks/useList";
import { useResource } from "hooks/useResource";
import { IPage } from "interfaces/page.interface";
import React, { useEffect, useState } from "react";
import { PermissionService } from "services/user/permission.service";
import { CommonTools } from "tools/utils/commontools";

const service = new PermissionService();
const SpecialPermission: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { _getListUrl } = useResource();

  const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);
  const [parentUrl, setParentUrl] = useState("");
  const [parentType, setParentType] = useState("");
  const [parentId, setParentId] = useState("");
  const [search, setSearch] = useState("");

  const { LL } = useResource();

  const setObjectField = (field: string, value: any) => {
    setSearch(value);
  };

  const getListFunction = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, , , setObjects] = useList<PermissionDto>(
    getListFunction,
    new RequestListDTO([], 1, -1, [
      RequestSortCriteriaDTO.prepareSortCriteria("name", true),
    ]),
    []
  );

  const processParentData = () => {
    if (!currentRoute) return;
    if (!currentRoute._paths) return;
    const path = currentRoute._paths;
    if (path.length < 3) return;
    setParentType(path[0]);
    setParentId(path[1]);
  };

  useEffect(() => {
    if (!currentRoute) return;
    const lu = _getListUrl(
      CommonTools.generateParentMainUrlSpecial(currentRoute)
    );
    setParentUrl(lu);
    processParentData();
  }, [currentRoute]);

  const handleClear = () => {
    setSearch("");
  };

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <CircularLoading />;
  if (!objects) return null;
  const parsedObjects = PermissionDto.groupByGroupName(objects, search);
  const checked = PermissionDto.checkByRoleId(objects, parentId);
  return (
    <React.Fragment>
      <HeaderListSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={""}
        specialType="permission"
        parentUrl={parentUrl}
        parentType={parentType}
        parentId={parentId}
        showAddBtn={false}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            maxWidth: "1200px",
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "70%" },
              display: "flex",
              flexDirection: "column",
              alignItems: "starts",
              justifyContent: "center",
              // px: 2,
              py: 3,
              maxWidth: "700px",
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                spacing={1}
              >
                <MyTextField
                  _label={LL("search")}
                  value={search}
                  setObj={setObjectField}
                  field="search"
                  _vresults={undefined}
                />
                <IconButton onClick={handleClear}>
                  <Close />
                </IconButton>
              </Stack>
              <Box>
                <PermissionActionAll
                  checked={checked}
                  idRole={parentId}
                  setObjects={setObjects}
                />
              </Box>
            </Stack>
            <Box
              sx={{
                width: "100%",
                maxWidth: "md",
                py: 3,
              }}
            >
              <GroupPermissionList
                objects={parsedObjects}
                idRole={parentId}
                setObjects={setObjects}
                objectsFull={objects}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "30%" },
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                position: { md: "fixed" },
                top: "150px",
                width: { xs: "100%", md: "30%" },
                px: 3,
              }}
            >
              <PermissionMenu objects={parsedObjects} />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export { SpecialPermission };
