import { Box, Stack, Typography } from "@mui/material";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import MyTextField from "components/elements/form/mytextfield";
import MyDateTimePicker from "components/elements/datepicker/MyDateTimePicker";
import { LessonTypeSelect } from "components/elements/select/LessonTypeSelect";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { MyButton } from "components/elements/button/MyButton";
import { LessonDto } from "dto/course/lesson.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import DateTools from "tools/utils/date.tools";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: LessonDto;
  onSubmit: (data: LessonDto) => void;
  loading: boolean;
};
const FormLesson: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, setObject, vresults] = useForm<LessonDto>(
    defaultObject,
    RequiredValidator.getValidators(["name", "idtypelesson", "description"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  const setObjDateField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = DateTools.getDateTimeStamp(value);
    setObject(t);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("Name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            id="ordercriteria"
            name="ordercriteria"
            variant="outlined"
            color="primary"
            _label={LL("Ordercriteria")}
            size="small"
            value={obj.ordercriteria}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <LessonTypeSelect
            value={obj.idtypelesson}
            setObjectField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <MyCheckboxField
            label={LL("islive")}
            field="islive"
            setObjectField={setObjField}
            checked={obj.islive}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6" mb={1}>
            {LL("Seteaza data si ora lectiei")}
          </Typography>
          <Stack direction="row" spacing={2}>
            <MyDateTimePicker
              className="form-picker"
              field="startdate"
              _defaultValue={DateTools.convertTimestampToDate(obj.startdate)}
              LL={LL}
              setObjField={setObjDateField}
            />

            <MyDateTimePicker
              className="form-picker"
              field="enddate"
              _defaultValue={DateTools.convertTimestampToDate(obj.enddate)}
              LL={LL}
              setObjField={setObjDateField}
            />
          </Stack>
        </Box>
        <Box mt={3}>
          <Typography color="text.disabled" variant="caption">
            {LL("description")}
          </Typography>
          <MyCKEditor
            data={obj.description ? obj.description : ""}
            setObj={setObjField}
            name="description"
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={6}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormLesson };
