import { NomenclatureAdd } from "components/elements/nomenclature/NomenclatureAdd";
import { NomenclatureDetail } from "components/elements/nomenclature/NomenclatureDetail";
import { NomenclatureEdit } from "components/elements/nomenclature/NomenclatureEdit";
import { NomenclatureList } from "components/elements/nomenclature/NomenclatureList";
import { IPage } from "interfaces/page.interface";
import { TypeFilterService } from "services/nomenclature/typefilter.service";

const service = new TypeFilterService();

const TypeFilter: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureList
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const DetailTypeFilter: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureDetail
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const AddTypeFilter: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureAdd
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const EditTypeFilter: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureEdit
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};
export { TypeFilter, DetailTypeFilter, AddTypeFilter, EditTypeFilter };
