import { GeneralAutocomplete } from "components/elements/autocomplete/newAutocomplete/GeneralAutocomplete";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { UserDto } from "dto/user/user.dto";

import { useResource } from "hooks/useResource";

import React from "react";
import {UserService} from "services/user/user.service";

type Props = {
  setObjField: (field: string, value: any) => void;
  defaultObject?: UserDto | null;
  value?: string | number;
};

const service = new UserService();
const AutocompleteUser: React.FC<Props> = ({
  setObjField,
  defaultObject,
  value,
}) => {
  const { LL } = useResource();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  let defaultObj: SelectOptions | null = null;
  if (!defaultObject) defaultObj = null;
  else defaultObj = UserDto.parseOption(defaultObject);

  
  return (
    <GeneralAutocomplete
      field="iduser"
      label={LL("user")}
      defaultObject={defaultObj}
      setObjectField={setObjField}
      parseOptions={UserDto.parseToSelectOptions}
      getListFunction={getList}
      mainValue={value}
      filterField="search"
    />
  );
};

export { AutocompleteUser };
