import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {SocialRepository} from "repositories/user/social.repository";

import RequestListDTO from "dto/app/requestlist.dto";

export class SocialService
  extends GeneralService<SocialRepository>
  implements IService
{
  constructor() {
    super(new SocialRepository(), [], "link");
  }
  async getSocialsByUserId(id: string, cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.getSocialsByUserId(id, this.handleGet, cbparameters);
}
getDefaultRequestListSelect(onPage?: number): RequestListDTO {
  onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
  if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
  var defaultRequestList = new RequestListDTO();
  defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
  defaultRequestList.onpage = onPage;
  return defaultRequestList;
}
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
// import { PostNomenclatureDto } from "dto/nomenclature/postnomenclature.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import SocialRepository from "repositories/user/social.repository";
// import { Status } from "tools/types/status";

// import GeneralService from "services/general.service";
// export default class SocialService extends GeneralService implements IService {
//     socialRepository: SocialRepository = new SocialRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }

//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.socialRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.socialRepository.delete(id, this.handleDelete, cbparameters);
//     }



//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
      
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.socialRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

    // async getSocialsByUserId(id: string, cb?: any, cbparameters?: any): Promise<any> {
    //     cbparameters = cbparameters ? cbparameters : {};
    //     cbparameters._cb = cb;
    //     this.socialRepository.getSocialsByUserId(id, this.handleGet, cbparameters);
    // }

   

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();

//         rez = result;
//         if (result.err) return;
     

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.socialRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.socialRepository.add(this.handleGetList, cbparameters, data);
//     }


    // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    //     var defaultRequestList = new RequestListDTO();
    //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    //     defaultRequestList.onpage = onPage;
    //     // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    //     // defaultRequestSortCriteria.asc = true;
    //     // defaultRequestSortCriteria.field = 'name';
    //     // defaultRequestList.sortcriteria = []
    //     // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    //     return defaultRequestList;
    // }
//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "link";

//             obj.sortcriteria = [sobj];
//         }


//         return obj;
//     }
// }
