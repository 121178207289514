import { NomenclatureAdd } from "components/elements/nomenclature/NomenclatureAdd";
import { NomenclatureDetail } from "components/elements/nomenclature/NomenclatureDetail";
import { NomenclatureEdit } from "components/elements/nomenclature/NomenclatureEdit";
import { NomenclatureList } from "components/elements/nomenclature/NomenclatureList";
import { IPage } from "interfaces/page.interface";
import { TypeLocalityService } from "services/nomenclature/typelocality.service";

const service = new TypeLocalityService();

const TypeLocality: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureList
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const DetailTypeLocality: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureDetail
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const AddTypeLocality: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureAdd
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const EditTypeLocality: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureEdit
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};
export { TypeLocality, DetailTypeLocality, AddTypeLocality, EditTypeLocality };
