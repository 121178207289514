import { List } from "@mui/material";
import { PermissionGroupDto } from "dto/user/permission.dto";
import React from "react";
import { PermissionMenuItem } from "./PermissionMenuItem";

type Props = {
  objects: PermissionGroupDto[];
};

const PermissionMenu: React.FC<Props> = ({ objects }) => {
  if (!objects) return null;
  return (
    <List
      sx={{
        height: 600,
        overflowY: "auto",
      }}
    >
        {objects.map((item, index) => <PermissionMenuItem key={index} item={item} />)}
    </List>
  );
};

export { PermissionMenu };
