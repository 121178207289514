import React, { useState, useEffect } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";

import {
  DataGrid,
  GridSortItem,
  GridSortModel,
  
  GridRowParams,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  
  GridToolbarDensitySelector,
  GridRowSelectionModel,
  GridColumnMenu,
  GridColumnMenuProps,
  GridCallbackDetails,
  useGridApiRef,
  GridPagination,
  GridDensity,
} from "@mui/x-data-grid";

import RequestListDTO from "dto/app/requestlist.dto";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import { useCookies } from "react-cookie";
import DateTools from "tools/utils/date.tools";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { useResource } from "hooks/useResource";

import { TableToolbar } from "./TableToolbar";
import Box from "@mui/material/Box/Box";

import { ComponentCommonTools } from "tools/utils/componentcommontools";

import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { MessagesContainerPopUpConfirmDelete } from "components/message/MessagesContainerPopUpConfirmDelete";
import { CommonTools } from "tools/utils/commontools";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import { PageSelect } from "../select/newSelect/PageSelect";
import { Config } from "tools/utils/config";
import { Loading } from "../loading/Loading";
interface MyTableProps {
  _columns: any;
  _data: any;
  _reqList: RequestListDTO;
  _tbFilterList?: TbFilterListDTO;
  _total: number;
  _totalPage: number;
  setReqList: (value: RequestListDTO) => any;
  loading: boolean;
  currentRoute: any;
  onDelete?: (value: any) => any;
  mainObject: any;
  onRowClickCb?: (obj: any) => any;
  service?: any;
  fieldToShowOnDelete?: string;
  deleteOneObject?: any;
  setDeleteOneObject?: any;
}
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  const { LL } = useResource();
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>{LL("No_data")}</Box>
    </StyledGridOverlay>
  );
}
const MyTable: React.FC<MyTableProps> = ({
  _columns,
  _data,
  _reqList,
  _tbFilterList,
  _total,
  _totalPage,
  setReqList,
  loading,
  currentRoute,
  onDelete,
  mainObject,
  onRowClickCb,
  service,
  deleteOneObject,
  setDeleteOneObject,
  fieldToShowOnDelete,
  ...props
}) => {
  
  const [cookies, setCookies] = useCookies();
  const processDefaultDensity = (): GridDensity => {
    const density = cookies["density"];
    if (!density) return "standard";
    return density;
  };
  const [density, setDensity] = useState<GridDensity>(processDefaultDensity());

  const handleSetDensity = (density: GridDensity) => {
    setDensity(density);
    setCookies("density", density, {
      path: "/",
      expires: DateTools.getDate(Config.COOKIE_EXPIRES),
    });
  };
  const [columns, setColumns] = useState(_columns);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [total, setTotal] = React.useState(_total);
  const [_existReqList, setExistReqList] = React.useState<RequestListDTO>(
    new RequestListDTO()
  );
  const apiRef = useGridApiRef();
  const convertFromRequestSortCriteriaToSortModel = (
    obj: Array<RequestSortCriteriaDTO>
  ) => {
    if (obj === undefined) return [];
    let gridSortModel: GridSortModel = obj
      .filter((item) => item !== undefined)
      .map((item) => {
        const gridSortItem: GridSortItem = {
          field: item.field ? item.field : "",
          sort: item.asc ? "asc" : "desc",
        };
        return gridSortItem;
      });

    return gridSortModel.length > 0 ? gridSortModel : [];
  };

  const _page = _reqList.page ?? 1;
  const _onPage = _reqList.onpage ?? Number(process.env.REACT_APP_ONPAGE);
  const _arrayRequestSortCriteria = _reqList.sortcriteria
    ? _reqList.sortcriteria
    : new Array<RequestSortCriteriaDTO>();

  const defaultGridSortModel = convertFromRequestSortCriteriaToSortModel(
    _arrayRequestSortCriteria
  );
  const [paginationModel, setPaginationModel] = React.useState({
    page: _page - 1,
    pageSize: _onPage,
  });
  const setPage = (page: number) => {
    setPaginationModel({ ...paginationModel, page: page - 1 });
  }

  useEffect(() => {
    setTotal(_total);

    let p = _reqList.page ?? 1;
    p = p - 1;
    const _onPage = _reqList.onpage ?? Number(process.env.REACT_APP_ONPAGE);
    const opm = {
      page: p,
      pageSize: _onPage,
    };
    setPaginationModel(opm);

    const _filterTmps =
      _reqList.filters !== undefined ? _reqList.filters : null;
    setFilters(_filterTmps);
  }, [
    _columns,
    _data,
    _reqList,
    _tbFilterList,
    _total,
    _totalPage,
    loading,
    currentRoute,
    mainObject,
  ]);

  // =========================
  const [objectsToDelete, setObjectsToDelete] = useState<Array<any>>([]);
  const [objToDelete, setObjToDelete] = useState<any>(undefined);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const handleDeleteAll = (e: any) => {
    // logger("On Delete All", e);
    if (onDelete !== undefined) {
      if (rowSelectionModel?.length > 0) {
        const selectedRows = apiRef.current.getSelectedRows();
        const selectedRowsArray: any = [];
        selectedRows.forEach((value, key) => {
          selectedRowsArray.push(value);
        });
        // logger("On Delete selectedRowsArray", selectedRowsArray);
        setObjectsToDelete(selectedRowsArray);
      }
    }
  };
  useEffect(() => {
    // logger("useEffect rowSelectionModel", objectsToDelete);
    if (objectsToDelete.length > 0) {
      setObjToDelete(objectsToDelete[0]);
      setOpenConfirmDelete(true);
    }
  }, [objectsToDelete]);

  useEffect(() => {
    if (objectsToDelete.length > 0) {
      apiRef.current.selectRow(objectsToDelete[0].id, false, false);
      objectsToDelete.shift();
      setObjToDelete(objectsToDelete[0]);
    }

    if (objectsToDelete.length > 0) {
      setOpenConfirmDelete(true);
    }
    if (objectsToDelete.length === 0) {
      setObjToDelete(undefined);
    }
  }, [deleteAction]);

  useEffect(() => {
    if (deleteOneObject !== undefined) {
      setObjToDelete(deleteOneObject);
      setOpenConfirmDelete(true);
    }
  }, [deleteOneObject]);

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setDeleteAction(!deleteAction);
    if (onDelete !== undefined) {
      onDelete(objToDelete);
    }
    if (setDeleteOneObject !== undefined) setDeleteOneObject(undefined);
  };
  const handleCancelDelete = () => {
    setOpenConfirmDelete(false);
    if (setDeleteOneObject !== undefined) setDeleteOneObject(undefined);
    setDeleteAction(!deleteAction);
  };
  // =========================

  const [arrayRequestSortCriteria, setArrayRequestSortCriteria] =
    React.useState<Array<RequestSortCriteriaDTO>>(_arrayRequestSortCriteria);

  const [sortModel, setSortModel] =
    useState<GridSortModel>(defaultGridSortModel);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const _filterTmps = _reqList.filters !== undefined ? _reqList.filters : null;
  const [filters, setFilters] = useState<RequestFilterDTO[] | null | undefined>(
    _filterTmps
  );

  const processFilters = (tfilters: Array<RequestFilterDTO>) => {
    setFilters(tfilters);
  };

  const handleRequestList = () => {
    const req = new RequestListDTO();
    req.page = paginationModel.page + 1;
    req.onpage = paginationModel.pageSize;
    if (arrayRequestSortCriteria.length !== 0)
      req.sortcriteria = arrayRequestSortCriteria;
    else req.sortcriteria = [];

    req.filters = filters ?? [];

    setCookies("onPage", paginationModel.pageSize, {
      path: "/",
      expires: DateTools.getDate(
        Number(process.env.REACT_APP_USERCOOKIES_EXPIRE)
      ),
    });

    if (CommonTools.areObjectsEqual(_existReqList, req)) {
      return;
    }
    setExistReqList(req);

    setReqList(req);
  };

  useEffect(() => {
    handleRequestList();
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    arrayRequestSortCriteria,
    filters,
  ]);

  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      handleSorter(sortModel);
      if (sortModel.length === 0) setSortModel([]);
      else setSortModel(sortModel);
    },
    []
  );

 
  const CustomPagination = (props: any) => {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        spacing={1}
        mr={1}
      >
        <GridPagination {...props} />
        <Box>
          <PageSelect
            totalPages={_totalPage}
            setPage={setPage}
            page={paginationModel.page + 1}
          />
        </Box>
      </Stack>
    );
  };
  
  const convertToRequestSortCriteria = (item: GridSortItem) => {
    const requestSortCriteriaDTO = new RequestSortCriteriaDTO();
    requestSortCriteriaDTO.field = item.field;
    let asc: boolean = item.sort === "asc" ? true : false;
    requestSortCriteriaDTO.asc = asc;

    return requestSortCriteriaDTO;
  };

  const handleSorter = (obj: GridSortModel) => {
    let arrRequestSortCriteriaDTO = new Array<RequestSortCriteriaDTO>();
    arrRequestSortCriteriaDTO = obj.map((item) =>
      convertToRequestSortCriteria(item)
    );
    setArrayRequestSortCriteria(arrRequestSortCriteriaDTO);
  };

  const onRowClick = (params: GridRowParams, event: any, details: any) => {
    // logger("On Row click", params, event, details);
    event.stopPropagation();
    event.preventDefault();
    if (onRowClickCb === undefined)
      ComponentCommonTools.goToDetails(params.row, mainObject);
    else onRowClickCb(params.row);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ boxShadow: 2, p: 1 }}>
        <GridToolbarColumnsButton slotProps={{
            button: { color: "inherit" },
          }} />
        <GridToolbarDensitySelector slotProps={{
            button: { color: "inherit" },
          }} />
        {/* <GridToolbarExport tabIndex={3} color="inherit" /> */}
        <TableToolbar
          setFilter={processFilters}
          filters={filters}
          tbFilterList={_tbFilterList}
          // filterTmp={valueFilterTmp}
          tabIndex={4}
          setPage={setPage}
        />
        {onDelete !== undefined ? (
          <IconButton
            tabIndex={5}
            aria-label="delete"
            color="inherit"
            onClick={handleDeleteAll}
          >
            <DeleteOutlineIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        )}
      </GridToolbarContainer>
    );
  };

  const CustomColumnMenu = (props: GridColumnMenuProps) => {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuFilterItem: null,
        }}
      />
    );
  };

  const handleSetRowSelectionModel = (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => {
    setRowSelectionModel(rowSelectionModel);
  };
  return (
    <>
      <Box className="boxTable">
        <DataGrid
          apiRef={apiRef}
          sx={{
            bgcolor: "background.paper",
            boxShadow: 2,
            "--DataGrid-overlayHeight": "400px",
          }}
          rows={_data}
          columns={columns}
          paginationMode="server"
          pagination
          rowCount={total > 0 ? total : 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          sortModel={sortModel}
          slots={{
            toolbar: CustomToolbar,
            loadingOverlay: Loading,
            columnMenu: CustomColumnMenu,
            noRowsOverlay: CustomNoRowsOverlay,
            pagination: CustomPagination,
          }}
          loading={loading}
          onRowSelectionModelChange={handleSetRowSelectionModel}
          onRowClick={onRowClick}
          pageSizeOptions={[5, 10, 15, 25]}
          checkboxSelection
          autoHeight
          density={density}
          onDensityChange={handleSetDensity}
          
        />
      </Box>
      <MessagesContainerPopUpConfirmDelete
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        showField={fieldToShowOnDelete}
        _obj={objToDelete}
      />
    </>
  );
};

export default MyTable;
