import { Box, Grid, Tab, Tabs } from "@mui/material";

import { IPage } from "interfaces/page.interface";
import React from "react";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";

import { Loading } from "components/elements/loading/Loading";
import { useDetailObject } from "hooks/useDetailObject";

import { CourseDto } from "dto/course/course.dto";
import { CourseService } from "services/course/course.service";
import { useResource } from "hooks/useResource";
import { TabPanel } from "components/elements/tabs/TabPanel";
import { TabCoursePriceDetail } from "components/course/courseprice/TabCoursePriceDetail";
import { CommonTools } from "tools/utils/commontools";
import { TabCoursePriceNeedMoreInfo } from "components/course/courseprice/TabCoursePriceNeedMoreInfo";
import { TabCourseSettingsDetail } from "components/course/coursesettings/TabCoursePriceDetail";
import { NoData } from "components/elements/empty/NoData";

const service = new CourseService();
const DetailCourse: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    editUrl,
    listUrl,
    object,
    loading,
    handleChangeTab,
    tabValue,
  } = useDetailObject<CourseDto>(mainObject, currentRoute, get);
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
        anchorTab={tabValue}
      />
      <Box maxWidth="md" className="tabs">
        <Tabs variant="scrollable" value={tabValue} onChange={handleChangeTab}>
          <Tab label={LL("Course_main_Info")} value={"generalinfo"} />
          <Tab label={LL("Course_Settings")} value={"coursesettings"} />
          <Tab label={LL("Course_Price")} value={"courseprice"} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={"generalinfo"}>
        <DataDisplayBox>
          <Grid>
            <DetailGrid object={object} fields={CourseDto.getDetailFields()} />
          </Grid>
        </DataDisplayBox>
      </TabPanel>
      <TabPanel value={tabValue} index={"coursesettings"}>
        {CommonTools.processObjectField(object, ["idcoursesettings"]) ? (
          <DataDisplayBox>
            <TabCourseSettingsDetail
              idCourse={CommonTools.processObjectField(object, ["id"])}
            />
          </DataDisplayBox>
        ) : (
          <NoData />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={"courseprice"}>
        <DataDisplayBox>
          <TabCoursePriceNeedMoreInfo
            idTypeCourse={CommonTools.processObjectField(object, [
              "coursesettings",
              "idtypecourse",
            ])}>
            <TabCoursePriceDetail
              idCourse={CommonTools.processObjectField(object, ["id"])}
            />
          </TabCoursePriceNeedMoreInfo>
        </DataDisplayBox>
      </TabPanel>
    </React.Fragment>
  );
};

export { DetailCourse };
