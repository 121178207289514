import { HeaderAdd } from "components/elements/add/HeaderAdd";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useGetUrls } from "hooks/useGetUrls";

import { IPage } from "interfaces/page.interface";
import React, { useState } from "react";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { CourseDto } from "dto/course/course.dto";
import { FormCourse } from "components/course/course/FormCourse";
import { CourseService } from "services/course/course.service";

const service = new CourseService();
const AddCourse: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject ?? "", currentRoute);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (obj: CourseDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
        <FormCourse
          defaultObject={new CourseDto()}
          onSubmit={onSubmit}
          loading={loading}
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { AddCourse };
