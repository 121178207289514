import React from "react";

import Typography from "@mui/material/Typography";

import { TicketMessageDto } from "dto/support/ticketmessage.dto";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";
import { Box, Stack, Divider } from "@mui/material";

type Props = {
  obj: TicketMessageDto;
};

const TicketMessageItemAdmin: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();

  const processItem = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("id")) return <></>;
    if (!obj.id) return <></>;

    return (
      <Box
        sx={{
          bgcolor: "#c5d5e6",
          padding: 2,
          borderRadius: "16px 16px 0px 16px",
          maxWidth: "400px",
        }}
      >
        <Box>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontSize: 13 }}>{LL("Operator")}</Typography>
            <Typography sx={{ fontSize: 13 }}>
              {ComponentCommonTools.processObjectFieldOfObject(
                obj,
                "operator",
                "email"
              )}
            </Typography>
          </Stack>
        </Box>
        <Divider sx={{ my: 1, backgroundColor: "#d9dbde" }} />
        <Box>
          <Typography>
            {ComponentCommonTools.processObjectField(obj, "message")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            fontSize: "12px",
            mt: 1,
          }}
        >
          <Typography sx={{ fontSize: 12 }}>
            {ComponentCommonTools.processObjectField(obj, "date_name")}
          </Typography>
        </Box>
      </Box>
    );
  };

  return processItem();
};

export { TicketMessageItemAdmin };
