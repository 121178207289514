import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonIcon from "@mui/icons-material/Person";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import PaymentsIcon from "@mui/icons-material/Payments";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default class MenuDomDto {
  constructor(
    public identifier?: string,
    public childs?: MenuDomDto[] | boolean,
    public href?: string | boolean,
    public label?: string,
    public icon?: string,
    public permissionhash?: string,
    public isSelected?: boolean
  ) {}
  getIcon = () => {
    if (this.icon == "amg_user") return <PersonIcon sx={{ mr: 3 }} />;
    if (this.icon == "amg_static")
      return <DisplaySettingsIcon sx={{ mr: 3 }} />;
    if (this.icon == "amg_nomenclature")
      return <AppRegistrationRoundedIcon sx={{ mr: 3 }} />;
    if (this.icon == "amg_system")
      return <SettingsSuggestRoundedIcon sx={{ mr: 3 }} />;
    if (this.icon == "amg_finance") return <PaymentsIcon sx={{ mr: 3 }} />;
    if (this.icon == "amg_course")
      return <CastForEducationIcon sx={{ mr: 3 }} />;
    if (this.icon == "amg_support") return <SupportAgentIcon sx={{ mr: 3 }} />;
    return <></>;
  };
}
