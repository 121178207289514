import React from "react";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import { Box, Stack, Typography } from "@mui/material";
import { useResource } from "hooks/useResource";
import { NewMyBreadcrumbs } from "../breadcrumbs/NewMyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";
import Idto from "interfaces/idto.interface";
import { MyButton } from "components/elements/button/MyButton";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  mainObject: string;
  mainUrl: string;
  addUrl: string;
  object: Idto | null;
  goUp: (e:any) => void;
  idParent: string;
  showAddButton?: boolean;
};
const HeaderListWithChildren: React.FC<Props> = ({
  mainObject,
  mainUrl,
  addUrl,
  object,
  goUp,
  idParent,
  showAddButton = true,
}) => {
  const { LL } = useResource();
  return (
    <React.Fragment>
      <NewMyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbList(mainObject)}
        mainObject={mainObject}
      />
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          my:3
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems={"center"}
          justifyContent={"flex-start"}
          
        >
          <Typography variant="h4" >
            {LL(mainObject + "page")}{" "}
            {object && CommonTools.processObjectField(object, ["name"])}
          </Typography>
          {object && <MyButton cb={goUp}>{LL("Go up")}</MyButton>}
        </Stack>
        {showAddButton && (
          <MyNavBt
            href={addUrl}
            children={LL("bt_Add")}
            className="addButton"
            _hstate={CommonTools.prepareObjectToAddChild(mainObject, idParent)}
            _mainurl={mainUrl}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export { HeaderListWithChildren };
