
import { StaticHTTPCodePage } from 'components/general/StaticHTTPCodePage';
import { IPage } from 'interfaces/page.interface';
import React from 'react';

const NotFound:React.FC<IPage> = ({  currentRoute }) => {
  
  return <StaticHTTPCodePage code="404" label="404_notFound" />;
};

export { NotFound };
