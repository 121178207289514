import { HeaderList } from "components/elements/list/HeaderList";
import { useListAdmin } from "hooks/useListAdmin";
import { LabelDto } from "dto/static/label.dto";
import MyTable from "components/elements/tabeltools/MyTable";
import { LabelService } from "services/static/label.service";
import RequestListDTO from "dto/app/requestlist.dto";
import { useResource } from "hooks/useResource";
import React from "react";

const service = new LabelService();

interface LabelProps {
  children: any;
  currentRoute: any;
  mainObject: any;
}

const Label: React.FC<LabelProps> = ({
  children,
  currentRoute,
  mainObject,
  ...props
}) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    addUrl,
    mainUrl,
    setDeleteOneObject,
    handleDelete,
    rows,
    total,
    totalPage,
    deleteOneObject,
    reqList,
    setReqList,
    loading,
  } = useListAdmin<LabelDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun
  );
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <React.Fragment>
      <HeaderList
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={addUrl}
        showAddButton={false}
      />
      <MyTable
        _columns={LabelDto.getColumns(LL, mainObject, setDeleteOneObject)}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={LabelDto.getFilters()}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        onDelete={handleDelete}
        mainObject={mainObject}
        fieldToShowOnDelete="identifier"
        deleteOneObject={deleteOneObject}
        setDeleteOneObject={setDeleteOneObject}
      />
    </React.Fragment>
  );
};

export { Label };
