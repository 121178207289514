import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import {
  QuestionWithAnswerListTestDto,
  QuestionWithAnswerTestDto,
} from "dto/course/question.dto";

import React, { useState } from "react";
import { FormQuestionTest } from "./FormQuestionTest";
import { CommonTools } from "tools/utils/commontools";
import { useResource } from "hooks/useResource";
import { QuestionService } from "services/course/question.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { TestDto } from "dto/course/test.dto";

type Props = {
  object: QuestionWithAnswerListTestDto;
  defaultOpen?: boolean;
  allowExpand?: boolean;
  label?: string;
  handelResult: (data: QuestionWithAnswerListTestDto) => void;
  handelDelete?: (id: string,obj:TestDto) => void;
  forAdd?: boolean;
};
const service = new QuestionService();
const QuestionTestItem: React.FC<Props> = ({
  object,
  defaultOpen = false,
  allowExpand = true,
  label = "Edit",
  handelResult,
  forAdd = false,
  handelDelete,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [loading, setLoading] = useState(false);
  const { LL } = useResource();
  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    if (allowExpand) setOpen(expanded);
  };

  const onSubmit = (data: QuestionWithAnswerTestDto) => {
    setLoading(true);
    if (forAdd) {
      service.addWithAnswers(handleCallbackResult, {}, data);
    } else {
      const id = CommonTools.processObjectField(object, ["question", "id"]);
      if (!id) return;
      service.updateWithAnswers(id, handleCallbackResult, {}, data);
    }
  };
  const handleCallback = () => {
    setLoading(false);
    if (forAdd) setOpen(false);
  };

  const handleCallbackResult = (data: ResultObjectDTO) => {
    if (!data) {
      handleCallback();
      return;
    }
    if (!data.obj) {
      handleCallback();
      return;
    }

    handelResult(data.obj as QuestionWithAnswerListTestDto);
    handleCallback();
  };
  const handleDelete = (id: string) => {
    if (!id) return;
    setLoading(true);
    service.deleteAndGetTest(id, handleResultDelete, { id });
  };

  const handleResultDelete = (result: ResultObjectDTO, cbParams: any) => {
    if (!result) return;
    if(!result.obj) return;
    if (!cbParams) return;
    if (!cbParams.id) return;
    if (!handelDelete) return;
    handelDelete(cbParams.id,result.obj as TestDto);
    handleCallback();
  };

  if (!object) return null;
  if (!object.question) return null;
  if (!object.answers) return null;
  return (
    <Accordion expanded={open} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="question"
        id="question-header"
      >
        {open ? (
          <Typography sx={{ width: "66%", flexShrink: 0 }}>
            {LL(label)}
          </Typography>
        ) : (
          <Typography sx={{ width: "66%", flexShrink: 0 }}>
            {CommonTools.processObjectField(object, ["question", "name"])}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularLoading />
          </Box>
        ) : (
          <FormQuestionTest
            question={object.question}
            answers={object.answers}
            onSubmit={onSubmit}
            onDelete={handleDelete}
            forAdd={forAdd}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export { QuestionTestItem };
