import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { AddressDto } from "dto/nomenclature/address.dto";
import MyTextField from "components/elements/form/mytextfield";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { AutocompleteLocality } from "components/static/locality/AutocompleteLocality";

type Props = {
  defaultObject: AddressDto;
  onSubmit: (data: AddressDto) => void;
  loading: boolean;
};
const FormAddress: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<AddressDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "iduser",
      "street",
      "streetnumber",
      "zipcode",
      "idlocality",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="street"
            name="street"
            variant="outlined"
            color="primary"
            _label={LL("Street")}
            size="small"
            value={obj.street}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="streetnumber"
            name="streetnumber"
            variant="outlined"
            color="primary"
            _label={LL("streetnumber")}
            size="small"
            value={obj.streetnumber}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="zipcode"
            name="zipcode"
            variant="outlined"
            color="primary"
            _label={LL("zipcode")}
            size="small"
            value={obj.zipcode}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        <Box mt={3}>
          <AutocompleteLocality
            setObjField={setObjField}
            value={obj.idlocality}
            defaultObject={obj.locality}
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormAddress };
