import React, { useState } from "react";
import { FormUserSettings } from "./FormUserSettings";
import { UserSettingsTabProps } from "./UserSettingsTab";
import { UserSettingsDto } from "dto/user/usersettings.dto";
import {UserSettingsService} from "services/user/usersettings.service";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CommonTools } from "tools/utils/commontools";

const service = new UserSettingsService();
const UserSettingsTabAdd: React.FC<UserSettingsTabProps> = ({
  idUser,
  mainObject,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = (obj: UserSettingsDto) => {
    if(!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        idRoute: idUser,
        cb: CommonTools.processLoadingCallback(setLoading),
        needUpdate: true,
      },
      obj
    );
  };
  return (
    <FormUserSettings
      defaultObject={new UserSettingsDto(idUser)}
      onSubmit={onSubmit}
      forAdd
      loading={loading}
    />
  );
};

export { UserSettingsTabAdd };
