import React from "react";

import { Link, LinkProps } from "@mui/material";

// import { v4 as uuidv4 } from "uuid";
// import MD5 from "crypto-js/md5";
import { useEffect, useState } from "react";

import { RouteTools } from "tools/utils/routetools";
import { useResource } from "hooks/useResource";

type Props = LinkProps & {
  [key: string]: any;
  children: React.ReactNode;
  href: string;
  _hstate?: any;
  _mainurl?: string;
};
const MyNavBt: React.FC<Props> = ({
  children,
  href,
  _hstate,
  _mainurl,
  ...props
}) => {
  

  const [hstate, setHstate] = useState({});
  const [mainurl, setMainUrl] = useState("");

  useEffect(() => {
    const h = _hstate != undefined ? _hstate : {};
    setHstate(h);
  }, [_hstate]);

  useEffect(() => {
    const m = _mainurl != undefined ? _mainurl : href;
    setMainUrl(m);
  }, [_mainurl]);

  const handleClick = (e:any) => {
    e.stopPropagation();
    e.preventDefault();

    const h:any = hstate ? hstate : {};
    h._mainurl = mainurl;
    let anchor = "";
    if (h.hasOwnProperty("anchor") && h.anchor) anchor = h.anchor;

    RouteTools.setHistory(href, h, anchor);
  };

  return (
    <Link href={href} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

export { MyNavBt };
