import React from "react";
import { TicketMessageDto } from "dto/support/ticketmessage.dto";
import { Box, List, ListItem } from "@mui/material";
import { TicketMessageItem } from "./TicketMessageItem";
import { Types } from "tools/types/types";
import { SkeletonTicketMessages } from "components/elements/skeleton/SkeletonTicket";


type Props = {
    objects: TicketMessageDto[] |  null;
    loading: boolean;
};
const NewTicketMessageList: React.FC<Props> = ({ objects, loading }) => {
  


  const processList = () => {
    if (loading) return <SkeletonTicketMessages lines={1} />;
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <List>
        {objects.map((item: TicketMessageDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };

  const justifyContentMap = {
    [Types.TYPE_SENDER_ADMIN]: "flex-end",
    [Types.TYPE_SENDER_TEACHER]: "flex-start",
    [Types.TYPE_SENDER_STUDENT]: "flex-start",
  };

  const processItem = (obj: TicketMessageDto, index: number) => {
    if (!obj) return null;
    const justifyContent =
      obj.typesender !== undefined
        ? justifyContentMap[obj.typesender]
        : "center";

    return (
      <ListItem
        key={index}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: justifyContent,
          alignItems: "center",
          pb: 0,
        }}
      >
        <TicketMessageItem obj={obj} />
      </ListItem>
    );
  };

  const processComponent = () => {
    return <Box>{processList()}</Box>;
  };

  return processComponent();
};

export { NewTicketMessageList };
