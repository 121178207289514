import React, { useState } from "react";

import { CourseSettingsService } from "services/course/coursesettings.service";
import { CommonTools } from "tools/utils/commontools";
import { CourseSettingsDto } from "dto/course/coursesettings.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { FormCourseSettings } from "./FormCourseSettings";
import { TabCourseSettingsProps } from "./TabCourseSettings";
const service = new CourseSettingsService();

const TabCourseSettingsAdd: React.FC<TabCourseSettingsProps> = ({
  idCourse,
  mainObject,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = (obj: CourseSettingsDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        idRoute: idCourse,
        cb: CommonTools.processLoadingCallback(setLoading),
        anchor: "coursesettings",
        needUpdate: true,
      },
      obj
    );
  };
  return (
    <FormCourseSettings
      defaultObject={new CourseSettingsDto(idCourse)}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export { TabCourseSettingsAdd };
