import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {LangRepository} from "repositories/static/lang.repository";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class LangService
  extends GeneralService<LangRepository>
  implements IService
{
  constructor() {
    super(new LangRepository(), [{ key: "status", type: "status" }], "name");
  }

  async getServerLangsHash(cb?: any, cbparameters?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.getServerLangsHash(this.handleGet, cbparameters);
  }
  
  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = 'name';
    defaultRequestList.sortcriteria = []
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PostLanguageDto } from "dto/language/postlanguage.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import LangRepository from "repositories/lang/lang.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";
// import ResultListDTO from "dto/app/resultlist.dto";
// export default class LangService extends GeneralService implements IService {
//   langRepository: LangRepository = new LangRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(id: string, cb?: any, cbparameters?: any, data?: PostLanguageDto): Promise<any> {

//     const updateData: PostLanguageDto = {
//       name: data ? data.name : '',
//       cod2: data ? data.cod2 : '',
//       cod3: data ? data.cod3 : '',
//       status: data ? data.status : Status.INACTIVE,
//       ordercriteria: data ? data.ordercriteria : 0,
//       hmtlcode: data ? data.hmtlcode : ''
//     }

//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.langRepository.update(id, this.handleUpdate, updateData, cbparameters);
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.langRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGetServerLangsHash(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async getServerLangsHash(cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.langRepository.getServerLangsHash(
//       this.handleGetServerLangsHash,
//       cbparameters
//     );
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: 'status', type: 'status' },
//   ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.langRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {

//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

// this.parseResultPopulate(result, rez, [
// { key: 'status', type: 'status' },

// ]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//         return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.langRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(
//     cb?: any,
//     cbparameters?: any,
//     data?: PostLanguageDto
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.langRepository.add(this.handleGetList, cbparameters, data);
//   }

  // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
  //   onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
  //   if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
  //   var defaultRequestList = new RequestListDTO();
  //   defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
  //   defaultRequestList.onpage = onPage;
  //   var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
  //   defaultRequestSortCriteria.asc = true;
  //   defaultRequestSortCriteria.field = 'name';
  //   defaultRequestList.sortcriteria = []
  //   defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
  //   return defaultRequestList;
  // }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "name";

//       obj.sortcriteria = [sobj];
//     }

//     return obj;
//   }
// }
