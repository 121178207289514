import { Box } from "@mui/material";
import MyTextField from "components/elements/form/mytextfield";

import { StatusSelect } from "components/elements/select/StatusSelect";
import { TestTypeSelect } from "components/elements/select/TestTypeSelect";

import { MyButton } from "components/elements/button/MyButton";
import { SelectLesson } from "components/special/lesson/SelectLesson";
import { TestDto } from "dto/course/test.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import { Types } from "tools/types/types";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: TestDto;
  onSubmit: (data: TestDto) => void;
  loading: boolean;
  fromLesson?: boolean;
};
const FormTest: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  fromLesson = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<TestDto>(
    defaultObject,
    RequiredValidator.getValidators(["name", "idcourse"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("Name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="mintestscore"
            name="mintestscore"
            variant="outlined"
            color="primary"
            _label={LL("mintestscore")}
            size="small"
            value={obj.mintestscore}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        {!fromLesson && (
          <Box mt={3}>
            <TestTypeSelect
              value={obj.type}
              field="type"
              setObjectField={setObjField}
            />
          </Box>
        )}
        {obj.type === Types.TYPE_TEST_LESSON && !fromLesson && (
          <Box mt={3}>
            <SelectLesson
              value={obj.idlesson ?? undefined}
              setObjectField={setObjField}
              idCourse={obj.idcourse ?? ""}
            />
          </Box>
        )}
        <Box mt={3}>
          <MyButton
            tabIndex={6}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormTest };
