import React from "react";

import { IPage } from "interfaces/page.interface";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";

import { FormFilterDictionary } from "components/course/filterdictionary/FormFilterDictionary";
import { Loading } from "components/elements/loading/Loading";
import { FilterDictionaryDto } from "dto/course/filterdictionary.dto";
import { useEditObject } from "hooks/useEditObject";
import { useResource } from "hooks/useResource";
import { FilterDictionaryService } from "services/course/filterdictionary.service";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { LanguageSelect } from "components/static/language/newLanguageSelect";

const service = new FilterDictionaryService();
const EditFilterDictionary: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };
  const { getCookieStayOnPage } = useResource();
  const typeCallback = getCookieStayOnPage(mainObject || "");
  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    idLanguage,
    setIdLanguage,
    getByLanguage,
  } = useEditObject<FilterDictionaryDto>(mainObject, currentRoute, get, true);

  const onSubmit = (obj: FilterDictionaryDto) => {
    if (!obj) return;
    setLoading(true);
    const callBackFunction = typeCallback
      ? getByLanguage
      : ComponentCommonTools.handleSuccessAddAndEdit;
    const callBackParams = typeCallback
      ? {}
      : {
          mainObject,
          cb: CommonTools.processLoadingCallback(setLoading),
          idLanguage,
        };

    obj.idlanguage = idLanguage.toString();
    service.update(id, callBackFunction, callBackParams, obj);
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        idLanguage={idLanguage}
      />
      <DataDisplayBox>
        <Box>
          <LanguageSelect value={idLanguage} setValue={setIdLanguage} />
        </Box>

        <Box mt={3}>
          <FormFilterDictionary
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { EditFilterDictionary };
