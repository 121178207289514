import React from "react";

import { useAuth } from "hooks/useAuth";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { IconButton, Typography, Stack } from "@mui/material";
import { RouteTools } from "tools/utils/routetools";
import { MyButton } from "components/elements/button/MyButton";
import { CommonTools } from "tools/utils/commontools";

const UserTopInfo: React.FC = () => {
  const { user } = useAuth();

  const { signOut } = useAuth();

  const handleLogOut = () => {
    signOut();
  };

  const handleNav = () => {
    RouteTools.setHistory("/profile", {});
  };

  return !user ? null : (
    <Stack
      className="userInfo"
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={1}
    >
      <MyButton
        cb={handleNav}
        variant="text"
        sx={{
          color: "#000",
          textTransform: "none",
        }}
      >
        <Typography>
          {CommonTools.processObjectField(user, ["email"])}
        </Typography>
      </MyButton>
      <IconButton
        aria-label="delete"
        onClick={handleLogOut}
        sx={{ color: "primary" }}
      >
        <ExitToAppIcon />
      </IconButton>
    </Stack>
  );
};

export { UserTopInfo };
