import { Box, InputAdornment } from "@mui/material";

import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { LockOpen, MailOutline } from "@mui/icons-material";
import SignInDto from "dto/auth/signin.dto";
import { useState } from "react";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { logger } from "tools/utils/logger";

type Props = {
  defaultObject: SignInDto;
  onSubmit: (data: SignInDto, remember: boolean) => void;
  loading: boolean;
};
const FormLogin: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<SignInDto>(
    defaultObject,
    RequiredValidator.getValidators(["identifier", "password"])
  );
  const [remember, setRemember] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj, remember);
  };
  
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box pb={3}>
          <MyTextField
            fullWidth
            id="identifier"
            name="identifier"
            variant="outlined"
            color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline />
                </InputAdornment>
              ),
            }}
            _label={LL("Identifier Login")}
            value={obj.identifier}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box pb={1}>
          <MyTextField
            fullWidth
            type="password"
            id="password"
            name="password"
            variant="outlined"
            color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpen />
                </InputAdornment>
              ),
            }}
            _label={LL("Password")}
            value={obj.password}
            setObj={setObjField}
            // _vresults={
            //   vresults && vresults.password ? vresults.password : false
            // }
            _vresults={vresults}
          />
        </Box>
        <Box>
          <MyCheckboxField
            id="remember"
            name="remember"
            label={LL("remember")}
            setObjectField={(field: string, value: any) => setRemember(value)}
            checked={remember}
            color="primary"
            size="small"
            field="remember"
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={4}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt Login")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormLogin };
