import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class TicketCategoryDto implements Idto {
  id?: string;
  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idticketcategory?: string;

  constructor(
    idlanguage?: string,
    idparent?: string,
    
    ordercriteria?: number,
    name?: string,
    status?: number
  ) {
    this.status = status || Status.ACTIVE;
    this.ordercriteria = ordercriteria || 0;
    this.name = name || "";
    this.idlanguage = idlanguage || "";
    this.idparent = idparent || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "children"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}

export class PostTicketCategoryDto implements Idto {
  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idticketcategory?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}
