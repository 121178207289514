import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class NomenclatureDto implements Idto {
  id?: string;

  ordercriteria?: number;
  status?: number;
  idlanguage?: number | string;
  name?: string;

  constructor(
    idlanguage?: string,
    id?: string,
    ordercriteria?: number,
    status?: number,
    name?: string
  ) {
    this.id = id || "";

    this.ordercriteria = ordercriteria || 0;
    this.status = status || Status.ACTIVE;
    this.idlanguage = idlanguage || "";
    this.name = name || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "gallery", "attachment", "video"],
        ["detail", "attachment", "video"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(
    data: Array<NomenclatureDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["name"])
        )
      );
    });
    return result;
  }
}
