import { TeacherDto } from "dto/user/teacher.dto";
import Idto from "interfaces/idto.interface";
import { PricePolicyDto } from "./pricepolicy.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";

export class PostPricePolicyLinkTeacherDto implements Idto {
  id?: string;
  idteacher?: string;
  idpricepolicy?: string;
  teacher?: TeacherDto;
  pricepolicy?: PricePolicyDto;

  constructor(
    id?: string,
    idteacher?: string,
    idpricepolicy?: string,
  ) {

    this.id = id || "";
    this.idteacher = idteacher || "";
    this.idpricepolicy = idpricepolicy || "";
  }
}

export class PricePolicyLinkTeacherDto implements Idto {
  id?: string;
  idteacher?: string;
  idpricepolicy?: string;
  teacher?: TeacherDto;
  constructor(
    id?: string,
    idteacher?: string,
    idpricepolicy?: string,
  ) {

    this.id = id || "";
    this.idteacher = idteacher || "";
    this.idpricepolicy = idpricepolicy || "";
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Email_teacher",
        value: ["teacher", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PricePolicy",
        value: ["pricepolicy", "name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "teacher", "email"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "pricepolicy",
        "name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}
