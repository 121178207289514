import { Box } from "@mui/material";

import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { BlogDto } from "dto/static/blog.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import RequiredValidator from "validators/required.validator";
import { BlogTypeSelect } from "components/elements/select/BlogTypeSelect";
import { BlogCategorySelect } from "components/elements/select/BlogCategorySelect";

import { LanguageSelect } from "components/static/language/newLanguageSelect";

type Props = {
  defaultObject: BlogDto;
  onSubmit: (data: BlogDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormBlog: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults, setObjectLanguage] =
    useForm<BlogDto>(defaultObject, RequiredValidator.getValidators(["name"]));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <MyTextField
          tabIndex={0}
          fullWidth
          id="name"
          name="name"
          variant="outlined"
          color="primary"
          _label={LL("name")}
          size="small"
          value={obj.name}
          setObj={setObjField}
          _vresults={vresults}
        />

        <Box sx={{ mt: 3 }}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>

        <Box sx={{ mt: 3 }}>
          <BlogTypeSelect
            value={obj.type}
            field="type"
            setObjectField={setObjField}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <BlogCategorySelect
            value={obj.idblogcategory}
            setObjectField={setObjField}
          />
        </Box>

        <Box sx={{ mt: 3 }}>
          <MyTextField
            tabIndex={5}
            fullWidth
            id="title"
            name="title"
            variant="outlined"
            color="primary"
            _label={LL("title")}
            size="small"
            value={obj.title}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Box sx={{ mt: 3 }}>
          <MyTextField
            tabIndex={7}
            fullWidth
            id="descriptionmeta"
            name="descriptionmeta"
            variant="outlined"
            color="primary"
            _label={LL("descriptionmeta")}
            size="small"
            value={obj.descriptionmeta}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <MyTextField
            tabIndex={8}
            fullWidth
            id="keymeta"
            name="keymeta"
            variant="outlined"
            color="primary"
            _label={LL("keymeta")}
            size="small"
            value={obj.keymeta}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <MyTextField
            tabIndex={9}
            fullWidth
            id="url"
            name="url"
            variant="outlined"
            color="primary"
            _label={LL("url")}
            size="small"
            value={obj.url}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <MyCKEditor
            data={obj.content ? obj.content : ""}
            setObj={setObjField}
            name="content"
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect
              value={obj.idlanguage ?? ""}
              setValue={setObjectLanguage}
            />
          </Box>
        )}
        <MyButton
          tabIndex={6}
          disabled={disabled || loading}
          color="primary"
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          sx={{ mt: 3 }}
          children={LL("Bt_Submit")}
        />
      </form>
    </Box>
  );
};

export { FormBlog };
