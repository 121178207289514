import React from "react";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import { Box, Typography } from "@mui/material";
import { useResource } from "hooks/useResource";
import { NewMyBreadcrumbs } from "../breadcrumbs/NewMyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";


type Props = {
  mainObject: string;
  mainUrl: string;
  addUrl: string;
  showAddButton?: boolean;
};
const HeaderList: React.FC<Props> = ({
  mainObject,
  mainUrl,
  addUrl,
  showAddButton = true,
}) => {
  const { LL } = useResource();
  return (
    <React.Fragment>
      <NewMyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbList(mainObject)}
        mainObject={mainObject}
      />
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 3 }}>
          {LL(mainObject + "page")}
        </Typography>

        {showAddButton && (
          <MyNavBt
            href={addUrl}
            children={LL("bt_Add")}
            className="addButton"
            _hstate={{}}
            _mainurl={mainUrl}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export { HeaderList };
