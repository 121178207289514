import RequestListDTO from "dto/app/requestlist.dto";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Types } from "tools/types/types";
import DateTools from "./date.tools";
import { jwtDecode } from "jwt-decode";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import Idto from "interfaces/idto.interface";
import { PrepareObjectToUpdateDto } from "dto/app/prepareobjecttoupdate.dto";
import { DetailObject } from "interfaces/detailobject.interface";

export class CommonTools {
  public static generateThirdLevelSpecial = (
    input: any,
    str: string,
    idCourse?: string,
    id?: string
  ) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += idCourse !== undefined ? idCourse : "-";
    url += `/lesson/`;
    url += id !== undefined ? id : "-";
    url += `/${str}`;

    return url;
  };

  public static generateSpecialUrl = (input: any, str: string, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += `/${str}`;

    return url;
  };

  static extractFiles = (
    obj: any
  ): { [key: string]: File | { [key: string]: File } } => {
    const result: { [key: string]: File | { [key: string]: File } } = {};

    // Check if the value is of type File
    const isFile = (value: any): boolean => value instanceof File;

    // Recursively check and extract files from the object
    const extractKeys = (object: any, parentKey?: string): void => {
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const currentKey = parentKey ? `${parentKey}.${key}` : key;
          if (isFile(object[key])) {
            if (parentKey) {
              if (!result[parentKey]) {
                result[parentKey] = {};
              }
              (result[parentKey] as { [key: string]: File })[key] = object[key];
            } else {
              result[key] = object[key];
            }
          } else if (typeof object[key] === "object" && object[key] !== null) {
            extractKeys(object[key], currentKey);
          }
        }
      }
    };

    extractKeys(obj);
    return result;
  };

  static parseDetailFields = (
    fields: string[],
    arr: Array<DetailObject>
  ): Array<DetailObject> => {
    const result: Array<DetailObject> = [];
    for (const field of fields) {
      const obj = arr.find(
        (x) => x.label.toLowerCase() === field.toLowerCase()
      );
      if (obj) result.push(obj);
    }
    return result;
  };

  public static getAnchor = (currentRoute: any, defaultValue: string) => {
    if (!currentRoute) return defaultValue;
    if (!currentRoute.hasOwnProperty("anchor")) return defaultValue;
    if (!currentRoute.anchor) return defaultValue;
    return currentRoute.anchor;
  };

  public static generateParentMainUrlSpecialOfSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;
    const url = "/" + path[0] + "/" + path[1] + "/" + path[2];

    return url;
  };

  public static generateAddUrlSpecialOfSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url =
      "/" +
      path[0] +
      "/" +
      path[1] +
      "/" +
      path[2] +
      "/" +
      path[3] +
      "/" +
      path[4] +
      "/add";

    return url;
  };

  public static processObjectField(
    obj: any,
    fields: string[],
    type?: number,
    numberAfterPoint?: number
  ): string {
    if (!obj) return "";
    if (numberAfterPoint === undefined || numberAfterPoint === null)
      numberAfterPoint = 2;
    if (!type) type = Types.FIELD_TYPE_STRING;

    let currentObj = obj;

    for (const field of fields) {
      if (
        !currentObj ||
        typeof currentObj !== "object" ||
        !currentObj.hasOwnProperty(field)
      ) {
        return "";
      }
      currentObj = currentObj[field];
    }

    if (typeof currentObj === "object" && currentObj !== null) return "";

    if (currentObj == null || currentObj == undefined) return "";
    if (type == Types.FIELD_TYPE_NUMBER && typeof currentObj === "number") {
      return currentObj.toFixed(numberAfterPoint);
    }

    return currentObj;
  }

  public static processObject(obj: any, fields: string[]): object {
    if (!obj) return {};
    let currentObj = obj;

    for (const field of fields) {
      if (
        !currentObj ||
        typeof currentObj !== "object" ||
        !currentObj.hasOwnProperty(field)
      ) {
        return {};
      }
      currentObj = currentObj[field];
    }
    return currentObj;
  }

  public static processListLoadObjects = (
    obj: any,
    setData: any,
    setRows: any,
    setTotal: any,
    setTotalPage: any,
    setResponseParams: any
  ) => {
    setData(obj);

    if (!obj) return;

    if (!obj.err) {
      const objects = obj.objects ? obj.objects : [{}];
      const total = obj.total !== undefined ? obj.total : -1;
      const totalPage = obj.totalpages !== undefined ? obj.totalpages : -1;
      const reqInfo = obj.requestinfo ? obj.requestinfo : {};

      setRows(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };

  public static checkIsLoading = (
    reqList: any,
    responseParams: any,
    data: any,
    total: any,
    totalPage: any,
    rows: any
  ) => {
    if (!data) return true;
    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (rows === undefined) return true;
    if (Object.keys(responseParams).length === 0) return true;
    return CommonTools.checkRequestAndParams(reqList, responseParams);
  };

  static processIdFromPaths = (
    currentRoute: ICurrentRoute | null,
    index: number
  ): string => {
    // // logger("processIdFromPaths", currentRoute, index);
    if (!currentRoute) return "";
    if (!currentRoute._paths) return "";
    if (!currentRoute._paths.length) return "";
    if (currentRoute._paths.length <= index) return "";
    if (!currentRoute._paths[index]) return "";
    return currentRoute._paths[index];
  };

  public static generateDetailUrlSpecialThirdLevel = (
    id?: string,
    parentType?: string,
    parentId?: string,
    specialtype?: string,
    firstLevelParentType?: string,
    firstLevelParentId?: string
  ) => {
    return (
      "/" +
      firstLevelParentType +
      "/" +
      firstLevelParentId +
      "/" +
      parentType +
      "/" +
      parentId +
      "/" +
      specialtype +
      "/" +
      id
    );
  };

  public static generateEditUrlSpecialThirdLevel = (
    id?: string,
    parentType?: string,
    parentId?: string,
    specialtype?: string,
    firstLevelParentType?: string,
    firstLevelParentId?: string
  ) => {
    return (
      "/" +
      firstLevelParentType +
      "/" +
      firstLevelParentId +
      "/" +
      parentType +
      "/" +
      parentId +
      "/" +
      specialtype +
      "/" +
      "edit" +
      "/" +
      id
    );
  };

  static processLoadingCallback = (setLoading: (loading: boolean) => void) => {
    if (!setLoading) return;
    setLoading(false);
  };
  public static generateMainUrlSpecialOfSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;
    const url =
      "/" +
      path[0] +
      "/" +
      path[1] +
      "/" +
      path[2] +
      "/" +
      path[3] +
      "/" +
      path[4];

    return url;
  };
  static processLanguageFromHistoryState = (
    currentRoute: ICurrentRoute | null
  ): string => {
    if (!currentRoute) return "";
    if (!currentRoute.historystate) return "";
    return CommonTools.processObjectField(currentRoute, [
      "historystate",
      "idLanguage",
    ]);
  };

  static processObjectFromHistoryState = <T extends Idto>(
    currentRoute: ICurrentRoute | null
  ): T | null => {
    if (!currentRoute) return null;
    if (!currentRoute.historystate) return null;
    if (!currentRoute.historystate.obj) return null;
    const obj = currentRoute.historystate.obj as T;
    if (!CommonTools.processObjectField(obj, ["id"])) return null;
    return obj;
  };

  static processNeedUpdateHistoryState = (
    currentRoute: ICurrentRoute | null
  ): boolean => {
    if (!currentRoute) return false;
    if (!currentRoute.historystate) return false;
    if (!currentRoute.historystate.hasOwnProperty("needUpdate")) return false;
    if (!currentRoute.historystate.needUpdate) return false;
    return true;
  };

  public static checkRequestAndParams = (req: any, res: any) => {
    if (req.page?.toString() !== res.page?.toString()) return true;
    if (req.onpage?.toString() !== res.onpage?.toString()) return true;
    // if (!CommonTools.arraysAreEqual(req.sortcriteria, res.sortcriteria))
    //   return true;
    // if (!CommonTools.arraysAreEqual(req.filters, res.filters)) return true;
    return false;
  };

  public static prepareLabeldentifier = (str: string): string => {
    str = str ?? "";

    str = str.replace(/[^\w\d]/gi, "-");
    str = str.replace(/(-+)/gi, "-");

    str = str.replace(/^[-]/gi, "");
    str = str.replace(/[-]$/gi, "");

    str = str.toString().toLowerCase();

    str = str ?? "-";

    return str;
  };

  public static arraysAreEqual = (arr1: any, arr2: any) => {
    if (arr1 == undefined && arr2 == undefined) return true;
    if (arr1 == undefined || arr2 == undefined) return false;

    if (arr1.length !== arr2.length) return false;

    const sortedArr1 = arr1.map(JSON.stringify).sort();
    const sortedArr2 = arr2.map(JSON.stringify).sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }

    return true;
  };

  public static atLeastOneFieldDefined = (obj: any) => {
    for (const key in obj) {
      if (obj[key] !== undefined) {
        return true;
      }
    }
    return false;
  };

  public static generateMainUrlSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url = "/" + path[0] + "/" + path[1] + "/" + path[2];

    return url;
  };

  public static generateParentMainUrlSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url = "/" + path[0];

    return url;
  };

  public static generateEditUrlSpecial = (
    id?: string,
    parentType?: string,
    parentId?: string,
    specialtype?: string
  ) => {
    return (
      "/" +
      parentType +
      "/" +
      parentId +
      "/" +
      specialtype +
      "/" +
      "edit" +
      "/" +
      id
    );
  };

  public static generateDetailUrlSpecial = (
    id?: string,
    parentType?: string,
    parentId?: string,
    specialtype?: string
  ) => {
    return "/" + parentType + "/" + parentId + "/" + specialtype + "/" + id;
  };

  public static generateAddUrlSpecial = (currentRoute: any) => {
    const path = currentRoute._paths;

    const url = "/" + path[0] + "/" + path[1] + "/" + path[2] + "/add";

    return url;
  };

  public static generateMainUrl = (input: any) => {
    if (typeof input !== "string") return "";
    const url = "/" + input.toLowerCase();
    return url;
  };

  public static generateListUrl = (mainObject: any, currentRoute: any) => {
    if (typeof currentRoute != "object") {
      return CommonTools.generateMainUrl(mainObject);
    }

    // if (currentRoute.url)
    // {
    //   return currentRoute.url;
    // }

    if (
      currentRoute.historystate == undefined ||
      typeof currentRoute.historystate != "object"
    ) {
      return CommonTools.generateMainUrl(mainObject);
    }
    if (currentRoute.historystate.listUrl == undefined) {
      return CommonTools.generateMainUrl(mainObject);
    }

    return currentRoute.historystate.listUrl;
  };

  public static generateDetailUrl = (input: any, id: string | undefined) => {
    if (typeof input !== "string" && id === undefined) return "";

    const mainUrl = this.generateMainUrl(input);
    const url = mainUrl + "/" + id;
    return url;
  };

  public static generateAddUrl = (input: any) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    const url = mainUrl + "/add";
    return url;
  };

  public static userDetailUrl = (obj: any) => {
    if (!obj) return "";
    const url = "/user/" + obj.iduser;
    return url;
  };

  public static generateEditUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl + "/edit";
    if (id !== undefined) url = url + "/" + id;
    return url;
  };

  public static generateGalleryUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += "/gallery";

    return url;
  };

  public static generateAttachmentUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += "/attachment";

    return url;
  };

  public static generateVideoUrl = (input: any, id?: string) => {
    if (typeof input !== "string") return "";

    const mainUrl = this.generateMainUrl(input);
    let url = mainUrl;
    url += "/";
    url += id !== undefined ? id : "-";
    url += "/video";

    return url;
  };

  public static prepareObjectForUpdate = (
    obj: any,
    mainObj: string,
    anchor?: string,
    idLanguage?: string
  ): PrepareObjectToUpdateDto | null => {
    if (!obj) return null;
    if (!mainObj) return null;

    const mainUrl = this.generateMainUrl(mainObj);

    return new PrepareObjectToUpdateDto(
      CommonTools.processObjectField(obj, ["id"]),
      obj,
      mainUrl,
      anchor,
      idLanguage
    );
  };

  public static generateRandomString(length: number) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  public static prepareObjectForAddChild = (
    obj: any,
    mainObj: any,
    field: any
  ) => {
    if (!obj) return;
    if (!field) return;
    if (!mainObj) return;

    const mainUrl = this.generateMainUrl(mainObj);
    const object: any = {
      id: obj[field],
      _mainurl: mainUrl,
    };
    return object;
  };
  public static goToParent = (
    obj: any,
    setObj: any,
    setIdParent: any,
    setId: any,
    defaultValueForObj?: any
  ) => {
    // logger("goToParent", obj);
    if (obj) {
      if (obj.idparent !== undefined) {
        setIdParent(obj.idparent);
        setObj(defaultValueForObj);
        if (obj.idparent === "") {
          setId("");
        } else setId(obj.idparent);
      }
    }
  };
  public static prepareObjectToAddChild = (mainObj: any, idParent: string) => {
    if (!mainObj) return;
    if (idParent === "") return {};
    const mainUrl = CommonTools.generateMainUrl(mainObj);
    const object: any = {
      idParent: idParent,
      _mainurl: mainUrl,
    };
    return object;
  };

  public static handleCheckValue = (
    obj: any,
    setObj: any,
    field: string,
    value: any
  ) => {
    if (!obj) return;
    if (!field) return;
    if (!setObj) return;
    if (value === undefined) return;

    let t = JSON.parse(JSON.stringify(obj));

    t[field] = value;
    setObj(t);
  };

  public static addToRequestFilter = (
    obj: RequestListDTO,
    field: string,
    value: any
  ): RequestListDTO => {
    const filters: RequestFilterDTO[] = [];

    obj.filters = obj.filters ?? [];

    for (var i in obj.filters) {
      if (obj.filters[i].field == field) continue;
      filters.push(obj.filters[i]);
    }

    const f = new RequestFilterDTO();
    f.field = field;
    f.values = Array.isArray(value) ? value : [value];

    filters.push(f);

    obj.filters = filters;

    return obj;
  };
  public static processObjectFieldArray(obj: any, fields: string[]): any[] {
    if (!obj) return [];
    let currentObj = obj;

    for (const field of fields) {
      if (
        !currentObj ||
        typeof currentObj !== "object" ||
        !currentObj.hasOwnProperty(field)
      ) {
        return [];
      }
      currentObj = currentObj[field];
    }
    return currentObj;
  }

  public static areObjectsEqual(objA: any, objB: any) {
    // Check if both values are objects
    if (typeof objA !== "object" || typeof objB !== "object") {
      return objA == objB; // For non-objects, perform a simple comparison
    }

    // Get the keys of both objects
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    // Check if the number of keys is the same
    if (keysA.length != keysB.length) {
      return false;
    }

    // Check if the values of each key are equal (recursively)
    for (const key of keysA) {
      if (!CommonTools.areObjectsEqual(objA[key], objB[key])) {
        return false;
      }
    }

    // If all checks passed, the objects are equal
    return true;
  }
}

export const isTokenExpired = (token: string) => {
  if (!token) return true;
  const payload = jwtDecode(token);
  if (!payload) return true;
  const exp = payload.exp ?? 0;
  const now = DateTools.getTimeStamp();
  // // logger("getAxios", exp, now, exp < now);
  return exp < now;
};
