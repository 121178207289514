import { useResource } from "hooks/useResource";
import React from "react";

import { Stack, Typography } from "@mui/material";
import { DataDisplayBox } from "../display/DataDisplayBox";

type Props = {
  identifier?: string;
};
const NoData: React.FC<Props> = ({ identifier = "No_data" }) => {
  const { LL } = useResource();
  return (
    <DataDisplayBox>
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Typography>{LL(identifier)}</Typography>
      </Stack>
    </DataDisplayBox>
  );
};

export { NoData };
