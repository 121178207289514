import { Grid, Skeleton } from "@mui/material";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { CourseSettingsDto } from "dto/course/coursesettings.dto";
import useObject from "hooks/useObject";
import React from "react";

import { CourseSettingsService } from "services/course/coursesettings.service";

type Props = {
  idCourse: string;
};
const service = new CourseSettingsService();
const TabCourseSettingsDetail: React.FC<Props> = ({ idCourse }) => {
  const get = (id: string, cb?: any, cbParams?: any) => {
    if (!id) return;
    service.getCourseSettingsByCourseId(id, cb, cbParams);
  };
  const [loading, object] = useObject<CourseSettingsDto>(get, idCourse, [
    idCourse,
  ]);

  if (loading) return <Skeleton />;
  if (!object) return null;
  return (
    <Grid>
      <DetailGrid
        object={object}
        fields={CourseSettingsDto.getDetailFields()}
      />
    </Grid>
  );
};

export { TabCourseSettingsDetail };
