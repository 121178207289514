import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import Idto from "interfaces/idto.interface";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { useGetUrls } from "./useGetUrls";
import { CommonTools } from "tools/utils/commontools";
import { Config } from "tools/utils/config";
import { RouteTools } from "tools/utils/routetools";
import { useResource } from "./useResource";


type HasId = Idto & {
  id?: string | number;
};
type UseDetailObject = <T extends HasId>(
  mainObject: string | undefined,
  currentRoute: ICurrentRoute | null,
  getObjectFun: (
    id: string,
    cb: any,
    cbParams: any,
    idLanguage?: string
  ) => void,
  withLanguage?: boolean,
  defaultTab?: string,
  index?: number
) => {
  mainUrl: string;
  loading: boolean;
  object: T | null;
  detailUrl: string;
  listUrl: string;
  setLoading: (loading: boolean) => void;
  id: string;
  idLanguage: string;
  setIdLanguage: (id: string) => void;
  tabValue: string;
  handleChangeTab: (event: React.SyntheticEvent, newValue: any) => void;
  getByLanguage: () => void;
};

const useEditObject: UseDetailObject = <T extends HasId>(
  mainObject: string | undefined,
  currentRoute: ICurrentRoute | null,
  getObjectFun: (
    id: string,
    cb: any,
    cbParams: any,
    idLanguage?: string
  ) => void,
  withLanguage?: boolean,
  defaultTab?: string,
  index?: number
) => {
  if (!index) index = 2;
  if (!withLanguage) withLanguage = false;
  if (!defaultTab) defaultTab = Config.DEFAULT_TAB;
  const id = CommonTools.processIdFromPaths(currentRoute, index);
  const isFirstRender = useRef(true);
  const { mainUrl, listUrl } = useGetUrls(mainObject ?? "", currentRoute);
  const { _idlanguage } = useResource();
  const [object, setObject] = useState<T | null>(
    CommonTools.processObjectFromHistoryState(currentRoute)
  );
  const [loading, setLoading] = useState<boolean>(true);
  const processDefaultLanguage = () => {
    const id = CommonTools.processLanguageFromHistoryState(currentRoute);
    if (!id) return _idlanguage;
    return id;
  };
  const [idLanguage, setIdLanguage] = useState<string>(
    processDefaultLanguage()
  );
  const [tabValue, setTabValue] = useState<string>(
    CommonTools.getAnchor(currentRoute, defaultTab)
  );
  const handleChangeTab = (event: React.SyntheticEvent, newValue: any) => {
    setTabValue(newValue);
    RouteTools.setAnchor(newValue);
  };
  const detailUrl = CommonTools.generateDetailUrl(mainObject, id);

  const checkIsLoading = useCallback(() => {
    let load: boolean = false;
    if (!object) load = true;
    setLoading(load);
  }, [object]);

  useEffect(() => {
    checkIsLoading();
  }, [checkIsLoading]);

  const get = () => {
    if (object) return;
    setLoading(true);
    getObjectFun(id, handleGetObject, {});
  };

  const getByLanguage = useCallback(() => {
    if (!withLanguage) return;
    
    setLoading(true);
    getObjectFun(id, handleGetObject, {}, idLanguage);
  }, [idLanguage]);

  const handleGetObject = (result: ResultObjectDTO) => {
    if (!result) return;
    const obj = (result.obj as T) ?? null;
    setObject(obj);
  };

  useEffect(() => {
    if (!listUrl) return;
    if (!mainUrl) return;
    if (withLanguage) return;
    get();
  }, [listUrl, mainUrl]);

  useEffect(() => {
    if (!listUrl) return;
    if (!mainUrl) return;
    if (isFirstRender.current && object) {
      isFirstRender.current = false;
      return;
    }else if (!object) {
      isFirstRender.current = false;
    }
    getByLanguage();
  }, [getByLanguage, listUrl, mainUrl]);

  return useMemo(
    () => ({
      mainUrl,
      loading,
      object,
      detailUrl,
      listUrl,
      setLoading,
      id,
      setIdLanguage,
      idLanguage,
      tabValue,
      handleChangeTab,
      getByLanguage
    }),
    [mainUrl, loading, object, detailUrl, listUrl, id, idLanguage, tabValue,getByLanguage]
  );
};

export { useEditObject };
