import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {PricePolicyRepository} from "repositories/finance/pricepolicy.repository";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import { PostPricePolicyDto } from "dto/finance/pricepolicy.dto";

export class PricePolicyService
  extends GeneralService<PricePolicyRepository>
  implements IService
{
  constructor() {
    super(
      new PricePolicyRepository(),
      [
        { key: "status", type: "status" },
        { key: "teacherstatus", type: "status" },
        { key: "isdefault", type: "booleans" },
      ],
      "name"
    );
  }
  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "name";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }
  prepareObjToUpdate(obj: any): PostPricePolicyDto {
    const rez = new PostPricePolicyDto();

     rez.idlanguage = obj.idlanguage;
    if (obj.hasOwnProperty("status")) rez.status = obj.status;
    if (obj.hasOwnProperty("ordercriteria"))
      rez.ordercriteria = obj.ordercriteria;
    if (obj.hasOwnProperty("name")) rez.name = obj.name;
    if (obj.hasOwnProperty("idtypecourse")) rez.idtypecourse = obj.idtypecourse;
    if (obj.hasOwnProperty("teacherstatus"))
      rez.teacherstatus = obj.teacherstatus;
    if (obj.hasOwnProperty("percentageteacher"))
      rez.percentageteacher = obj.percentageteacher;
    if (obj.hasOwnProperty("percentageplatform"))
      rez.percentageplatform = obj.percentageplatform;

      rez.idpricepolicy = obj.idpricepolicy;
    if (obj.hasOwnProperty("isdefault")) rez.isdefault = obj.isdefault;
    return rez;
  }
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PostPricePolicyDto } from "dto/finance/pricepolicy.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import PricePolicyRepository from "repositories/finance/pricepolicy.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";

// export default class PricePolicyService
//   extends GeneralService
//   implements IService
// {
//   pricePolicyRepository: PricePolicyRepository = new PricePolicyRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.pricePolicyRepository.update(
//       id,
//       this.handleUpdate,
//       this.prepareObjToUpdate(data),
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.pricePolicyRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//       { key: "teacherstatus", type: "status" },
//       { key: "isdefault", type: "booleans" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     idLanguage?: string
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.pricePolicyRepository.get(
//       id,
//       this.handleGet,
//       cbparameters,
//       idLanguage
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;
//     this.parseResultPopulate(result, rez, [
//       { key: "status", type: "status" },
//       { key: "teacherstatus", type: "status" },
//       { key: "isdefault", type: "booleans" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.pricePolicyRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   prepareObjToAdd(obj: any): PostPricePolicyDto {
//     const rez = new PostPricePolicyDto();

//     rez.idlanguage = obj.idlanguage;
//     if (obj.hasOwnProperty("status")) rez.status = obj.status;
//     if (obj.hasOwnProperty("ordercriteria"))
//       rez.ordercriteria = obj.ordercriteria;

//     if (obj.hasOwnProperty("name")) rez.name = obj.name;
//     if (obj.hasOwnProperty("idtypecourse")) rez.idtypecourse = obj.idtypecourse;
//     if (obj.hasOwnProperty("teacherstatus"))
//       rez.teacherstatus = obj.teacherstatus;
//     if (obj.hasOwnProperty("percentageteacher"))
//       rez.percentageteacher = obj.percentageteacher;
//     if (obj.hasOwnProperty("percentageplatform"))
//       rez.percentageplatform = obj.percentageplatform;
//     if (obj.hasOwnProperty("isdefault")) rez.isdefault = obj.isdefault;
//     return rez;
//   }

  // prepareObjToUpdate(obj: any): PostPricePolicyDto {
  //   const rez = new PostPricePolicyDto();

  //    rez.idlanguage = obj.idlanguage;
  //   if (obj.hasOwnProperty("status")) rez.status = obj.status;
  //   if (obj.hasOwnProperty("ordercriteria"))
  //     rez.ordercriteria = obj.ordercriteria;
  //   if (obj.hasOwnProperty("name")) rez.name = obj.name;
  //   if (obj.hasOwnProperty("idtypecourse")) rez.idtypecourse = obj.idtypecourse;
  //   if (obj.hasOwnProperty("teacherstatus"))
  //     rez.teacherstatus = obj.teacherstatus;
  //   if (obj.hasOwnProperty("percentageteacher"))
  //     rez.percentageteacher = obj.percentageteacher;
  //   if (obj.hasOwnProperty("percentageplatform"))
  //     rez.percentageplatform = obj.percentageplatform;

  //     rez.idpricepolicy = obj.idpricepolicy;
  //   if (obj.hasOwnProperty("isdefault")) rez.isdefault = obj.isdefault;
  //   return rez;
  // }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.pricePolicyRepository.add(
//       this.handleGetList,
//       cbparameters,
//       this.prepareObjToAdd(data)
//     );
//   }

  // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
  //   onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
  //   if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
  //   var defaultRequestList = new RequestListDTO();
  //   defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
  //   defaultRequestList.onpage = onPage;
  //   var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
  //   defaultRequestSortCriteria.asc = true;
  //   defaultRequestSortCriteria.field = "name";
  //   defaultRequestList.sortcriteria = [];
  //   defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
  //   return defaultRequestList;
  // }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "name";

//       obj.sortcriteria = [sobj];
//     }

//     return obj;
//   }
// }
