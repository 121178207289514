import { Box, Stack, IconButton } from "@mui/material";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import { StayOnPageCheckbox } from "./StayOnPageCheckbox";
import { MyBreadcrumbs } from "../breadcrumbs/MyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";
import SpecialTopInfo from "components/special/SpecialTopInfo";

type Props = {
  mainObject: string;
  mainUrl: string;
  detailUrl: string;
  object: any;
  parentType: string;
  parentId: string;
  specialType: string;
  idLanguage?: string;
  anchorTab?: string;
  defaultTab?: string;
  excludedTabs?: string[];
  needUpdate?: boolean;
};
const HeaderEditSpecial: React.FC<Props> = ({
  mainObject,
  mainUrl,
  detailUrl,
  parentId,
  specialType,
  parentType,
  object,
  idLanguage,
  anchorTab = "",
  defaultTab = "",
  excludedTabs = [],
  needUpdate = false,
}) => {
  const processAnchorTab = () => {
    if (!anchorTab) return defaultTab;
    if (excludedTabs.includes(anchorTab)) {
      return defaultTab;
    }
    return defaultTab;
  };

  const hState = object
    ? {
        obj: object,
        idLanguage: idLanguage ?? "",
        anchor: processAnchorTab(),
        needUpdate: needUpdate,
      }
    : {};

  return (
    <React.Fragment>
      <MyBreadcrumbs
        childs={BreadcrumbDto.getBreadcrumbEditSpecial(
          specialType,
          parentType,
          parentId,
          detailUrl
        )}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",

          width: "100%",
        }}
        maxWidth="md"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <MyNavBt
              href={detailUrl}
              sx={{ ml: "auto" }}
              _hstate={hState}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </MyNavBt>
          </Box>
        </Stack>
        <Box>
          <SpecialTopInfo
            parentType={parentType}
            parentId={parentId}
            specialtype={specialType}
            suplimentar={"edit"}
            variant="h4"
            sx={{ my: 3 }}
          />
        </Box>
        {idLanguage ? (
          <StayOnPageCheckbox mainObject={mainObject + specialType} />
        ) : null}
      </Box>
    </React.Fragment>
  );
};

export { HeaderEditSpecial };
