import { Box } from "@mui/material";

import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { CourseCategoryDto } from "dto/course/coursecategory.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyFileField } from "components/elements/form/MyFileField";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  defaultObject: CourseCategoryDto;
  onSubmit: (data: CourseCategoryDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormCourseCategory: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, setObject, vresults, setObjectLanguage] =
    useForm<CourseCategoryDto>(
      defaultObject,
      RequiredValidator.getValidators(["name"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  const setCheckDeleteFile = (value: any) => {
    CommonTools.handleCheckValue(obj, setObject, "deleteicon", value);
  };

  const processFileDelete = () => {
    if (forAdd) return null;
    if (!obj) return null;
    return (
      <Box mt={3}>
        {ComponentCommonTools.processCheckboxDelete(
          obj,
          obj,
          "icon",
          "deleteicon",
          "name",
          setCheckDeleteFile,
          LL
        )}
      </Box>
    );
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="url"
            name="url"
            variant="outlined"
            color="primary"
            _label={LL("url")}
            size="small"
            value={obj.url}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
        <MyTextField
              tabIndex={1}
              fullWidth
              id="ordercriteria"
              name="ordercriteria"
              variant="outlined"
              color="primary"
              _label={LL("ordercriteria")}
              size="small"
              value={obj.ordercriteria}
              setObj={setObjField}
              _vresults={vresults}
            />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>

        {forAdd && (
          <Box mt={3}>
            <LanguageSelect
              value={obj.idlanguage ?? ""}
              setValue={setObjectLanguage}
            />
          </Box>
        )}
        <Box mt={3}>
          <MyTextField
            tabIndex={6}
            fullWidth
            id="keymeta"
            name="keymeta"
            variant="outlined"
            color="primary"
            _label={LL("keymeta")}
            size="small"
            value={obj.keymeta}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Box mt={3}>
          <MyTextField
            tabIndex={7}
            fullWidth
            id="descriptionmeta"
            name="descriptionmeta"
            variant="outlined"
            color="primary"
            _label={LL("descriptionmeta")}
            size="small"
            value={obj.descriptionmeta}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyCheckboxField
            label={LL("istop")}
            field="istop"
            setObjectField={setObjField}
            checked={obj.istop}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={9}
            fullWidth
            id="popularidentifier"
            name="popularidentifier"
            variant="outlined"
            color="primary"
            _label={LL("PopularIdentifier")}
            size="small"
            value={obj.popularidentifier}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyFileField
            field="files"
            label={LL("field_file")}
            setObj={setObject}
            obj={obj}
          />
        </Box>
        {processFileDelete()}
        <Box mt={3}>
          <MyButton
            tabIndex={6}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseCategory };
