import React from "react";

import { IPage } from "interfaces/page.interface";
import { TestService } from "services/course/test.service";
import { TestDto } from "dto/course/test.dto";

import { FormTest } from "components/special/test/FormTest";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";

import { useEditSpecial } from "hooks/useEditSpecial";
import { HeaderEditSpecial } from "components/elements/edit/HeaderEditSpecial";
import { Loading } from "components/elements/loading/Loading";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const service = new TestService();
const SpecialTestEdit: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,

    parentId,
    parentType,
  } = useEditSpecial<TestDto>("test", mainObject, currentRoute, get);

  const onSubmit = (obj: TestDto) => {
    if (!obj) return;
    setLoading(true);

    obj.idcourse = parentId.toString();
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "test",
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEditSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        parentId={parentId}
        parentType={parentType}
        specialType="test"
      />
      <DataDisplayBox>
        <Box>
          <FormTest
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialTestEdit };
