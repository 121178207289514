import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import DateTools from "tools/utils/date.tools";
import FilterTools from "tools/utils/filtertools";

export class NewsletterDto implements Idto {
  id: string;
  type: number;
  date: number;
  status: number;
  email: string;

  constructor(
    email?: string,
    id?: string,
    type?: number,
    date?: number,
    status?: number
  ) {
    this.id = id || "";
    this.type = type || Types.TYPE_GENERAL_NEWSLETTER;
    this.date = date || DateTools.getTimeStamp();
    this.status = status || Status.ACTIVE;
    this.email = email || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "date",
        value: ["date_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Email",
        value: ["email"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "email"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_ConstantColumn(LL, "date"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detail"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);
    
    return obj;
  }

}
