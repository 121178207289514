import React, { useState, useEffect } from "react";
import { useResource } from "hooks/useResource";
import Typography from "@mui/material/Typography";
import { ServiceTools } from "tools/utils/servicetools";

interface SpecialTopInfoProps {
  parentType: string;
  parentId: string;
  specialtype: string;
  suplimentar?: string;
  variant?: any;
  sx?: any;
}

const SpecialTopInfo: React.FC<SpecialTopInfoProps> = ({
  parentType,
  parentId,
  specialtype,
  suplimentar,
  ...props
}) => {
  const { LL } = useResource();
  suplimentar = suplimentar ?? "";

  
  const [parentName, setParentName] = useState<string>('');
  
  const setParentInfo = async () => {
    const t = await ServiceTools.getObjectName(parentId, parentType);
    setParentName(t);
  };

  useEffect(() => {
    setParentInfo();
  }, [parentType, parentId]);

  return (
    <Typography {...props} variant="h4"
    sx={{ my: 3 }}>
      {LL(suplimentar + specialtype + " page")} {LL('SPT from')} {parentName}
    </Typography>
  );
};

export default SpecialTopInfo;
