import React, { useState } from "react";

import { CourseSettingsService } from "services/course/coursesettings.service";
import useObject from "hooks/useObject";
import { CourseSettingsDto } from "dto/course/coursesettings.dto";
import { Skeleton } from "@mui/material";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CommonTools } from "tools/utils/commontools";
import { TabCourseSettingsProps } from "./TabCourseSettings";
import { FormCourseSettings } from "./FormCourseSettings";

const service = new CourseSettingsService();
const TabCourseSettingsEdit: React.FC<TabCourseSettingsProps> = ({
  idCourse,
  mainObject,
}) => {
  const get = (id: string, cb?: any, cbParams?: any) => {
    if (!id) return;
    service.getCourseSettingsByCourseId(id, cb, cbParams);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = (obj: CourseSettingsDto) => {
    if (!obj) return;
    if (!obj.id) return;
    setLoading(true);
    service.update(
      obj.id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        idRoute: idCourse,
        cb: CommonTools.processLoadingCallback(setLoading),
        anchor: "coursesettings",
        needUpdate: true,
      },
      obj
    );
  };
  const [loadingObject, object] = useObject<CourseSettingsDto>(get, idCourse, [
    idCourse,
  ]);
  if (loadingObject || loading) return <Skeleton />;
  if (!object) return null;
  return (
    <FormCourseSettings
      defaultObject={object}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

export { TabCourseSettingsEdit };
