import React from "react";

import { Types } from "tools/types/types";

import { StaticSelect } from "./newSelect/StaticSelect";
type TypeSelectProps = {
  setObjField: (field: any, value: any) => void;
  value: any;
};
const SectionSelect: React.FC<TypeSelectProps> = ({ setObjField, value }) => {
  // const { LL } = useResource();
  // const defaultValue = _defaultValue ? _defaultValue : '';
  // const label = "Section";
  // const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
  // const [loading, setLoading] = useState<boolean>(true);
  // const [value, setValue] = useState<number | any>(defaultValue);
  // const processOptions = () => {
  //     const options: Array<SelectOptions> = Types.GA("section");
  //     setOptions(options);
  // }

  // useEffect(() => {
  //     processOptions()
  // }, []);

  // useEffect(() => {
  //     checkIsLoading();
  // }, [options]);

  // useEffect(() => {
  //     setObj(field, value);
  // }, [value]);

  // const checkIsLoading = () => {
  //     let load: boolean = false;
  //     if (options === undefined) load = true;
  //     setLoading(load);
  // };

  // return !loading ? (
  //     <MySelect
  //         options={options}
  //         _label={label}
  //         setValue={setValue}
  //         value={value}
  //         {...props}
  //     />
  // ) : (<Skeleton variant="rectangular" height={50} />);

  return (
    <StaticSelect
      setObjField={setObjField}
      value={value}
      service={Types}
      type="section"
      field="section"
    />
  );
};

export { SectionSelect };
