import { DetailObject } from 'interfaces/detailobject.interface';
import Idto from 'interfaces/idto.interface';
import { Status } from "tools/types/status";
import { Types } from 'tools/types/types';
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";


export class NotificationDto implements Idto {
    
    id?: string;
    type?: number;
    date?: number;
    showdate?: number;
    showstarttime?: number;
    showendtime?: number;
    status?: number;
    iduser?: string;
    idteacher?: string;
    idstudent?: string;
    typerecipient?: number;
    user?: any;
    student?: any;
    teacher?: any;

    constructor(
      id?: string,
      type?: number,
      date?: number,
      showdate?: number,
      showstarttime?: number,
      showendtime?: number,
      status?: number,
      iduser?: string,
      idteacher?: string,
      idstudent?: string,
      typerecipient?: number,
    ) {
      this.id = id || "";
      this.type = type || Types.TYPE_NOTIFICATION_EMAIL;
      this.date = date || 0;
      this.showdate = showdate || 0;
      this.showstarttime = showstarttime || 0;
      this.showendtime = showendtime || 0;
      this.status = status || 0;
      this.iduser = iduser || "";
      this.idteacher = idteacher || "";
      this.idstudent = idstudent || "";
      this.typerecipient = typerecipient || 0;

    }
    
    static getDetailFields(): Array<DetailObject> {
        return [
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "Type",
            value: ["type_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "date",
            value: ["date_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "showdate",
            value: ["showdate_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "showstarttime",
            value: ["showstarttime_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "showendtime",
            value: ["showendtime_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "Status",
            value: ["status_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "typerecipient",
            value: ["typerecipient_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "teacherfullname",
            value: ["teacherfullname"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "studentfullname",
            value: ["studentfullname"],
          },
          
        ];
      }

      
  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_ConstantColumn(LL, "showdate"),
      ComponentCommonTools.columns_ConstantColumn(LL, "date"),
      ComponentCommonTools.columns_ConstantColumn(LL, "showstarttime"),
      ComponentCommonTools.columns_ConstantColumn(LL, "showendtime"),
      ComponentCommonTools.columns_ConstantColumn(LL, "typerecipient"),
      ComponentCommonTools.columns_StandardColumn(LL, 'userfullname', undefined, false),
      ComponentCommonTools.columns_StandardColumn(LL, 'teacherfullname', undefined, false),
      ComponentCommonTools.columns_StandardColumn(LL, 'studentfullname', undefined, false),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detail"],
        ["detail"]
      )
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("notification", true));
    obj.addFilter(t);
    
    return obj;
  }
}


export class PostNotificationDto implements Idto {
    
    id?: string;
    type?: number;
    date?: number;
    showdate?: number;
    showstarttime?: number;
    showendtime?: number;
    status?: number;
    iduser?: string;
    idteacher?: string;
    idstudent?: string;
    typerecipient?: number;
    user?: any;
    student?: any;
    teacher?: any;
}