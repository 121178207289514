import Idto from "interfaces/idto.interface";

export default class RequestSortCriteriaDTO implements Idto {
  field?: string;
  asc?: boolean;
  constructor(field?: string, asc?: boolean) {
    this.field = field || "";
    this.asc = asc || false;
  }
  static prepareSortCriteria(field: string, asc: boolean): RequestSortCriteriaDTO {
    return new RequestSortCriteriaDTO(field, asc);
  }
}
