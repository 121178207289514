import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import SelectOptions from "dto/app/selectoptions.dto";
import { CommonTools } from "tools/utils/commontools";

export class CourseCategoryDto implements Idto {
  id?: string;
  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idcoursecategory?: string;
  keymeta?: string;
  descriptionmeta?: string;
  url?: string;

  istop?: boolean;
  popularidentifier?: number;
  files?: File | null;
  icon?: any;
  idicon?: string | null;
  deleteicon?: boolean;

  constructor(
    idlanguage?: string,
    idparent?: string,
    id?: string,
    ordercriteria?: number,
    name?: string,
    status?: number,
    popularidentifier?: number,
    istop?: boolean,
    keymeta?: string,
    descriptionmeta?: string,
    url?: string,
    deleteicon?: boolean,
    idicon?: string | null,
    icon?: any
  ) {
    this.id = id || "";
    this.status = status || Status.ACTIVE;
    this.ordercriteria = ordercriteria || 0;
    this.name = name || "";
    this.idlanguage = idlanguage || "";
    this.idparent = idparent || "";
    this.popularidentifier = popularidentifier || 0;
    this.istop = istop || false;
    this.keymeta = keymeta || "";
    this.descriptionmeta = descriptionmeta || "";
    this.url = url || "";
    this.deleteicon = deleteicon || false;
    this.files = null;
    this.idicon = idicon || null;
    this.icon = icon || null;
  }

  static parseForEdit(obj: any): CourseCategoryDto {
    return new CourseCategoryDto(
      obj.idlanguage,
      obj.idparent,
      obj.id,
      obj.ordercriteria,
      obj.name,
      obj.status,
      obj.popularidentifier,
      obj.istop,
      obj.keymeta,
      obj.descriptionmeta,
      obj.url,
      false,
      obj.idicon,
      obj.icon
    );
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "keymeta",
        value: ["keymeta"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "descriptionmeta",
        value: ["descriptionmeta"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "url",
        value: ["url"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "istop",
        value: ["istop_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PopularIdentifier",
        value: ["popularidentifier"],
      },
      {
        typeField: Types.FIELD_TYPE_IMAGE,
        label: "Icon",
        value: ["icon"],
        options: {
          width: 100,
          height: 100,
          withLink: true,
        },
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "istop"),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_StandardColumn(LL, "popularidentifier"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        [
          "edit",
          "detail",
          "delete",
          "children",
          "gallery",
          "attachment",
          "video",
        ],
        ["detail", "attachment", "video"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<CourseCategoryDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(CourseCategoryDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: CourseCategoryDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["name"])
    );
  };
}

export class PostCourseCategoryDto implements Idto {
  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idcoursecategory?: string;
  keymeta?: string;
  descriptionmeta?: string;
  url?: string;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  istop?: boolean;
  popularidentifier?: number;
  files?: any;
  idicon?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.popularidentifier = 0;
    this.istop = false;
  }
}
