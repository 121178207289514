import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Types } from "tools/types/types";

export class CoursePriceDto implements Idto {
  id?: string;
  idcurrency?: string;
  idtypeprice?: string;
  idpricepolicy?: string;
  userecommendationprice?: boolean;
  idrecommendationprice?: string | null;
  idcourse?: string;
  totalprice?: number;
  priceperlesson?: number;
  teacherpercentage?: number;
  teachertotalprice?: number;
  teachertotalpriceperlesson?: number;

  currency?: any;
  typeprice?: any;
  pricepolicy?: any;
  recommendationprice?: any;
  course?: any;

  constructor(
    idcourse?: string,
    idcurrency?: string,
    idtypeprice?: string,
    idpricepolicy?: string,
    userecommendationprice?: boolean,
    idrecommendationprice?: string | null,
    totalprice?: number,
    priceperlesson?: number
  ) {
    this.idcourse = idcourse || "";
    this.idcurrency = idcurrency || "";
    this.idtypeprice = idtypeprice || "";
    this.idpricepolicy = idpricepolicy || "";
    this.userecommendationprice = userecommendationprice || false;
    this.idrecommendationprice = idrecommendationprice || null;
    this.totalprice = totalprice || 0;
    this.priceperlesson = priceperlesson || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "totalprice",
        value: ["totalprice"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "priceperlesson",
        value: ["priceperlesson"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "teachertotalprice",
        value: ["teachertotalprice"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "teachertotalpriceperlesson",
        value: ["teachertotalpriceperlesson"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pricepolicy",
        value: ["pricepolicy", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "userecommendationprice",
        value: ["userecommendationprice_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "recommendationprice",
        value: ["recommendationprice", "name"],
      },
    ];
  }
}
