import React, { useState } from "react";
import { useResource } from "hooks/useResource";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { Box, Stack, Typography } from "@mui/material";

import { PostNewStudentDto, StudentDto } from "dto/user/student.dto";
import { DialogRegisterStudent } from "./DialogRegisterStudent";
import { MyButton } from "components/elements/button/MyButton";
import {UserService} from "services/user/user.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/commontools";
import { AutocompleteStudent } from "../AutocompleteStudent";

type Props = {
  object: CourseEnrollmentDto;
  setObjField: (field: string, value: any) => void;
};

const service = new UserService();

const AutocompleteAddStudent: React.FC<Props> = ({
  object,
  setObjField,
}) => {
  const { LL } = useResource();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [newStudent, setNewStudent] = useState<StudentDto | null>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (obj: PostNewStudentDto) => {
    setLoading(true);
    service.addNewStudent(handleAdd, {}, obj);
  };

  const handleAdd = (result: ResultObjectDTO) => {
    if (result.err) return;
    if (!result.obj) return;
    const obj = result.obj as StudentDto;
    setNewStudent(obj);
    const id = obj.id ?? "";
    setObjField("idstudent", id);
    setLoading(false);
    handleClose();
  };
  return (
    <Box>
      {newStudent ? (
        <Typography>
          {CommonTools.processObjectField(newStudent, ["email"])}
        </Typography>
      ) : (
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <AutocompleteStudent
            setObjField={setObjField}
            value={object.idstudent}
            defaultObject={object.student}
            idCourse={object.idcourse}
          />
          <MyButton color="primary" cb={handleOpen}>
            {LL("Add_new_student")}
          </MyButton>
        </Stack>
      )}
      <DialogRegisterStudent
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </Box>
  );
};

export { AutocompleteAddStudent };
