export class BreadcrumbDto {
  url: string;
  label: string;
  constructor(label: string, url?: string) {
    this.url = url ?? "";
    this.label = label;
  }

  static getBreadcrumbListSpecialThirdLevel(
    firstLevelParent: string,
    idFirstLevelParent: string,
    mainObject: string,
    parentType: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + firstLevelParent, "/" + firstLevelParent),
      new BreadcrumbDto(
        "crumb_" + parentType,
        "/" + firstLevelParent + "/" + idFirstLevelParent + "/" + parentType
      ),
      new BreadcrumbDto("crumb_" + mainObject),
    ];
  }

  static getBreadcrumbEditThirdLevel(
    firstLevelParent: string,
    idFirstLevelParent: string,
    parentType: string,
    idParent: string,
    specialType: string,
    detailUrl: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + firstLevelParent, "/" + firstLevelParent),
      new BreadcrumbDto(
        "crumb_" + parentType,
        "/" +
          firstLevelParent +
          "/" +
          idFirstLevelParent +
          "/" +
          parentType
      ),
      new BreadcrumbDto(
        "crumb_" + specialType,
        "/" +
          firstLevelParent +
          "/" +
          idFirstLevelParent +
          "/" +
          parentType +
          "/" +
          idParent +
          "/" +
          specialType
      ),
      new BreadcrumbDto("crumb_Detail_" + specialType, detailUrl),
      new BreadcrumbDto("crumb_Edit_" + specialType),
    ];
  }


  static getBreadcrumbEditSpecial(
    mainObject: string,
    parentType: string,
    idParent: string,
    detailUrl: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + parentType, "/" + parentType),
      new BreadcrumbDto(
        "crumb_" + mainObject,
        "/" + parentType + "/" + idParent + "/" + mainObject
      ),
      new BreadcrumbDto("crumb_Detail_" + mainObject, detailUrl),
      new BreadcrumbDto("crumb_Edit_" + mainObject),
    ];
  }


  static getBreadcrumbDetailThirdLevel(
    firstLevelParent: string,
    idFirstLevelParent: string,
    parentType: string,
    idParent: string,
    specialType: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + firstLevelParent, "/" + firstLevelParent),
      new BreadcrumbDto(
        "crumb_" + parentType,
        "/" +
          firstLevelParent +
          "/" +
          idFirstLevelParent +
          "/" +
          parentType
      ),
      new BreadcrumbDto(
        "crumb_" + specialType,
        "/" +
          firstLevelParent +
          "/" +
          idFirstLevelParent +
          "/" +
          parentType +
          "/" +
          idParent +
          "/" +
          specialType
      ),
      new BreadcrumbDto("crumb_Detail_" + specialType),
    ];
  }

  static getBreadcrumbDetailSpecial(
    mainObject: string,
    parentType: string,
    idParent: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + parentType, "/" + parentType),
      new BreadcrumbDto(
        "crumb_" + mainObject,
        "/" + parentType + "/" + idParent + "/" + mainObject
      ),
      new BreadcrumbDto("crumb_Detail_" + mainObject),
    ];
  }

  static getBreadcrumbAddThirdLevel(
    firstLevelParent: string,
    idFirstLevelParent: string,
    parentType: string,
    idParent: string,
    specialType: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + firstLevelParent, "/" + firstLevelParent),
      new BreadcrumbDto(
        "crumb_" + parentType,
        "/" + firstLevelParent + "/" + idFirstLevelParent + "/" + parentType
      ),
      new BreadcrumbDto(
        "crumb_" + specialType,
        "/" +
          firstLevelParent +
          "/" +
          idFirstLevelParent +
          "/" +
          parentType +
          "/" +
          idParent +
          "/" +
          specialType
      ),
      new BreadcrumbDto("crumb_Add_" + specialType),
    ];
  }
  static getBreadcrumbAddSpecial(
    mainObject: string,
    parentType: string,
    idParent: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + parentType, "/" + parentType),
      new BreadcrumbDto(
        "crumb_" + mainObject,
        "/" + parentType + "/" + idParent + "/" + mainObject
      ),
      new BreadcrumbDto("crumb_Add_" + mainObject),
    ];
  }

  static getBreadcrumbList(mainObject: string): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + mainObject),
    ];
  }
  static getBreadcrumbListSpecial(
    mainObject: string,
    parentType: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + parentType, "/" + parentType),
      new BreadcrumbDto("crumb_" + mainObject),
    ];
  }

  static getBreadcrumbAdd(
    mainUrl: string,
    mainObject: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + mainObject, mainUrl),
      new BreadcrumbDto("crumb_Add_" + mainObject),
    ];
  }

  static getBreadcrumbDetail(
    mainUrl: string,
    mainObject: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + mainObject, mainUrl),
      new BreadcrumbDto("crumb_Detail_" + mainObject),
    ];
  }

  static getBreadcrumbEdit(
    mainUrl: string,
    detailUrl: string,
    mainObject: string
  ): BreadcrumbDto[] {
    return [
      new BreadcrumbDto("crumb_homepage", "/"),
      new BreadcrumbDto("crumb_" + mainObject, mainUrl),
      new BreadcrumbDto("crumb_Detail_" + mainObject, detailUrl),
      new BreadcrumbDto("crumb_Edit_" + mainObject),
    ];
  }
}
