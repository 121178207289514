import { Box } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
};

const DataDisplayBox: React.FC<Props> = ({ children }) => {
  return (
    <Box
      maxWidth="100%"
      sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}>
      {children}
    </Box>
  );
};

export { DataDisplayBox };
