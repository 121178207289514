import { useMemo, useState } from "react";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import { CommonTools } from "tools/utils/commontools";



type UseDetailObject = (currentRoute: ICurrentRoute | null) => {
  mainUrl: string;

  parentId: string;
  parentType: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const useAddSpecial: UseDetailObject = (currentRoute: ICurrentRoute | null) => {
  const parentId = CommonTools.processIdFromPaths(currentRoute, 1);
  const parentType = CommonTools.processIdFromPaths(currentRoute, 0);
  const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);
  const [loading, setLoading] = useState<boolean>(false);

  return useMemo(
    () => ({
      mainUrl,
      parentId,
      parentType,
      loading,
      setLoading,
    }),
    [mainUrl, parentId, parentType, loading]
  );
};

export { useAddSpecial };
