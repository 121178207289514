import React, { useEffect, useState } from "react";
import { MessageTypes } from "tools/types/messagetypes";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Config } from "tools/utils/config";
import MessageDto from "dto/app/message.dto";

type Props = {
  _obj: MessageDto;
  _closeMessage: any;
};

type ClassTypeSeverity = "success" | "warning" | "error";

const MessageItem: React.FC<Props> = ({ _obj, _closeMessage }) => {
  const [obj] = useState(_obj);
  const [typeClass, setTypeClass] = useState<ClassTypeSeverity>("error");

  const clMess = () => {
    if (!_closeMessage) return;
    _closeMessage(obj);
  };

  const processClassType = () => {
    let type: ClassTypeSeverity = "error";

    if (obj && obj.mestype === MessageTypes.MESSAGE_SUCCESS) type = "success";
    if (obj && obj.mestype === MessageTypes.MESSAGE_WARNING) type = "warning";
    if (obj && obj.mestype === MessageTypes.MESSAGE_ERROR) type = "error";

    setTypeClass(type);
  };

  const processAutomatedClosed = () => {
    if (!obj) return;

    let exp = 10000;

    if (obj.mestype === MessageTypes.MESSAGE_SUCCESS)
      exp = Config.CLOSE_MESSAGE_SUCCESS;
    if (obj.mestype === MessageTypes.MESSAGE_WARNING)
      exp = Config.CLOSE_MESSAGE_WARNING;
    if (obj.mestype === MessageTypes.MESSAGE_ERROR)
      exp = Config.CLOSE_MESSAGE_ERROR;

    if (isNaN(exp)) exp = 10000;

    const timer = setTimeout(() => {
      clMess();
    }, exp);
    return () => clearTimeout(timer);
  };

  // ----------------------------------------
  useEffect(() => {
    processClassType();
    processAutomatedClosed();
  }, [obj]);

  return obj ? (
    <Alert
      sx={{
        position: "absolute",
        fontSize: "12px",
        right: "10px",
        bottom: "10px",
        width: "350px",
      }}
      severity={typeClass}
      onClick={clMess}
      onClose={() => {}}
    >
      <AlertTitle>{obj.code}</AlertTitle>
      {obj.message}
    </Alert>
  ) : null;
};

export { MessageItem };
