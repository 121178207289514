import { Close, Done, Edit } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";

import { TestDto } from "dto/course/test.dto";

import React, { useState } from "react";

import { CommonTools } from "tools/utils/commontools";
import { TestEditBlock } from "./TestEditBlock";
import { FormTest } from "components/special/test/FormTest";
import { Types } from "tools/types/types";
import { TestService } from "services/course/test.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import { useResource } from "hooks/useResource";



type Props = {
  object: TestDto | null;
  open: boolean;
  handleClose: () => void;
  setObject: (value: TestDto | null) => void;
  idCourse?: string;
  idLesson?: string;
  allowEdit?: boolean;
};
const service = new TestService();
const TestDialog: React.FC<Props> = ({
  object,
  open,
  handleClose,
  setObject,
  idCourse = "",
  idLesson = "",
  allowEdit = false,
}) => {
  const { LL } = useResource();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  
  const onSubmit = (data: TestDto) => {
    setLoading(true);
    service.add(handleResult, {}, data);
  };


  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) {
      setLoading(false);
      return;
    }
    if (!result.obj) {
      setLoading(false);
      return;
    }
    setObject(result.obj);
    setLoading(false);
    if (allowEdit && editMode) setEditMode(false);
  };

  const onEdit = (data: TestDto) => {
    setLoading(true);
    if (!object) return;
    if (!object.id) return;
    service.update(object.id, handleResult, {}, data);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth className="dialogAdd">
        <DialogTitle>
          {CommonTools.processObjectField(object, ["name"])
            ? CommonTools.processObjectField(object, ["name"])
            : LL("AddTest")}
        </DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          {object ? (
            <Box>
              <Box>
                {allowEdit && (
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    <IconButton onClick={toggleEditMode}>
                      {editMode ? <Done /> : <Edit />}
                    </IconButton>
                  </Stack>
                )}
              </Box>
              {editMode ? (
                <FormTest
                  defaultObject={object}
                  fromLesson
                  loading={loading}
                  onSubmit={onEdit}
                />
              ) : (
                <TestEditBlock
                  object={object}
                  setObject={setObject}
                  open={open}
                />
              )}
            </Box>
          ) : (
            <Box>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularLoading />
                </Box>
              ) : (
                <FormTest
                  defaultObject={
                    new TestDto(
                      idCourse,
                      idLesson,
                      undefined,
                      undefined,
                      undefined,
                      Types.TYPE_TEST_LESSON
                    )
                  }
                  fromLesson
                  loading={false}
                  onSubmit={onSubmit}
                />
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export { TestDialog };
