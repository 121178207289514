import { Box, Stack, IconButton, Typography } from "@mui/material";
import Idto from "interfaces/idto.interface";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { MyBreadcrumbs } from "../breadcrumbs/MyBreadcrumbs";
import { useResource } from "hooks/useResource";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  object: Idto;
  mainObject: string;
  mainUrl: string;
  listUrl: string;
  editUrl: string;
  idLanguage?: string;
  anchorTab?: string;
  showEdit?: boolean;
  children?: React.ReactNode;
};
const HeaderDetail: React.FC<Props> = ({
  object,
  mainObject,
  mainUrl,
  listUrl,
  editUrl,
  idLanguage,
  anchorTab = "",
  showEdit = true,
  children = null,
}) => {
  const { LL } = useResource();

  return (
    <React.Fragment>
      <MyBreadcrumbs
        childs={BreadcrumbDto.getBreadcrumbDetail(mainUrl, mainObject)}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: showEdit ? "space-between" : "flex-start",
          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <MyNavBt
              href={listUrl}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </MyNavBt>
          </Box>
          <Box sx={{ mr: 10 }}>
            <Typography variant="h4" sx={{ my: 3, ml: 3, pr: 1 }}>
              {LL("DetailBlock")}
            </Typography>
          </Box>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          spacing={2}
        >
          <Box>{children}</Box>
          {showEdit && (
            <Box>
              <MyNavBt
                href={editUrl}
                sx={{ ml: "auto" }}
                _hstate={CommonTools.prepareObjectForUpdate(
                  object,
                  mainObject,
                  anchorTab,
                  idLanguage
                )}
                _mainurl={mainUrl}
                component={IconButton}
                className="editBtn"
              >
                <EditIcon />
              </MyNavBt>
            </Box>
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export { HeaderDetail };
