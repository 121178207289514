import { Box } from "@mui/material";

import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { AdvertisementDto } from "dto/static/advertisement.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { AdvertisementTypeSelect } from "components/elements/select/AdvertisementTypeSelect";
import { AdvertisementSectionSelect } from "components/elements/select/AdvertisementSectionSelect";
import MyDatePicker from "components/elements/datepicker/MyDatePicker";

type Props = {
  defaultObject: AdvertisementDto;
  onSubmit: (data: AdvertisementDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormAdvertisement: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults, setObjectLanguage] =
    useForm<AdvertisementDto>(
      defaultObject,
      RequiredValidator.getValidators(["name", "type", "section"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            id="description"
            name="description"
            variant="outlined"
            color="primary"
            _label={LL("description")}
            size="small"
            value={obj.description}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            id="ordercriteria"
            name="ordercriteria"
            variant="outlined"
            color="primary"
            _label={LL("ordercriteria")}
            size="small"
            value={obj.ordercriteria}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={3}
            fullWidth
            id="clabt"
            name="clabt"
            variant="outlined"
            color="primary"
            _label={LL("clabt")}
            size="small"
            value={obj.clabt}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>

        {forAdd && (
          <Box mt={3}>
            <LanguageSelect
              value={obj.idlanguage ?? ""}
              setValue={setObjectLanguage}
            />
          </Box>
        )}
        <Box mt={3}>
          <MyTextField
            tabIndex={5}
            fullWidth
            id="url"
            name="url"
            variant="outlined"
            color="primary"
            _label={LL("url")}
            size="small"
            value={obj.url}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <AdvertisementTypeSelect
            value={obj.type}
            field="type"
            setObjectField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <AdvertisementSectionSelect
            value={obj.section}
            field="section"
            setObjectField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <MyDatePicker
            tabIndex={9}
            field="startdate"
            _defaultValue={obj.startdate}
            LL={LL}
            setObjField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <MyDatePicker
            tabIndex={10}
            field="enddate"
            _defaultValue={obj.enddate}
            LL={LL}
            setObjField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={6}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormAdvertisement };
