import React from "react";

import { IPage } from "interfaces/page.interface";

import RequestListDTO from "dto/app/requestlist.dto";
import { VideoDto } from "dto/system/video.dto";

import { VideoService } from "services/system/video.service";
import { useListAdminSpecialThirdLevel } from "hooks/useListAdminSpecialThirdLevel";

import { useResource } from "hooks/useResource";
import MyTable from "components/elements/tabeltools/MyTable";
import { HeaderListSpecialThirdLevel } from "components/elements/list/HeaderListSpecialThirdLevel";

const service = new VideoService();

const ThirdLevelSpecialVideo: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    addUrl,
    mainUrl,
    setDeleteOneObject,
    handleDelete,
    rows,
    total,
    totalPage,
    deleteOneObject,
    reqList,
    setReqList,
    loading,
    onRowClickCb,
    parentId,
    parentType,
    parentUrl,
    firstLevelParentId,
    firstLevelParentType,
  } = useListAdminSpecialThirdLevel<VideoDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun,
    "thirdlevelvideo"
  );
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <React.Fragment>
      <HeaderListSpecialThirdLevel
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={addUrl}
        specialType="thirdlevelvideo"
        parentUrl={parentUrl}
        parentType={parentType}
        parentId={parentId}
        firstLevelParentId={firstLevelParentId}
        firstLevelParentType={firstLevelParentType}
      />
      <MyTable
        _columns={VideoDto.getColumnsSpecial(
          LL,
          mainObject,
          setDeleteOneObject,
          "thirdlevelvideo"
        )}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        onDelete={handleDelete}
        mainObject={mainObject}
        fieldToShowOnDelete="name"
        deleteOneObject={deleteOneObject}
        setDeleteOneObject={setDeleteOneObject}
        onRowClickCb={onRowClickCb}
      />
    </React.Fragment>
  );
};

export { ThirdLevelSpecialVideo };
