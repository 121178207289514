import { Box } from "@mui/material";
import { PermissionDto, PermissionGroupDto } from "dto/user/permission.dto";
import React from "react";
import { GroupPermissionItem } from "./GroupPermissionItem";

type Props = {
  objects: PermissionGroupDto[];
  idRole: string;
  setObjects: (objects: PermissionDto[]) => void;
  objectsFull: PermissionDto[];
};

const GroupPermissionList: React.FC<Props> = ({ objects, idRole,setObjects,objectsFull }) => {
  const processItem = (item: PermissionGroupDto, index: number) => {
    return <GroupPermissionItem key={index} item={item} idRole={idRole} setObjects={setObjects} objects={objectsFull}/>;
  };

  return <Box>{objects.map((item, index) => processItem(item, index))}</Box>;
};

export { GroupPermissionList };
