import { PermissionGroupDto } from "dto/user/permission.dto";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { ListItemButton, Typography } from "@mui/material";

type Props =  {
    item: PermissionGroupDto;
}

const PermissionMenuItem: React.FC<Props> = ({ item }) => {
    const navigateToSection = () => {
        const element = document.getElementById(
          CommonTools.processObjectField(item, ["groupname"])
        );
    
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start",
          });
        }
      };
      return (
        <ListItemButton onClick={navigateToSection}>
          <Typography>
            {CommonTools.processObjectField(item, ["groupname"])}
          </Typography>
        </ListItemButton>
      );
}

export { PermissionMenuItem };