import React, { useState } from "react";

import { CoursePriceService } from "services/course/courseprice.service";
import useObject from "hooks/useObject";
import { CoursePriceDto } from "dto/course/courseprice.dto";
import { Skeleton } from "@mui/material";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CommonTools } from "tools/utils/commontools";
import { TabCoursePriceProps } from "./TabCoursePrice";
import { FormCoursePrice } from "./FormCoursePrice";

const service = new CoursePriceService();
const TabCoursePriceEdit: React.FC<TabCoursePriceProps> = ({
  idCourse,
  mainObject,
  idTypeCourse,
}) => {
  const get = (id: string, cb?: any, cbParams?: any) => {
    if (!id) return;
    service.getCoursePriceByCourseId(id, cb, cbParams);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = (obj: CoursePriceDto) => {
    if (!obj) return;
    if (!obj.id) return;
    setLoading(true);
    service.update(
      obj.id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        idRoute: idCourse,
        cb: CommonTools.processLoadingCallback(setLoading),
        anchor: "courseprice",
        needUpdate: true,
      },
      obj
    );
  };
  const [loadingObject, object] = useObject<CoursePriceDto>(get, idCourse, [
    idCourse,
  ]);
  if (loadingObject || loading) return <Skeleton />;
  if (!object) return null;
  return (
    <FormCoursePrice
      defaultObject={object}
      onSubmit={onSubmit}
      loading={loading}
      idTypeCourse={idTypeCourse}
    />
  );
};

export { TabCoursePriceEdit };
