import { GridRenderCellParams } from "@mui/x-data-grid";
import { ColumnQuestion } from "./ColumnQuestion";
import { TestDto } from "dto/course/test.dto";
import { ColumnLessonTestAdd } from "./ColumnLessonTestAdd";
import { ColumnLessonTestEdit } from "./ColumnLessonTestEdit";

export class TestListColumns {
  static columnQuestions = (
    LL: any,
    setObject: (value: TestDto | null) => void
  ) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return <ColumnQuestion object={params.row} setObject={setObject} />;
        } else {
          return "";
        }
      },
    };

    obj["field"] = "questions";
    obj["headerName"] = LL("headerName_questions");
    obj["hideable"] = true;
    obj["sortable"] = false;
    obj["align"] = "center";

    obj["flex"] = 1;
    obj["minWidth"] = 50;

    return obj;
  };

  static columnTestQuestions = (LL: any) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined && params.row.istest !== undefined) {
          return params.row.istest ? (
            <ColumnLessonTestEdit object={params.row} />
          ) : (
            <ColumnLessonTestAdd object={params.row} />
          );
        } else {
          return "";
        }
      },
    };

    obj["field"] = "test";
    obj["headerName"] = LL("headerName_test");
    obj["hideable"] = true;
    obj["sortable"] = false;
    obj["align"] = "center";

    obj["flex"] = 1;
    obj["minWidth"] = 50;

    return obj;
  };
}
