import { ArrowBack } from "@mui/icons-material";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { FormChangePassword } from "components/auth/FormChangePassword";
import { NewMyBreadcrumbs } from "components/elements/breadcrumbs/NewMyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { Loading } from "components/elements/loading/Loading";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import ModifyPasswordDto from "dto/user/modifypassword.dto";
import { UserDto } from "dto/user/user.dto";
import { useAuth } from "hooks/useAuth";
import { useResource } from "hooks/useResource";
import { IPage } from "interfaces/page.interface";
import React, { useState } from "react";
import { UserService } from "services/user/user.service";
import { CommonTools } from "tools/utils/commontools";

const service = new UserService();
const ProfilePage: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { user } = useAuth();
  const { LL } = useResource();
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultObject, setDefaultObject] = useState<ModifyPasswordDto>(
    new ModifyPasswordDto(CommonTools.processObjectField(user, ["email"]))
  );

  const handleSubmit = (data: ModifyPasswordDto) => {
    if (!data) return;
    setLoading(true);
    service.modifyPassword(handleResult, {}, data);
  };
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    setDefaultObject(
      new ModifyPasswordDto(CommonTools.processObjectField(user, ["email"]))
    );
    setLoading(false);
  };

  if (!user) return null;
  if (!mainObject) return null;
  if (!currentRoute) return null;
  if (loading) return <Loading />;
  return (
    <Box>
      <NewMyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbList(mainObject)}
        mainObject={mainObject}
      />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          width: "100%",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <MyNavBt
              href={"/"}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={CommonTools.generateMainUrl(mainObject)}
              component={IconButton}
            >
              <ArrowBack />
            </MyNavBt>
          </Box>
          <Box sx={{ mr: 10 }}>
            <Typography variant="h4" sx={{ my: 3, ml: 3, pr: 1 }}>
              {LL(mainObject)}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <DataDisplayBox>
        <Grid mb={3}>
          <DetailGrid
            object={service.populateDto(user)}
            fields={CommonTools.parseDetailFields(
              [
                "Email",
                "Status",
                "RegisterDate",
                "StatusEmail",
                "StatusPassword",
              ],
              UserDto.getDetailFields()
            )}
          />
        </Grid>
        <FormChangePassword
          defaultObject={defaultObject}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </DataDisplayBox>
    </Box>
  );
};

export { ProfilePage };
