
import Idto from 'interfaces/idto.interface';


export  class ChangePasswordByAdminDto implements Idto {
  
  password: string;

  constructor(
    password?: string
  ) {
    this.password = password || "";
  }
  
}
