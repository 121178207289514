import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class SiteConfigFileDto implements Idto {
  id?: string;
  identifier?: string;
  idfile?: string | null;
  file?: any;
  files?: File | null;
  deletefile?: boolean;

  constructor(
    id?: string,
    identifier?: string,
    idfile?: string,
    file?: any,
    files?: File| null,
    deletefile?: boolean
  ) {
    this.id = id || "";
    this.identifier = identifier || "";
    this.idfile = idfile || null;
    this.file = file || null;
    this.files = files || null;
    this.deletefile = deletefile || false;
  }

  static parseForEdit(obj: any): SiteConfigFileDto {
    return new SiteConfigFileDto(
      obj.id,
      obj.identifier,
      obj.idfile,
      obj.file,
      obj.files,
      false
    );
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_FILE,
        label: "File",
        value: ["file"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_FileColumn(LL, "idfile"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}

export class PostSiteConfigFileDto implements Idto {
  identifier?: string;
  idfile?: string | null;
  files?: any;
  deletefile?: boolean;
}
