import { useEffect, useState } from "react";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { useResource } from "hooks/useResource";
import { RouteTools } from "tools/utils/routetools";

import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

type Props = {
  [x: string]: any;
  setOpen: (value: boolean) => void;
};
const LeftPartUserMenu: React.FC<Props> = ({ setOpen, ...props }) => {
  const { currentRoute } = useResource();
  const [adminMenu, setAdminMenu] = useState(false);
  const [openedIdentifier, setOpenedIdentifier] = useState("");

  // ----------------------------------------
  useEffect(() => {
    let t = RouteTools.getAdminLeftMenu();
    t = processAdminMenSelected(t);
    setAdminMenu(t);
  }, [currentRoute]);

  const processAdminMenSelected = (objects: any) => {
    if (!objects) return false;
    if (!objects.length) return false;

    var rez = [];

    for (var k in objects) {
      var obj = objects[k];
      var childs = processAdminMenSelected(obj.childs);

      var isChildsSelected = false;

      if (childs && childs.length) {
        for (var i in childs) {
          if (!childs[i].isSelected) continue;
          isChildsSelected = true;

          break;
        }
      }

      obj.childs = childs;

      obj.isSelected = false;

      if (isChildsSelected) {
        obj.isSelected = true;
      } else {
        if (currentRoute) {
          let h = currentRoute.url.split("?");
          h = h[0];
          // // logger(
          //   "currentRoutecurrentRoutecurrentRoutecurrentRoute",
          //   currentRoute,
          //   obj.href, h
          // );
          if (
            obj.href == currentRoute.url ||
            obj.href == h ||
            (currentRoute &&
              currentRoute.historystate &&
              currentRoute.historystate._mainurl &&
              currentRoute.historystate._mainurl == obj.href)
          ) {
            obj.isSelected = true;
          }
        }
      }

      rez.push(obj);
    }

    // ---------

    return rez;
  };

  const openMenu = (item: any) => {
    // var item = obj.target;
    // logger("openMenu", item)
    if (item.identifier != openedIdentifier) {
      setOpenedIdentifier(item.identifier);
      setOpen(true);
    } else {
      setOpenedIdentifier("");
      // setOpen(false)
    }
  };

  const processAdminMenItemChilds = (item: any, level: any, i: any) => {
    const nextLevel = level + 1;

    if (!item.isSelected && item.identifier != openedIdentifier) return <></>;

    return processAdminMen(item.childs, nextLevel, item, i);
  };

  const processAdminMenItemDetailBt = (item: any) => {
    if (!item.childs) return <></>;

    if (!item.isSelected && item.identifier != openedIdentifier)
      return <ChevronRightRoundedIcon color="primary" sx={{ ml: "auto" }} />;

    return <ExpandMoreRoundedIcon color="primary" sx={{ ml: "auto" }} />;
  };

  const processAdminMenItemDetailIcon = (item: any) => {
    return item.getIcon();
  };

  const processAdminMenItem = (item: any, level: any, i: any) => {
    return (
      <div key={i}>
        <li
          className={item.isSelected ? "active" : "incative"}
          onClick={() => {
            openMenu(item);
          }}
        >
          {processAdminMenItemDetailIcon(item)}

          {item.href ? (
            <MyNavBt
              href={item.href}
              sx={{ width: "100%" }}
              _hstate={{}}
              _mainurl={""}
            >
              {item.label}
              {/* {level} */}
            </MyNavBt>
          ) : (
            <span>
              {item.label}
              {/* {level} */}
            </span>
          )}
          {processAdminMenItemDetailBt(item)}
        </li>
        {processAdminMenItemChilds(item, level, i)}
      </div>
    );
  };

  const processAdminMen = (
    objects: any,
    level: any,
    parentObj?: any,
    i?: any
  ) => {
    if (!objects) return <></>;
    if (!objects.length) return <></>;

    parentObj = parentObj ? parentObj : false;

    // const cl = vresults.isOk ? "active" : "disabled";
    if (i === undefined) i = 1;

    

    return (
      <ul key={i}>
        {objects.map((item: any, i: any) =>
          processAdminMenItem(item, level, i)
        )}
      </ul>
    );
  };

  return processAdminMen(adminMenu, 1);
};

export { LeftPartUserMenu };
