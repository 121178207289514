import { Box } from "@mui/material";
import RequestListDTO from "dto/app/requestlist.dto";
import { QuestionWithAnswerListTestDto } from "dto/course/question.dto";
import { TestDto } from "dto/course/test.dto";

import { useList } from "hooks/useList";
import React from "react";
import { QuestionService } from "services/course/question.service";
import { CommonTools } from "tools/utils/commontools";

import { CircularLoading } from "components/elements/loading/CircularLoading";
import { QuestionTestItem } from "./QuestionTestItem";
import { AddQuestionTest } from "./AddQuestionTest";

import { TestService } from "services/course/test.service";

type Props = {
  object: TestDto;
  setObject: (value: TestDto | null) => void;
};
const service = new QuestionService();
const serviceTest = new TestService();
const QuestionTestList: React.FC<Props> = ({ object, setObject }) => {
  const getList = (cb?: any, cbparameters?: any, data?: RequestListDTO) => {
    if (!object) return;
    if (!CommonTools.processObjectField(object, ["id"])) return;
    service.getQuestionByIdTest(
      CommonTools.processObjectField(object, ["id"]),
      cb,
      cbparameters
    );
  };
  const [loading, objects, , , setObjects] =
    useList<QuestionWithAnswerListTestDto>(getList, new RequestListDTO(), []);

  const handelUpdateResult = (data: QuestionWithAnswerListTestDto) => {
    if (!data) return;
    if (!objects) return;
    const newObjects = objects.map((item) => {
      if (item.question.id === data.question.id) return data;
      return item;
    });
    setObjects([...newObjects]);
    if (data.test) {
      setObject(serviceTest.populateDto(data.test));
    }
  };

  const handleAddResult = (data: QuestionWithAnswerListTestDto) => {
    if (!data) return;
    if (!objects) return;
    setObjects([...objects, data]);
    if (data.test) {
      setObject(serviceTest.populateDto(data.test));
    }
  };

  const handleDeleteResult = (id: string,obj:TestDto) => {
    if (!objects) return;
    const newObjects = objects.filter((item) => item.question.id !== id);
    setObjects([...newObjects]);
    if (obj) {
      setObject(obj);
    }
  };
  const processItem = (item: QuestionWithAnswerListTestDto, index: number) => {
    if (!item) return null;

    return (
      <QuestionTestItem
        key={index}
        object={item}
        handelResult={handelUpdateResult}
        handelDelete={handleDeleteResult}
      />
    );
  };
  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularLoading />
      </Box>
    );
  if (!objects) return null;
  // if (!objects.length) return null;
  return (
    <Box>
      {objects.map((item, index) => processItem(item, index))}
      <AddQuestionTest object={object} handleAddResult={handleAddResult} />
    </Box>
  );
};

export { QuestionTestList };
