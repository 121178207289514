import React, { useCallback, useLayoutEffect } from "react";

import { useEffect, useState } from "react";

import { CookiesProvider } from "react-cookie";

import { ResourceProvider } from "providers/ResourceProvider";

import { AuthProvider } from "providers/AuthProvider";

import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { MessageProvider } from "providers/MessageProvider";
import { AppRoutes } from "providers/AppRoutes";
import GeneralRepository from "repositories/general.repository";
import { PermissionProvider } from "providers/PermissionProvider";
import { ServerError } from "components/general/ServerError";

const App: React.FC = () => {
  const [_theme, setTheme] = useState({});
  const [serverError, setServerError] = useState<boolean>(false);

  const isServerError = useCallback(() => {
    return serverError;
  }, [serverError]);
  
  useEffect(() => {
    setTheme(theme);
  }, []);

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const processServerError = () => {
    setServerError(true);
  };
  
  useLayoutEffect(() => {
    GeneralRepository.setReloadFunction(handleReload);
    GeneralRepository.setServerErrorFunction(processServerError);
  }, []);

  useEffect(() => {
    GeneralRepository.setIsServerError(isServerError);
  }, [isServerError]);
  
  if (serverError) {
    return (
      <ThemeProvider theme={_theme}>
        <ServerError />
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={_theme}>
      <CookiesProvider>
        <AuthProvider>
          <PermissionProvider>
            <ResourceProvider>
              <MessageProvider>
                <AppRoutes />
              </MessageProvider>
            </ResourceProvider>
          </PermissionProvider>
        </AuthProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
};

export { App };
