import { Box } from "@mui/material";
import { PermissionDto } from "dto/user/permission.dto";
import React from "react";
import { PermissionItem } from "./PermissionItem";
import { PermissionActionGroup } from "./PermissionActionGroup";

type Props = {
  objects: PermissionDto[];
  idRole: string;
  group: string;
  setObjects: (objects: PermissionDto[]) => void;
  objectsFull: PermissionDto[];
};

const PermissionList: React.FC<Props> = ({
  objects,
  idRole,
  group,
  setObjects,
  objectsFull
}) => {
  const processItem = (item: PermissionDto, index: number) => {
    return (
      <PermissionItem
        key={index}
        item={item}
        idRole={idRole}
        setObjects={setObjects}
        objects={objectsFull}
      />
    );
  };

  const checked = PermissionDto.checkByRoleIdAndGroup(objects, idRole, group);
  return (
    <Box>
      <Box>
        <PermissionActionGroup
          group={group}
          idRole={idRole}
          checked={checked}
          setObjects={setObjects}
        />
      </Box>
      <Box>{objects.map((item, index) => processItem(item, index))}</Box>
    </Box>
  );
};

export { PermissionList };
