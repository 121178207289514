import { DetailGridItemProps } from "interfaces/detailobject.interface";
import React from "react";

import { Stack } from "@mui/material";

import { DetailGridItem } from "./DetailGridItem";
import { DataGridArrayItem } from "./DataGridArrayItem";

import { DataGridNoData } from "./DataGridNoData";
import { CommonTools } from "tools/utils/commontools";

const DetailGridArray: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
  const arr = CommonTools.processObjectFieldArray(object, item.value);
  if (!arr || arr.length === 0)
    return <DataGridNoData item={item} index={index} />;
  const fields = item.options?.fields;
  const direction = item.options?.stackOptions?.direction || "row";
  const divider =
    item.options?.stackOptions?.divider !== undefined ? (
      item.options?.stackOptions?.divider
    ) : (
      <>,</>
    );
  const spacing = item.options?.stackOptions?.spacing || 1;
  const alignItems = item.options?.stackOptions?.alignItems || "center";
  const justifyContent =
    item.options?.stackOptions?.justifyContent || "flex-start";

  if (!fields) return null;
  if (!fields.length) return null;
  return (
    <DetailGridItem item={item} index={index}>
      <Stack
        direction={direction}
        spacing={spacing}
        divider={divider}
        justifyContent={justifyContent}
        alignItems={alignItems}
      >
        {arr.map((obj: any, index: number) => {
          return (
            <DataGridArrayItem
              key={index}
              obj={obj}
              fields={fields}
              type={item.options?.displayArrayType}
              options={item.options}
            />
          );
        })}
      </Stack>
    </DetailGridItem>
  );
};

export { DetailGridArray };
