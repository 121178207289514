// MyCKEditor.tsx

import React, { useState, useEffect } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import "ckeditor5/ckeditor5.css";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  SourceEditing,
  FontSize,
  Alignment,
  Link,
  Heading,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  List,
  Indent,
  IndentBlock,
  MediaEmbed,
  SimpleUploadAdapter,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  LinkImage,
  ImageInsert,
  ImageInsertViaUrl,
} from "ckeditor5";
// import { Editor } from '@ckeditor/ckeditor5-core';

// const editor = ClassicEditor as unknown as Editor;
// const editor = ClassicEditor;

interface MyCKEditorProps {
  field?: string | undefined;
  name?: string | undefined;
  data: string;
  setObj: (field: any, value: any) => void;
}

const MyCKEditor: React.FC<MyCKEditorProps> = ({ data, setObj, ...props }) => {
  const [field, setField] = useState("");

  useEffect(() => {
    // const p: any = props;
    const f = props.field ? props.field : props.name;
    if (f != undefined) {
      setField(f);
    }
  }, [props]);

  const handleChange = (event: any, editor: any) => {
    if (!setObj) return;
    setObj(field, editor.getData());
  };

  return (
    <CKEditor
      data={data ?? ""}
      editor={ClassicEditor}
      onChange={handleChange}
      config={{
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "heading",
            "sourceEditing",
            "|",
            "fontfamily",
            "fontsize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "alignment",
            "|",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "insertImage",
            "mediaEmbed",
          ],

          shouldNotGroupWhenFull: false,
        },
        plugins: [
          Bold,
          Essentials,
          Italic,
          Mention,
          Paragraph,
          Undo,
          SourceEditing,
          FontSize,
          Alignment,
          Link,
          Heading,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          List,
          Indent,
          IndentBlock,
          MediaEmbed,
          SimpleUploadAdapter,
          Image,
          ImageCaption,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          LinkImage,
          ImageInsert,
          ImageInsertViaUrl,
        ],
        image: {
          toolbar: [
            "imageStyle:block",
            "imageStyle:side",
            "|",
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "linkImage",
          ],
          insert: {
            type: "auto",
            integrations: ["url"],
          },
        },

        mediaEmbed: {
          previewsInData: true,
        },
      }}
    />
  );
};

export default MyCKEditor;
