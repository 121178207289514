import { Grid } from "@mui/material";

import { IPage } from "interfaces/page.interface";
import React from "react";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";

import { Loading } from "components/elements/loading/Loading";
import { useDetailObject } from "hooks/useDetailObject";

import { TeacherDto } from "dto/user/teacher.dto";
import {TeacherService} from "services/user/teacher.service";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { CommonTools } from "tools/utils/commontools";
import { useResource } from "hooks/useResource";

const service = new TeacherService();
const DetailTeacher: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, editUrl, listUrl, object, loading } =
    useDetailObject<TeacherDto>(mainObject, currentRoute, get);
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
      >
        <MyNavBt
          href={CommonTools.userDetailUrl(object)}
          className="detailBtn"
          _hstate={{}}
          _mainurl={mainUrl}
        >
          {LL("DetailUser")}
        </MyNavBt>
      </HeaderDetail>
      <DataDisplayBox>
        <Grid>
          <DetailGrid object={object} fields={TeacherDto.getDetailFields()} />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { DetailTeacher };
