import React from "react";
import { useResource } from "hooks/useResource";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import { PostNewStudentDto } from "dto/user/student.dto";
import RequiredValidator from "validators/required.validator";
import { useForm } from "hooks/useForm";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { CircularLoading } from "components/elements/loading/CircularLoading";


type Props = {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (obj: PostNewStudentDto) => void;
  loading: boolean;
};

const DialogRegisterStudent: React.FC<Props> = ({
  open,
  handleClose,
  handleSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, isFormDisabled, setObjField] =
    useForm<PostNewStudentDto>(
      new PostNewStudentDto(),
      RequiredValidator.getValidators(["email"])
    );

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (isFormDisabled) return false;
    handleSubmit(obj);
  };

  
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{LL("Register_Student")}</DialogTitle>

      {loading ? (
        <CircularLoading />
      ) : (
        <form onSubmit={handleOnSubmit}>
          <DialogContent>
            <Box>
              <MyTextField
                tabIndex={0}
                fullWidth
                id="email"
                name="email"
                variant="outlined"
                color="primary"
                field="email"
                _label={LL("Email")}
                size="small"
                value={obj.email}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems={"center"}>
            <MyButton
              color="error"
              variant="contained"
              size="large"
              cb={handleClose}
              children={LL("Bt_Cancel")}
            />
            <MyButton
              tabIndex={1}
              disabled={isFormDisabled}
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
            </Stack>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export { DialogRegisterStudent };
