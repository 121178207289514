import { TestListColumns } from "components/course/test/TestListColumns";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class LessonDto implements Idto {
  id?: string;
  name?: string;
  description?: string;
  status?: number;
  ordercriteria?: number;

  istest?: boolean;

  lessonsettings?: any;
  idtypelesson?: string;

  idcourse?: string;
  lessonduration?: number;

  islive?: boolean;
  startdate?: number;
  enddate?: number;
  constructor(
    idcourse?: string,
    name?: string,
    description?: string,
    status?: number,
    ordercriteria?: number,
    idtypelesson?: string,
    islive?: boolean,
    startdate?: number,
    enddate?: number,
    id?: string
  ) {
    this.status = status || Status.ACTIVE;
    this.ordercriteria = ordercriteria || 0;
    this.islive = islive || false;
    this.lessonduration = 0;
    this.idcourse = idcourse || "";
    this.name = name || "";
    this.description = description || "";
    this.idtypelesson = idtypelesson || "";
    this.startdate = startdate || 0;
    this.enddate = enddate || 0;
    this.id = id || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "description",
        value: ["description"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typelesson",
        value: ["typelesson", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "islive",
        value: ["islive_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "startdate",
        value: ["startdate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "enddate",
        value: ["enddate_name"],
      },
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "islive"),
      ComponentCommonTools.columns_ConstantColumn(LL, "startdate"),
      ComponentCommonTools.columns_ConstantColumn(LL, "enddate"),
      ComponentCommonTools.columns_StandardColumn(LL, "lessonduration"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "typelesson",
        "name"
      ),
      TestListColumns.columnTestQuestions(LL),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        [
          "editspecial",
          "detailspecial",
          "delete",
          "thirdlevelattachment",
          "thirdlevelgallery",
          "thirdlevelvideo",
        ],
        ["detailspecial"],
        undefined,
        "lesson"
      ),
    ];
  }
  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idcourse";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idcourse") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };

  static parseToSelectOptions(data: Array<LessonDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["name"])
        )
      );
    });
    return result;
  }
}

export class PostLessonDto implements Idto {
  name?: string;
  description?: string;
  status?: number;
  ordercriteria?: number;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  istest?: boolean;
  idtest?: string | null;
  idlessonsettings?: string | null;
  lessonsettings?: any;
  idtypelesson?: string;
  idcourse?: string;
  lessonduration?: number;
  islive?: boolean;
  startdate?: number;
  enddate?: number;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.islive = false;
    this.lessonduration = 0;
  }
}
