import * as React from "react";
import {Box, Divider} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const useResponsive = () => {
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesCustom = useMediaQuery('(min-width:1050px)');

  return { matchesXS, matchesSM, matchesMD, matchesLG, matchesCustom };
};

const SkeletonTicketMessages: React.FC<SkeletonProps>= ({lines}) => {
  const { matchesXS, matchesSM, matchesMD, matchesLG } = useResponsive();

  let gridItemsCount;
  if (matchesSM) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 12;
  }


  const gridItem = (
    
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "column",
        width: "100%",
        gap: 2,
        mb: 2
      }}
    > 
      <Box sx={{width:"100%",display:"flex", justifyContent:"start",alignItems:"center"}}>
        <Box sx={{bgcolor:'#d9dbde', padding:2, borderRadius: '16px 16px 16px 0px', maxWidth:"400px", width:"40%", minWidth:"150px"}}>
        <Skeleton variant="text" sx={{ fontSize: 13, width:"40%", minWidth:"90px" }} />
        <Divider sx={{my:1, backgroundColor: '#d9dbde', }} />    
        <Skeleton variant="text" sx={{ fontSize: 14, width:"90%", minWidth:"90px" }} />
        <Skeleton variant="text" sx={{ fontSize: 14, width:"40%", minWidth:"90px" }} />
        <Box sx={{width:"100%", display:"flex", justifyContent:"end"}}>
        <Skeleton variant="text" sx={{ fontSize: 13, width:"10%", minWidth:"80px" }} />
        </Box>    
        </Box>
      </Box>  
      <Box sx={{width:"100%",display:"flex", justifyContent:"end",alignItems:"center"}}>
        <Box sx={{bgcolor:'#c5d5e6', padding:2, borderRadius: '16px 16px 0px 16px', maxWidth:"400px", width:"40%", minWidth:"150px" }}>
        <Skeleton variant="text" sx={{ fontSize: 14, width:"40%", minWidth:"90px" }} />
        <Divider sx={{my:1, backgroundColor: '#d9dbde', }} />    
        <Skeleton variant="text" sx={{ fontSize: 14, width:"90%", minWidth:"90px" }} />
        <Box sx={{width:"100%", display:"flex", justifyContent:"end"}}>
        <Skeleton variant="text" sx={{ fontSize: 13, width:"10%", minWidth:"80px" }} />
        </Box>    
        </Box>
      </Box>
      <Box sx={{width:"100%",display:"flex", justifyContent:"start",alignItems:"center"}}>
        <Box sx={{bgcolor:'#d9dbde', padding:2, borderRadius: '16px 16px 16px 0px', maxWidth:"400px", width:"40%", minWidth:"150px"}}>
        <Skeleton variant="text" sx={{ fontSize: 13, width:"40%", minWidth:"90px" }} />
        <Divider sx={{my:1, backgroundColor: '#d9dbde', }} />    
        <Skeleton variant="text" sx={{ fontSize: 14, width:"90%", minWidth:"90px" }} />
        <Skeleton variant="text" sx={{ fontSize: 14, width:"94%", minWidth:"90px" }} />
        <Skeleton variant="text" sx={{ fontSize: 14, width:"20%", minWidth:"90px" }} />
        <Box sx={{width:"100%", display:"flex", justifyContent:"end"}}>
        <Skeleton variant="text" sx={{ fontSize: 13, width:"10%", minWidth:"80px" }} />
        </Box>    
        </Box>
      </Box>   
    </Box>
    
    
  );

  return (
    <Box sx={{ width: "100%", height: 'auto' }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
        //   margin: "auto",
          padding: "20px 0px 20px 0px",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "100%",}}
          spacing={2}
        >
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonTicketAccordions: React.FC<SkeletonProps>= ({lines}) => {
  const { matchesXS, matchesSM, matchesMD, matchesLG } = useResponsive();

  let gridItemsCount;
  if (matchesSM) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 12;
  }


  const gridItem = (
    
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "column",
        width: "100%",
        gap: 2,
        mb: 5
      }}
    >
        <Grid xs={12} container spacing={2} mb={0}>
            <Grid xs={12} sx={{py:0, my:0}} >
                <Skeleton variant="text" sx={{ fontSize: "1.25rem", width: "33%", minWidth:"120px",py:"0px", my:0 }} />
            </Grid>
            <Grid xs={12} sx={{py:0, my:0}}  >
                <Skeleton variant="text" sx={{ fontSize: "1.25rem", width: "20%", minWidth:"90px", py:"0px", my:0 }} />
            </Grid>
            <Grid xs={12} sx={{display:"flex", justifyContent:"center", alignItems:"start", flexDirection:"column" , my:0, py:0}} >
                <Skeleton variant="text" sx={{ fontSize: "1.25rem", width: "33%", minWidth:"120px", py:"0px", my:0 }} />
            </Grid>
        </Grid>
    </Box>
    
  );

  return (
    <Box sx={{ width: "100%", height: 'auto',mt:5 }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
        //   margin: "auto",
          padding: "20px 0px 20px 0px",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "100%",}}
          spacing={2}
        >
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};
  
  
export {SkeletonTicketMessages,SkeletonTicketAccordions};