import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { TestDto } from "dto/course/test.dto";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { QuestionTestItem } from "./QuestionTestItem";
import {
  QuestionTestDto,
  QuestionWithAnswerListTestDto,
} from "dto/course/question.dto";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  object: TestDto;
  handleAddResult: (data: QuestionWithAnswerListTestDto) => void;
};

const AddQuestionTest: React.FC<Props> = ({ object, handleAddResult }) => {
  const [active, setActive] = useState(false);
  const { LL } = useResource();
  const handleOpen = () => {
    setActive(true);
  };

  const handleClose = () => {
    setActive(false);
  };
  const handleAddResultQuestion = (data: QuestionWithAnswerListTestDto) => {
    handleAddResult(data);
    handleClose();
  }
  return (
    <Box>
      {active ? (
        <Box>
          <QuestionTestItem
            object={
              new QuestionWithAnswerListTestDto(
                new QuestionTestDto(
                  CommonTools.processObjectField(object, ["id"])
                )
              )
            }
            defaultOpen
            allowExpand={false}
            label="Add"
            handelResult={handleAddResultQuestion}
            forAdd
          />
          <MyButton onClick={handleClose}>{LL("cancel")}</MyButton>
        </Box>
      ) : (
        <MyButton onClick={handleOpen}>{LL("add_question")}</MyButton>
      )}
    </Box>
  );
};

export { AddQuestionTest };
