import { Box } from "@mui/material";

import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { TestimonialDto } from "dto/user/testimonial.dto";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { AutocompleteTeacher } from "../teacher/AutocompleteTeacher";
import { AutocompleteUser } from "../user/AutocompleteUser";
import { AutocompleteCourse } from "components/course/course/AutocompleteCourse";

type Props = {
  defaultObject: TestimonialDto;
  onSubmit: (data: TestimonialDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormTestimonial: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<TestimonialDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "message",
      "iduser",
      "idteacher",
      "idcourse",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="message"
            name="message"
            variant="outlined"
            color="primary"
            _label={LL("message")}
            size="small"
            value={obj.message}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            inputProps={{ inputMode: "numeric", min: 0, max: 5 }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            id="rating"
            name="rating"
            variant="outlined"
            color="primary"
            _label={LL("rating")}
            size="small"
            value={obj.rating}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect
            value={obj.status}
            setObjField={setObjField}
            typeStatus="testimonial"
          />
        </Box>
        <Box mt={3}>
          <AutocompleteUser
            setObjField={setObjField}
            value={obj.iduser}
            defaultObject={obj.user}
          />
        </Box>
        <Box mt={3}>
          <AutocompleteTeacher
            setObjField={setObjField}
            value={obj.idteacher}
            defaultObject={obj.teacher}
          />
        </Box>

        {obj.idteacher ? (
          <Box mt={3}>
            <AutocompleteCourse
              setObjField={setObjField}
              value={obj.idcourse}
              defaultObject={obj.course}
              idTeacher={obj.idteacher}
            />
          </Box>
        ) : null}
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormTestimonial };
