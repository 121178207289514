import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";

import { Types } from "tools/types/types";

export class UserSettingsDto implements Idto {
  id?: string;
  iduser?: string;
  name?: string;
  surname?: string;
  birthday?: Date;
  idtypegender?: string;
  
  idlanguage?: string;
  files?: any;
  deleteavatar?: boolean;
  idavatar?: string | null;
  
  avatar?: any;

  constructor(
    iduser?: string,
    name?: string,
    surname?: string,
    birthday?: Date,
    idtypegender?: string,
    idlanguage?: string,
    files?: any,
    deleteavatar?: boolean,
    idavatar?: string | null,
    avatar?: any,
    id?: string
  ) {
    this.iduser = iduser || "";
    this.name = name || "";
    this.surname = surname || "";
    this.birthday = birthday || new Date();
    this.idtypegender = idtypegender || "";
    this.idlanguage = idlanguage || "";
    this.files = files || null;
    this.deleteavatar = deleteavatar || false;
    this.idavatar = idavatar || null;
    this.avatar = avatar || null;
    this.id = id || "";
  
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Surname",
        value: ["surname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Birthday",
        value: ["birthday_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typegender",
        value: ["typegender", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_IMAGE,
        label: "Avatar",
        value: ["avatar"],
        options: {
          width: 200,
          height: 200,
          withLink: true,
        },
      },
    ];
  }
}
