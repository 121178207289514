import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { PhoneDto } from "dto/user/phone.dto";
import MyTextField from "components/elements/form/mytextfield";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { PhoneTypeSelect } from "components/elements/select/PhoneTypeSelect";

type Props = {
  defaultObject: PhoneDto;
  onSubmit: (data: PhoneDto) => void;
  loading: boolean;
};
const FormPhone: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<PhoneDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "iduser",
      "countrycode",
      "phonenumber",
      "idtypephone",
      "status",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="countrycode"
            name="countrycode"
            variant="outlined"
            color="primary"
            _label={LL("countrycode")}
            size="small"
            value={obj.countrycode}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="phonenumber"
            name="phonenumber"
            variant="outlined"
            color="primary"
            _label={LL("phonenumber")}
            size="small"
            value={obj.phonenumber}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <PhoneTypeSelect
            value={obj.idtypephone}
            setObjectField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>

        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormPhone };
