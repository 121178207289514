import React from "react";
import { CourseEnrollmentService } from "services/course/courseenrollment.service";

import { IPage } from "interfaces/page.interface";

import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";

import { FormCourseEnrollment } from "components/special/courseenrollment/FormCourseEnrollment";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const service = new CourseEnrollmentService();
const SpecialCourseEnrollmentAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  const onSubmit = (obj: CourseEnrollmentDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "courseenrollment",
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="courseenrollment"
      />
      <DataDisplayBox>
        <FormCourseEnrollment
          defaultObject={new CourseEnrollmentDto(parentId)}
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialCourseEnrollmentAdd };
