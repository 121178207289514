import { Box } from "@mui/material";

import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { StudentDto } from "dto/user/student.dto";
import { StatusSelect } from "components/elements/select/StatusSelect";

import { AutocompleteUser } from "components/user/user/AutocompleteUser";

import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { StudyTypeSelect } from "components/elements/select/StudyTypeSelect";

type Props = {
  defaultObject: StudentDto;
  onSubmit: (data: StudentDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormStudent: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField,,vresults] = useForm<StudentDto>(
    defaultObject,
    RequiredValidator.getValidators(["iduser"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="institution"
            name="institution"
            variant="outlined"
            color="primary"
            _label={LL("institution")}
            size="small"
            value={obj.institution}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <AutocompleteUser
              setObjField={setObjField}
              value={obj.iduser}
              defaultObject={obj.user}
            />
          </Box>
        )}
        <Box mt={3}>
          <StudyTypeSelect
            value={obj.idtypestudies}
            setObjectField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <MyCKEditor
            data={obj.bio ? obj.bio : ""}
            setObj={setObjField}
            name="bio"
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormStudent };
