import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
export class PostLabelDto implements Idto {
  identifier?: string;
  type?: number;
  status?: number;
  value?: string;
  idlanguage?: string;
  idlabelvalue?: string;
  idlabel?: string;
  _nonupdate?: any
  constructor() {
    this.status = Status.ACTIVE;
}
}
