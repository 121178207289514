import { Box } from "@mui/material";

import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { UserDto } from "dto/user/user.dto";
import { StatusSelect } from "components/elements/select/StatusSelect";
import React from "react";

type Props = {
  defaultObject: UserDto;
  onSubmit: (data: UserDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormUser: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<UserDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "email",
      "password",
      "status",
      "statuspassword",
      "statusemail",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {forAdd && (
          <React.Fragment>
            <Box>
              <MyTextField
                tabIndex={0}
                fullWidth
                id="email"
                name="email"
                variant="outlined"
                color="primary"
                _label={LL("Email")}
                size="small"
                value={obj.email}
                setObj={setObjField}
                _vresults={vresults}
              />
            </Box>
            <Box mt={3}>
              <MyTextField
                tabIndex={1}
                fullWidth
                id="password"
                name="password"
                variant="outlined"
                color="primary"
                _label={LL("Password")}
                size="small"
                value={obj.password}
                setObj={setObjField}
                _vresults={vresults}
              />
            </Box>
          </React.Fragment>
        )}
        <Box mt={forAdd ? 3 : 0}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        <Box mt={3}>
          <StatusSelect
            value={obj.statuspassword}
            setObjField={setObjField}
            field="statuspassword"
            typeStatus="password"
            label="statuspassword"
          />
        </Box>
        <Box mt={3}>
          <StatusSelect
            value={obj.statusemail}
            setObjField={setObjField}
            field="statusemail"
            typeStatus="email"
            label="statusemail"
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormUser };
