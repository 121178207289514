import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class PermissionDto implements Idto {
  id?: string;
  name?: string;
  description?: string;
  acceptedroles?: string[];
  groupname?: string;

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Description",
        value: ["description"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_StandardColumn(LL, "description"),
      ComponentCommonTools.columns_ActionsColumn(LL, mainObj, deleteFun, [
        "edit",
      ]),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static groupByGroupName(
    data: PermissionDto[],
    search: string
  ): PermissionGroupDto[] {
    const groups: PermissionGroupDto[] = [];
    data.forEach((item) => {
      const group = groups.find((g) => g.groupname === item.groupname);
      if (group) {
        group.permissions.push(item);
      } else {
        groups.push(new PermissionGroupDto(item.groupname, [item]));
      }
    });
    return groups
      .filter((g) => g.groupname.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) => a.groupname.localeCompare(b.groupname));
  }

  static checkByRoleId(data: PermissionDto[], idRole: string): boolean {
    return data.every((item) => item.acceptedroles?.includes(idRole));
  }

  static checkByRoleIdAndGroup(
    data: PermissionDto[],
    idRole: string,
    group: string
  ): boolean {
    return data
      .filter(
        (item) =>
          item.groupname && item.groupname.toLowerCase() === group.toLowerCase()
      )
      .every((item) => item.acceptedroles?.includes(idRole));
  }
}

export class PermissionGroupDto implements Idto {
  groupname: string;
  permissions: PermissionDto[];

  constructor(groupname?: string, permissions?: PermissionDto[]) {
    this.groupname = groupname || "";
    this.permissions = permissions || [];
  }
}
