// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import ResultListDTO from "dto/app/resultlist.dto";

import IService from "interfaces/iservice.interface";
import {FAQRepository} from "repositories/static/faq.repository";

import GeneralService from "services/globalgeneral.service";

export class FAQService
  extends GeneralService<FAQRepository>
  implements IService
{
  constructor() {
    super(
      new FAQRepository(),
      [
        { key: "status", type: "status" },
        { key: "type", type: "faqs" },
      ],
      "question"
    );
  }
  // faqRepository: FAQRepository = new FAQRepository();
  // constructor() {
  //     super();
  //     this.handleGetList = this.handleGetList.bind(this);
  //     this.handleGet = this.handleGet.bind(this);
  // }
  // handleUpdate(result?: any, cbparameters?: any, data?: any): any {
  //     let rez = new ResultObjectDTO();
  //     rez = result;

  //     if (result.err) return;

  //     if (cbparameters && cbparameters._cb) {
  //         cbparameters._cb(rez, cbparameters, data);
  //     }
  // }

  // async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

  //     cbparameters = cbparameters ? cbparameters : {};
  //     cbparameters._cb = cb;
  //     this.faqRepository.update(id, this.handleGet, data, cbparameters);
  // }

  // handleDelete(result?: any, cbparameters?: any): any {
  //     let rez = new ResultObjectDTO();
  //     rez = result;
  //     if (result.err) return;

  //     if (cbparameters && cbparameters._cb) {
  //         cbparameters._cb(rez, cbparameters);
  //     }
  // }

  // async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
  //     cbparameters = cbparameters ? cbparameters : {};
  //     cbparameters._cb = cb;

  //     this.faqRepository.delete(id, this.handleDelete, cbparameters);
  // }

  // handleGet(result?: any, cbparameters?: any, data?: any): any {
  //     let rez = new ResultObjectDTO();
  //     rez = result;
  //     if (result.err) return;
  //     this.populateObject(rez.obj, result.obj, [
  //         { key: 'status', type: 'status' },
  //         { key: 'type', type: 'faqs' },
  //     ]);
  //     if (cbparameters && cbparameters._cb) {
  //         cbparameters._cb(rez, cbparameters, data);
  //     }

  // return rez;
  // }

  // async get(id: string, cb?: any, cbparameters?: any,idLanguage?:string): Promise<any> {
  //     cbparameters = cbparameters ? cbparameters : {};
  //     cbparameters._cb = cb;
  //     const t = await this.faqRepository.get(id, this.handleGet, cbparameters, idLanguage);
  //     if (cb == undefined)
  //     {
  //         return this.handleGet(t);
  //     }
  // }

  // handleGetList(result?: any, cbparameters?: any, data?: any): any {
  //     let rez = new ResultListDTO();

  //     rez = result;
  //     if (result.err) return;

  //     this.parseResultPopulate(result, rez, [
  //         { key: 'status', type: 'status' },
  //         { key: 'type', type: 'faqs' },
  //     ]);

  //     if (cbparameters && cbparameters._cb) {
  //         cbparameters._cb(rez, cbparameters, data);
  //     }

  //     return rez;
  // }

  // async getList(
  //     cb?: any,
  //     cbparameters?: any,
  //     data?: RequestListDTO
  // ): Promise<any> {
  //     cbparameters = cbparameters ? cbparameters : {};
  //     cbparameters._cb = cb;
  //     const t = await this.faqRepository.getList(this.handleGetList, cbparameters, data);
  //     if (cb == undefined)
  //     {
  //         return this.handleGetList(t);
  //     }
  // }

  // handleAdd(result?: any, cbparameters?: any, data?: any): any {
  //     let rez = new ResultObjectDTO();
  //     rez = result;
  //     if (result.err) return;

  //     if (cbparameters && cbparameters._cb) {
  //         cbparameters._cb(rez, cbparameters, data);
  //     }
  // }

  // async add(
  //     cb?: any,
  //     cbparameters?: any,
  //     data?: any
  // ): Promise<any> {
  //     cbparameters = cbparameters ? cbparameters : {};
  //     cbparameters._cb = cb;

  //     this.faqRepository.add(this.handleGet, cbparameters, data);
  // }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "question";

//       obj.sortcriteria = [sobj];
//     }

//     return obj;
//   }
  // getDefaultRequestList(onPage?: number): RequestListDTO {
  //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
  //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
  //     var defaultRequestList = new RequestListDTO();
  //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
  //     defaultRequestList.onpage = onPage;
  //     var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
  //     defaultRequestSortCriteria.asc = true;
  //     defaultRequestSortCriteria.field = 'question';
  //     defaultRequestList.sortcriteria = []
  //     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
  //     return defaultRequestList;
  // }
}
