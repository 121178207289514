import { IPage } from "interfaces/page.interface";
import React from "react";
import { TabCoursePriceNeedMoreInfo } from "./TabCoursePriceNeedMoreInfo";
import { TabCoursePriceAdd } from "./TabCoursePriceAdd";
import { TabCoursePriceEdit } from "./TabCoursePriceEdit";

export type TabCoursePriceProps = IPage & {
  idTypeCourse: string;
  idCourse: string;
  idCoursePrice: string;
};

const TabCoursePrice: React.FC<TabCoursePriceProps> = ({
  idTypeCourse,
  idCoursePrice,
  currentRoute,
  idCourse,
  mainObject,
}) => {
  return (
    <TabCoursePriceNeedMoreInfo idTypeCourse={idTypeCourse}>
      {idCoursePrice ? (
        <TabCoursePriceEdit
          idCoursePrice={idCoursePrice}
          idTypeCourse={idTypeCourse}
          idCourse={idCourse}
          mainObject={mainObject}
          currentRoute={currentRoute}
        />
      ) : (
        <TabCoursePriceAdd
          idTypeCourse={idTypeCourse}
          idCourse={idCourse}
          mainObject={mainObject}
          currentRoute={currentRoute}
          idCoursePrice={""}
        />
      )}
    </TabCoursePriceNeedMoreInfo>
  );
};

export { TabCoursePrice };
