import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import DateTools from "tools/utils/date.tools";
import { CommonTools } from "tools/utils/commontools";

export class CourseTeacherRequestDto implements Idto {
  id?: string;
  idteacher?: string;
  idmanager?: string;
  idcourse?: string;
  date?: number;
  status?: number;
  changedate?: number;
  type?: number;
  managermessage?: string;
  teachermessage?: string;

  manager?: any;
  teacher?: any;
  course?: any;

  constructor(
    id?: string,
    idteacher?: string,
    idmanager?: string,
    date?: number,
    status?: number,
    changedate?: number,
    type?: number,
    managermessage?: string,
    teachermessage?: string,
    idcourse?: string
  ) {
    this.id = id || "";
    this.idteacher = idteacher || "";
    this.idmanager = idmanager || "";
    this.date = date || DateTools.getTimeStamp();
    this.status = status || Status.UNPROCESSED;
    this.changedate = changedate || DateTools.getTimeStamp();
    this.type = type || Types.TYPE_REQUEST_TEACHER_COURSE_UNPUBLISH;
    this.managermessage = managermessage || "";
    this.teachermessage = teachermessage || "";
    this.idcourse = idcourse || "";
  }
  static parseForEdit = (obj: any): any => {
    if (!CommonTools.processObjectField(obj, ["managermessage"]))
      obj.managermessage = "";
    return obj;
  };
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "date",
        value: ["date_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "changedate",
        value: ["changedate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "teacherfullname",
        value: ["teacherfullname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "teacher_email",
        value: ["teacher", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "teachermessage",
        value: ["teachermessage"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "managerfullname",
        value: ["managerfullname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "manager_email",
        value: ["manager", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "managermessage",
        value: ["managermessage"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "course_name",
        value: ["course", "name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "teacherfullname"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "teacher", "email"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_ConstantColumn(LL, "date"),
      ComponentCommonTools.columns_ConstantColumn(LL, "changedate"),
      ComponentCommonTools.columns_StandardColumn(LL, "managerfullname"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "manager", "email"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "course", "name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editteacherrequest", "detail"],
        [],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("teacherrequest", true));
    obj.addFilter(t);

    t = new TbFilterDTO("type", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Types.GA("teacherrequestcoursetype", true));
    obj.addFilter(t);

    return obj;
  }
}

export class PostCourseTeacherRequestDto implements Idto {
  idteacher?: string;
  idmanager?: string;
  idcourse?: string;
  date?: number;
  status?: number;
  changedate?: number;
  type?: number;
  managermessage?: string;
  teachermessage?: string;
  manager?: any;
  teacher?: any;
  course?: any;
}
