// import React, { useEffect, useState } from "react";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { logger } from "tools/utils/logger";
// import { useResource } from "hook/useResource";

// import { MySelect } from "components/elements/Select/MySelect";
// import { Types } from "tools/types/types"
// import SelectOptions from "dto/app/selectoptions.dto";
// import { LabelTypes } from "tools/types/labeltypes";
// import { Skeleton } from "@mui/material";
// type TypeSelectProps = {
//     setObj: ((field: any, value: any) => void);
//     field: any;
//     _defaultValue?: number;
//     [key: string]: any; // for the rest of the props which we do not have types for
// }
// const TypesSelect: React.FC<TypeSelectProps> = ({
//     setObj,
//     field,

//     _defaultValue,
//     ...props
// }) => {
//     const { LL } = useResource();
//     const defaultValue = _defaultValue ? _defaultValue : '';
//     const label = "Type";
//     const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
//     const [loading, setLoading] = useState<boolean>(true);
//     const [value, setValue] = useState<number | any>(defaultValue);
//     const processOptions = () => {
//         const options: Array<SelectOptions> = Types.GA("pagetype");
//         setOptions(options);
//     }

//     useEffect(() => {
//         processOptions()
//     }, []);

//     useEffect(() => {
//         checkIsLoading();
//     }, [options]);

//     useEffect(() => {
//         setObj(field, value);
//     }, [value]);

//     const checkIsLoading = () => {
//         let load: boolean = false;
//         if (options === undefined) load = true;
//         setLoading(load);
//     };

//     return !loading ? (
//         <MySelect
//             options={options}
//             _label={label}
//             setValue={setValue}
//             value={value}
//             {...props}
//         />
//     ) : (<Skeleton variant="rectangular" height={50} />);
// };

// export { TypesSelect };


import { Types } from "tools/types/types";
import { StaticSelect } from "./newSelect/StaticSelect";


type Props = {
  field?: string;
  setObjectField: (field: string, value: any) => void;
  value?: string | number;
};
const TypesSelect: React.FC<Props> = ({ field, setObjectField, value }) => {
  return (
    <StaticSelect
      setObjField={setObjectField}
      value={value}
      service={Types}
      field={field}
      label="Type"
      type="pagetype"
    />
  );
};

export { TypesSelect };
