import { Box } from "@mui/material";
import { BlogCategoryTypeSelect } from "components/elements/select/BlogCategoryTypeSelect";
import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { FAQDto } from "dto/static/faq.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { LanguageSelect } from "components/static/language/newLanguageSelect";

type Props = {
  defaultObject: FAQDto;
  onSubmit: (data: FAQDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormFAQ: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults, setObjectLanguage] =
    useForm<FAQDto>(
      defaultObject,
      RequiredValidator.getValidators([
        "question",
        "answer",
        "idtypeblogcategory",
      ])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="question"
            name="question"
            variant="outlined"
            color="primary"
            _label={LL("question")}
            size="small"
            value={obj.question}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="answer"
            name="answer"
            variant="outlined"
            color="primary"
            _label={LL("answer")}
            size="small"
            value={obj.answer}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            id="ordercriteria"
            name="ordercriteria"
            variant="outlined"
            color="primary"
            _label={LL("ordercriteria")}
            size="small"
            value={obj.ordercriteria}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        <Box mt={3}>
          <BlogCategoryTypeSelect
            value={obj.idtypeblogcategory}
            setObjectField={setObjField}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect
              value={obj.idlanguage ?? ""}
              setValue={setObjectLanguage}
            />
          </Box>
        )}
       <Box mt={3}>
          <MyButton
            tabIndex={6}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormFAQ };
