// import React, { useEffect, useState } from "react";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { logger } from "tools/utils/logger";
// import { useResource } from "hook/useResource";
// import RoleService from "services/user/role.service";
// import { MySelect } from "components/elements/Select/MySelect";
// import RequestListDTO from 'dto/app/requestlist.dto';
// import SelectOptions from "dto/app/selectoptions.dto";
// import ResultListDTO from 'dto/app/resultlist.dto';
// import {RoleDto} from 'dto/user/role.dto';
// import { CommonTools } from 'tools/utils/commontools';
// import { Skeleton } from "@mui/material";
// const roleService = new RoleService();

// type RoleSelectProps = {
//     setObj: ((field: any, value: any) => void);
//     field: any;
//     obj: any;
//     _defaultValues?: string;
//     [key: string]: any; // for the rest of the props which we do not have types for
// }

// const RoleSelect: React.FC<RoleSelectProps> = ({
//     setObj,
//     field,
//     obj,
//     _defaultValues,
//     ...props
// }) => {
//     const { LL } = useResource();

//     const label = "Role";
//     const defaultValues = _defaultValues !== undefined ? _defaultValues : '';
//     const [options, setOptions] = useState<Array<SelectOptions>>(new Array<SelectOptions>());
//     const [loading, setLoading] = useState<boolean>(true);
//     const [value, setValue] = useState<string>(defaultValues);
//     const [data, setData] = useState<Array<RoleDto> | undefined>();
//     const onPage = 2000;

//     const [reqList, setReqList] = useState<RequestListDTO>(roleService.getDefaultRequestListSelect(onPage));

//     const loadObjects = (data: ResultListDTO) => {
//         setData(data.objects);
//     };

//     const getReqList = () => {
//         const reqList =roleService.getDefaultRequestListSelect(onPage)
//         setReqList(reqList);
//     };

//     const getList = () => {
//         setLoading(true);
//        roleService.getList(loadObjects, {}, reqList);
//     };

//     const processOptions = () => {

//         if (data !== undefined) {
//             const options: Array<SelectOptions> = data.map((item) => {
//                 const obj = {
//                     label: item.name,
//                     value: item.id
//                 }
//                 return obj;
//             })
//             setOptions(options);
//         }

//     }

//     useEffect(() => {
//         setLoading(true);
//         if (CommonTools.atLeastOneFieldDefined(reqList)) {
//             getList();
//         }
//     }, [reqList]);

//     useEffect(() => {
//         if (data !== undefined) processOptions()
//         checkIsLoading();
//     }, [options, data]);

//     useEffect(() => {
//         setObj(field, value);

//     }, [value]);

//     const checkIsLoading = () => {
//         let load: boolean = false;
//         if (data === undefined) load = true;
//         if (options === undefined) load = true;
//         // if (value === undefined) load = true;
//         setLoading(load);
//     };

//     return !loading && options && options.length > 0 ? (
//         <MySelect
//             options={options}
//             variant="outlined"
//             _label={label}
//             setValue={setValue}
//             value={value}
//         />
//     ) : (<Skeleton variant="rectangular" height={50} />);
// };

// export { RoleSelect };

import React from "react";
import RequestListDTO from "dto/app/requestlist.dto";
import { ServerSelect } from "./newSelect/ServerSelect";
import {RoleService} from "services/user/role.service";
import { RoleDto } from "dto/user/role.dto";

const service = new RoleService();

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
};

const RoleSelect: React.FC<Props> = ({
  field = "idrole",
  setObjectField,
  value,
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    service.getList(cb, cbParams, data);
  };

  return (
    <ServerSelect
      processOptions={RoleDto.parseToSelectOptions}
      getList={getList}
      field={field}
      setObjectField={setObjectField}
      value={value}
      label="Role"
    />
  );
};

export { RoleSelect };
