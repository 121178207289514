import { Box } from "@mui/material";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";
import { SiteConfigFileDto } from "dto/static/siteconfigfile.dto";
import { MyFileField } from "components/elements/form/MyFileField";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";


type Props = {
  defaultObject: SiteConfigFileDto;
  onSubmit: (data: SiteConfigFileDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormSiteConfigFile: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();
  

  const [obj, disabled, setObjField, setObject, vresults] =
    useForm<SiteConfigFileDto>(
      defaultObject,
      RequiredValidator.getValidators(["identifier"])
    );

  const setCheckDeleteFile = (value: any) => {
    
    CommonTools.handleCheckValue(obj, setObject, "deletefile", value);
  };
  const processFileDelete = () => {
    if (forAdd) return null;
    if (!obj) return null;
    return (
      <Box mt={3}>
        {ComponentCommonTools.processCheckboxDelete(
          obj,
          obj,
          "file",
          "deletefile",
          "name",
          setCheckDeleteFile,
          LL
        )}
      </Box>
    );
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="identifier"
            name="identifier"
            variant="outlined"
            color="primary"
            _label={LL("label_text_field_identifier")}
            size="small"
            value={obj.identifier}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyFileField
            field="files"
            label={LL("field_file")}
            setObj={setObject}
            obj={obj}
          />
        </Box>
        {processFileDelete()}
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormSiteConfigFile };
