import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class PostTransactionDto implements Idto {
  id?: string;
  idcurrency?: string;
  data?: number;
  status?: number;
  typeowner?: number;
  transactiontype?: number;
  idstudent?: string;
  idteacher?: string;
  amount?: number;
  balancevalue?: number;
  currency?: any;
  student?: any;
  teacher?: any;
  constructor() {
    this.status = Status.ACTIVE;
  }
}

export class TransactionDto implements Idto {
  id?: string;
  idcurrency?: string;
  data?: number;
  status?: number;
  typeowner?: number;
  transactiontype?: number;
  idstudent?: string;
  idteacher?: string;
  amount?: number;
  balancevalue?: number;

  currency?: any;
  student?: any;
  teacher?: any;
  constructor(
    id?: string,
    idcurrency?: string,
    data?: number,
    status?: number,
    typeowner?: number,
    transactiontype?: number,
    idstudent?: string,
    idteacher?: string,
    amount?: number,
    balancevalue?: number
  ) {
    this.id = id || "";
    this.status = status || Status.ACTIVE;
    this.idcurrency = idcurrency || "";
    this.data = data || 0;
    this.typeowner = typeowner || Types.TYPE_OWNER_STUDENT;
    this.transactiontype = transactiontype || 0;
    this.idstudent = idstudent || "";
    this.idteacher = idteacher || "";
    this.amount = amount || 0;
    this.balancevalue = balancevalue || 0;
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "amount"),
      ComponentCommonTools.columns_ConstantColumn(LL, "data"),
      ComponentCommonTools.columns_ConstantColumn(LL, "status"),
      ComponentCommonTools.columns_ConstantColumn(LL, "typeowner"),
      ComponentCommonTools.columns_ConstantColumn(LL, "transactiontype"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "currency", "name"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "student", "email"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "teacher", "email"),
      ComponentCommonTools.columns_StandardColumn(LL, "balancevalue"),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("daterange", FilterTools.fiterTypeDateRange);
    obj.addFilter(t);

    return obj;
  }
}
