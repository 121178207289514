import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

import { StudentDto } from "dto/user/student.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import FilterTools from "tools/utils/filtertools";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

export class CourseEnrollmentDto implements Idto {
  id?: string;
  idcourse?: string;
  idstudent?: string;
  status?: number;
  paymentstatus?: number;
  data?: number;

  numberoflessons?: number;
  numberoflessonspassed?: number;
  coursepercentage?: number;
  student?: StudentDto;

  constructor(
    idcourse?: string,
    idstudent?: string,
    status?: number,
    paymentstatus?: number,
    data?: number
  ) {
    this.status = status || Status.ENR_NEED_CHECKOUT;
    this.paymentstatus = paymentstatus || Status.PAYMENT_UNPAID;
    this.idcourse = idcourse || "";
    this.idstudent = idstudent || "";
    this.data = data || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "student",
        value: ["student", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "paymentstatus",
        value: ["paymentstatus_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "data",
        value: ["data_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "numberoflessons",
        value: ["numberoflessons"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "numberoflessonspassed",
        value: ["numberoflessonspassed"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "coursepercentage",
        value: ["coursepercentage"],
      },
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_ConstantColumn(LL, "status"),
      ComponentCommonTools.columns_ConstantColumn(LL, "paymentstatus"),
      ComponentCommonTools.columns_ConstantColumn(LL, "data"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "student", "email"),
      ComponentCommonTools.columns_StandardColumn(LL, "numberoflessons"),
      ComponentCommonTools.columns_StandardColumn(LL, "numberoflessonspassed"),
      ComponentCommonTools.columns_StandardColumn(LL, "coursepercentage"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        "courseenrollment"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    return obj;
  }

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idcourse";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idcourse") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}

export class PostCourseEnrollmentDto implements Idto {
  idcourse?: string;
  idstudent?: string;
  status?: number;
  paymentstatus?: number;
  data?: number;

  constructor() {
    this.status = Status.ENR_NEED_CHECKOUT;
    this.paymentstatus = Status.PAYMENT_UNPAID;
  }
}
