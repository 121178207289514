import React, { useState } from "react";

import { IPage } from "interfaces/page.interface";

import { HeaderAdd } from "components/elements/add/HeaderAdd";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useResource } from "hooks/useResource";
import { useGetUrls } from "hooks/useGetUrls";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { FormSupportArticleCategory } from "components/static/supportarticle/FormSupportArticleCategory";
import { SupportArticleCategoryDto } from "dto/support/supportarticlecategory.dto";
import { SupportArticleCategoryService } from "services/support/supportarticlecategory.service";

const service = new SupportArticleCategoryService();
const AddSupportArticleCategory: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject ?? "", currentRoute);
  const [loading, setLoading] = useState<boolean>(false);
  const { _idlanguage } = useResource();
  const onSubmit = (obj: SupportArticleCategoryDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["idlanguage"]),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
        <FormSupportArticleCategory
          defaultObject={
            new SupportArticleCategoryDto(
              _idlanguage,
              CommonTools.processObjectField(currentRoute, [
                "historystate",
                "idParent",
              ])
            )
          }
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { AddSupportArticleCategory };
