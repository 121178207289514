import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {SiteConfigFileRepository} from "repositories/static/siteconfigfile.repository";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class SiteConfigFileService
  extends GeneralService<SiteConfigFileRepository>
  implements IService
{
  constructor() {
    super(
      new SiteConfigFileRepository(),[],"identifier");
  }
  

  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = 'identifier';
    defaultRequestList.sortcriteria = []
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }

}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";

// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import SiteConfigFileRepository from "repositories/siteconfig/siteconfigfile.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { PostSiteConfigFileDto, SiteConfigFileDto } from "dto/app/siteconfigfile.dto";

// export default class SiteConfigFileService extends GeneralService implements IService {
//   siteConfigFileRepository: SiteConfigFileRepository = new SiteConfigFileRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   prepareObjectUpdate(obj: any): SiteConfigFileDto {
//     const rez = new SiteConfigFileDto();
//     if (obj.hasOwnProperty('id')) rez.id = obj.id;
//     if (obj.hasOwnProperty('idfile') && obj.idfile) rez.idfile = obj.idfile;
//     if (obj.hasOwnProperty('identifier')) rez.identifier = obj.identifier;
//     if (obj.hasOwnProperty('deletefile') && obj.deletefile) rez.deletefile = obj.deletefile;
//     if(obj.hasOwnProperty('files')) rez.files = obj.files;
//     return rez;
// }

//   async update(id: string, cb?: any, cbparameters?: any, data?: PostSiteConfigFileDto): Promise<any> {
    
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.siteConfigFileRepository.update(id, this.handleUpdate, this.prepareObjectUpdate(data), cbparameters);
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.siteConfigFileRepository.delete(id, this.handleDelete, cbparameters);
//   }


//   handleGetServerSiteConfigFilesHash(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async getServerSiteConfigFilesHash(cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.siteConfigFileRepository.getServerSiteConfigFilesHash(
//       this.handleGetServerSiteConfigFilesHash,
//       cbparameters
//     );
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
    
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.siteConfigFileRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
    
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;



//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//         return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.siteConfigFileRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(
//     cb?: any,
//     cbparameters?: any,
//     data?: PostSiteConfigFileDto
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.siteConfigFileRepository.add(this.handleGetList, cbparameters, data);
//   }


  // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
  //   onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
  //   if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
  //   var defaultRequestList = new RequestListDTO();
  //   defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
  //   defaultRequestList.onpage = onPage;
  //   var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
  //   defaultRequestSortCriteria.asc = true;
  //   defaultRequestSortCriteria.field = 'identifier';
  //   defaultRequestList.sortcriteria = []
  //   defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
  //   return defaultRequestList;
  // }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "identifier";

//       obj.sortcriteria = [sobj];
//     }

//     return obj;
//   }
// }
