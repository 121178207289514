import React from "react";

import { IPage } from "interfaces/page.interface";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box, Tab, Tabs } from "@mui/material";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";

import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { FormTeacher } from "components/user/teacher/FormTeacher";
import { TeacherDto, TeacherMediaDto } from "dto/user/teacher.dto";
import {TeacherService} from "services/user/teacher.service";
import { TabPanel } from "components/elements/tabs/TabPanel";
import { useResource } from "hooks/useResource";
import { FormTeacherMedia } from "components/user/teacher/FormTeacherMedia";


const service = new TeacherService();
const EditTeacher: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };

  const { LL } = useResource();
  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    handleChangeTab,
    tabValue,
  } = useEditObject<TeacherDto>(mainObject, currentRoute, get);

  const onSubmit = (obj: TeacherDto) => {
    if (!obj) return;
    setLoading(true);
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };

  const onSubmitMedia = (obj: TeacherMediaDto) => {
    if (!obj) return;

    if (
      object &&
      (object.hasOwnProperty("attachmentcv") ||
        object.hasOwnProperty("videopresentation") ||
        object.hasOwnProperty("videolessonexample")) &&
      (object.attachmentcv ||
        object.videopresentation ||
        object.videolessonexample)
    ) {
      service.updateMedia(
        ComponentCommonTools.handleSuccessAddAndEdit,
        { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
        obj
      );
    } else {
      service.addMedia(
        ComponentCommonTools.handleSuccessAddAndEdit,
        { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
        obj
      );
    }
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
      />
      <Box maxWidth="md" className="tabs">
        <Tabs variant="scrollable" value={tabValue} onChange={handleChangeTab}>
          <Tab label={LL("Edit_Teacher")} value={"generalinfo"} />
          <Tab label={LL("Teacher_Media")} value={"media"} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={"generalinfo"}>
        <DataDisplayBox>
          <Box>
            <FormTeacher
              defaultObject={object}
              onSubmit={onSubmit}
              loading={loading}
            />
          </Box>
        </DataDisplayBox>
      </TabPanel>
      <TabPanel value={tabValue} index={"media"}>
        <DataDisplayBox>
          <Box>
            <FormTeacherMedia
              defaultObject={new TeacherMediaDto(id)}
              onSubmit={onSubmitMedia}
              loading={loading}
              teacherObject={object}
            />
          </Box>
        </DataDisplayBox>
      </TabPanel>
    </React.Fragment>
  );
};

export { EditTeacher };
