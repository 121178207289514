import { Box, Stack, IconButton } from "@mui/material";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import  SpecialTopInfo  from "components/special/SpecialTopInfo";
import { MyBreadcrumbs } from "../breadcrumbs/MyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";

type Props = {
  mainObject: string;
  mainUrl: string;
  parentType: string;
  parentId: string;
  specialType: string;
  firstLevelParentId: string;
  firstLevelParentType: string;
};

const HeaderAddSpecialThirdLevel: React.FC<Props> = ({
  mainObject,
  mainUrl,
  parentType,
  parentId,
  specialType,
  firstLevelParentId,
  firstLevelParentType,
}) => {
  return (
    <React.Fragment>
      <MyBreadcrumbs
        childs={BreadcrumbDto.getBreadcrumbAddThirdLevel(
            firstLevelParentType,
            firstLevelParentId,
            parentType,
            parentId,
            specialType
        )}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",

          width: "100%",
        }}
        maxWidth="md"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <MyNavBt
              href={mainUrl}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </MyNavBt>
          </Box>
        </Stack>
        <Box>
          <SpecialTopInfo
            parentType={parentType}
            parentId={parentId}
            specialtype={specialType}
            suplimentar={"add"}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export { HeaderAddSpecialThirdLevel };
