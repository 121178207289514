import React from "react";
import { GalleryService } from "services/system/gallery.service";
import { IPage } from "interfaces/page.interface";
import { GalleryDto } from "dto/system/gallery.dto";
import { FormGallery } from "components/special/gallery/FormGallery";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useResource } from "hooks/useResource";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useAddSpecialThirdLevel } from "hooks/useAddSpecialThirdLevel";
import { HeaderAddSpecialThirdLevel } from "components/elements/add/HeaderAddSpecialThirdLevel";

const service = new GalleryService();
const ThirdLevelSpecialGalleryAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const {
    mainUrl,
    loading,
    setLoading,
    parentId,
    parentType,
    firstLevelParentId,
    firstLevelParentType,
  } = useAddSpecialThirdLevel(currentRoute);

  const { _idlanguage } = useResource();
  const onSubmit = (obj: GalleryDto) => {
    if (!obj) return;
    obj.idparent = parentId;
    obj.parent = parentType;
    setLoading(true);
    service.addMultipart(
      ComponentCommonTools.handleSuccessAddAndEditSpecialThirdLevel,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "thirdlevelgallery",
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["idlanguage"]),
        firstLevelParentId,
        firstLevelParentType,
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecialThirdLevel
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="thirdlevelgallery"
        firstLevelParentId={firstLevelParentId}
        firstLevelParentType={firstLevelParentType}
      />
      <DataDisplayBox>
        <FormGallery
          defaultObject={new GalleryDto(_idlanguage)}
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { ThirdLevelSpecialGalleryAdd };
