import { Grid, Link } from "@mui/material";

import { IPage } from "interfaces/page.interface";
import React from "react";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";

import { Loading } from "components/elements/loading/Loading";
import { useDetailObject } from "hooks/useDetailObject";

import { CourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
import { CourseTeacherRequestService } from "services/course/courseteacherrequest.service";
import { CommonTools } from "tools/utils/commontools";
import { useResource } from "hooks/useResource";
import { Status } from "tools/types/status";

const service = new CourseTeacherRequestService();
const DetailCourseTeacherRequest: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, editUrl, listUrl, object, loading } =
    useDetailObject<CourseTeacherRequestDto>(mainObject, currentRoute, get);
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
        showEdit={object.status === Status.UNPROCESSED}
      >
        <Link
          href={
            CommonTools.processObjectField(object, ["idteacher"])
              ? `/teacher/${CommonTools.processObjectField(object, [
                  "idteacher",
                ])}`
              : ""
          }
          className="detailBtn"
          target="_blank"
          rel="noopener noreferrer"
        >
          {LL("DetailTeacher")}
        </Link>
        <Link
          href={
            CommonTools.processObjectField(object, ["idcourse"])
              ? `/course/${CommonTools.processObjectField(object, [
                  "idcourse",
                ])}`
              : ""
          }
          className="detailBtn"
          target="_blank"
          rel="noopener noreferrer"
        >
          {LL("DetailCourse")}
        </Link>
      </HeaderDetail>

      <DataDisplayBox>
        <Grid>
          <DetailGrid
            object={object}
            fields={CourseTeacherRequestDto.getDetailFields()}
          />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { DetailCourseTeacherRequest };
