import React, {  useState } from "react";

import { LanguageBlockBt } from "./LanguageBlockBt";

import { LanguageBlockDetail } from "./LanguageBlockDetail";
import Menu from '@mui/material/Menu';
import { Box } from "@mui/material";



const TopLanguageBlock: React.FC = () => {
  const [isdetailopened, setIsDetailOpened] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const toggleDetail = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const t: boolean = !isdetailopened;
    setIsDetailOpened(t);
    if (event !== undefined) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsDetailOpened(false);
    setAnchorEl(null);
  };

  const processDetail = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isdetailopened}
        // onClose={toggleDetail}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <LanguageBlockDetail
          toggleDetail={toggleDetail}
          isopened={isdetailopened}
        />
      </Menu>
    );
  };

  return (
    <Box>
      <LanguageBlockBt toggleDetail={toggleDetail} isdetailopened={isdetailopened} />
      {processDetail()}
    </Box>
  );
};

export { TopLanguageBlock };
