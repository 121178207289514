import { useState } from "react";
import { TestDialog } from "./TestDialog";
import { LessonDto } from "dto/course/lesson.dto";
import { TestDto } from "dto/course/test.dto";
import { CommonTools } from "tools/utils/commontools";
import { CommonColumnQuestion } from "./CommonColumnQuestion";

type Props = {
  object: LessonDto;
};

const ColumnLessonTestAdd: React.FC<Props> = ({ object }) => {
  const [open, setOpen] = useState(false);
  const [test, setTest] = useState<TestDto | null>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <CommonColumnQuestion handleOpen={handleOpen}>
      <TestDialog
        object={test}
        open={open}
        handleClose={handleClose}
        setObject={setTest}
        idCourse={CommonTools.processObjectField(object, ["idcourse"])}
        idLesson={CommonTools.processObjectField(object, ["id"])}
        allowEdit
      />
    </CommonColumnQuestion>
  );
};

export { ColumnLessonTestAdd };
