import { Box } from "@mui/material";

import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import RequiredValidator from "validators/required.validator";

import { TicketCategoryDto } from "dto/support/ticketcategory.dto";

import { LanguageSelect } from "components/static/language/newLanguageSelect";
type Props = {
  defaultObject: TicketCategoryDto;
  onSubmit: (data: TicketCategoryDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormTicketCategory: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults, setObjectLanguage] =
    useForm<TicketCategoryDto>(
      defaultObject,
      RequiredValidator.getValidators(["name"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <MyTextField
          tabIndex={0}
          fullWidth
          id="name"
          name="name"
          variant="outlined"
          color="primary"
          _label={LL("name")}
          size="small"
          value={obj.name}
          setObj={setObjField}
          _vresults={vresults}
        />

        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            id="ordercriteria"
            name="ordercriteria"
            variant="outlined"
            color="primary"
            _label={LL("ordercriteria")}
            size="small"
            value={obj.ordercriteria}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>

        {forAdd && (
          <Box mt={3}>
            <LanguageSelect
              value={obj.idlanguage ?? ""}
              setValue={setObjectLanguage}
            />
          </Box>
        )}
        <MyButton
          tabIndex={6}
          disabled={disabled || loading}
          color="primary"
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          sx={{ mt: 3 }}
          children={LL("Bt_Submit")}
        />
      </form>
    </Box>
  );
};

export { FormTicketCategory };
