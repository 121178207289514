import React, { useState } from "react";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import SelectOptions from "dto/app/selectoptions.dto";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import FormControl from "@mui/material/FormControl/FormControl";
import { useResource } from "hooks/useResource";
import { FormHelperText } from "@mui/material";
import ValidatorResult from "validators/validatorresult";

type MySelectProps = SelectProps & {
  options: Array<SelectOptions>;
  setObjectField: (field: string, value: any) => void;
  value: any;
  _label: string;
  field: string;
  vresults?: ValidatorResult;
};

const MySelect: React.FC<MySelectProps> = ({
  options,
  setObjectField,
  value,
  _label,
  field,
  vresults,
  ...props
}) => {
  const { LL } = useResource();

  const label = _label !== undefined ? LL(_label) : LL("select_label");
  const [wasEntered, setWasEntered] = useState(false);

  const handleOnBlur = () => {
    setWasEntered(true);
  };
  const handleChange = (event: SelectChangeEvent<any>) => {
    setObjectField(field, event.target.value);
  };
  const processOptions = () => {
    if (!options)
      return (
        <MenuItem value="" disabled>
          {LL("No_Data")}
        </MenuItem>
      );
    if (options.length === 0)
      return (
        <MenuItem value="" disabled>
          {LL("No_Data")}
        </MenuItem>
      );
    return options.map((item, index) => (
      <MenuItem key={index} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  };

  const infoMessages = () => {
    if (!wasEntered) return null;
    if (!vresults) return null;
    if (!vresults.hasOwnProperty(field)) return null;
    if (!vresults[field]) return null;
    if (!vresults[field].objects) return null;
    if (!vresults[field].objects.length) return null;
    if (vresults[field].isOk == "-1") return null;

    const cl = vresults[field].isOk ? "active" : "disabled";

    return vresults[field].objects.map((_obj: any, i: number) => {
      if (!_obj.message) return null;

      return (
        <FormHelperText className={cl} key={i}>
          {_obj.message}
        </FormHelperText>
      );
    });
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id="simple-select"
        color="primary"
        variant="outlined"
        size="medium"
        onChange={handleChange}
        onBlur={handleOnBlur}
        value={value !== undefined && value !== 0 ? value : ""}
        label={label}
        sx={{ width: "100%" }}
        {...props}
      >
        {processOptions()}
      </Select>
      {infoMessages()}
    </FormControl>
  );
};

export { MySelect };
