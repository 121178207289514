
import {GeneralNomenclatureService} from "./generalnomenclature.service";
import IService from "interfaces/iservice.interface";

import {TypeStudiesRepository} from "repositories/nomenclature/typestudies.repository";


export class TypeStudiesService
  extends GeneralNomenclatureService<TypeStudiesRepository>
  implements IService
{
  constructor() {
    super(new TypeStudiesRepository());
  }
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
// import { PostNomenclatureDto } from "dto/nomenclature/postnomenclature.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import TypeStudiesRepository from "repositories/nomenclature/typestudies.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";

// import {GeneralNomenclatureService} from "./generalnomenclature.service";

// export default class TypeStudiesService extends GeneralNomenclatureService  implements IService {
//   constructor() {
//     super(new TypeStudiesRepository());
//   }
// }