import React, { useState, useEffect } from 'react';

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import DateTools from "tools/utils/date.tools";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';



interface MyDateTimePickerProps {
    [key: string]: any;
    setObjField: ((field: any, value: any) => void),
    LL: any,
    field: string,
    _defaultValue?: any,
    _label?: string,
}

const MyDateTimePicker: React.FC<MyDateTimePickerProps> = ({
    setObjField,
    LL,
    field,
    _defaultValue,
    _label,
    ...props
}) => {


    const label = _label !== undefined ? _label : LL("FN_" + field);
    const defaultValue = _defaultValue !== undefined ? DateTools.convertToDate(_defaultValue) : DateTools.convertToDate(Date.now());

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>

                <DateTimePicker
                    ampm={false}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                    }}
                    openTo={'day'}
                    views={["day","year", "month","hours", "minutes"]}
                    label={label}
                    defaultValue={defaultValue}
                    onChange={(e: Date | null) => {
                        if (e !== null) {
                            setObjField(field, e);
                        }

                    }}
                    format={DateTools.getDateTimeFormat()}
                    {...props}
                />

            </LocalizationProvider>

        </>
    )

};

export default MyDateTimePicker;