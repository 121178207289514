import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { CourseTeacherRequestRepository } from "repositories/course/courseteacherrequest.repository";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class CourseTeacherRequestService
  extends GeneralService<CourseTeacherRequestRepository>
  implements IService
{
  constructor() {
    super(
      new CourseTeacherRequestRepository(),
      [
        { key: "status", type: "status" },
        { key: "date", type: "timestamp" },
        { key: "changedate", type: "timestamp" },
        { key: "type", type: "types" },
      ],
      "date"
    );
  }
  async accept(
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = this.repository.accept(id, this.handleGet, data, cbparameters);

    if (cb === undefined) {
      return this.handleGet(t);
    }
  }

  async reject(
    id: string,
    cb?: any,
    cbparameters?: any,
    data?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = this.repository.reject(id, this.handleGet, data, cbparameters);
    
    if (cb === undefined) {
      return this.handleGet(t);
    }
  }

  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "date";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }
}
// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { CourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import CourseTeacherRequestRepository from "repositories/course/courseteacherrequest.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";

// export default class CourseTeacherRequestService extends GeneralService implements IService {
//     courseteacherrequestRepository: CourseTeacherRequestRepository = new CourseTeacherRequestRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.courseteacherrequestRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

// async accept(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.courseteacherrequestRepository.accept(id, this.handleUpdate, data, cbparameters);
// }

// async reject(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.courseteacherrequestRepository.reject(id, this.handleUpdate, data, cbparameters);
// }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.courseteacherrequestRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
//             { key: 'status', type: 'status' },
//             { key: 'date', type: 'timestamp' },
//             { key: 'changedate', type: 'timestamp' },
//             { key: 'type', type: 'types' },

//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseteacherrequestRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'status', type: 'status' },
//             { key: 'date', type: 'timestamp' },
//             { key: 'changedate', type: 'timestamp' },
//             { key: 'type', type: 'types' },
//         ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.courseteacherrequestRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.courseteacherrequestRepository.add(this.handleGetList, cbparameters, data);
//     }

// getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//     defaultRequestSortCriteria.asc = true;
//     defaultRequestSortCriteria.field = 'date';
//     defaultRequestList.sortcriteria = []
//     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
// }

//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "date";

//             obj.sortcriteria = [sobj];
//         }

//         return obj;
//     }
// }
