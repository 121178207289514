import { Loading } from "components/elements/loading/Loading";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PermissionDto } from "dto/user/permission.dto";
import IProvider from "interfaces/provider.interface";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { PermissionService } from "services/user/permission.service";
import { CommonTools } from "tools/utils/commontools";
import LocalStorageTools from "api/localstorage.api";

type Props = {
  getPermission: (key: string) => PermissionDto | null;
};

export const PermissionContext = createContext<Props>({
  getPermission: () => null,
});

const getLocalPermissions = (): Array<PermissionDto> => {
  const permissions = LocalStorageTools.getObject("permissions");
  if (!permissions) return [];
  return permissions;
};
const getLocalHash = (): string => {
  const hash = LocalStorageTools.getValue("permission_hash");
  if (!hash) return "";
  return hash;
};
var localPermissionsGlobal = getLocalPermissions();

const service = new PermissionService();

export const PermissionProvider: React.FC<IProvider> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [hashLocal, setHashLocal] = useState<string>(getLocalHash());

  const [hash, setHash] = useState<string>("");
  const [permissionLength, setPermissionLength] = useState<number>(
    getLocalPermissions().length
  );

  const getHash = () => {
    service.getServerPermissionsHash(handleGetHash, {});
  };

  const handleGetHash = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.obj) return;

    const hash = CommonTools.processObjectField(result, ["obj", "hash"]);

    setHash(hash);
  };

  useEffect(() => {
    getHash();
  }, []);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!hash) loading = true;
    if (!permissionLength) loading = true;
    setLoading(loading);
  }, [hash, permissionLength]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const loadPermissions = () => {
    service.getList(handleGetList, {}, new RequestListDTO([], 1, -1));
  };

  const handleGetList = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    if (!result.objects) return;

    processPermissions(result.objects);
  };

  const processPermissions = (permissions: Array<PermissionDto>) => {
    localPermissionsGlobal = permissions;
    setPermissionLength(permissions.length);
    LocalStorageTools.saveObject("permissions", permissions);
    LocalStorageTools.saveValue("permission_hash", hash);
    setHashLocal(hash);
  };

  const checkHash = useCallback(() => {
    if (!hash) return;
    if (hash === hashLocal) return;
    else {
      localPermissionsGlobal = [];
      setPermissionLength(0);
      loadPermissions();
    }
  }, [hash, hashLocal]);

  useEffect(() => {
    checkHash();
  }, [checkHash]);

  const getPermission = (key: string): PermissionDto | null => {
    if (!localPermissionsGlobal) return null;
    if (!localPermissionsGlobal.length) return null;

    const rez = localPermissionsGlobal.find(
      (x: PermissionDto) => x.name === key
    );
    if (!rez) return null;
    return rez;
  };

  const value = {
    getPermission,
  };
  return loading ? (
    <Loading />
  ) : (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};
