import React, { useEffect } from "react";
import { Box, InputAdornment } from "@mui/material";

import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { LockOpen } from "@mui/icons-material";
import ModifyPasswordDto from "dto/user/modifypassword.dto";

type Props = {
  defaultObject: ModifyPasswordDto;
  onSubmit: (data: ModifyPasswordDto) => void;
  loading: boolean;
};
const FormChangePassword: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, setObject, vresults] =
    useForm<ModifyPasswordDto>(
      defaultObject,
      RequiredValidator.getValidators([
        "currentpassword",
        "newpassword",
        "email",
      ])
    );

  useEffect(() => {
    if (!defaultObject) return;
    setObject(defaultObject);
  }, [defaultObject]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box pb={3}>
          <MyTextField
            fullWidth
            id="currentpassword"
            name="currentpassword"
            variant="outlined"
            color="primary"
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpen />
                </InputAdornment>
              ),
            }}
            _label={LL("currentpassword")}
            value={obj.currentpassword}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box pb={1}>
          <MyTextField
            fullWidth
            type="password"
            id="newpassword"
            name="newpassword"
            variant="outlined"
            color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpen />
                </InputAdornment>
              ),
            }}
            _label={LL("newpassword")}
            value={obj.newpassword}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Box mt={3}>
          <MyButton
            tabIndex={4}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt ChangPassword")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormChangePassword };
