import React from "react";

import { TestDto } from "dto/course/test.dto";
import { Box, Grid } from "@mui/material";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { QuestionTestList } from "./QuestionTestList";

type Props = {
  object: TestDto;
  open: boolean;
  setObject: (value: TestDto | null) => void;
};

const TestEditBlock: React.FC<Props> = ({ object, open, setObject }) => {
  if (!object) return null;
  return (
    <Box>
      <Box>
        <Grid>
          <DetailGrid object={object} fields={TestDto.getDetailFields()} />
        </Grid>
      </Box>
      {open ? <QuestionTestList object={object} setObject={setObject} /> : null}
    </Box>
  );
};

export { TestEditBlock };
