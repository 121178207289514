import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class TestimonialDto implements Idto {
  id?: string;
  iduser?: string;
  idteacher?: string;
  idcourse?: string;
  date?: number;
  rating?: number;
  status?: number;
  message?: string;

  date_name?: string;
  status_name?: string;
  user?: any;
  teacher?: any;
  course?: any;
  constructor(
    id?: string,
    iduser?: string,
    idteacher?: string,
    idcourse?: string,
    date?: number,
    rating?: number,
    status?: number,
    message?: string
  ) {
    this.status = status || Status.ACTIVE;
    this.rating = rating || 5;
    this.id = id || "";
    this.iduser = iduser || "";
    this.idteacher = idteacher || "";
    this.idcourse = idcourse || "";
    this.date = date || 0;
    this.message = message || "";
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "message",
        value: ["message"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Date",
        value: ["date_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Rating",
        value: ["rating"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "user_email",
        value: ["user_email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "teacher_email",
        value: ["teacher_email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "course",
        value: ["course", "name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_ConstantColumn(LL, "date"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "rating"),
      ComponentCommonTools.columns_StandardColumn(
        LL,
        "user_email",
        undefined,
        false
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}

export class PostTestimonialDto implements Idto {
  iduser?: string;
  idteacher?: string;
  idcourse?: string;
  date?: number;
  rating?: number;
  status?: number;
  message?: string;
  date_name?: string;
  status_name?: string;
  user?: any;
  teacher?: any;
  course?: any;
  constructor() {
    this.status = Status.ACTIVE;
    this.rating = 5;
  }
}
