import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { TicketMessageDto } from "./ticketmessage.dto";

export class TicketDto implements Idto {
  id?: string;
  status?: number;
  type?: number;
  idoperator?: string;
  idstudent?: string;
  idteacher?: string;
  idsticketcategory?: string[];
  constructor() {
    this.status = Status.ACTIVE;
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Date",
        value: ["date_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ticketcategory",
        value: ["ticketcategory", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "student",
        value: ["student", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "teacher",
        value: ["teacher", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "operator",
        value: ["operator", "email"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "date"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "student", "email"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "teacher", "email"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "operator",
        "email"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "ticketcategory",
        "name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}

export class PostTicketDto implements Idto {
  status?: number;
  type?: number;
  idoperator?: string;
  idstudent?: string;
  idteacher?: string;
  idsticketcategory?: string[];
  constructor() {
    this.status = Status.ACTIVE;
  }
}

export class ResponseTicketAdminDto implements Idto {
  idoperator?: string;
  type?: number;
  status?: number;
  message?: string;

  constructor(
    idoperator?: string,
    type?: number,
    status?: number,
    message?: string
  ) {
    this.idoperator = idoperator || "";
    this.type = type || Types.TYPE_TICKET_STUDENT;
    this.status = status || Status.OPEN_TICKET;
    this.message = message || "";
  }
}

export class TicketWithMessagesDto implements Idto {
  ticket?: TicketDto;
  messages?: TicketMessageDto;

  constructor(ticket: TicketDto, messages: TicketMessageDto) {
    this.ticket = ticket;
    this.messages = messages;
  }
}
