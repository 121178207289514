
import Idto from 'interfaces/idto.interface';


export default class ModifyPasswordDto implements Idto {
  
  email?: string;
  currentpassword?: string;
  newpassword?: string;

  constructor(
    email?: string,
    currentpassword?: string,
    newpassword?: string
  ) {
    this.email = email || '';
    this.currentpassword = currentpassword || '';
    this.newpassword = newpassword || '';
  
  }
  
}
