
import { DetailObject } from 'interfaces/detailobject.interface';
import Idto from 'interfaces/idto.interface';
import { Status } from "tools/types/status";
import { Types } from 'tools/types/types';

export class PostAgreementDto implements Idto {

    status?: number;
    idlanguage?: string;
    idagreement?: string;
    type?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    name?: string;
    description?: string;    
    idtypeagreement?:string;
    constructor() {
        this.status = Status.ACTIVE;
    }
}

export class AgreementDto implements Idto {
    
    id?: string;
    status?: number;
    idlanguage?: string;
    idagreement?: string;
    type?: number;
    idgallery?: string;
    idattachment?: string;
    idvideo?: string;
    name?: string;
    description?: string;    
    idtypeagreement?:string;
    language?: any;
    status_name?: any;
    date_name?: any;
    typeagreement?:any;
    
    constructor() {
        this.status = Status.ACTIVE;
    }

    static getDetailFields(): Array<DetailObject> {
        return [
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "Name",
            value: ["name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "Status",
            value: ["status_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "Date",
            value: ["date_name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "language",
            value: ["language", "name"],
          },
          {
            typeField: Types.FIELD_TYPE_TEXT,
            label: "description",
            value: ["description"],
          },
        ];
      }
}