import React from "react";
import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";
import AppBar from "@mui/material/AppBar";

import SignInDto from "dto/auth/signin.dto";

import { IPage } from "interfaces/page.interface";
import { Typography, Container, Box } from "@mui/material";
import { FormLogin } from "../../components/auth/FormLogin";

const Login: React.FC<IPage> = () => {
  const { signIn } = useAuth();

  const { LL } = useResource();

  return (
    <Container
      maxWidth="sm"
      className="loginForm"
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <AppBar component="nav" sx={{ bgcolor: "white", p: 2 }}>
        <Box className="logo"></Box>
      </AppBar>
      <Box
        sx={{
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1" align="center" mb={3}>
          {LL("Autentificare în cont")}
        </Typography>

        <FormLogin
          onSubmit={signIn}
          defaultObject={new SignInDto()}
          loading={false}
        />
      </Box>
    </Container>
  );
};

export { Login };
