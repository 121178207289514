import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { UserDto } from "./user.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { CommonTools } from "tools/utils/commontools";


export class StudentDto implements Idto {
  id?: string;
  iduser?: string;
  status?: number;
  
  bio?: string;
  idtypestudies?: string;
  institution?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  
  user?: UserDto
  email?: string;
  status_name?: string;
  name?: string;
  surname?: string;
  
  constructor(
    id?: string,
    iduser?: string,
    status?: number,
    bio?: string,
    idtypestudies?: string,
    institution?: string,
    notificationtype1?: boolean,
    notificationtype2?: boolean,
    notificationtype3?: boolean,
    showprofile?: boolean,
    showcourses?: boolean,
  ) {
    this.status = status|| Status.ACTIVE;
    this.id = id || "";
    this.iduser = iduser || "";
    this.bio = bio || "";
    this.idtypestudies = idtypestudies || "";
    this.institution = institution || "";
    this.notificationtype1 = notificationtype1 || false;
    this.notificationtype2 = notificationtype2 || false;
    this.notificationtype3 = notificationtype3 || false;
    this.showprofile = showprofile || false;
    this.showcourses = showcourses || false;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Email",
        value: ["email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Surname",
        value: ["surname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Birthday",
        value: ["birthday_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "bio",
        value: ["bio"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "institution",
        value: ["institution"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typestudies",
        value: ["typestudies","name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "email",undefined,false),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "name",undefined,false),
      ComponentCommonTools.columns_StandardColumn(LL, "surname",undefined,false),
      ComponentCommonTools.columns_StandardColumn(LL, "institution"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);
    
    return obj;
  }
  
  static parseToSelectOptions(data: Array<StudentDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(StudentDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: StudentDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["email"])
    );
  };
}

export class PostStudentDto implements Idto {
  iduser?: string;
  status?: number;
  idssociallink?: string[];
  bio?: string;
  idtypestudies?: string;
  institution?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  constructor() {
    this.status = Status.ACTIVE;
  }
}

export class PostNewStudentDto implements Idto {
  email: string;
  constructor(email?: string) {
    this.email = email ?? "";
  }
}
