import { Box } from "@mui/material";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyBackdrop } from "components/elements/loading/MyBackdrop";
import ResultListDTO from "dto/app/resultlist.dto";
import { PermissionDto } from "dto/user/permission.dto";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import { PermissionService } from "services/user/permission.service";

type Props = {
  setObjects: (objects: PermissionDto[]) => void;
  checked: boolean;
  idRole: string;
};

const service = new PermissionService();
const PermissionActionAll: React.FC<Props> = ({
  setObjects,
  checked,
  idRole,
}) => {
  const { LL } = useResource();
  const [loading, setLoading] = useState(false);
  const setRole = () => {
    setLoading(true);
    service.addOrRemoveAll(idRole, handleResult, {});
  };

  const handleResult = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.objects) return;
    setObjects(result.objects);
    setLoading(false);
  };

  const setObjectField = (field: string, value: any) => {
    setRole();
  };

  return (
    <Box>
      <MyCheckboxField
        field="value"
        checked={checked}
        label={checked ? LL("unassign_all") : LL("assign_all")}
        setObjectField={setObjectField}
      />
      <MyBackdrop loading={loading} />
    </Box>
  );
};

export { PermissionActionAll };
