import React, { useState } from "react";
import { TabCoursePriceProps } from "./TabCoursePrice";
import { CoursePriceService } from "services/course/courseprice.service";
import { CommonTools } from "tools/utils/commontools";
import { CoursePriceDto } from "dto/course/courseprice.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { FormCoursePrice } from "./FormCoursePrice";
const service = new CoursePriceService();
const TabCoursePriceAdd: React.FC<TabCoursePriceProps> = ({
  idTypeCourse,
  idCourse,
  mainObject,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onSubmit = (obj: CoursePriceDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        idRoute: idCourse,
        cb: CommonTools.processLoadingCallback(setLoading),
        anchor: "courseprice",
        needUpdate: true,
      },
      obj
    );
  };
  return (
    <FormCoursePrice
      defaultObject={new CoursePriceDto(idCourse)}
      onSubmit={onSubmit}
      loading={loading}
      idTypeCourse={idTypeCourse}
    />
  );
};

export { TabCoursePriceAdd };
