import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class SupportArticleCategoryDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  code?: string;
  level?: number;
  idparent?: string;
  type?: number;
  idlanguage?: string;
  language?: any;
  name?: string;
  haschildren?: boolean;
  idsupportarticlecategory?: string;
  shortdescription?: string;
  constructor(
    idlanguage?: string,
    idparent?: string,
    name?: string,
    shortdescription?: string,
    type?: number,
  ) {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.shortdescription = shortdescription || "";
    this.type = type || Types.TYPE_SUPPORT_ARTICLE_CATEGORY_STUDENT;
    this.name = name || "";
    this.idlanguage = idlanguage || "";
    this.idparent = idparent || "";

  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "shortdescription",
        value: ["shortdescription"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["type_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name", undefined, false),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        [
          "edit",
          "detail",
          "delete",
          "children",
          "gallery",
          "attachment",
          "video",
        ],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}

export class PostSupportArticleCategoryDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  code?: string;
  level?: number;
  idparent?: string;
  type?: number;
  allvalues?: any;
  idlanguage?: any;
  language?: any;
  name?: any;
  haschildren?: boolean;
  idsupportarticlecategory?: string;
  shortdescription?: string;

  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}
