import { useState } from "react";
import { TestDialog } from "./TestDialog";
import { LessonDto } from "dto/course/lesson.dto";
import { TestDto } from "dto/course/test.dto";
import { CommonTools } from "tools/utils/commontools";
import { CommonColumnQuestion } from "./CommonColumnQuestion";
import useObject from "hooks/useObject";
import { TestService } from "services/course/test.service";

type Props = {
  object: LessonDto;
};

const service = new TestService();
const ColumnLessonTestEdit: React.FC<Props> = ({ object }) => {
  const [open, setOpen] = useState(false);
  const get = (id: string, cb?: any, cbparameters?: any) => {
    if (!id) return;
    service.getByLessonId(id, cb, cbparameters);
  };
  const [, test, setTest] = useObject<TestDto>(get, object.id ?? "", [
    object.id,
  ]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <CommonColumnQuestion handleOpen={handleOpen}>
      {test && (
        <TestDialog
          object={test}
          open={open}
          handleClose={handleClose}
          setObject={setTest}
          idCourse={CommonTools.processObjectField(object, ["idcourse"])}
          idLesson={CommonTools.processObjectField(object, ["id"])}
          allowEdit
        />
      )}
    </CommonColumnQuestion>
  );
};

export { ColumnLessonTestEdit };
