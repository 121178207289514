import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";

import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import SelectOptions from "dto/app/selectoptions.dto";
import { CommonTools } from "tools/utils/commontools";

export class RoleDto implements Idto {
  id?: string;
  name?: string;
  fixed?: boolean;

  constructor(id?: string, name?: string) {
    this.id = id || "";
    this.name = name || "";
    this.fixed = false;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Fixed",
        value: ["fixed_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_ConstantColumn(LL, "fixed"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "permission"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<RoleDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(RoleDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: RoleDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["name"])
    );
  };
}
