import { Box, Typography } from "@mui/material";


import { useResource } from "hooks/useResource";
import React from "react";

type Props = {
    idTypeCourse: string;
    children: React.ReactNode;
}

const TabCoursePriceNeedMoreInfo: React.FC<Props> = ({ idTypeCourse, children }) => {
    const { LL } = useResource();
    
    if (!idTypeCourse)  return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography variant="h6">
            {LL("you_have_to_set_the_course_type_first")}
          </Typography>
        </Box>
      );;
    return <Box>{children}</Box>;
}

export { TabCoursePriceNeedMoreInfo };