import Idto from "interfaces/idto.interface";
export default class SelectOptions implements Idto {
  value?: number | string;
  label?: string;

  constructor(value?: number | string, label?: string) {
    this.value = value || "";
    this.label = label || "";
  }
}
