import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class FileDto implements Idto {
  id?: string;
  name?: string;
  type?: string;
  hash?: string;
  permissions?: string[];
  location?: string;
  storage?: string;
  size?: number;
  value?: string;

  constructor(
    id?: string,
    name?: string,
    type?: string,
    hash?: string,
    permissions?: string[],
    location?: string,
    storage?: string,
    size?: number,
    value?: string
  ) {
    this.id = id || "";
    this.name = name || "";
    this.type = type || "";
    this.hash = hash || "";
    this.permissions = permissions || [];
    this.location = location || "";
    this.storage = storage || "";
    this.size = size || 0;
    this.value = value || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["type"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "location",
        value: ["location"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "storage",
        value: ["storage"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "size",
        value: ["size"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "value",
        value: ["value"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_StandardColumn(LL, "type"),
      ComponentCommonTools.columns_StandardColumn(LL, "location"),
      ComponentCommonTools.columns_StandardColumn(LL, "storage"),
      ComponentCommonTools.columns_StandardColumn(LL, "size"),
      ComponentCommonTools.columns_StandardColumn(LL, "value"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}
