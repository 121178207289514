import { OptionsDetailObject } from "interfaces/detailobject.interface";
import React from "react";

import { Link, Stack, Typography } from "@mui/material";
import { CommonTools } from "tools/utils/commontools";

import { Types } from "tools/types/types";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

type Props = {
  obj: any;
  fields: string[];
  type?: number;
  options?: OptionsDetailObject;
};
const DataGridArrayItem: React.FC<Props> = ({
  obj,
  fields,
  type = Types.FIELD_TYPE_TEXT,
  options,
}) => {
  switch (type) {
    case Types.FIELD_TYPE_TEXT: {
      return (
        <Typography>{CommonTools.processObjectField(obj, fields)}</Typography>
      );
    }
    case Types.FIELD_TYPE_MULTI_TEXT: {
      const secondaryFields = options?.secondaryFields
        ? options.secondaryFields
        : [];
      const divider = options?.stackOptions?.divider
        ? options?.stackOptions?.divider
        : null;
      return (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Typography>{CommonTools.processObjectField(obj, fields)}</Typography>
          {divider ? divider : <Typography>-</Typography>}
          <Typography>
            {CommonTools.processObjectField(obj, secondaryFields)}
          </Typography>
        </Stack>
      );
    }
    case Types.FIELD_TYPE_IMAGE: {
      let width: string | number = 50;
      let height: string | number = 50;
      let alt = "";
      let withLink = false;
      if (options) {
        width = options.width ? options.width : 50;
        height = options.height ? options.height : 50;
        alt = options.alt ? options.alt : "";
        withLink = options.withLink ? options.withLink : false;
      }
      return ComponentCommonTools.getFileBlockImage(
        CommonTools.processObject(obj, fields),
        typeof width == "string" ? parseInt(width) : width,
        typeof height == "string" ? parseInt(height) : height,
        alt,
        withLink
      );
    }
    case Types.FIELD_TYPE_FILE: {
      return (
        <Link
          href={CommonTools.processObjectField(obj, fields)}
          target="_blank"
          rel="noreferrer"
        >
          {CommonTools.processObjectField(obj, ["name"])}
        </Link>
      );
    }

    default: {
      return null;
    }
  }
};
export { DataGridArrayItem };
