import React from "react";

import { IPage } from "interfaces/page.interface";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";

import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { FormSiteConfigFile } from "components/static/siteconfigfile/FormSiteConfigFile";
import { SiteConfigFileDto } from "dto/static/siteconfigfile.dto";
import { SiteConfigFileService } from "services/static/siteconfigfile.service";

const service = new SiteConfigFileService();
const EditSiteConfigFile: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };

  const { mainUrl, detailUrl, object, loading, setLoading, id } =
    useEditObject<SiteConfigFileDto>(mainObject, currentRoute, get);

  const onSubmit = (obj: SiteConfigFileDto) => {
    if (!obj) return;
    setLoading(true);
    service.updateMultipart(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
      />
      <DataDisplayBox>
        <Box>
          <FormSiteConfigFile
            defaultObject={SiteConfigFileDto.parseForEdit(object)}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { EditSiteConfigFile };
