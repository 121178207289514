import React, { useState } from "react";

import { NomenclatureDto } from "dto/nomenclature/nomenclature.dto";

import { HeaderAdd } from "components/elements/add/HeaderAdd";
import { FormNomenclature } from "components/elements/nomenclature/FormNomenclature";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { INomenclaturePage } from "interfaces/nomenclaturepage.interface";
import { useResource } from "hooks/useResource";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useGetUrls } from "hooks/useGetUrls";
import { CommonTools } from "tools/utils/commontools";

const NomenclatureAdd: React.FC<INomenclaturePage> = ({
  currentRoute,
  mainObject,
  service,
}) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject || "", currentRoute);
  const [loading, setLoading] = useState<boolean>(false);
  const { _idlanguage } = useResource();
  const onSubmit = (obj: NomenclatureDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["idlanguage"]),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
        <FormNomenclature
          defaultObject={new NomenclatureDto(_idlanguage)}
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { NomenclatureAdd };
