import { Box } from "@mui/material";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyBackdrop } from "components/elements/loading/MyBackdrop";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { PermissionDto } from "dto/user/permission.dto";
import { useForm } from "hooks/useForm";
import React, { useState } from "react";
import { PermissionService } from "services/user/permission.service";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  item: PermissionDto;
  idRole: string;
  setObjects: (objects: PermissionDto[]) => void;
  objects: PermissionDto[];
};
const service = new PermissionService();
const PermissionItem: React.FC<Props> = ({
  item,
  idRole,
  setObjects,
  objects,
}) => {
  const [obj, , , setObject] = useForm<PermissionDto>(item, []);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (field: string, value: any) => {
    let t: PermissionDto = JSON.parse(JSON.stringify(obj));
    const roles = t.acceptedroles || [];
    if (roles.includes(idRole)) {
      const index = roles.indexOf(idRole);
      roles.splice(index, 1);
    } else {
      roles.push(idRole);
    }
    t.acceptedroles = roles;
    setObject(t);

    setLoading(true);
    if (!t.id) return;
    service.update(t.id, handleResult, {}, t);
  };
  const handleResult = (result: ResultObjectDTO) => {
    if (!result) {
      setLoading(false);
      return;
    }
    if (!result.obj) {
      setLoading(false);
      return;
    }
    setObject(result.obj);
    const id = CommonTools.processObjectField(result, ["obj", "id"]);
    if (id) {
      const newObjects = objects.map((item) => {
        if (item.id === id) {
          return result.obj as PermissionDto;
        }
        return item;
      });
      setObjects(newObjects);
    }

    setLoading(false);
  };
  const processChecked = (): boolean => {
    if (!item.acceptedroles) return false;
    return item.acceptedroles.includes(idRole);
  };
  if (!item) return null;
  return (
    <Box>
      <MyCheckboxField
        field="id"
        setObjectField={handleSubmit}
        checked={processChecked()}
        label={CommonTools.processObjectField(item, ["name"])}
      />
      <MyBackdrop loading={loading} />
    </Box>
  );
};

export { PermissionItem };
