import { useEffect, useMemo, useState } from "react";
import { useResource } from "./useResource";
import { CommonTools } from "tools/utils/commontools";

type Props = (mainObject: string, currentRoute: any) => {
    mainUrl: string;
    listUrl: string;
    addUrl: string;
};

const useGetUrls: Props = (mainObject, currentRoute) => {
  const [mainUrl, setMainUrl] = useState("");
  const [listUrl, setListUrl] = useState("");
  const [addUrl, setAddUrl] = useState("");

  const { _getListUrl } = useResource();

  useEffect(() => {
    if (!mainObject || !currentRoute) return;
    const mu = CommonTools.generateMainUrl(mainObject);
    setMainUrl(mu);
    const au = CommonTools.generateAddUrl(mainObject);
    setAddUrl(au);
    const lu = _getListUrl(mu);
    setListUrl(lu);
  }, [mainObject, currentRoute]);

  return useMemo(
    () => ({
      mainUrl,
      listUrl,
      addUrl,
    }),
    [mainUrl, listUrl, addUrl]
  );
};

export { useGetUrls };
