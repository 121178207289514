import { Box } from "@mui/material";
import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";
import { LanguageDto } from "dto/static/language.dto";

type Props = {
  defaultObject: LanguageDto;
  onSubmit: (data: LanguageDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormLanguage: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<LanguageDto>(
    defaultObject,
    RequiredValidator.getValidators(["name"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <MyTextField
          tabIndex={0}
          fullWidth
          id="name"
          name="name"
          variant="outlined"
          color="primary"
          _label={LL("label_text_field_Name")}
          size="small"
          value={obj.name}
          setObj={setObjField}
          _vresults={vresults}
        />
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="cod2"
            name="cod2"
            variant="outlined"
            color="primary"
            _label={LL("label_text_field_cod2")}
            size="small"
            value={obj.cod2}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            id="cod3"
            name="cod3"
            variant="outlined"
            color="primary"
            _label={LL("label_text_field_cod3")}
            size="small"
            value={obj.cod3}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex="4"
            fullWidth
            id="ordercriteria"
            name="ordercriteria"
            variant="outlined"
            color="primary"
            _label={LL("label_text_field_ordercriteria")}
            size="small"
            value={obj.ordercriteria}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex="5"
            fullWidth
            id="hmtlcode"
            name="hmtlcode"
            variant="outlined"
            color="primary"
            _label={LL("label_text_field_hmtlcode")}
            size="small"
            value={obj.hmtlcode}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <MyButton
          tabIndex="6"
          disabled={disabled}
          color="primary"
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          sx={{ mt: 3 }}
          children={LL("Bt_Submit")}
        />
      </form>
    </Box>
  );
};

export { FormLanguage };
