import { Box } from "@mui/material";

import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { TeacherFAQDto } from "dto/user/teacherfaq.dto";

import { AutocompleteUser } from "components/user/user/AutocompleteUser";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { AutocompleteCourse } from "components/course/course/AutocompleteCourse";
import { AutocompleteTeacher } from "../teacher/AutocompleteTeacher";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";

type Props = {
  defaultObject: TeacherFAQDto;
  onSubmit: (data: TeacherFAQDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormTeacherFAQ: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<TeacherFAQDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "message",
      "iduser",
      "idteacher",
      "idcourse",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="message"
            name="message"
            variant="outlined"
            color="primary"
            _label={LL("message")}
            size="small"
            value={obj.message}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="teacherresponse"
            name="teacherresponse"
            variant="outlined"
            color="primary"
            _label={LL("teacherresponse")}
            size="small"
            value={obj.teacherresponse}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        <Box mt={3}>
          <MyCheckboxField
            label={LL("showoncourse")}
            field="showoncourse"
            setObjectField={setObjField}
            checked={obj.showoncourse}
          />
        </Box>
        {forAdd && (
          <Box>
            <Box mt={3}>
              <AutocompleteUser
                setObjField={setObjField}
                value={obj.iduser}
                defaultObject={obj.user}
              />
            </Box>
            <Box mt={3}>
              <AutocompleteTeacher
                setObjField={setObjField}
                value={obj.idteacher}
                defaultObject={obj.teacher}
              />
            </Box>
            {obj.idteacher ? (
              <Box mt={3}>
                <AutocompleteCourse
                  setObjField={setObjField}
                  value={obj.idcourse}
                  defaultObject={obj.course}
                  idTeacher={obj.idteacher}
                />
              </Box>
            ) : null}
          </Box>
        )}

        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormTeacherFAQ };
