import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class AttachmentDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  idfile?: string;
  parent?: string;
  idparent?: string;
  isdefault?: boolean;
  idlanguage?: string;
  language?: any;
  status_name?: string;
  idattachment?: string;
  name?: string;
  fileInfoObj?: any;
  files?: File | null;
  constructor(
    idlanguage?: string,
    parent?: string,
    idparent?: string,
    ordercriteria?: number,
    status?: number,
    isdefault?: boolean,
    name?: string,
    fileInfoObj?: any,
    idfile?: string
  ) {
    this.status = status || Status.ACTIVE;
    this.ordercriteria = ordercriteria || 0;
    this.idlanguage = idlanguage || "";
    this.parent = parent || "";
    this.idparent = idparent || "";
    this.isdefault = isdefault || false;
    this.name = name || "";
    this.files = null;
    this.fileInfoObj = fileInfoObj || null;
    this.idfile = idfile || "";
  }

  static parseForEdit(obj: any): AttachmentDto {
    return new AttachmentDto(
      obj.idlanguage,
      obj.parent,
      obj.idparent,
      obj.ordercriteria,
      obj.status,
      obj.isdefault,
      obj.name,
      obj.fileInfoObj,
      obj.idfile
    );
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "isdefault",
        value: ["isdefault_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_ConstantColumn(LL, "isdefault"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    specialType?: string
  ): Array<any> {
    if(!specialType) specialType = "attachment";
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_FileColumn(LL, "idfile"),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_ConstantColumn(
        LL,
        "isdefault",
        undefined,
        true,
        true,
        "boolean"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}

export class PostAttachmentDto implements Idto {
  status?: number;
  ordercriteria?: number;
  idfile?: string | null;
  parent?: string;
  idparent?: string;
  isdefault?: boolean;
  idlanguage?: string;
  language?: any;
  status_name?: string;
  idattachment?: string;
  name?: string;
  files?: any;
  fileInfoObj?: any;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}
