import React, { useCallback, useEffect } from "react";
import { HeaderListSpecial } from "components/elements/list/HeaderListSpecial";
import { TestService } from "services/course/test.service";
import { IPage } from "interfaces/page.interface";

import RequestListDTO from "dto/app/requestlist.dto";
import { TestDto } from "dto/course/test.dto";
import { useListAdminSpecial } from "hooks/useListAdminSpecial";
import { useResource } from "hooks/useResource";
import MyTable from "components/elements/tabeltools/MyTable";


const service = new TestService();

const SpecialTest: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    addUrl,
    mainUrl,
    setDeleteOneObject,
    handleDelete,
    rows,
    total,
    totalPage,
    deleteOneObject,
    reqList,
    setReqList,
    loading,
    onRowClickCb,
    parentId,
    parentType,
    parentUrl,
    setRows,
  } = useListAdminSpecial<TestDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun,
    "test",
    TestDto.defaultGetRequestList
  );
  const getRows = useCallback(() => {
    return rows;
  }, [rows]);

  useEffect(() => {
    TestDto.setRows(getRows);
  }, [getRows]);

  const setObject = (value: TestDto | null) => {
    if (!value) return;
    const newRows = TestDto.getRows().map((item) => {
      if (item.id === value.id) return value;
      return item;
    });
    setRows(newRows);
  };
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <React.Fragment>
      <HeaderListSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={addUrl}
        specialType="test"
        parentUrl={parentUrl}
        parentType={parentType}
        parentId={parentId}
      />
      <MyTable
        _columns={TestDto.getColumnsSpecial(
          LL,
          mainObject,
          setObject,

          setDeleteOneObject
        )}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={TestDto.getFilters()}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        onDelete={handleDelete}
        mainObject={mainObject}
        fieldToShowOnDelete="name"
        deleteOneObject={deleteOneObject}
        setDeleteOneObject={setDeleteOneObject}
        onRowClickCb={onRowClickCb}
      />
    </React.Fragment>
  );
};

export { SpecialTest };
