import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import DateTools from "tools/utils/date.tools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class PostAdvertisementDto implements Idto {
  status?: number;
  section?: number;
  ordercriteria?: number;
  startdate?: Date;
  enddate?: Date | null;
  clabt?: string;
  url?: string;
  type?: number;
  description?: string;
  attachment?: any;
  gallery?: any;
  video?: any;
  fullurl?: any;
  idattachment?: string;
  idgallery?: string;
  idvideo?: string;
  idlanguage?: string;
  idadvertisement?: string;
  name?: string;
  language?: any;
  status_name?: any;
  section_name?: string;
  type_name?: string;
  startdate_name?: string;
  enddate_name?: string;

  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.startdate = DateTools.convertToDate(Date.now());
  }
}

export class AdvertisementDto implements Idto {
  id?: string;
  status?: number;
  section?: number;
  ordercriteria?: number;
  startdate?: Date;
  enddate?: Date | null;
  clabt?: string;
  url?: string;
  type?: number;
  description?: string;

  attachment?: any;
  gallery?: any;
  video?: any;
  fullurl?: any;

  idlanguage?: string;
  idadvertisement?: string;
  name?: string;
  language?: any;
  status_name?: any;
  section_name?: string;
  type_name?: string;
  startdate_name?: string;
  enddate_name?: string;

  constructor(
    idlanguage?: string,
    status?: number,
    section?: number,
    ordercriteria?: number,
    startdate?: Date,
    enddate?: Date,
    clabt?: string,
    url?: string,
    type?: number,
    description?: string,
    name?: string
  ) {
    this.status = status || Status.ACTIVE;
    this.ordercriteria = ordercriteria || 0;
    this.startdate = startdate || DateTools.convertToDate(Date.now());
    this.enddate = enddate || null;
    this.clabt = clabt || "";
    this.url = url || "";
    this.type = type || Types.ADVERTISEMENT_TYPE_BANNER;
    this.description = description || "";
    this.name = name || "";
    this.idlanguage = idlanguage || "";
    this.section = section || Types.SECTION_INFO_ADVERTISEMENT;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "description",
        value: ["description"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "section",
        value: ["section_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "url",
        value: ["url"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "startdate",
        value: ["startdate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "enddate",
        value: ["enddate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "clabt",
        value: ["clabt"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name", undefined, false),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_ConstantColumn(LL, "section"),
      ComponentCommonTools.columns_ConstantColumn(LL, "startdate"),
      ComponentCommonTools.columns_ConstantColumn(LL, "enddate"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "gallery", "attachment", "video"],
        ["detail", "attachment", "video"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}
