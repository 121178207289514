import React from "react";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import { Box, IconButton } from "@mui/material";
import { useResource } from "hooks/useResource";
import { NewMyBreadcrumbs } from "../breadcrumbs/NewMyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";
import SpecialTopInfo from "components/special/SpecialTopInfo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type Props = {
  mainObject: string;
  mainUrl: string;
  addUrl: string;
  parentUrl: string;
  parentType: string;
  parentId: string;
  specialType: string;
  showAddBtn?: boolean;
};
const HeaderListSpecial: React.FC<Props> = ({
  mainObject,
  mainUrl,
  addUrl,
  parentUrl,
  parentType,
  parentId,
  specialType,
  showAddBtn = true,
}) => {
  const { LL } = useResource();
  return (
    <React.Fragment>
      <NewMyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbListSpecial(
          specialType,
          parentType
        )}
        mainObject={mainObject}
      />
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: showAddBtn ? "space-between" : "flex-start",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MyNavBt
          tabIndex={1}
          href={parentUrl}
          children={<ArrowBackIcon />}
          size="large"
          _hstate={{}}
          _mainurl={parentUrl}
          component={IconButton}
          color="primary"
        />

        <SpecialTopInfo
          parentType={parentType}
          parentId={parentId}
          specialtype={specialType}
          variant="h4"
          sx={{ my: 3 }}
        />

        {showAddBtn && (
          <MyNavBt
            href={addUrl}
            children={LL("bt_Add")}
            className="addButton"
            _hstate={{}}
            _mainurl={mainUrl}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export { HeaderListSpecial };
