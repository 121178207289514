import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Types } from "tools/types/types";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class CourseFilterDto implements Idto {
  id?: string;
  value?: number | string | null;
  idfilter?: string;
  idfilterdictionary?: string;
  idcourse?: string;

  fillter?: any;
  filterdictionary?: any;

  constructor(
    idcourse?: string,
    idfilter?: string,
    idfilterdictionary?: string,
    id?: string
  ) {
    this.id = id || "";
    this.idcourse = idcourse || "";
    this.idfilter = idfilter || "";
    this.idfilterdictionary = idfilterdictionary || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "filter",
        value: ["filter", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "filterdictionary",
        value: ["filterdictionary", "name"],
      },
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "filter", "name"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "filterdictionary",
        "name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        "coursefilter"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    return obj;
  }

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idcourse";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idcourse") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}

export class PostCourseFilterDto implements Idto {
  value?: number | string | null;
  idfilter?: string;
  idfilterdictionary?: string;
  idcourse?: string;
  fillter?: any;
  filterdictionary?: any;
}
