import React from "react";

import { IPage } from "interfaces/page.interface";
import { Box } from "@mui/material";
import { NewMyBreadcrumbs } from "components/elements/breadcrumbs/NewMyBreadcrumbs";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

const HomePage: React.FC<IPage> = () => {
  return (
    <Box>
      <NewMyBreadcrumbs
        objects={[new BreadcrumbDto("crumb_homepage", "/")]}
        mainObject={"HomePage"}
      />
    </Box>
  );
};

export { HomePage };
