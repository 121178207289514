import { Box, Grid, Typography } from "@mui/material";

import { IPage } from "interfaces/page.interface";
import React, { useEffect, useState } from "react";

import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";

import { Loading } from "components/elements/loading/Loading";
import { useDetailObject } from "hooks/useDetailObject";

import {
  ResponseTicketAdminDto,
  TicketDto,
  TicketWithMessagesDto,
} from "dto/support/ticket.dto";
import { TicketService } from "services/support/ticket.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { TicketMessageDto } from "dto/support/ticketmessage.dto";
import { useList } from "hooks/useList";
import { TicketMessageService } from "services/support/ticketmessage.service";
import { NewTicketMessageList } from "components/support/ticketmessage/NewTicketMessageList";
import { useResource } from "hooks/useResource";
import { FormTicket } from "components/support/ticket/FormTicket";
import { CommonTools } from "tools/utils/commontools";
import { useAuth } from "hooks/useAuth";
import ResultObjectDTO from "dto/app/resultobject.dto";

const service = new TicketService();
const serviceTicketMessage = new TicketMessageService();
const DetailTicket: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { LL } = useResource();
  const { user } = useAuth();
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };
  const [defaultObject, setDefaultObject] =
    useState<ResponseTicketAdminDto | null>(null);

  const { mainUrl, editUrl, listUrl, object, loading, id, setObject } =
    useDetailObject<TicketDto>(mainObject, currentRoute, get);

  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    serviceTicketMessage.getList(cb, cbParams, req);
  };
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [loadingList, objects, , , setObjects] = useList<TicketMessageDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("idticket", [id])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("date", true)]
    ),
    [id]
  );

  useEffect(() => {
    if (!object) return;
    setDefaultObject(
      new ResponseTicketAdminDto(
        CommonTools.processObjectField(user, ["id"]),
        object.type,
        object.status
      )
    );
  }, [object]);

  const onSubmit = (obj: ResponseTicketAdminDto) => {
    if (!obj) return;
    setLoadingAction(true);
    service.responseByAdmin(id, handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) {
      setLoadingAction(false);
      return;
    }
    if (!result.obj) {
      setLoadingAction(false);
      return;
    }
    const object = result.obj as TicketWithMessagesDto;
    if (object.ticket) {
      setObject(service.populateDto(object.ticket));
      setDefaultObject(
        new ResponseTicketAdminDto(
          CommonTools.processObjectField(user, ["id"]),
          object.ticket.type,
          object.ticket.status
        )
      );
    }
    if (object.messages && objects) setObjects([...objects, object.messages]);
    setLoadingAction(false);
  };

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!user) return null;
  if (loading) return <Loading />;
  if (!object) return null;
  if (!defaultObject) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
        showEdit={false}
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid object={object} fields={TicketDto.getDetailFields()} />
        </Grid>
        <Box>
          <Box mt={3}>
            <Typography>{LL("Message_List")}</Typography>
          </Box>
          <Box mt={1}>
            <NewTicketMessageList objects={objects} loading={loadingList} />
          </Box>
          <Box mt={3}>
            <FormTicket
              defaultObject={
                new ResponseTicketAdminDto(
                  CommonTools.processObjectField(user, ["id"]),
                  object.type,
                  object.status
                )
              }
              onSubmit={onSubmit}
              loading={loadingAction}
            />
          </Box>
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { DetailTicket };
