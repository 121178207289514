import { HeaderAdd } from "components/elements/add/HeaderAdd";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { FormFAQ } from "components/static/faq/FormFAQ";
import { FAQDto } from "dto/static/faq.dto";
import { useGetUrls } from "hooks/useGetUrls";
import { useResource } from "hooks/useResource";
import { IPage } from "interfaces/page.interface";
import React, { useState } from "react";
import { FAQService } from "services/static/faq.service";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const service = new FAQService();
const AddFAQ: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject ?? "", currentRoute);
  const [loading, setLoading] = useState<boolean>(false);
  const { _idlanguage } = useResource();
  const onSubmit = (obj: FAQDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["idlanguage"]),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
        <FormFAQ
          defaultObject={new FAQDto(_idlanguage)}
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { AddFAQ };
