// import RequestListDTO from "dto/app/requestlist.dto";
// import Idto from "interfaces/idto.interface";
// import IRepository from "interfaces/irepository.interface";
// import GeneralRepository from "repositories/general.repository";

// const { REACT_APP_API_SERVER, REACT_APP_URLPREFIX } = process.env;

import IRepository from "interfaces/irepository.interface";
import {GlobalGeneralRepository} from "repositories/globalgeneral.repository";

export class PricePolicyLinkTeacherRepository
  extends GlobalGeneralRepository
  implements IRepository
{
  constructor() {
    super("pricepolicyteacherlink");
  }
}
// export default class PricePolicyLinkTeacherRepository
//   extends GeneralRepository
//   implements IRepository
// {
//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       "/pricepolicyteacherlink/" +
//       id;
//     return await this.deleteAxios(url, cb, cbparameters);
//   }

//   async update(
//     id: string,
//     cb?: any,
//     data?: Idto | undefined,
//     cbparameters?: any
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       "/pricepolicyteacherlink/" +
//       id;
//     return await this.putAxios(url, cb, data, cbparameters);
//   }
//   patch(
//     id: string,
//     cb?: any,
//     data?: Idto | undefined,
//     cbparameters?: any
//   ): Promise<any> {
//     throw new Error("Method not implemented.");
//   }

  

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/pricepolicyteacherlink/${id}`;

//     return await this._get(url, cb, cbparameters);
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/pricepolicyteacherlink`;
//     return await this._getList(url, cb, cbparameters, data);
//   }

//   async add(cb?: any, cbparameters?: any, data?: Idto): Promise<any> {
//     const url =
//       (REACT_APP_API_SERVER ?? "") +
//       (REACT_APP_URLPREFIX ?? "") +
//       `/pricepolicyteacherlink`;

//     return await this.postAxios(url, cb, data, cbparameters);
//   }
// }
