import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { ICurrentRoute } from "interfaces/currentroute.interface";

import Idto from "interfaces/idto.interface";

import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/commontools";
import { Config } from "tools/utils/config";
import { RouteTools } from "tools/utils/routetools";
import { useResource } from "./useResource";

type HasId = Idto & {
  id?: string | number;
};
type UseDetailObject = <T extends HasId>(
  specialType: string,
  currentRoute: ICurrentRoute | null,
  getObjectFun: (id: string, cb: any, cbParams: any, idLang?: string) => void,
  withLanguage?: boolean,
  defaultTab?: string,
  index?: number
) => {
  mainUrl: string;
  loading: boolean;
  object: T | null;
  editUrl: string;
  parentId: string;
  parentType: string;
  idLanguage: string;
  setIdLanguage: (id: string) => void;
  tabValue: string;
  handleChangeTab: (event: React.SyntheticEvent, newValue: any) => void;
  mainListUrl: string;
  setObject: React.Dispatch<React.SetStateAction<T | null>>;
};
var isRequesting = false;
const useDetailSpecial: UseDetailObject = <T extends HasId>(
  specialType: string,
  currentRoute: ICurrentRoute | null,
  getObjectFun: (id: string, cb: any, cbParams: any, idLang?: string) => void,
  withLanguage?: boolean,
  defaultTab?: string,
  index?: number
) => {
  if (!index) index = 3;

  if (!withLanguage) withLanguage = false;
  if (!defaultTab) defaultTab = Config.DEFAULT_TAB;
  const { _getListUrl } = useResource();
  const id = CommonTools.processIdFromPaths(currentRoute, index);
  const parentId = CommonTools.processIdFromPaths(currentRoute, 1);
  const parentType = CommonTools.processIdFromPaths(currentRoute, 0);
  const isFirstRender = useRef(true);
  const editUrl = CommonTools.generateEditUrlSpecial(
    id,
    parentType,
    parentId,
    specialType
  );
  const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);
  const { _idlanguage } = useResource();
  const [object, setObject] = useState<T | null>(
    CommonTools.processObjectFromHistoryState(currentRoute)
  );
  const mainListUrl = _getListUrl(
    CommonTools.generateParentMainUrlSpecial(currentRoute)
  );
  const [loading, setLoading] = useState<boolean>(true);
  const needUpdate = CommonTools.processNeedUpdateHistoryState(currentRoute);
  const processDefaultLanguage = () => {
    const id = CommonTools.processLanguageFromHistoryState(currentRoute);
    if (!id) return _idlanguage;
    return id;
  };
  const [idLanguage, setIdLanguage] = useState<string>(
    processDefaultLanguage()
  );
  const [tabValue, setTabValue] = useState<string>(
    CommonTools.getAnchor(currentRoute, defaultTab)
  );
  const handleChangeTab = (event: React.SyntheticEvent, newValue: any) => {
    setTabValue(newValue);
    RouteTools.setAnchor(newValue);
  };

  const checkIsLoading = useCallback(() => {
    let load: boolean = false;
    if (!object) load = true;
    setLoading(load);
  }, [object]);

  useEffect(() => {
    checkIsLoading();
  }, [checkIsLoading]);

  const get = () => {
    if (object && !needUpdate) return;
    if (isRequesting) return;
    isRequesting = true;
    setLoading(true);
    getObjectFun(id, handleGetObject, {});
  };

  const getByLanguage = useCallback(() => {
    if (!withLanguage) return;
    if (isRequesting) return;
    isRequesting = true;
    setLoading(true);
    getObjectFun(id, handleGetObject, {}, idLanguage);
  }, [idLanguage]);

  const handleGetObject = (result: ResultObjectDTO) => {
    if (!result) return;
    const obj = (result.obj as T) ?? null;
    setObject(obj);
    isRequesting = false;
  };

  useEffect(() => {
    if (!mainUrl) return;
    if (withLanguage) return;

    get();
  }, [mainUrl]);

  useEffect(() => {
    if (!mainUrl) return;
    if (isFirstRender.current && object) {
      isFirstRender.current = false;
      return;
    } else if (!object) {
      isFirstRender.current = false;
    }

    getByLanguage();
  }, [getByLanguage, mainUrl]);

  return useMemo(
    () => ({
      mainUrl,
      loading,
      object,
      editUrl,
      idLanguage,
      setIdLanguage,
      tabValue,
      handleChangeTab,
      parentId,
      parentType,
      mainListUrl,
      setObject
    }),
    [
      mainUrl,
      loading,
      object,
      editUrl,
      idLanguage,
      tabValue,
      parentId,
      parentType,
      mainListUrl
    ]
  );
};

export { useDetailSpecial };
