import { BlogService } from "services/static/blog.service";
import { CourseService } from "services/course/course.service";
import { CourseCategoryService } from "services/course/coursecategory.service";
import { PageService } from "services/static/page.service";
import { FilterService } from "services/course/filter.service";
import { LessonService } from "services/course/lesson.service";
import { AgreementService } from "services/system/agreement.service";
import { CountryService } from "services/nomenclature/country.service";
import { TypeAgreementService } from "services/nomenclature/typeagreement.service";
import { TypeBlogCategoryService } from "services/nomenclature/typeblogcategory.service";
import { TypeCourseService } from "services/nomenclature/typecourse.service";
import { TypeDisciplineService } from "services/nomenclature/typediscipline.service";
import { TypeExperienceService } from "services/nomenclature/typeexperience.service";
import { TypeFilterService } from "services/nomenclature/typefilter.service";
import { TypeHardwareService } from "services/nomenclature/typehardware.service";
import { TypeInternetConnectionService } from "services/nomenclature/typeinternetconnection.service";
import { TypeLessonService } from "services/nomenclature/typelesson.service";
import { TypeLocalityService } from "services/nomenclature/typelocality.service";
import { TypePhoneService } from "services/nomenclature/typephone.service";
import { TypePriceService } from "services/nomenclature/typeprice.service";
import { TypeProfessionalismLevelService } from "services/nomenclature/typeprofessionalismlevel.service";
import { TypeSocialService } from "services/nomenclature/typesocial.service";
import { TypeOccupationService } from "services/nomenclature/typeoccupation.service";

import { TypeStudiesService } from "services/nomenclature/typestudies.service";
import { TypeTargetGroupService } from "services/nomenclature/typetargetgroup.service";
import { FAQService } from "services/static/faq.service";
import { AdvertisementService } from "services/static/advertisement.service";
import { UserService } from "services/user/user.service";
import { CommonTools } from "./commontools";
import { RoleService } from "services/user/role.service";

const pageService = new PageService();
const blogService = new BlogService();
const courseService = new CourseService();
const courseCategory = new CourseCategoryService();
const filterService = new FilterService();
const lessonService = new LessonService();
const agreementService = new AgreementService();
const countryService = new CountryService();
const typeAgreementService = new TypeAgreementService();
const typeBlogCategoryService = new TypeBlogCategoryService();
const typeCourseService = new TypeCourseService();
const typeDisciplineService = new TypeDisciplineService();
const typeExperienceService = new TypeExperienceService();
const typeFilterService = new TypeFilterService();
const typeHardwareService = new TypeHardwareService();
const typeInternetConnectionService = new TypeInternetConnectionService();
const typeLessonService = new TypeLessonService();
const typeLocalityService = new TypeLocalityService();
const typeOccupationService = new TypeOccupationService();
const typePhoneService = new TypePhoneService();
const typePriceService = new TypePriceService();
const typeProfessionalismLevelService = new TypeProfessionalismLevelService();
const typeSocialService = new TypeSocialService();
const typeSpecialtyService = new TypeOccupationService();
const typeStudiesService = new TypeStudiesService();
const typeTargetGroupService = new TypeTargetGroupService();
const faqService = new FAQService();
const advertisementService = new AdvertisementService();
const userService = new UserService();
const roleService = new RoleService();
export class ServiceTools {
  static _SaveCache: any = false;
  static _GetCache: any = false;

  static setSaveCache(_t: any): any {
    ServiceTools._SaveCache = _t;
  }

  static setGetCache(_t: any): any {
    ServiceTools._GetCache = _t;
  }

  public static getObjectName = async (
    id: string,
    type: string
  ): Promise<string> => {
    const _cache = ServiceTools._GetCache(id + "_" + type);
    if (_cache !== false) {
      return _cache;
    }

    let rez: string | boolean = false;

    if (
      rez === false &&
      (type === "page" ||
        type === "blog" ||
        type === "course" ||
        type === "coursecategory" ||
        type === "filter" ||
        type === "lesson" ||
        type === "agreement" ||
        type === "country" ||
        type === "typeagreement" ||
        type === "typeblogcategory" ||
        type === "typecourse" ||
        type === "typediscipline" ||
        type === "typeexperience" ||
        type === "typefilter" ||
        type === "typehardware" ||
        type === "typeinternetconnection" ||
        type === "typelesson" ||
        type === "typelocality" ||
        type === "typeoccupation" ||
        type === "typephone" ||
        type === "typeprice" ||
        type === "typeprofessionalismlevel" ||
        type === "typesocial" ||
        type === "typespecialty" ||
        type === "typestudies" ||
        type === "typetargetgroup" ||
        type === "faq" ||
        type === "advertisement" ||
        type === "user" ||
        type === "role")
    )
      rez = await ServiceTools.getObjectName_Page(id, type);

    if (rez !== false) {
      const _rez: string = rez as string;
      ServiceTools._SaveCache(_rez, id + "_" + type);
      return _rez;
    }

    return "";
  };

  public static getObjectName_Page = async (
    id: string,
    type: string
  ): Promise<string> => {
    let data;
    switch (type) {
      case "page": {
        data = await pageService.get(id);
        break;
      }
      case "blog": {
        data = await blogService.get(id);
        break;
      }
      case "course": {
        data = await courseService.get(id);
        break;
      }
      case "coursecategory": {
        data = await courseCategory.get(id);
        break;
      }
      case "filter": {
        data = await filterService.get(id);
        break;
      }
      case "lesson": {
        data = await lessonService.get(id);
        break;
      }
      case "agreement": {
        data = await agreementService.get(id);
        break;
      }
      case "country": {
        data = await countryService.get(id);
        break;
      }
      case "typeagreement": {
        data = await typeAgreementService.get(id);
        break;
      }
      case "typeblogcategory": {
        data = await typeBlogCategoryService.get(id);
        break;
      }
      case "typecourse": {
        data = await typeCourseService.get(id);
        break;
      }
      case "typediscipline": {
        data = await typeDisciplineService.get(id);
        break;
      }
      case "typeexperience": {
        data = await typeExperienceService.get(id);
        break;
      }
      case "typefilter": {
        data = await typeFilterService.get(id);
        break;
      }
      case "typehardware": {
        data = await typeHardwareService.get(id);
        break;
      }
      case "typeinternetconnection": {
        data = await typeInternetConnectionService.get(id);
        break;
      }
      case "typelesson": {
        data = await typeLessonService.get(id);
        break;
      }
      case "typelocality": {
        data = await typeLocalityService.get(id);
        break;
      }
      case "typeoccupation": {
        data = await typeOccupationService.get(id);
        break;
      }
      case "typephone": {
        data = await typePhoneService.get(id);
        break;
      }
      case "typeprice": {
        data = await typePriceService.get(id);
        break;
      }
      case "typeprofessionalismlevel": {
        data = await typeProfessionalismLevelService.get(id);
        break;
      }
      case "typesocial": {
        data = await typeSocialService.get(id);
        break;
      }
      case "typespecialty": {
        data = await typeSpecialtyService.get(id);
        break;
      }
      case "typestudies": {
        data = await typeStudiesService.get(id);
        break;
      }
      case "typetargetgroup": {
        data = await typeTargetGroupService.get(id);
        break;
      }
      case "faq": {
        data = await faqService.get(id);
        break;
      }
      case "advertisement": {
        data = await advertisementService.get(id);
        break;
      }
      case "user": {
        data = await userService.get(id);
        break;
      }
      case "role": {
        data = await roleService.get(id);
        break;
      }

      default: {
        return "";
      }
    }

    if (!data) return "";
    if (!data.obj) return "";
    if (type === "user") {
      return CommonTools.processObjectField(data, ["obj", "email"]);
    }
    return CommonTools.processObjectField(data, ["obj", "name"]);
  };
}
