import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class PostAddressDto implements Idto {
  status?: number;
  street?: string;
  zipcode?: string;
  streetnumber?: number;
  idlocality?: string;
  iduser?: string;
  locality?: any;
  constructor() {
    this.status = Status.ACTIVE;
  }
}

export class AddressDto implements Idto {
  id?: string;
  status?: number;
  street?: string;
  zipcode?: string;
  streetnumber?: number;
  idlocality?: string;
  locality?: any;
  iduser?: string;

  constructor(
    iduser?: string,
    street?: string,
    zipcode?: string,
    streetnumber?: number,
    idlocality?: string,
    id?: string,
    status?: number
  ) {
    this.status = status || Status.ACTIVE;
    this.id = id || "";
    this.street = street || "";
    this.zipcode = zipcode || "";
    this.streetnumber = streetnumber || 0;
    this.idlocality = idlocality || "";
    this.iduser = iduser || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "street",
        value: ["street"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "zipcode",
        value: ["zipcode"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "streetnumber",
        value: ["streetnumber"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "locality",
        value: ["locality", "fullname"],
      },
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "street"),
      ComponentCommonTools.columns_StandardColumn(LL, "zipcode"),
      ComponentCommonTools.columns_StandardColumn(LL, "streetnumber"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "locality",
        "fullname"
      ),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["editspecial", "detailspecial", "delete"],
        ["detailspecial"],
        undefined,
        "address"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "iduser";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "iduser") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}
