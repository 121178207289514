import { Quiz } from "@mui/icons-material";
import { Tooltip, IconButton, Box } from "@mui/material";
import { useResource } from "hooks/useResource";


type Props = {
    handleOpen: () => void;
    children: React.ReactNode;
};

const CommonColumnQuestion: React.FC<Props> = ({ handleOpen,children }) => {
  
  const { LL } = useResource();
  
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Tooltip title={LL("questions")} placement="right">
        <IconButton onClick={handleOpen}>
          <Quiz />
        </IconButton>
      </Tooltip>
      {children}
    </Box>
  );
};

export { CommonColumnQuestion };
