import { HeaderList } from "components/elements/list/HeaderList";
import { useListAdmin } from "hooks/useListAdmin";
import { NewsletterDto } from "dto/user/newsletter.dto";
import MyTable from "components/elements/tabeltools/MyTable";
import {NewsletterService} from "services/user/newsletter.service";
import RequestListDTO from "dto/app/requestlist.dto";
import { useResource } from "hooks/useResource";
import React from "react";
import { IPage } from "interfaces/page.interface";

const service = new NewsletterService();

const Newsletter: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    addUrl,
    mainUrl,
    rows,
    total,
    totalPage,
    reqList,
    setReqList,
    loading,
  } = useListAdmin<NewsletterDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun
  );
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <React.Fragment>
      <HeaderList
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={addUrl}
        showAddButton={false}
      />
      <MyTable
        _columns={NewsletterDto.getColumns(LL, mainObject)}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={NewsletterDto.getFilters()}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        mainObject={mainObject}
        fieldToShowOnDelete="email"
      />
    </React.Fragment>
  );
};

export { Newsletter };
