import { PermissionDto, PermissionGroupDto } from "dto/user/permission.dto";
import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CommonTools } from "tools/utils/commontools";
import { PermissionList } from "./PermissionList";

type Props = {
  item: PermissionGroupDto;
  idRole: string;
  setObjects: (objects: PermissionDto[]) => void;
  objects: PermissionDto[];
};

const GroupPermissionItem: React.FC<Props> = ({ item, idRole,setObjects,objects }) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <Accordion
      expanded={open}
      onChange={handleToggle}
      id={CommonTools.processObjectField(item, ["groupname"])}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="group-acl-content"
        id="group-acl-header"
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {CommonTools.processObjectField(item, ["groupname"])}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {open && (
          <PermissionList
            group={item.groupname}
            objects={item.permissions}
            idRole={idRole}
            setObjects={setObjects}
            objectsFull={objects}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export { GroupPermissionItem };
