import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";

import { StatusSelect } from "components/elements/select/StatusSelect";
import { AutocompleteAddStudent } from "components/user/student/components/AutocompleteAddStudent";

type Props = {
  defaultObject: CourseEnrollmentDto;
  onSubmit: (data: CourseEnrollmentDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormCourseEnrollment: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField] = useForm<CourseEnrollmentDto>(
    defaultObject,
    RequiredValidator.getValidators(["idcourse", "idstudent"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {forAdd && (
          <Box>
            <AutocompleteAddStudent object={obj} setObjField={setObjField} />
          </Box>
        )}
        <Box mt={forAdd ? 3 : 0}>
          <StatusSelect
            value={obj.status}
            setObjField={setObjField}
            typeStatus="enrollment"
          />
        </Box>
        <Box mt={3}>
          <StatusSelect
            value={obj.paymentstatus}
            setObjField={setObjField}
            field="paymentstatus"
            typeStatus="payment"
            label="paymentstatus"
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseEnrollment };
