import axios from "axios";
import { Config } from "tools/utils/config";
import axiosRetry from "axios-retry";

const getInstance = (options: object) => {
  const instance = axios.create({
    baseURL: Config.API_URL,
    timeout: 900000,
    ...options,
  });
  axiosRetry(instance, {
    retries: 5,
    retryCondition: (error) => {
      if (error && error.response)
        return (
          (error.response.status >= 500 && error.response.status <= 550) ||
          error.response.status === 0
        );
      else return false;
    },
    retryDelay: (retryCount) => {
      return axiosRetry.exponentialDelay(retryCount);
    },
  });
  return instance;
};
export { getInstance };
