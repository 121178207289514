import { DetailGridItemGeneralProps } from "interfaces/detailobject.interface";
import React from "react";
import { Grid, Typography } from "@mui/material";
import { useResource } from "hooks/useResource";


const DetailGridItem: React.FC<DetailGridItemGeneralProps> = ({
  item,
  index,
  children,
}) => {
  const { LL } = useResource();
  const label = "Field_" + item.label;
  const mt = index === 0 ? 0 : 3;
  return (
    <React.Fragment>
      <Grid mt={mt}>
        <Typography color="text.disabled" variant="caption">
          {LL(label)}
        </Typography>
      </Grid>
      <Grid>{children}</Grid>
    </React.Fragment>
  );
};

export { DetailGridItem };
