import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";
import Box from "@mui/material/Box";
import { logger } from "tools/utils/logger";
import { LanguageDto } from "dto/static/language.dto";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
interface LanguageBlockDetailProps {
  toggleDetail: any;
  isopened: boolean;
}

const LanguageBlockDetail: React.FC<LanguageBlockDetailProps> = ({
  toggleDetail,
  isopened,
  ...props
}) => {
  const { langs, _idlanguage, changeLanguage } = useResource();

  const [currentLang, setCurrentLang] = useState<LanguageDto>(
    new LanguageDto()
  );

  const processCurrentLang = () => {
    if (!Array.isArray(langs)) return;
    var cl: LanguageDto = new LanguageDto();
    for (let lang of langs) {
      if (lang.id != _idlanguage) continue;
      cl = lang;
      break;
    }
    if (cl.id == undefined || cl.id == currentLang.id) {
      return;
    }
    setCurrentLang(cl);
  };

  useEffect(() => {
    processCurrentLang();
  }, [langs, _idlanguage]);
  
  const changeLanguageL = (obj: LanguageDto,event:any) => {
    const isSelected = obj.id == _idlanguage ? true : false;
    if (isSelected) return;

    changeLanguage(obj.id);
    toggleDetail(event);
  };

  const processLang = (obj: LanguageDto, index: number) => {
    const isSelected = obj.id == _idlanguage ? true : false;
    return !isSelected ?(
      <MenuItem  color={isSelected? "primary" : "secondary" } onClick={(event:any) => {changeLanguageL(obj,event);} } >
        {obj.name}
      </MenuItem >
    ): (<></>);
  };

  return  (
    <>
      {langs.map((item: LanguageDto, i: number) => {
        return processLang(item, i);
      })}
    </>
  ) 
};

export { LanguageBlockDetail };
