import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { PricePolicyLinkTeacherDto } from "dto/finance/pricepolicylinkteacher.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import RequiredValidator from "validators/required.validator";
import { PricePolicySelect } from "components/elements/select/PricePolicySelect";

import { AutocompleteTeacher } from "components/user/teacher/AutocompleteTeacher";

type Props = {
  defaultObject: PricePolicyLinkTeacherDto;
  onSubmit: (data: PricePolicyLinkTeacherDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormPricePolicyLinkTeacher: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField] = useForm<PricePolicyLinkTeacherDto>(
    defaultObject,
    RequiredValidator.getValidators(["idpricepolicy","idteacher"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <PricePolicySelect
            value={obj.idpricepolicy}
            field="idpricepolicy"
            setObjectField={setObjField}
          />
        </Box>
        {forAdd && (
          <AutocompleteTeacher
            setObjField={setObjField}
            value={obj.idteacher}
            defaultObject={obj.teacher ?? null}
          />
        )}

        <Box mt={3}>
          <MyButton
            tabIndex={4}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormPricePolicyLinkTeacher };
