import { Box } from "@mui/material";

import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { TeacherDto } from "dto/user/teacher.dto";

import { AutocompleteUser } from "components/user/user/AutocompleteUser";

import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { StudyTypeSelect } from "components/elements/select/StudyTypeSelect";
import { TeacherStatusSelect } from "components/elements/select/TeacherStatusSelect";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { SpecialtyTypeSelect } from "components/elements/select/SpecialtyTypeSelect";
import { TargetGroupTypeSelect } from "components/elements/select/TargetGroupTypeSelect";
import { InternetConnectionTypeSelect } from "components/elements/select/InternetConnectionTypeSelect";
import { HardwareTypeSelect } from "components/elements/select/HardwareTypeSelect";
import { LessonTypeSelect } from "components/elements/select/LessonTypeSelect";
import { OccupationTypeSelect } from "components/elements/select/OccupationTypeSelect";
import { ProfessionalismLevelTypeSelect } from "components/elements/select/ProfessionalismLevelTypeSelect";
import { ExperienceTypeSelect } from "components/elements/select/ExperienceTypeSelect";
import { DisciplineTypeSelect } from "components/elements/select/DisciplineTypeSelect";

type Props = {
  defaultObject: TeacherDto;
  onSubmit: (data: TeacherDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormTeacher: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<TeacherDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "iduser",
      "timetoteachperweek",
      "whyliveclass",
      "graduatedinstitution",
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="timetoteachperweek"
            name="timetoteachperweek"
            variant="outlined"
            color="primary"
            _label={LL("timetoteachperweek")}
            size="small"
            value={obj.timetoteachperweek}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={3}
            fullWidth
            id="whyliveclass"
            name="whyliveclass"
            variant="outlined"
            color="primary"
            _label={LL("whyliveclass")}
            size="small"
            value={obj.whyliveclass}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={4}
            fullWidth
            id="graduatedinstitution"
            name="graduatedinstitution"
            variant="outlined"
            color="primary"
            _label={LL("graduatedinstitution")}
            size="small"
            value={obj.graduatedinstitution}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <TeacherStatusSelect
            value={obj.status}
            field="status"
            setObjectField={setObjField}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <AutocompleteUser
              setObjField={setObjField}
              value={obj.iduser}
              defaultObject={obj.user}
            />
          </Box>
        )}
        <Box mt={3}>
          <StudyTypeSelect
            value={obj.idtypestudies}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <SpecialtyTypeSelect
            value={obj.idtypespecialty}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <TargetGroupTypeSelect
            value={obj.idtypetargetgroup}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <InternetConnectionTypeSelect
            value={obj.idtypeinternetconnection}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyCheckboxField
            label={LL("hasaudience")}
            field="hasaudience"
            setObjectField={setObjField}
            checked={obj.hasaudience}
          />
        </Box>
        <Box mt={3}>
          <HardwareTypeSelect
            value={obj.idtypehardware}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <LessonTypeSelect
            value={obj.idtypelesson}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <OccupationTypeSelect
            value={obj.idtypeoccupation}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <ProfessionalismLevelTypeSelect
            value={obj.idtypeprofessionalismlevel}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <ExperienceTypeSelect
            value={obj.idtypeexperience}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <DisciplineTypeSelect
            value={obj.idtypediscipline}
            setObjectField={setObjField}
            vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyCKEditor
            data={obj.bio ? obj.bio : ""}
            setObj={setObjField}
            name="bio"
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormTeacher };
