import { HeaderList } from "components/elements/list/HeaderList";
import { useListAdmin } from "hooks/useListAdmin";
import MyTable from "components/elements/tabeltools/MyTable";
import RequestListDTO from "dto/app/requestlist.dto";
import { useResource } from "hooks/useResource";
import React from "react";

import { IPage } from "interfaces/page.interface";
import { PricePolicyLinkTeacherDto } from "dto/finance/pricepolicylinkteacher.dto";
import { PricePolicyLinkTeacherService } from "services/finance/pricepolicylinkteacher.service";

const service = new PricePolicyLinkTeacherService();

const PricePolicyLinkTeacher: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    addUrl,
    mainUrl,
    setDeleteOneObject,
    handleDelete,
    rows,
    total,
    totalPage,
    deleteOneObject,
    reqList,
    setReqList,
    loading,
  } = useListAdmin<PricePolicyLinkTeacherDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun
  );
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <React.Fragment>
      <HeaderList mainObject={mainObject} mainUrl={mainUrl} addUrl={addUrl} />
      <MyTable
        _columns={PricePolicyLinkTeacherDto.getColumns(
          LL,
          mainObject,
          setDeleteOneObject
        )}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={PricePolicyLinkTeacherDto.getFilters()}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        onDelete={handleDelete}
        mainObject={mainObject}
        fieldToShowOnDelete="id"
        deleteOneObject={deleteOneObject}
        setDeleteOneObject={setDeleteOneObject}
      />
    </React.Fragment>
  );
};

export { PricePolicyLinkTeacher };
