import { Box } from "@mui/material";
import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { SocialTypeSelect } from "components/elements/select/SocialTypeSelect";
import { SiteSocialDto } from "dto/static/sitesocial.dto";

type Props = {
  defaultObject: SiteSocialDto;
  onSubmit: (data: SiteSocialDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormSiteSocial: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<SiteSocialDto>(
    defaultObject,
    RequiredValidator.getValidators(["link"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <SocialTypeSelect
          value={obj.idtypesocial}
          setObjectField={setObjField}
        />

        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="link"
            name="link"
            variant="outlined"
            color="primary"
            _label={LL("link")}
            size="small"
            value={obj.link}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>

        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormSiteSocial };
