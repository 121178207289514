import { Box } from "@mui/material";

import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { PricePolicyDto } from "dto/finance/pricepolicy.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { TeacherStatusSelect } from "components/elements/select/TeacherStatusSelect";
import { CourseTypeSelect } from "components/elements/select/CourseTypeSelect";
import { useEffect } from "react";

type Props = {
  defaultObject: PricePolicyDto;
  onSubmit: (data: PricePolicyDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormPricePolicy: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults, setObjectLanguage] =
    useForm<PricePolicyDto>(
      defaultObject,
      RequiredValidator.getValidators(["name", "teacherstatus", "idtypecourse"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  useEffect(() => {
    if (obj.percentageteacher !== undefined) {
      if (obj.percentageteacher >= 0 && obj.percentageteacher <= 100) {
        const value = 100 - obj.percentageteacher;
        setObjField("percentageteacher", obj.percentageteacher);
        setObjField("percentageplatform", value);
      }
    }
  }, [obj?.percentageteacher]);

  useEffect(() => {
    if (obj.percentageplatform !== undefined) {
      if (obj.percentageplatform >= 0 && obj.percentageplatform <= 100) {
        const value = 100 - obj.percentageplatform;
        setObjField("percentageplatform", obj.percentageplatform);
        setObjField("percentageteacher", value);
      }
    }
  }, [obj?.percentageplatform]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="ordercriteria"
            name="ordercriteria"
            variant="outlined"
            color="primary"
            _label={LL("ordercriteria")}
            size="small"
            value={obj.ordercriteria}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={2}
            fullWidth
            inputProps={{ inputMode: "numeric", min: 0, max: 100 }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            id="percentageteacher"
            name="percentageteacher"
            variant="outlined"
            color="primary"
            _label={LL("percentageteacher")}
            size="small"
            value={obj.percentageteacher}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            inputProps={{ inputMode: "numeric", min: 0, max: 100 }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            tabIndex={3}
            fullWidth
            id="percentageplatform"
            name="percentageplatform"
            variant="outlined"
            color="primary"
            _label={LL("percentageplatform")}
            size="small"
            value={obj.percentageplatform}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyCheckboxField
            label={LL("isdefault")}
            field="isdefault"
            setObjectField={setObjField}
            checked={obj.isdefault}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        <Box mt={3}>
          <TeacherStatusSelect
            value={obj.teacherstatus}
            field="teacherstatus"
            setObjectField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <CourseTypeSelect
            value={obj.idtypecourse}
            setObjectField={setObjField}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect
              value={obj.idlanguage ?? ""}
              setValue={setObjectLanguage}
            />
          </Box>
        )}

        <Box mt={3}>
          <MyButton
            tabIndex={4}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormPricePolicy };
