import { DetailGridItemProps } from "interfaces/detailobject.interface";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { Grid, Typography } from "@mui/material";

import { useResource } from "hooks/useResource";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const DetailGridImage: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
  const { LL } = useResource();
  // const label = "Field_" + item.label;
  let width: string | number = 50;
  let height: string | number = 50;
  let alt = "";
  let withLink = false;
  const options = item.options ? item.options : null;
  if (options) {
    width = options.width ? options.width : 50;
    height = options.height ? options.height : 50;
    alt = options.alt ? options.alt : "";
    withLink = options.withLink ? options.withLink : false;
  }
  const mt = index === 0 ? 0 : 3;
  return (
    <React.Fragment>
      <Grid mt={mt}>
        <Typography color="text.disabled" variant="caption">
          {LL(item.label)}
        </Typography>
      </Grid>
      <Grid>
        {ComponentCommonTools.getFileBlockImage(
          CommonTools.processObject(object, item.value),
          typeof width == "string" ? parseInt(width) : width,
          typeof height == "string" ? parseInt(height) : height,
          alt,
          withLink
        )}
      </Grid>
    </React.Fragment>
  );
};

export { DetailGridImage };
