import { NomenclatureAdd } from "components/elements/nomenclature/NomenclatureAdd";
import { NomenclatureDetail } from "components/elements/nomenclature/NomenclatureDetail";
import { NomenclatureEdit } from "components/elements/nomenclature/NomenclatureEdit";
import { NomenclatureList } from "components/elements/nomenclature/NomenclatureList";
import { IPage } from "interfaces/page.interface";
import { TypePriceService } from "services/nomenclature/typeprice.service";

const service = new TypePriceService();

const TypePrice: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureList
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const DetailTypePrice: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureDetail
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const AddTypePrice: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureAdd
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const EditTypePrice: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureEdit
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};
export { TypePrice, DetailTypePrice, AddTypePrice, EditTypePrice };
