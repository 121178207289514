import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "pages/general/Login";

import { Loading } from "components/elements/loading/Loading";
import { Layout } from "components/general/Layout";
import { MainLayout } from "components/general/MainLayout";
import { useResource } from "hooks/useResource";

const AppRoutes: React.FC = () => {
  const { currentRoute } = useResource();
  const [isLoading, setIsLoading] = useState(true);
  // ----------------------------------------
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={<Login currentRoute={currentRoute} mainObject="login" />}
        />

        <Route path="/" element={<Layout />}>
          <Route index element={<MainLayout />} />
          <Route path="*" element={<MainLayout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
