import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { logger } from "tools/utils/logger";
import { useResource } from "hooks/useResource";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { SupportArticleCategoryService } from "services/support/supportarticlecategory.service";
import { Status } from "tools/types/status";
import { GeneralAutocomplete } from "./newAutocomplete/GeneralAutocomplete";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { SupportArticleDto } from "dto/support/supportarticle.dto";
const service = new SupportArticleCategoryService();

type Props = {
  setObjField: (field: string, value: any) => void;
  defaultObject?: SupportArticleDto | null;
};

const SupportArticleCategoryAutocomplete: React.FC<Props> = ({
  setObjField,
  defaultObject,
}) => {
  const { LL } = useResource();

  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  let defaultObj: SelectOptions | null = null;
  if (!defaultObject) defaultObj = null;
  else defaultObj = SupportArticleDto.parseOption(defaultObject);

  return (
    <GeneralAutocomplete
      field="idsupportarticlecategory"
      label={LL("field_supportarticlecategory")}
      defaultObject={defaultObj}
      setObjectField={setObjField}
      parseOptions={SupportArticleDto.parseToSelectOptions}
      getListFunction={getList}
      filterField="searchvalue"
    />
  );
};

export { SupportArticleCategoryAutocomplete };
