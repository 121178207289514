import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import SelectOptions from "dto/app/selectoptions.dto";
import { CommonTools } from "tools/utils/commontools";

export class SupportArticleDto implements Idto {
  id?: string;

  status?: number;

  idsupportarticlecategory?: string;

  idlanguage?: string;
  name?: string;
  url?: string;
  title?: string;
  keymeta?: string;
  descriptionmeta?: string;
  content?: string;
  supportarticlecategory?: any;
  attachment?: any;
  gallery?: any;
  video?: any;
  language?: any;
  fullurl?: any;
  idsupportarticle?: string;
  ordercriteria?: number;
  shortdescription?: string;

  constructor(
    idlanguage?: string,
    name?: string,
    idsupportarticlecategory?: string,
    title?: string,
    descriptionmeta?: string,
    keymeta?: string,
    url?: string
  ) {
    this.status = Status.ACTIVE;

    this.ordercriteria = 0;
    this.name = name || "";
    this.idsupportarticlecategory = idsupportarticlecategory || "";
    this.title = title || "";
    this.descriptionmeta = descriptionmeta || "";
    this.keymeta = keymeta || "";
    this.url = url || "";
    this.idlanguage = idlanguage || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "title",
        value: ["title"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "shortdescription",
        value: ["shortdescription"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "content",
        value: ["content"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "descriptionmeta",
        value: ["descriptionmeta"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "keymeta",
        value: ["keymeta"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "url",
        value: ["url"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "supportarticlecategory",
        value: ["supportarticlecategory", "name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name",undefined, false),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_StandardColumn(LL, "title"),
      ComponentCommonTools.columns_StandardColumn(LL, "url"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "gallery", "attachment", "video"],
        ["detail","attachment", "video"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(
    data: Array<SupportArticleDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(SupportArticleDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: SupportArticleDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["fullname"])
    );
  };
}

export class PostSupportArticleDto implements Idto {
  id?: string;
  type?: number;
  status?: number;
  idsupportarticlecategory?: string;
  idlanguage?: any;
  name?: any;
  url?: any;
  title?: any;
  keymeta?: any;
  descriptionmeta?: any;
  content?: any;
  idsupportarticle?: string;
  ordercriteria?: number;
  shortdescription?: string;

  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}
