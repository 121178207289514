import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";



type Props = {
  mainObject?: string;
};

const StayOnPageCheckbox: React.FC<Props> = ({ mainObject }) => {
  const { setCookieStayOnPage, getCookieStayOnPage,LL } = useResource();
  const stayOnPage = getCookieStayOnPage(mainObject || "");

  const [checked, setChecked] = useState(stayOnPage);
  
  const setValue = (field: string, value: boolean) => {
    if (!mainObject) return;
    setChecked(value);
    setCookieStayOnPage(mainObject, value);
  };
  if (!mainObject) return null;
  return (
    <MyCheckboxField
      label={LL("stay_on_page")}
      field={"stayonpage"}
      setObjectField={setValue}
      checked={checked}
    />
  );
};

export { StayOnPageCheckbox };
