import { Box, Stack, IconButton } from "@mui/material";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import SpecialTopInfo from "components/special/SpecialTopInfo";
import { MyBreadcrumbs } from "../breadcrumbs/MyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";

type Props = {
  mainObject: string;
  mainUrl: string;
  parentType: string;
  parentId: string;
  specialType: string;
};

const HeaderAddSpecial: React.FC<Props> = ({
  mainObject,
  mainUrl,
  parentType,
  parentId,
  specialType,
}) => {
  return (
    <React.Fragment>
      <MyBreadcrumbs
        childs={BreadcrumbDto.getBreadcrumbAddSpecial(
          specialType,
          mainObject,
          parentId
        )}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",

          width: "100%",
        }}
        maxWidth="md"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <MyNavBt
              href={mainUrl}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </MyNavBt>
          </Box>
        </Stack>
        <Box>
          <SpecialTopInfo
            parentType={parentType}
            parentId={parentId}
            specialtype={specialType}
            suplimentar={"add"}
            variant="h4"
            sx={{ my: 3 }}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export { HeaderAddSpecial };
