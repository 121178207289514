import { Box } from "@mui/material";

import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { UserSettingsDto } from "dto/user/usersettings.dto";

import React from "react";
import MyDatePicker from "components/elements/datepicker/MyDatePicker";
import { LanguageSelect } from "components/static/language/newLanguageSelect";
import AvatarCropper from "components/elements/myavatar/AvatarCropper";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { CommonTools } from "tools/utils/commontools";
import { TypeGenderSelect } from "components/elements/select/GenderTypeSelect";

type Props = {
  defaultObject: UserSettingsDto;
  onSubmit: (data: UserSettingsDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormUserSettings: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, setObject, vresults, setObjectLanguage] =
    useForm<UserSettingsDto>(
      defaultObject,
      RequiredValidator.getValidators(["name", "surname", "idlanguage"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  const setAvatar = (file: File | null) => {
    if (!obj) return;
    const newObj = { ...obj };
    newObj["files"] = file;
    setObject(newObj);
  };
  const setCheckDeleteAvatar = (value: any) => {
    CommonTools.handleCheckValue(obj, setObject, "deleteavatar", value);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("Name")}
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="surname"
            name="surname"
            variant="outlined"
            color="primary"
            _label={LL("surname")}
            value={obj.surname}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyDatePicker
            tabIndex={3}
            field="birthday"
            _defaultValue={obj.birthday}
            LL={LL}
            setObjField={setObjField}
          />
        </Box>
        <Box mt={3}>
          <LanguageSelect
            value={obj.idlanguage ?? ""}
            setValue={setObjectLanguage}
          />
        </Box>
        <Box mt={3}>
          <AvatarCropper setFile={setAvatar} avatar={obj.avatar} />
        </Box>
        <Box mt={3}>
          <TypeGenderSelect
            value={obj.idtypegender}
            setObjectField={setObjField}
          />
        </Box>
        {!forAdd && (
          <Box mt={3}>
            {ComponentCommonTools.processCheckboxDelete(
              obj,
              obj,
              "avatar",
              "deleteavatar",
              "name",
              setCheckDeleteAvatar,
              LL
            )}
          </Box>
        )}
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormUserSettings };
