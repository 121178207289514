import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class PostFAQDto implements Idto {
  status?: number;
  ordercriteria?: number;
  hasurl?: boolean;
  idlanguage?: string;
  idfaq?: string;
  question?: string;
  answer?: string;
  type?: number;
  idobject?: string;
  idgallery?: string;
  idattachment?: string;
  idvideo?: string;
  idtypeblogcategory?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}

export class FAQDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  hasurl?: boolean;
  idlanguage?: string;
  idfaq?: string;
  question?: string;
  answer?: string;
  type?: number;
  idobject?: string;

  language?: any;
  status_name?: string;
  type_name?: string;

  idtypeblogcategory?: string;
  typeblogcategory?: any;
  constructor(
    idlanguage?: string,
    question?: string,
    answer?: string,
    status?: number,
    ordercriteria?: number,
    idtypeblogcategory?: string
  ) {
    this.status = status || Status.ACTIVE;
    this.ordercriteria = ordercriteria || 0;
    this.idlanguage = idlanguage || "";
    this.question = question || "";
    this.answer = answer || "";
    this.idtypeblogcategory = idtypeblogcategory || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "question",
        value: ["question"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "answer",
        value: ["answer"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["typeblogcategory", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(
        LL,
        "question",
        undefined,
        false
      ),
      ComponentCommonTools.columns_StandardColumn(
        LL,
        "answer",
        undefined,
        false
      ),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "typeblogcategory",
        "name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "gallery", "attachment", "video"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}
