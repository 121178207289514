import React from "react";

import { IPage } from "interfaces/page.interface";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box, Tab, Tabs } from "@mui/material";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";

import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { FormUser } from "components/user/user/FormUser";
import { UserDto } from "dto/user/user.dto";
import {UserService} from "services/user/user.service";
import { useResource } from "hooks/useResource";
import { TabPanel } from "components/elements/tabs/TabPanel";
import { UserSettingsTab } from "components/user/usersettings/UserSettingsTab";
import { FormModifyPassword } from "components/user/user/FormModifyPassword";
import { ChangePasswordByAdminDto } from "dto/user/changepasswordbyadmin.dto";

const service = new UserService();
const EditUser: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };
  const { LL } = useResource();
  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    handleChangeTab,
    tabValue,
  } = useEditObject<UserDto>(mainObject, currentRoute, get);

  const onSubmit = (obj: UserDto) => {
    if (!obj) return;
    setLoading(true);
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };

  const onSubmitModifyPassword = (obj: UserDto) => {
    if (!obj) return;
    setLoading(true);
    service.modifyPasswordByAdmin(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
        needUpdate: true,
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        needUpdate={true}
      />
      <Box maxWidth="md" className="tabs">
        <Tabs variant="scrollable" value={tabValue} onChange={handleChangeTab}>
          <Tab label={LL("Edit_User_main_Info")} value={"generalinfo"} />
          <Tab label={LL("Edit_User_Settings_Info")} value={"usersettings"} />
          <Tab label={LL("User_Change_Password")} value={"changepassword"} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={"generalinfo"}>
        <DataDisplayBox>
          <Box>
            <FormUser
              defaultObject={object}
              onSubmit={onSubmit}
              loading={loading}
            />
          </Box>
        </DataDisplayBox>
      </TabPanel>
      <TabPanel value={tabValue} index={"usersettings"}>
        <DataDisplayBox>
          <UserSettingsTab
            idUser={id}
            mainObject={mainObject}
            currentRoute={currentRoute}
            idUserSettings={CommonTools.processObjectField(object, [
              "idusersettings",
            ])}
          />
        </DataDisplayBox>
      </TabPanel>
      <TabPanel value={tabValue} index={"changepassword"}>
        <DataDisplayBox>
          <FormModifyPassword
            defaultObject={new ChangePasswordByAdminDto()}
            onSubmit={onSubmitModifyPassword}
            loading={loading}
          />
        </DataDisplayBox>
      </TabPanel>
    </React.Fragment>
  );
};

export { EditUser };
