import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {AddressRepository} from "repositories/nomenclature/address.repository";

import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class AddressService
  extends GeneralService<AddressRepository>
  implements IService
{
  constructor() {
    super(new AddressRepository(), [{ key: "status", type: "status" }], "street");
  }

  async getAddressByUserId(
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.getAddressByUserId(id, this.handleGet, cbparameters);
  }
  
  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = 'street';
    defaultRequestList.sortcriteria = []
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
}

}
// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// // import { PostAddressDto } from "dto/address/address.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// // import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import AddressRepository from "repositories/address/address.repository";
// // import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";

// export default class AddressService extends GeneralService implements IService {
//     addressRepository: AddressRepository = new AddressRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//         this.handleGetSpecial = this.handleGetSpecial.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.addressRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.addressRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     async getAddressByUserId(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.addressRepository.getAddressByUserId(id, this.handleGetSpecial, cbparameters);
//     }

//     handleGetSpecial(result?: any, cbparameters?: any, data?: any): any {
//         let rez: any;
//         rez = result;
//         if (result.err) return;

//         if (result.obj && rez.obj) {
//             for (let index = 0; index < result.obj.length; index++) {
//                 const obj = result.obj[index];
//                 if (rez.obj[index]) {
//                     this.populateObject(rez.obj[index], obj, [
//                         { key: 'status', type: 'status' },
//                     ]);
//                 }
//             }
//         }
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         this.populateObject(rez.obj, result.obj, [
//             { key: 'status', type: 'status' },
//         ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.addressRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'status', type: 'status' },

//           ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.addressRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     // prepareObjToAdd(obj: any): PostAddressDto {
//     //     const rez = new PostAddressDto();

//     //     rez.idlanguage = obj.idlanguage;
//     //     rez.status = obj.status;
//     //     rez.ordercriteria = obj.ordercriteria;

//     //     rez.idparent = obj.idparent !== undefined ? obj.idparent : '';
//     //     rez.idtypeaddress = obj.idtypeaddress;
//     //     rez.idcountry = obj.idcountry;
//     //     rez.name = obj.name;

//     //     return rez;
//     // }

// prepareObjToUpdate(obj: any): PostAddressDto {
//     const rez = new PostAddressDto();

//     rez.idlanguage = obj.idlanguage;
//     rez.status = obj.status;
//     rez.ordercriteria = obj.ordercriteria;
//     rez.idaddress = obj.idaddress;

//     rez.idparent = obj.idparent !== undefined ? obj.idparent : '';
//     rez.idtypeaddress = obj.idtypeaddress;
//     rez.idcountry = obj.idcountry;
//     rez.name = obj.name;

//     return rez;
// }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.addressRepository.add(this.handleGetList, cbparameters, data);
//     }

    // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    //     var defaultRequestList = new RequestListDTO();
    //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    //     defaultRequestList.onpage = onPage;
    //     var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    //     defaultRequestSortCriteria.asc = true;
    //     defaultRequestSortCriteria.field = 'street';
    //     defaultRequestList.sortcriteria = []
    //     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    //     return defaultRequestList;
    // }

//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "street";

//             obj.sortcriteria = [sobj];
//         }

//         return obj;
//     }
// }
