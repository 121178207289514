import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";

import Idto from "interfaces/idto.interface";
import React from "react";
import { MySelect } from "./MySelect";
import { Skeleton } from "@mui/material";
import { useList } from "hooks/useList";
import ValidatorResult from "validators/validatorresult";


type Props = {
  processOptions: (objects: Idto[]) => Array<SelectOptions>;
  getList: (cb?: any, cbParams?: any, data?: RequestListDTO) => void;
  field: string;
  setObjectField: (field: string, value: any) => void;
  value: any;
  label?: string;
  dependencies?: any[];
  vresults?: ValidatorResult;
};

const ServerSelect: React.FC<Props> = ({
  processOptions,
  getList,
  field,
  setObjectField,
  value,
  label = "",
  dependencies = [],
  vresults
}) => {
  
    const [loading, objects] = useList<Idto>(
    getList,
    new RequestListDTO([], 1, -1),
    dependencies,
    true
  );

  if (loading) return <Skeleton variant="rectangular" height={50}/>;
  if (!objects) return null;
  return (
    <MySelect
      options={processOptions(objects)}
      _label={label}
      setObjectField={setObjectField}
      value={value}
      field={field}
      vresults={vresults}
    />
  );
};

export { ServerSelect };
