import { TestDto } from "dto/course/test.dto";
import { useState } from "react";
import { TestDialog } from "./TestDialog";
import { CommonColumnQuestion } from "./CommonColumnQuestion";

type Props = {
  object: TestDto;
  setObject: (value: TestDto | null) => void;
};

const ColumnQuestion: React.FC<Props> = ({ object, setObject }) => {
  const [open, setOpen] = useState(false);
  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <CommonColumnQuestion handleOpen={handleOpen}>
      <TestDialog
        object={object}
        open={open}
        handleClose={handleClose}
        setObject={setObject}
      />
    </CommonColumnQuestion>
  );
};

export { ColumnQuestion };
