import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";


export class PostCurrencyRateDto implements Idto {
  date?: Date;
  fromidcurrency?: string;
  toidcurrency?: string;
  rate?: number;
}

export class CurrencyRateDto implements Idto {
  id?: string;
  date?: Date;
  fromidcurrency?: string;
  toidcurrency?: string;
  rate?: number;
  fromidcurrencyobj?: any;
  toidcurrencyobj?: any;

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),

      ComponentCommonTools.columns_StandardColumn(LL, "rate"),
      ComponentCommonTools.columns_ConstantColumn(LL, "date"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "fromidcurrencyobj",
        "identifier"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "toidcurrencyobj",
        "identifier"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    

    return obj;
  }
}
