// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PostSiteSocialDto } from "dto/sitesocial/sitesocial.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import SiteSocialRepository from "repositories/sitesocial/sitesocial.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";

import GeneralService from "services/globalgeneral.service";
import {SiteSocialRepository} from "repositories/static/sitesocial.repository";
import IService from "interfaces/iservice.interface";
import { SiteSocialDto } from "dto/static/sitesocial.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

export class SiteSocialService
    extends GeneralService<SiteSocialRepository>
    implements IService
{
    constructor() {
        super(
            new SiteSocialRepository(),
            [
          { key: 'status', type: 'status' },
            ],
            "link"
        );
    }

        prepareObjToUpdate(obj: any): SiteSocialDto {
        const rez = new SiteSocialDto();

        rez.link = obj.link;
        rez.status = obj.status;
        rez.idtypesocial = obj.idtypesocial;

        return rez;
    }

    getDefaultRequestListSelect(onPage?: number): RequestListDTO {
        onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
        if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
        var defaultRequestList = new RequestListDTO();
        defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
        defaultRequestList.onpage = onPage;
        var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
        defaultRequestSortCriteria.asc = true;
        defaultRequestSortCriteria.field = 'link';
        defaultRequestList.sortcriteria = []
        defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
        return defaultRequestList;
    }
}

// export default class SiteSocialService extends GeneralService implements IService {
//     siteSocialRepository: SiteSocialRepository = new SiteSocialRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.siteSocialRepository.update(id, this.handleUpdate, this.prepareObjToUpdate(data), cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.siteSocialRepository.delete(id, this.handleDelete, cbparameters);
//     }



//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
//             { key: 'status', type: 'status' },

//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//       cbparameters = cbparameters ? cbparameters : {};
//       cbparameters._cb = cb;
//       const t = await this.siteSocialRepository.get(id, this.handleGet, cbparameters);
//     if (cb == undefined)
//     {
//         return this.handleGet(t);
//     }
//     }

    
//     handleGetList(result?: any, cbparameters?: any, data?: any): any {

//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'status', type: 'status' },

//         ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.siteSocialRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined) {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     prepareObjToAdd(obj: any): PostSiteSocialDto {
//         const rez = new PostSiteSocialDto();

//         rez.link = obj.link;
//         rez.status = obj.status;
//         rez.idtypesocial = obj.idtypesocial;


//         return rez;
//     }

//     prepareObjToUpdate(obj: any): PostSiteSocialDto {
//         const rez = new PostSiteSocialDto();

//         rez.link = obj.link;
//         rez.status = obj.status;
//         rez.idtypesocial = obj.idtypesocial;

//         return rez;
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.siteSocialRepository.add(this.handleGetList, cbparameters, this.prepareObjToAdd(data));
//     }


    // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    //     var defaultRequestList = new RequestListDTO();
    //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    //     defaultRequestList.onpage = onPage;
    //     var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    //     defaultRequestSortCriteria.asc = true;
    //     defaultRequestSortCriteria.field = 'link';
    //     defaultRequestList.sortcriteria = []
    //     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    //     return defaultRequestList;
    // }
//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "link";

//             obj.sortcriteria = [sobj];
//         }

//         return obj;
//     }
// }
