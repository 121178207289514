import { Box } from "@mui/material";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";
import { SiteConfigDto } from "dto/static/siteconfig.dto";

type Props = {
  defaultObject: SiteConfigDto;
  onSubmit: (data: SiteConfigDto) => void;
  loading: boolean;
};
const FormSiteConfig: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults] = useForm<SiteConfigDto>(
    defaultObject,
    RequiredValidator.getValidators(["value"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="value"
            name="value"
            variant="outlined"
            color="primary"
            _label={LL("label_text_field_value")}
            size="small"
            value={obj.value}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormSiteConfig };