import React from "react";
import  {SocialService}  from "services/user/social.service";

import { IPage } from "interfaces/page.interface";

import { SocialDto } from "dto/user/social.dto";


import { FormSocial } from "components/special/social/FormSocial";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";


const service = new SocialService();
const SpecialSocialAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  
  const onSubmit = (obj: SocialDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject, parentType, parentId, specialPart: "social",
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="social"
      />
      <DataDisplayBox>
        <FormSocial
          defaultObject={
            new SocialDto(parentId)
          }
          onSubmit={onSubmit}
          loading={loading}
          
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialSocialAdd };
