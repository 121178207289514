import React from "react";
import { Typography } from "@mui/material";


import { DetailGridItemSimpleProps } from "interfaces/detailobject.interface";
import { useResource } from "hooks/useResource";
import { DetailGridItem } from "./DetailGridItem";

const DataGridNoData: React.FC<DetailGridItemSimpleProps> = ({
  item,
  index,
}) => {
  const { LL } = useResource();

  return (
    <DetailGridItem item={item} index={index}>
      <Typography>{LL("No_data_yet")}</Typography>
    </DetailGridItem>
  );
};

export { DataGridNoData };
