import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { CoursePriceRepository } from "repositories/course/courseprice.repository";

export class CoursePriceService
  extends GeneralService<CoursePriceRepository>
  implements IService
{
  constructor() {
    super(
      new CoursePriceRepository(),
      [{ key: "userecommendationprice", type: "booleans" }],
      "totalprice"
    );
  }
  async getCoursePriceByCourseId(
    id: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    this.repository.getCoursePriceByCourseId(id, this.handleGet, cbparameters);
  }
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { CoursePriceDto } from "dto/course/courseprice.dto";

// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import CoursePriceRepository from "repositories/course/courseprice.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";

// export default class CoursePriceService extends GeneralService implements IService {
//     coursePriceRepository: CoursePriceRepository = new CoursePriceRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.coursePriceRepository.update(id, this.handleUpdate, this.prepareObjToAdd(data), cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.coursePriceRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
//             { key: 'userecommendationprice', type: 'booleans' },
//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.coursePriceRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined) {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.parseResultPopulate(result, rez, [
//             { key: 'userecommendationprice', type: 'booleans' },
//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     prepareObjToAdd(obj: any): CoursePriceDto {
//         const rez = new CoursePriceDto();

//         if (obj.hasOwnProperty('idcurrency') && obj.idcurrency) rez.idcurrency = obj.idcurrency;

//         if (obj.hasOwnProperty('idpricepolicy') && obj.idpricepolicy) rez.idpricepolicy = obj.idpricepolicy;
//         if (obj.hasOwnProperty('idcourse') && obj.idcourse) rez.idcourse = obj.idcourse;
//         if (obj.hasOwnProperty('totalprice') && obj.totalprice !== undefined) rez.totalprice = obj.totalprice;
//         if (obj.hasOwnProperty('priceperlesson') && obj.priceperlesson !== undefined) rez.priceperlesson = obj.priceperlesson;
//         if (obj.hasOwnProperty('userecommendationprice')) rez.userecommendationprice = obj.userecommendationprice;
//         else rez.userecommendationprice = false;
//         if (rez.userecommendationprice === true) {
//             if (obj.hasOwnProperty('idrecommendationprice') && obj.idrecommendationprice) rez.idrecommendationprice = obj.idrecommendationprice;
//         } else {
//             rez.idrecommendationprice = null;
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.coursePriceRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined) {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.coursePriceRepository.add(this.handleGetList, cbparameters, this.prepareObjToAdd(data));
//     }

// async getCoursePriceByCourseId(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.coursePriceRepository.getCoursePriceByCourseId(id, this.handleGet, cbparameters);
// }
//     // getDefaultRequestList(onPage?: number): RequestListDTO {
//     //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     //     var defaultRequestList = new RequestListDTO();
//     //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     //     defaultRequestList.onpage = onPage;
//     //     // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//     //     // defaultRequestSortCriteria.asc = true;
//     //     // defaultRequestSortCriteria.field = 'value';
//     //     // defaultRequestList.sortcriteria = []
//     //     // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     //     return defaultRequestList;
//     // }

//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "totalprice";

//             obj.sortcriteria = [sobj];
//         }

//         return obj;
//     }
// }
