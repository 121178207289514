import { Box, Grid } from "@mui/material";

import { NomenclatureDto } from "dto/nomenclature/nomenclature.dto";

import React from "react";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { INomenclaturePage } from "interfaces/nomenclaturepage.interface";
import { HeaderDetail } from "components/elements/detail/HeaderDetail";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { Loading } from "components/elements/loading/Loading";
import { useDetailObject } from "hooks/useDetailObject";

const NomenclatureDetail: React.FC<INomenclaturePage> = ({
  currentRoute,
  mainObject,
  service,
}) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };

  const {
    mainUrl,
    editUrl,
    listUrl,
    object,
    loading,
    idLanguage,
    setIdLanguage,
  } = useDetailObject<NomenclatureDto>(mainObject, currentRoute, get, true);

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetail
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        listUrl={listUrl}
        editUrl={editUrl}
        idLanguage={idLanguage}
      />
      <DataDisplayBox>
        <Box>
          <LanguageSelect value={idLanguage} setValue={setIdLanguage} />
        </Box>
        <Grid>
          <DetailGrid
            object={object}
            fields={NomenclatureDto.getDetailFields()}
          />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { NomenclatureDetail };
