import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { UserRoleDto } from "dto/user/userrole.dto";
import { RoleSelect } from "components/elements/select/RoleSelect";

type Props = {
  defaultObject: UserRoleDto;
  onSubmit: (data: UserRoleDto) => void;
  loading: boolean;
};
const FormUserRole: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField,] = useForm<UserRoleDto>(
    defaultObject,
    RequiredValidator.getValidators(["idrole", "iduser"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <RoleSelect setObjectField={setObjField} value={obj.idrole} />
        </Box>

        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormUserRole };
