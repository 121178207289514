import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import SelectOptions from "dto/app/selectoptions.dto";

import { CommonTools } from "tools/utils/commontools";

export class PostLocalityDto implements Idto {
  status?: number;
  ordercriteria?: number;
  idlanguage?: string;
  idlocality?: string;
  idparent?: string;
  idtypelocality?: string;
  name?: string;
  idcountry?: string;
  constructor() {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
  }
}

export class LocalityDto implements Idto {
  id?: string;
  status?: number;
  ordercriteria?: number;
  idlanguage?: string;
  idlocality?: string;
  idcountry?: string;
  language?: any;
  code?: string;
  level?: number;
  idparent?: string;
  idtypelocality?: string;
  name?: string;
  status_name?: string;
  country?: any;
  typelocality?: any;
  // constructor() {
  //   this.status = Status.ACTIVE;
  //   this.ordercriteria = 0;
  // }
  constructor(
    idlanguage?: string,
    idparent?: string,
    name?: string,
    status?: number,
    idcountry?: string,
    idtypelocality?: string,
    ordercriteria?: number
  ) {
    this.status = status || Status.ACTIVE;
    this.ordercriteria = ordercriteria || 0;
    this.idlanguage = idlanguage || "";
    this.idparent = idparent || "";
    this.name = name || "";
    this.idcountry = idcountry || "";
    this.idtypelocality = idtypelocality || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "ordercriteria",
        value: ["ordercriteria"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "country",
        value: ["country", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typelocality",
        value: ["typelocality", "name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "typelocality",
        "name"
      ),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "country", "name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "children"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<LocalityDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(LocalityDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: LocalityDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["name"])
    );
  };
}
