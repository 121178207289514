import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Types } from "tools/types/types";

import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";

export class PostJournalDto implements Idto {
  id?: string;
  date?: number;
  type?: number;
  iduser?: string;
  info?: string;
  idparent?: string;
  parent?: string;
  user?: any;
}

export class JournalDto implements Idto {
  id?: string;

  date?: number;
  type?: number;
  iduser?: string;
  info?: string;
  idparent?: string;
  parent?: string;

  constructor(
    id?: string,

    date?: number,
    type?: number,
    iduser?: string,
    info?: string,
    idparent?: string,
    parent?: string
  ) {
    this.id = id || "";

    this.date = date || 0;
    this.type = type || Types.TYPE_JOURNAL_ADD;
    this.iduser = iduser || "";
    this.info = info || "";
    this.idparent = idparent || "";
    this.parent = parent || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["type_name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "date",
        value: ["date_name"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "parent",
        value: ["parent"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "user_email",
        value: ["user", "email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "info",
        value: ["info"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_ConstantColumn(LL, "date"),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardObjectColumn(LL, "user", "email"),
      ComponentCommonTools.columns_StandardColumn(LL, "parent"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detail"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    return obj;
  }
}
