import { Backdrop } from "@mui/material";
import { CircularLoading } from "components/elements/loading/CircularLoading";
import React from "react";


type Props = {
  loading: boolean;
};

const MyBackdrop: React.FC<Props> = ({ loading }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularLoading />
    </Backdrop>
  );
};

export { MyBackdrop };
