import { NomenclatureAdd } from "components/elements/nomenclature/NomenclatureAdd";
import { NomenclatureDetail } from "components/elements/nomenclature/NomenclatureDetail";
import { NomenclatureEdit } from "components/elements/nomenclature/NomenclatureEdit";
import { NomenclatureList } from "components/elements/nomenclature/NomenclatureList";
import { IPage } from "interfaces/page.interface";
import { TypeStudiesService } from "services/nomenclature/typestudies.service";

const service = new TypeStudiesService();

const TypeStudies: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureList
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const DetailTypeStudies: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureDetail
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const AddTypeStudies: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureAdd
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};

const EditTypeStudies: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  return (
    <NomenclatureEdit
      currentRoute={currentRoute}
      service={service}
      mainObject={mainObject}
    />
  );
};
export { TypeStudies, DetailTypeStudies, AddTypeStudies, EditTypeStudies };
