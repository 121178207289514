import { Grid, Skeleton } from "@mui/material";
import { DetailGrid } from "components/elements/generalcomponents/DetailGrid";
import { CoursePriceDto } from "dto/course/courseprice.dto";
import useObject from "hooks/useObject";
import React from "react";

import { CoursePriceService } from "services/course/courseprice.service";

type Props = {
  idCourse: string;
};
const service = new CoursePriceService();
const TabCoursePriceDetail: React.FC<Props> = ({ idCourse }) => {
  const get = (id: string, cb?: any, cbParams?: any) => {
    if (!id) return;
    service.getCoursePriceByCourseId(id, cb, cbParams);
  };
  const [loading, object] = useObject<CoursePriceDto>(get, idCourse, [
    idCourse,
  ]);

  if (loading) return <Skeleton />;
  if (!object) return null;
  return (
    <Grid>
      <DetailGrid object={object} fields={CoursePriceDto.getDetailFields()} />
    </Grid>
  );
};

export { TabCoursePriceDetail };
