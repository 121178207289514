import { Box, Typography } from "@mui/material";

import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { CourseDto } from "dto/course/course.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { LanguageSelect } from "components/static/language/newLanguageSelect";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { AutocompleteTeacher } from "components/user/teacher/AutocompleteTeacher";

type Props = {
  defaultObject: CourseDto;
  onSubmit: (data: CourseDto) => void;
  loading: boolean;
};
const FormCourse: React.FC<Props> = ({ defaultObject, onSubmit, loading }) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, , vresults, setObjectLanguage] =
    useForm<CourseDto>(
      defaultObject,
      RequiredValidator.getValidators(["name", "idteacher", "idlanguage"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("Name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect
            value={obj.status}
            setObjField={setObjField}
            typeStatus="course"
          />
        </Box>
        <Box mt={3}>
          <AutocompleteTeacher
            setObjField={setObjField}
            value={obj.idteacher}
            defaultObject={obj.teacher}
          />
        </Box>
        <Box mt={3}>
          <Typography color="text.disabled" variant="caption">
            {LL("description")}
          </Typography>
          <MyCKEditor
            data={obj.description ? obj.description : ""}
            setObj={setObjField}
            name="description"
          />
        </Box>

        <Box mt={3}>
          <Typography color="text.disabled" variant="caption">
            {LL("requirementstext")}
          </Typography>
          <MyCKEditor
            data={obj.requirementstext ? obj.requirementstext : ""}
            setObj={setObjField}
            name="requirementstext"
          />
        </Box>
        <Box mt={3}>
          <Typography color="text.disabled" variant="caption">
            {LL("whatwillyoulearntext")}
          </Typography>
          <MyCKEditor
            data={obj.whatwillyoulearntext ? obj.whatwillyoulearntext : ""}
            setObj={setObjField}
            name="whatwillyoulearntext"
          />
        </Box>
        <Box mt={3}>
          <Typography color="text.disabled" variant="caption">
            {LL("targetaudiencetext")}
          </Typography>
          <MyCKEditor
            data={obj.targetaudiencetext ? obj.targetaudiencetext : ""}
            setObj={setObjField}
            name="targetaudiencetext"
          />
        </Box>
        <Box mt={3}>
          <Typography color="text.disabled" variant="caption">
            {LL("shortdescription")}
          </Typography>
          <MyCKEditor
            data={obj.shortdescription ? obj.shortdescription : ""}
            setObj={setObjField}
            name="shortdescription"
          />
        </Box>
        <Box mt={3}>
          <LanguageSelect
            value={obj.idlanguage ?? ""}
            setValue={setObjectLanguage}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <MyTextField
            tabIndex={5}
            fullWidth
            id="popularidentifier"
            name="popularidentifier"
            variant="outlined"
            color="primary"
            _label={LL("PopularIdentifier")}
            size="small"
            value={obj.popularidentifier}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>

       <Box mt={3}>
          <MyButton
            tabIndex={6}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourse };
