import { IPage } from "interfaces/page.interface";
import React from "react";
import { TabCourseSettingsAdd } from "./TabCourseSettingsAdd";
import { TabCourseSettingsEdit } from "./TabCourseSettingsEdit";

export type TabCourseSettingsProps = IPage & {
  idCourse: string;
  idCourseSettings: string;
};

const TabCourseSettings: React.FC<TabCourseSettingsProps> = ({
  idCourseSettings,
  currentRoute,
  idCourse,
  mainObject,
}) => {
  return idCourseSettings ? (
    <TabCourseSettingsEdit
      idCourseSettings={idCourseSettings}
      
      idCourse={idCourse}
      mainObject={mainObject}
      currentRoute={currentRoute}
    />
  ) : (
    <TabCourseSettingsAdd
      idCourse={idCourse}
      mainObject={mainObject}
      currentRoute={currentRoute}
      idCourseSettings={""}
    />
  );
};

export { TabCourseSettings };
