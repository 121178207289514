import React from "react";

import { UserTopInfo } from "components/auth/UserTopInfo";

import { TopLanguageBlock } from "components/static/language/TopLanguageBlock";
import { Stack } from "@mui/material";

const TopBar: React.FC = () => {
  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"center"}
      justifyContent={"flex-end"}
      width={"100%"}
    >
      <TopLanguageBlock />
      <UserTopInfo />
    </Stack>
  );
};

export { TopBar };
