import {
  DetailGridItemProps,
} from "interfaces/detailobject.interface";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { Grid, Typography } from "@mui/material";

import { useResource } from "hooks/useResource";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const DetailGridVideo: React.FC<DetailGridItemProps> = ({
  object,
  item,
  index,
}) => {
  const { LL } = useResource();
  // const label = "Field_" + item.label;
  let width: string | number = 640;
  let height: string | number = 360;
  let controls = false;
  const options = item.options ? item.options : null;
  if (options) {
    width = options.width ? options.width : 640;
    height = options.height ? options.height : 360;
    controls = options.controls ? options.controls : false;
  }
  const mt = index === 0 ? 0 : 3;
  return (
    <React.Fragment>
      <Grid mt={mt}>
        <Typography color="text.disabled" variant="caption">
          {LL(item.label)}
        </Typography>
      </Grid>
      <Grid mt={1}>
        {ComponentCommonTools.getFileBlockVideo(
          CommonTools.processObject(object, item.value),
          typeof width == "string" ? parseInt(width) : width,
          typeof height == "string" ? parseInt(height) : height,
          controls
        )}
      </Grid>
    </React.Fragment>
  );
};

export { DetailGridVideo };
