import { Box, Stack, IconButton, Typography } from "@mui/material";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

import { StayOnPageCheckbox } from "./StayOnPageCheckbox";
import { useResource } from "hooks/useResource";
import { MyBreadcrumbs } from "../breadcrumbs/MyBreadcrumbs";
import { MyNavBt } from "components/elements/button/MyNavBt";

type Props = {
  mainObject: string;
  mainUrl: string;
  detailUrl: string;
  object: any;
  idLanguage?: string;
  anchorTab?: string;
  defaultTab?: string;
  excludedTabs?: string[];
  needUpdate?: boolean;
};
const HeaderEdit: React.FC<Props> = ({
  mainObject,
  mainUrl,
  detailUrl,
  object,
  idLanguage,
  anchorTab = "",
  defaultTab = "",
  excludedTabs = [],
  needUpdate = false,
}) => {
  const { LL } = useResource();
  const processAnchorTab = () => {
    if (!anchorTab) return defaultTab;
    if (excludedTabs.includes(anchorTab)) {
      return defaultTab;
    }
    return anchorTab;
  };

  const hState = object
    ? {
        obj: object,
        idLanguage: idLanguage ?? "",
        anchor: processAnchorTab(),
        needUpdate: needUpdate,
      }
    : {};

  return (
    <React.Fragment>
      <MyBreadcrumbs
        childs={BreadcrumbDto.getBreadcrumbEdit(
          mainUrl,
          detailUrl,
          mainObject
        )}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",

          width: "100%",
        }}
        maxWidth="md"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <MyNavBt
              href={detailUrl}
              sx={{ ml: "auto" }}
              _hstate={hState}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </MyNavBt>
          </Box>
          <Box>
            <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
              {LL("EditBlock")}
            </Typography>
          </Box>
          {idLanguage ? <StayOnPageCheckbox mainObject={mainObject} /> : null}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export { HeaderEdit };
