import { useState, useEffect, useMemo } from "react";
import { ICurrentRoute } from "interfaces/currentroute.interface";
import Idto from "interfaces/idto.interface";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { useCookies } from "react-cookie";

import { useResource } from "./useResource";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { RouteTools } from "tools/utils/routetools";


type HasId = Idto & {
  id?: string | number;
};

type UseListAdminSpecial = <T extends HasId>(
  mainObject: string | undefined,
  currentRoute: ICurrentRoute | null,
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  getRequestListFun: (req: RequestListDTO) => RequestListDTO,
  deleteFun: (id: string, cb?: any, params?: any) => void,
  specialType: string,
  customGetRequestList?: (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ) => RequestListDTO
) => {
  mainUrl: string;
  addUrl: string;
  data: ResultListDTO;
  rows: Array<T>;
  total: number;
  totalPage: number;
  responseParams: RequestListDTO;
  reqList: RequestListDTO | null;
  loading: boolean;
  deleteOneObject: any;
  setDeleteOneObject: (obj: T | undefined) => void;
  handleDelete: (item?: T) => void;
  setReqList: (req: RequestListDTO) => void;
  getList: () => void;
  onRowClickCb: (row: any) => void;
  parentId: string;
  parentType: string;
  parentUrl: string;
  firstLevelParentId: string;
  firstLevelParentType: string;
};
var isRequesting = false;
const useListAdminSpecialThirdLevel: UseListAdminSpecial = <T extends HasId>(
  mainObject: string | undefined,
  currentRoute: ICurrentRoute | null,
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  getRequestListFun: (req: RequestListDTO) => RequestListDTO,
  deleteFun: (id: string, cb?: any, params?: any) => void,
  specialType: string,
  customGetRequestList?: (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ) => RequestListDTO
) => {
  const [cookies] = useCookies();
  const { _getListUrl } = useResource();
  const mainUrl = CommonTools.generateMainUrlSpecialOfSpecial(currentRoute);
  const addUrl = CommonTools.generateAddUrlSpecialOfSpecial(currentRoute);
  const [parentUrl, setParentUrl] = useState("");
  const [parentType, setParentType] = useState("");
  const [parentId, setParentId] = useState("");
  const [firstLevelParentId, setFirstLevelParentId] = useState("");
  const [firstLevelParentType, setFirstLevelParentType] = useState("");

  useEffect(() => {
    if (!currentRoute) return;

    // const lu = _getListUrl(
    //   CommonTools.generateParentMainUrlSpecialOfSpecial(currentRoute)
    // );
    // // logger("useListAdminSpecialThirdLevel", lu);
    setParentUrl(CommonTools.generateParentMainUrlSpecialOfSpecial(currentRoute));
    processParentData();
  }, [currentRoute]);

  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<T>>(new Array<T>());
  const [total, setTotal] = useState(-1);
  const [totalPage, setTotalPage] = useState(-1);
  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );
  const [reqList, setReqList] = useState<RequestListDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteOneObject, setDeleteOneObject] = useState<T | undefined>(
    undefined
  );

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
    isRequesting = false;
  };

  const getList = () => {
    if (!reqList) return;
    if (!reqList.filters) return;
    if (!reqList.filters.length) return;
    if (isRequesting) return;
    isRequesting = true;
    setLoading(true);
    const urlPart = RouteTools.prepareListLocation(reqList);
    RouteTools.setHistory(mainUrl + urlPart, {});
    getListFun(loadObjects, {}, reqList);
  };

  const getReqList = () => {
    if (!parentType) return;
    if (!parentId) return;
    let reqListLocal = RouteTools.prepareListRequest(currentRoute, cookies);

    if (!customGetRequestList)
      reqListLocal = defaultGetRequestList(reqListLocal, parentId, parentType);
    else
      reqListLocal = customGetRequestList(reqListLocal, parentId, parentType);

    reqListLocal = getRequestListFun(reqListLocal);
    setReqList(reqListLocal);
  };

  const processParentData = () => {
    if (!currentRoute) return;
    if (!currentRoute._paths) return;

    const path = currentRoute._paths;
    if (path.length < 5) return;
    setParentType(path[2]);
    setParentId(path[3]);
    setFirstLevelParentId(path[1]);
    setFirstLevelParentType(path[0]);
  };

  const checkIsLoading = () => {
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    setLoading(load);
  };

  useEffect(() => {
    getReqList();
  }, [parentType, parentId]);

  useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    checkIsLoading();
  }, [data, rows, reqList, total, totalPage]);

  const handleDelete = (item?: T) => {
    if (!item) return;
    if (!item.hasOwnProperty("id")) return;
    if (!item.id) return;
    deleteFun(CommonTools.processObjectField(item, ["id"]), getList, {});
  };

  const onRowClickCb = (row: any) => {
    ComponentCommonTools.goToDetailsSpecialThirdLevel(
      row,
      parentUrl,
      specialType
    );
  };

  return useMemo(
    () => ({
      mainUrl,
      addUrl,
      data,
      rows,
      total,
      totalPage,
      responseParams,
      reqList,
      loading,
      deleteOneObject,
      setDeleteOneObject,
      handleDelete,
      setReqList,
      getList,
      onRowClickCb,
      parentId,
      parentType,
      parentUrl,
      firstLevelParentId,
      firstLevelParentType,
    }),
    [
      mainUrl,
      addUrl,
      data,
      rows,
      total,
      totalPage,
      responseParams,
      reqList,
      loading,
      deleteOneObject,
      parentId,
      parentType,
      parentUrl,
      firstLevelParentId,
      firstLevelParentType,
    ]
  );
};

export { useListAdminSpecialThirdLevel };

const defaultGetRequestList = (
  req: RequestListDTO,
  parentId: string,
  parentType: string
): RequestListDTO => {
  const t = new RequestFilterDTO();
  t.field = "idparent";
  t.values = [parentId];

  const t2 = new RequestFilterDTO();
  t2.field = "parent";
  t2.values = [parentType];

  req.filters = req.filters ?? [];

  let exist = false;
  for (const v of req.filters) {
    if (v.field !== "idparent" && v.field !== "parent") continue;

    exist = true;
    break;
  }
  if (!exist) req.filters.push(t, t2);
  return req;
};
