import React from "react";

import { IPage } from "interfaces/page.interface";

import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box, Tab, Tabs } from "@mui/material";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";

import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import { FormCourse } from "components/course/course/FormCourse";
import { CourseDto } from "dto/course/course.dto";
import { CourseService } from "services/course/course.service";
import { useResource } from "hooks/useResource";
import { TabPanel } from "components/elements/tabs/TabPanel";
import { TabCoursePrice } from "components/course/courseprice/TabCoursePrice";
import { TabCourseSettings } from "components/course/coursesettings/TabCourseSettings";

const service = new CourseService();
const EditCourse: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };

  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    handleChangeTab,
    tabValue,
  } = useEditObject<CourseDto>(mainObject, currentRoute, get);
  const { LL } = useResource();
  const onSubmit = (obj: CourseDto) => {
    if (!obj) return;
    setLoading(true);
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        anchorTab={tabValue}
        defaultTab="generalinfo"
      />
      <Box maxWidth="md" className="tabs">
        <Tabs variant="scrollable" value={tabValue} onChange={handleChangeTab}>
          <Tab label={LL("Edit_Course_main_Info")} value={"generalinfo"} />
          <Tab label={LL("Course_Settings_Info")} value={"coursesettings"} />
          <Tab label={LL("Course_Price")} value={"courseprice"} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={"generalinfo"}>
        <DataDisplayBox>
          <Box>
            <FormCourse
              defaultObject={object}
              onSubmit={onSubmit}
              loading={loading}
            />
          </Box>
        </DataDisplayBox>
      </TabPanel>
      <TabPanel value={tabValue} index={"coursesettings"}>
        <DataDisplayBox>
          <TabCourseSettings
            currentRoute={currentRoute}
            mainObject={mainObject}
            idCourse={id}
            idCourseSettings={CommonTools.processObjectField(object, [
              "coursesettings",
              "id",
            ])}
          />
        </DataDisplayBox>
      </TabPanel>
      <TabPanel value={tabValue} index={"courseprice"}>
        <DataDisplayBox>
          <TabCoursePrice
            currentRoute={currentRoute}
            idTypeCourse={CommonTools.processObjectField(object, [
              "coursesettings",
              "idtypecourse",
            ])}
            idCourse={id}
            idCoursePrice={CommonTools.processObjectField(object, [
              "courseprice",
              "id",
            ])}
            mainObject={mainObject}
          />
        </DataDisplayBox>
      </TabPanel>
    </React.Fragment>
  );
};

export { EditCourse };
