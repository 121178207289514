import React from "react";

import Link from "@mui/material/Link";

// import { v4 as uuidv4 } from "uuid"; 
// import MD5 from "crypto-js/md5";
import { useEffect, useState } from "react";
import { logger } from "tools/utils/logger";
import { RouteTools } from "tools/utils/routetools";
import { useResource } from "hooks/useResource";


interface HtmlTagProps {
  content?: string | undefined | null;
}

const HtmlTag: React.FC<HtmlTagProps> = ({
  content,
  ...props
}) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: content != undefined && content != null ? content : "",
      }}
    />
  );
};

export { HtmlTag };
