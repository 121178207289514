import React from "react";
import { AttachmentService } from "services/system/attachment.service";
import { IPage } from "interfaces/page.interface";
import { AttachmentDto } from "dto/system/attachment.dto";
import { FormAttachment } from "components/special/attachment/FormAttachment";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useResource } from "hooks/useResource";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useAddSpecialThirdLevel } from "hooks/useAddSpecialThirdLevel";
import { HeaderAddSpecialThirdLevel } from "components/elements/add/HeaderAddSpecialThirdLevel";

const service = new AttachmentService();
const ThirdLevelSpecialAttachmentAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const {
    mainUrl,
    loading,
    setLoading,
    parentId,
    parentType,
    firstLevelParentId,
    firstLevelParentType,
  } = useAddSpecialThirdLevel(currentRoute);

  const { _idlanguage } = useResource();
  const onSubmit = (obj: AttachmentDto) => {
    if (!obj) return;
    obj.idparent = parentId;
    obj.parent = parentType;
    setLoading(true);
    service.addMultipart(
      ComponentCommonTools.handleSuccessAddAndEditSpecialThirdLevel,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "thirdlevelattachment",
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["idlanguage"]),
        firstLevelParentId,
        firstLevelParentType,
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecialThirdLevel
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="thirdlevelattachment"
        firstLevelParentId={firstLevelParentId}
        firstLevelParentType={firstLevelParentType}
      />
      <DataDisplayBox>
        <FormAttachment
          defaultObject={new AttachmentDto(_idlanguage)}
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { ThirdLevelSpecialAttachmentAdd };
