import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class LanguageDto implements Idto {
  id?: string;
  name?: string;
  cod2?: string;
  cod3?: string;
  status?: number;
  ordercriteria?: number;
  hmtlcode?: string;
  status_name?: any;
  constructor(
    name?: string,
    cod2?: string,
    cod3?: string,
    hmtlcode?: string,
  ) {
    this.status = Status.ACTIVE;
    this.ordercriteria = 0;
    this.name = name || "";
    this.cod2 = cod2 || "";
    this.cod3 = cod3 || "";
    this.hmtlcode = hmtlcode || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Cod2",
        value: ["cod2"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Cod3",
        value: ["cod3"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "OrderCriteria",
        value: ["ordercriteria"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "HtmlCode",
        value: ["hmtlcode"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "name"),
      ComponentCommonTools.columns_StandardColumn(LL, "cod2"),
      ComponentCommonTools.columns_StandardColumn(LL, "cod3"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "ordercriteria"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);
    
    return obj;
  }
  static parseToSelectOptions(data: Array<LanguageDto>): Array<SelectOptions> {
    if(!data) return [];
    if(!Array.isArray(data)) return [];
    if(!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["name"])
        )
      );
    });
    return result;
  }
}
