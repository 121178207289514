import { useEffect, useState, useCallback, useMemo } from "react";
import Idto from "../interfaces/idto.interface";
import ResultObjectDTO from "../dto/app/resultobject.dto";

type UseObjectType = <T extends Idto>(
  getObjectFunc: (id: string, cb: any, cbParams: any) => void,
  id: string,
  dependencies: Array<any>
) => [boolean, T | null, (obj: T | null) => void];

const useObject: UseObjectType = <T extends Idto>(
  getObjectFunc: (id: string, cb: any, cbParams: any) => void,
  id: string,
  dependencies: Array<any>
) => {
  const [object, setObject] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getObject = useCallback(() => {
    setLoading(true);
    getObjectFunc(id, handleGetObject, {});
  }, dependencies);

  const handleGetObject = (result: ResultObjectDTO) => {
    if (!result) return;
    const object = (result.obj as T) ?? null;

    setObject(object);
  };

  useEffect(() => {
    getObject();
  }, [getObject]);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (object === null) loading = true;
    setLoading(loading);
  }, [object]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  return useMemo(() => [loading, object, setObject], [loading, object]);
};

export default useObject;
