import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { CourseFilterDto } from "dto/course/coursefilter.dto";

import { SelectFilter } from "components/course/filter/SelectFilter";
import { SelectFilterDictionary } from "components/course/filterdictionary/SelectFilterDictionary";

type Props = {
  defaultObject: CourseFilterDto;
  onSubmit: (data: CourseFilterDto) => void;
  loading: boolean;
};
const FormCourseFilter: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField, setObject] = useForm<CourseFilterDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "idcourse",
      "idfilter",
      "idfilterdictionary",
    ])
  );

  const setFilter = (field: string, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    t["idfilterdictionary"] = "";
    setObject(t);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <SelectFilter setObjectField={setFilter} value={obj.idfilter} />
        </Box>
        {obj.idfilter && (
          <Box mt={3}>
            <SelectFilterDictionary
              setObjectField={setObjField}
              value={obj.idfilterdictionary}
              idFilter={obj.idfilter}
            />
          </Box>
        )}
        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormCourseFilter };
