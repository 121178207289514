// import React, { useState, useEffect } from "react";

// import { logger } from "tools/utils/logger";

// import { PostLanguageDto } from "dto/static/postlanguage.dto";
// import { useCookies } from "react-cookie";
// import { Loading } from "components/general/Loading";
// import { MyNavBt } from "components/general/MyNavBt";
// import { useResource } from "hook/useResource";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import { CommonTools } from "tools/utils/commontools";
// import { ComponentCommonTools } from "tools/utils/componentcommontools";
// import { StatusSelect } from "components/elements/Select/StatusSelect";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import MyTextField from "components/elements/form/mytextfield";
// import { LabelTypes } from "tools/types/labeltypes";
// import ValidateTools from "tools/utils/validate.tools";
// import RequiredValidator from "validators/required.validator";
// import { MyButton } from "components/general/MyButton";
// import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
// import { RouteTools } from "tools/utils/routetools";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { LanguageSelect } from "components/elements/Select/LanguageSelect";
// import IconButton from "@mui/material/IconButton";
// import { Status } from "tools/types/status";
// import {AttachmentService} from "services/system/attachment.service";
// import { AttachmentDto } from "dto/system/attachment.dto";
// import MyCheckboxField from "components/elements/form/mycheckboxfield";
// import { FileTools } from "tools/utils/filetools";
// import SpecialTopInfo from "../SpecialTopInfo";

// const attachmentService = new AttachmentService();
// interface SpecialAttachmentEditProps {
//   children: any;
//   currentRoute: any;
//   _obj: AttachmentDto;
//   mainObject: any;
// }

// const SpecialAttachmentEdit: React.FC<SpecialAttachmentEditProps> = ({
//   children,
//   currentRoute,
//   _obj,
//   mainObject,

//   ...props
// }) => {
//   // const mainUrl = CommonTools.generateMainUrl(mainObject);
//   const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);

//   const { LL } = useResource();
//   const editObj = _obj !== undefined ? _obj : undefined;
//   const [loading, setLoading] = useState<boolean>(true);
//   const [obj, setObj] = useState<AttachmentDto | undefined>(editObj);

//   const [parentType, setParentType] = useState<string>(currentRoute._paths[0]);
//   const [parentId, setParentId] = useState<string>(currentRoute._paths[1]);

//   const [imgUrl, setImgUrl] = useState<string>("");
//   const [fullUrl, setFullUrl] = useState<string>("");

//   const _id = currentRoute._paths !== undefined ? currentRoute._paths[4] : "";

//   const _idLanguage =
//     currentRoute.historystate.idLanguage !== undefined
//       ? currentRoute.historystate.idLanguage
//       : "";
//   const [id, setId] = useState<string | undefined>(_id);

//   const detailUrl = CommonTools.generateDetailUrlSpecial(
//     _id,
//     parentType,
//     parentId,
//     "attachment"
//   );

//   const editUrl = CommonTools.generateEditUrlSpecial(
//     _id,
//     parentType,
//     parentId,
//     "attachment"
//   );

//   const [isFormDisabled, setIsFormDisabled] = useState(false);

//   const [idLanguage, setIdLanguage] = useState<string>(_idLanguage);
//   const [validators, setValidators] = useState<any>();
//   const [vresults, setVResults] = useState<any>(false);

//   const getFileBlock = () => {
//     if (!imgUrl) return <></>;

//     return (
//       <Box>
//         <a href={fullUrl} target="_blank">
//           <img src={imgUrl} width={200} height={200} alt={""} />
//         </a>
//       </Box>
//     );
//   };

//   const setFileUrls = () => {
//     if (obj === undefined) return;

//     if (obj.fileInfoObj == undefined) {
//       setImgUrl("");
//       setFullUrl("");
//       return;
//     }

//     const imgUrl = FileTools.getFileImage(
//       obj.fileInfoObj._id,
//       obj.fileInfoObj.name,
//       200,
//       200
//     );
//     const aUrl = FileTools.getFileUrl(
//       obj.fileInfoObj._id,
//       obj.fileInfoObj.name
//     );

//     setImgUrl(imgUrl);
//     setFullUrl(aUrl);
//   };

//   const validateAll = () => {
//     if (!obj) return;
//     if (!validators) return;
//     // if (vresults) return;

//     obj.name = obj.name !== undefined ? obj.name : "";
//     obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
//     obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;

//     // obj.idattachment = obj.idattachment !== undefined ? obj.idattachment : "";
//     setObj(obj);

//     let t = validateField("name", obj.name);
//     t = validateField("status", obj.status, t);

//     t = validateField("ordercriteria", obj.ordercriteria, t);
//   };

//   const validateField = (field: any, value: any, v?: any) => {
//     v = v ? v : JSON.parse(JSON.stringify(vresults));
//     if (v === false) v = {};
//     v[field] = ValidateTools.validate(field, value, validators, vresults);
//     setIsFormDisabled(ValidateTools.vForm(v, field));
//     setVResults(v);
//     return v;
//   };

//   const processValidators = () => {
//     let v: any = {
//       idlanguage: [],
//       status: [],
//       name: [],
//       ordercriteria: [],
//     };

//     v.name.push(
//       new RequiredValidator(
//         LL("Required field name", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );

//     v.status.push(
//       new RequiredValidator(
//         LL("Required field status", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );
//     v.ordercriteria.push(
//       new RequiredValidator(
//         LL("Required field ordercriteria", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );

//     setValidators(v);
//   };

//   const checkLoading = () => {
//     if (!loading) return;
//     if (obj === undefined) return;
//     if (!validators) return;
//     validateAll();
//     setLoading(false);
//   };

//   const loadObject = (obj: ResultObjectDTO) => {
//     if (!obj) return;
//     if (!obj.err) {
//       const object = obj.obj ? obj.obj : {};
//       setObj(object);
//     }
//   };

//   const getObject = () => {
//     setLoading(true);
//     if (id !== undefined) {
//       if (idLanguage === "") attachmentService.get(id, loadObject, {});
//       else attachmentService.get(id, loadObject, {}, idLanguage);
//     }
//   };

//   useEffect(() => {
//     processValidators();
//     if (id === "") {
//       if (obj !== undefined) {
//         setId(obj.id);
//         if (obj.idlanguage !== undefined) {
//           setIdLanguage(obj.idlanguage);
//         }
//       }
//     }
//     if (idLanguage !== "") getObject();
//   }, [idLanguage]);

//   useEffect(() => {
//     validateAll();
//     checkLoading();
//     if (idLanguage === "") {
//       if (obj !== undefined) {
//         if (obj.idlanguage !== undefined) setIdLanguage(obj.idlanguage);
//       }
//     }

//     setFileUrls();
//   }, [obj, validators]);

//   useEffect(() => {
//     checkIsFormDisabled();
//   }, [vresults]);

//   useEffect(() => {
//     processValidators();
//     if (currentRoute.historystate.obj !== undefined) {
//       setObj(currentRoute.historystate.obj);
//       if (idLanguage === "")
//         setIdLanguage(currentRoute.historystate.obj.idlanguage);
//     }
//     if (obj === undefined) getObject();
//   }, []);

//   const setObjFieldLanguage = (field: any, value: any) => {
//     if (value !== undefined) setIdLanguage(value);
//   };

//   const handleSubmit = (event: any) => {
//     event.preventDefault();

//     if (isFormDisabled) return false;
//     if (obj !== undefined) {
//       if (obj.id !== undefined) {
//         obj.idlanguage = idLanguage;

//         // logger("handleSubmithandleSubmithandleSubmithandleSubmit", obj);

//         obj.parent = parentType;
//         obj.idparent = parentId;

//         setLoading(true);
//         attachmentService.update(
//           obj.id,
//           ComponentCommonTools.handleSuccessAddAndEditSpecial,
//           { mainObject, parentType, parentId, idLanguage, specialPart: "attachment" },
//           obj
//         );
//       }
//     }
//   };

//   const checkIsFormDisabled = () => {
//     var rez = ValidateTools.vForm(vresults, "status");
//     // var rez = ValidateTools.vForm(vresults, "name");
//     // rez = ValidateTools.vForm(vresults, "status", rez);
//     // rez = ValidateTools.vForm(vresults, "ordercriteria", rez);

//     setIsFormDisabled(rez);
//   };

//   const setObjField = (field: any, value: any) => {
//     let t = JSON.parse(JSON.stringify(obj));
//     t[field] = value;
//     setObj(t);
//   };
//   const setCheckFixed = (value: any) => {
//     let t = JSON.parse(JSON.stringify(obj));

//     t["isdefault"] = value;
//     setObj(t);
//   };

//   const ChildsCrumbs = [
//     { url: "/", label: LL("crumb_homepage") },
//     {
//       url: "/" + parentType,
//       label: LL("crumb_" + parentType),
//     },
//     {
//       url: "/" + parentType + "/" + parentId + "/attachment",
//       label: LL("crumb_attachment"),
//     },
//     { label: LL("crumb_Editattachment") },
//   ];
//   return !loading && obj ? (
//     <>
//       <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
//       <Box
//         sx={{
//           flexDirection: "row",
//           display: "flex",
//           alignContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <MyNavBt
//           tabIndex={6}
//           href={mainUrl}
//           children={
//             <IconButton color="primary">
//               <ArrowBackIcon />
//             </IconButton>
//           }
//           size="large"
//           _hstate={{}}
//           _mainurl={mainUrl}
//         />

//         <SpecialTopInfo
//           parentType={parentType}
//           parentId={parentId}
//           specialtype={"attachment"}
//           suplimentar={"edit"}
//           variant="h4"
//           sx={{ my: 3, ml: 3 }}
//         />
//       </Box>
//       <Box
//         maxWidth="md"
//         sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
//       >
//         <form onSubmit={handleSubmit}>
//           {getFileBlock()}

//           <LanguageSelect
//             tabIdex={0}
//             id="idlanguage"
//             name="idlanguage"
//             variant="outlined"
//             color="primary"
//             value={idLanguage}
//             field="idlanguage"
//             size="small"
//             setObj={setObjFieldLanguage}
//             obj={obj}
//           />
//           <Box sx={{ mt: 3 }}>
//             <MyTextField
//               tabIndex={0}
//               fullWidth
//               id="name"
//               name="name"
//               variant="outlined"
//               color="primary"
//               _label={LL("label_text_field_Name")}
//               size="small"
//               value={obj.name}
//               setObj={setObjField}
//               _vresults={vresults && vresults.name ? vresults.name : false}
//             />
//           </Box>

//           {/* <Box sx={{ mt: 3 }}>
//             <StatusSelect
//               tabIndex={1}
//               id="status"
//               name="status"
//               variant="outlined"
//               color="primary"
//               value={obj.status}
//               field="status"
//               size="small"
//               setObj={setObjField}
//               _defaultValue={obj.status}
//             />
//           </Box> */}
//            <Box sx={{ mt: 3 }}>
//             <StatusSelect
//               value={obj.status}
//               setObjField={setObjField}
//             />
//           </Box>
//           <Box sx={{ mt: 3 }}>
//             <MyTextField
//               tabIndex={2}
//               fullWidth
//               id="ordercriteria"
//               name="ordercriteria"
//               variant="outlined"
//               color="primary"
//               _label={LL("label_text_field_ordercriteria")}
//               size="small"
//               value={obj.ordercriteria}
//               setObj={setObjField}
//               _vresults={
//                 vresults && vresults.ordercriteria
//                   ? vresults.ordercriteria
//                   : false
//               }
//             />
//           </Box>

//           <Box sx={{ mt: 3 }}>
//             <MyCheckboxField
//               tabIndex={4}
//               id="isdefault"
//               name="isdefault"
//               _label={LL("isdefault")}
//               setObj={setCheckFixed}
//               checked={obj.isdefault}
//               color="primary"
//               size="small"
//               _vresults={undefined}
//             />
//           </Box>
//           <MyButton
//             tabIndex={5}
//             disabled={isFormDisabled}
//             color="primary"
//             fullWidth
//             variant="contained"
//             size="large"
//             type="submit"
//             sx={{ mt: 4 }}
//             children={LL("Bt_Submit")}
//           />
//         </form>
//       </Box>
//     </>
//   ) : (
//     <Loading />
//   );
// };

// export default SpecialAttachmentEdit;

// import React, { useState, useEffect } from "react";

// import { logger } from "tools/utils/logger";

// import { PostLanguageDto } from "dto/static/postlanguage.dto";
// import { useCookies } from "react-cookie";
// import { Loading } from "components/general/Loading";
// import { MyNavBt } from "components/general/MyNavBt";
// import { useResource } from "hook/useResource";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import { CommonTools } from "tools/utils/commontools";
// import { ComponentCommonTools } from "tools/utils/componentcommontools";
// import { StatusSelect } from "components/elements/Select/StatusSelect";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import MyTextField from "components/elements/form/mytextfield";
// import { LabelTypes } from "tools/types/labeltypes";
// import ValidateTools from "tools/utils/validate.tools";
// import RequiredValidator from "validators/required.validator";
// import { MyButton } from "components/general/MyButton";
// import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
// import { RouteTools } from "tools/utils/routetools";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { LanguageSelect } from "components/elements/Select/LanguageSelect";
// import IconButton from "@mui/material/IconButton";
// import { Status } from "tools/types/status";
// import {AttachmentService} from "services/system/attachment.service";
// import { AttachmentDto } from "dto/system/attachment.dto";
// import MyCheckboxField from "components/elements/form/mycheckboxfield";
// import { FileTools } from "tools/utils/filetools";
// import SpecialTopInfo from "../SpecialTopInfo";

// const attachmentService = new AttachmentService();
// interface SpecialAttachmentEditProps {
//   children: any;
//   currentRoute: any;
//   _obj: AttachmentDto;
//   mainObject: any;
// }

// const SpecialAttachmentEdit: React.FC<SpecialAttachmentEditProps> = ({
//   children,
//   currentRoute,
//   _obj,
//   mainObject,

//   ...props
// }) => {
//   // const mainUrl = CommonTools.generateMainUrl(mainObject);
//   const mainUrl = CommonTools.generateMainUrlSpecial(currentRoute);

//   const { LL } = useResource();
//   const editObj = _obj !== undefined ? _obj : undefined;
//   const [loading, setLoading] = useState<boolean>(true);
//   const [obj, setObj] = useState<AttachmentDto | undefined>(editObj);

//   const [parentType, setParentType] = useState<string>(currentRoute._paths[0]);
//   const [parentId, setParentId] = useState<string>(currentRoute._paths[1]);

//   const [imgUrl, setImgUrl] = useState<string>("");
//   const [fullUrl, setFullUrl] = useState<string>("");

//   const _id = currentRoute._paths !== undefined ? currentRoute._paths[4] : "";

//   const _idLanguage =
//     currentRoute.historystate.idLanguage !== undefined
//       ? currentRoute.historystate.idLanguage
//       : "";
//   const [id, setId] = useState<string | undefined>(_id);

//   const detailUrl = CommonTools.generateDetailUrlSpecial(
//     _id,
//     parentType,
//     parentId,
//     "attachment"
//   );

//   const editUrl = CommonTools.generateEditUrlSpecial(
//     _id,
//     parentType,
//     parentId,
//     "attachment"
//   );

//   const [isFormDisabled, setIsFormDisabled] = useState(false);

//   const [idLanguage, setIdLanguage] = useState<string>(_idLanguage);
//   const [validators, setValidators] = useState<any>();
//   const [vresults, setVResults] = useState<any>(false);

//   const getFileBlock = () => {
//     if (!imgUrl) return <></>;

//     return (
//       <Box>
//         <a href={fullUrl} target="_blank">
//           <img src={imgUrl} width={200} height={200} alt={""} />
//         </a>
//       </Box>
//     );
//   };

//   const setFileUrls = () => {
//     if (obj === undefined) return;

//     if (obj.fileInfoObj == undefined) {
//       setImgUrl("");
//       setFullUrl("");
//       return;
//     }

//     const imgUrl = FileTools.getFileImage(
//       obj.fileInfoObj._id,
//       obj.fileInfoObj.name,
//       200,
//       200
//     );
//     const aUrl = FileTools.getFileUrl(
//       obj.fileInfoObj._id,
//       obj.fileInfoObj.name
//     );

//     setImgUrl(imgUrl);
//     setFullUrl(aUrl);
//   };

//   const validateAll = () => {
//     if (!obj) return;
//     if (!validators) return;
//     // if (vresults) return;

//     obj.name = obj.name !== undefined ? obj.name : "";
//     obj.status = obj.status !== undefined ? obj.status : Status.ACTIVE;
//     obj.ordercriteria = obj.ordercriteria !== undefined ? obj.ordercriteria : 0;

//     // obj.idattachment = obj.idattachment !== undefined ? obj.idattachment : "";
//     setObj(obj);

//     let t = validateField("name", obj.name);
//     t = validateField("status", obj.status, t);

//     t = validateField("ordercriteria", obj.ordercriteria, t);
//   };

//   const validateField = (field: any, value: any, v?: any) => {
//     v = v ? v : JSON.parse(JSON.stringify(vresults));
//     if (v === false) v = {};
//     v[field] = ValidateTools.validate(field, value, validators, vresults);
//     setIsFormDisabled(ValidateTools.vForm(v, field));
//     setVResults(v);
//     return v;
//   };

//   const processValidators = () => {
//     let v: any = {
//       idlanguage: [],
//       status: [],
//       name: [],
//       ordercriteria: [],
//     };

//     v.name.push(
//       new RequiredValidator(
//         LL("Required field name", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );

//     v.status.push(
//       new RequiredValidator(
//         LL("Required field status", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );
//     v.ordercriteria.push(
//       new RequiredValidator(
//         LL("Required field ordercriteria", LabelTypes.LABEL_VALIDATION),
//         ""
//       )
//     );

//     setValidators(v);
//   };

//   const checkLoading = () => {
//     if (!loading) return;
//     if (obj === undefined) return;
//     if (!validators) return;
//     validateAll();
//     setLoading(false);
//   };

//   const loadObject = (obj: ResultObjectDTO) => {
//     if (!obj) return;
//     if (!obj.err) {
//       const object = obj.obj ? obj.obj : {};
//       setObj(object);
//     }
//   };

//   const getObject = () => {
//     setLoading(true);
//     if (id !== undefined) {
//       if (idLanguage === "") attachmentService.get(id, loadObject, {});
//       else attachmentService.get(id, loadObject, {}, idLanguage);
//     }
//   };

//   useEffect(() => {
//     processValidators();
//     if (id === "") {
//       if (obj !== undefined) {
//         setId(obj.id);
//         if (obj.idlanguage !== undefined) {
//           setIdLanguage(obj.idlanguage);
//         }
//       }
//     }
//     if (idLanguage !== "") getObject();
//   }, [idLanguage]);

//   useEffect(() => {
//     validateAll();
//     checkLoading();
//     if (idLanguage === "") {
//       if (obj !== undefined) {
//         if (obj.idlanguage !== undefined) setIdLanguage(obj.idlanguage);
//       }
//     }

//     setFileUrls();
//   }, [obj, validators]);

//   useEffect(() => {
//     checkIsFormDisabled();
//   }, [vresults]);

//   useEffect(() => {
//     processValidators();
//     if (currentRoute.historystate.obj !== undefined) {
//       setObj(currentRoute.historystate.obj);
//       if (idLanguage === "")
//         setIdLanguage(currentRoute.historystate.obj.idlanguage);
//     }
//     if (obj === undefined) getObject();
//   }, []);

//   const setObjFieldLanguage = (field: any, value: any) => {
//     if (value !== undefined) setIdLanguage(value);
//   };

//   const handleSubmit = (event: any) => {
//     event.preventDefault();

//     if (isFormDisabled) return false;
//     if (obj !== undefined) {
//       if (obj.id !== undefined) {
//         obj.idlanguage = idLanguage;

//         // logger("handleSubmithandleSubmithandleSubmithandleSubmit", obj);

//         obj.parent = parentType;
//         obj.idparent = parentId;

//         setLoading(true);
//         attachmentService.update(
//           obj.id,
//           ComponentCommonTools.handleSuccessAddAndEditSpecial,
//           { mainObject, parentType, parentId, idLanguage, specialPart: "attachment" },
//           obj
//         );
//       }
//     }
//   };

//   const checkIsFormDisabled = () => {
//     var rez = ValidateTools.vForm(vresults, "status");
//     // var rez = ValidateTools.vForm(vresults, "name");
//     // rez = ValidateTools.vForm(vresults, "status", rez);
//     // rez = ValidateTools.vForm(vresults, "ordercriteria", rez);

//     setIsFormDisabled(rez);
//   };

//   const setObjField = (field: any, value: any) => {
//     let t = JSON.parse(JSON.stringify(obj));
//     t[field] = value;
//     setObj(t);
//   };
//   const setCheckFixed = (value: any) => {
//     let t = JSON.parse(JSON.stringify(obj));

//     t["isdefault"] = value;
//     setObj(t);
//   };

//   const ChildsCrumbs = [
//     { url: "/", label: LL("crumb_homepage") },
//     {
//       url: "/" + parentType,
//       label: LL("crumb_" + parentType),
//     },
//     {
//       url: "/" + parentType + "/" + parentId + "/attachment",
//       label: LL("crumb_attachment"),
//     },
//     { label: LL("crumb_Editattachment") },
//   ];
//   return !loading && obj ? (
//     <>
//       <MyBreadcrumbs childs={ChildsCrumbs} mainObject={mainObject} />
//       <Box
//         sx={{
//           flexDirection: "row",
//           display: "flex",
//           alignContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <MyNavBt
//           tabIndex={6}
//           href={mainUrl}
//           children={
//             <IconButton color="primary">
//               <ArrowBackIcon />
//             </IconButton>
//           }
//           size="large"
//           _hstate={{}}
//           _mainurl={mainUrl}
//         />

//         <SpecialTopInfo
//           parentType={parentType}
//           parentId={parentId}
//           specialtype={"attachment"}
//           suplimentar={"edit"}
//           variant="h4"
//           sx={{ my: 3, ml: 3 }}
//         />
//       </Box>
//       <Box
//         maxWidth="md"
//         sx={{ bgcolor: "background.paper", boxShadow: 2, p: 4 }}
//       >
//         <form onSubmit={handleSubmit}>
//           {getFileBlock()}

//           <LanguageSelect
//             tabIdex={0}
//             id="idlanguage"
//             name="idlanguage"
//             variant="outlined"
//             color="primary"
//             value={idLanguage}
//             field="idlanguage"
//             size="small"
//             setObj={setObjFieldLanguage}
//             obj={obj}
//           />
//           <Box sx={{ mt: 3 }}>
//             <MyTextField
//               tabIndex={0}
//               fullWidth
//               id="name"
//               name="name"
//               variant="outlined"
//               color="primary"
//               _label={LL("label_text_field_Name")}
//               size="small"
//               value={obj.name}
//               setObj={setObjField}
//               _vresults={vresults && vresults.name ? vresults.name : false}
//             />
//           </Box>

//           {/* <Box sx={{ mt: 3 }}>
//             <StatusSelect
//               tabIndex={1}
//               id="status"
//               name="status"
//               variant="outlined"
//               color="primary"
//               value={obj.status}
//               field="status"
//               size="small"
//               setObj={setObjField}
//               _defaultValue={obj.status}
//             />
//           </Box> */}
//            <Box sx={{ mt: 3 }}>
//             <StatusSelect
//               value={obj.status}
//               setObjField={setObjField}
//             />
//           </Box>
//           <Box sx={{ mt: 3 }}>
//             <MyTextField
//               tabIndex={2}
//               fullWidth
//               id="ordercriteria"
//               name="ordercriteria"
//               variant="outlined"
//               color="primary"
//               _label={LL("label_text_field_ordercriteria")}
//               size="small"
//               value={obj.ordercriteria}
//               setObj={setObjField}
//               _vresults={
//                 vresults && vresults.ordercriteria
//                   ? vresults.ordercriteria
//                   : false
//               }
//             />
//           </Box>

//           <Box sx={{ mt: 3 }}>
//             <MyCheckboxField
//               tabIndex={4}
//               id="isdefault"
//               name="isdefault"
//               _label={LL("isdefault")}
//               setObj={setCheckFixed}
//               checked={obj.isdefault}
//               color="primary"
//               size="small"
//               _vresults={undefined}
//             />
//           </Box>
//           <MyButton
//             tabIndex={5}
//             disabled={isFormDisabled}
//             color="primary"
//             fullWidth
//             variant="contained"
//             size="large"
//             type="submit"
//             sx={{ mt: 4 }}
//             children={LL("Bt_Submit")}
//           />
//         </form>
//       </Box>
//     </>
//   ) : (
//     <Loading />
//   );
// };

// export default SpecialAttachmentEdit;

import React from "react";

import { IPage } from "interfaces/page.interface";
import { AttachmentService } from "services/system/attachment.service";
import { AttachmentDto } from "dto/system/attachment.dto";
import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { FormAttachment } from "components/special/attachment/FormAttachment";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";

import { useEditSpecial } from "hooks/useEditSpecial";
import { HeaderEditSpecial } from "components/elements/edit/HeaderEditSpecial";
import { useResource } from "hooks/useResource";
import { Loading } from "components/elements/loading/Loading";

const service = new AttachmentService();
const SpecialAttachmentEdit: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };
  const { getCookieStayOnPage } = useResource();
  const typeCallback = getCookieStayOnPage(mainObject || "");

  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    idLanguage,
    setIdLanguage,
    parentId,
    parentType,
    getByLanguage,
  } = useEditSpecial<AttachmentDto>(
    "attachment",
    mainObject,
    currentRoute,
    get,
    true
  );

  const onSubmit = (obj: AttachmentDto) => {
    if (!obj) return;
    setLoading(true);
    obj.idlanguage = idLanguage;
    obj.idparent = parentId;
    obj.parent = parentType;

    const callBackFunction = typeCallback
      ? getByLanguage
      : ComponentCommonTools.handleSuccessAddAndEditSpecial;
    const callBackParams = typeCallback
      ? {}
      : {
          mainObject,
          parentType,
          parentId,
          specialPart: "attachment",
          cb: CommonTools.processLoadingCallback(setLoading),
          idLanguage,
        };
    service.update(id, callBackFunction, callBackParams, obj);
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEditSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        idLanguage={idLanguage}
        parentId={parentId}
        parentType={parentType}
        specialType="attachment"
      />
      <DataDisplayBox>
        <Box>
          <LanguageSelect value={idLanguage} setValue={setIdLanguage} />
        </Box>

        <Box mt={3}>
          <FormAttachment
            defaultObject={AttachmentDto.parseForEdit(object)}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialAttachmentEdit };
