import React from "react";
import Typography from "@mui/material/Typography";
import { TicketMessageDto } from "dto/support/ticketmessage.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";
import { Box, Stack, Divider } from "@mui/material";

type Props = {
  obj: TicketMessageDto;
};

const TicketMessageItemTeacher: React.FC<Props> = ({
  obj,
}) => {
  const { LL } = useResource();

  const processItem = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;

    return (
      <Box
        sx={{
          bgcolor: "#d9dbde",
          padding: 2,
          borderRadius: "16px 16px 16px 0px",
          maxWidth: "400px",
        }}
      >
        <Box>
          <Stack direction="row" spacing={1}>
            <Typography sx={{ fontSize: 13 }}>{LL("Teacher")}</Typography>
            <Typography sx={{ fontSize: 13 }}>
              {ComponentCommonTools.processObjectFieldOfObject(
                obj,
                "teacher",
                "email"
              )}
            </Typography>
          </Stack>
        </Box>
        <Divider sx={{ my: 1, backgroundColor: "#d9dbde" }} />
        <Box>
          <Typography>
            {ComponentCommonTools.processObjectField(obj, "message")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            fontSize: "12px",
            mt: 1,
          }}
        >
          <Typography sx={{ fontSize: 12 }}>
            {ComponentCommonTools.processObjectField(obj, "date_name")}
          </Typography>
        </Box>
      </Box>
    );
  };

  return processItem();
};

export { TicketMessageItemTeacher };
