import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import React from "react";
import { Typography } from "@mui/material";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { CommonTools } from "tools/utils/commontools";
import { useResource } from "hooks/useResource";

type Props = {
  item: BreadcrumbDto;
  index: number;
  mainObject: string;
};

const BreadcrumbItem: React.FC<Props> = ({ item, index, mainObject }) => {
  const { LL } = useResource();
  const mainUrl = CommonTools.generateMainUrl(mainObject);
  return item.url ? (
    <MyNavBt
      href={item.url}
      underline="hover"
      key={index}
      _mainurl={mainUrl}
      color="text.secondary"
      _hstate={{}}
    >
      {LL(CommonTools.processObjectField(item, ["label"]))}
    </MyNavBt>
  ) : (
    <Typography key={index} color="text">
      {LL(CommonTools.processObjectField(item, ["label"]))}
    </Typography>
  );
};

export { BreadcrumbItem };
