import { Box } from "@mui/material";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import MyTextField from "components/elements/form/mytextfield";
import { CurrencySelect } from "components/elements/select/CurrencyTypeSelect";
import { PricePolicySelect } from "components/elements/select/PricePolicySelect";
import { SelectRecommendationPrice } from "components/finance/recommendationprice/SelectRecommendationPrice";

import { MyButton } from "components/elements/button/MyButton";
import { CoursePriceDto } from "dto/course/courseprice.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import { Config } from "tools/utils/config";

import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: CoursePriceDto;
  onSubmit: (data: CoursePriceDto) => void;
  loading: boolean;
  idTypeCourse: string;
};
const FormCoursePrice: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  idTypeCourse,
}) => {
  const { LL } = useResource();

  const validation = (): boolean => {
    if (!obj.idpricepolicy) return true;
    if (obj.userecommendationprice && !obj.idrecommendationprice) return true;
    if (!obj.userecommendationprice) {
      if (idTypeCourse === Config.ID_TYPE_COURSE_LIVE) {
        if (
          obj.priceperlesson?.toString() === "" ||
          obj.priceperlesson === null ||
          obj.priceperlesson === undefined
        )
          return true;
      } else {
        if (
          obj.totalprice?.toString() === "" ||
          obj.totalprice === null ||
          obj.totalprice === undefined
        )
          return true;
      }
      if (!obj.idcurrency) return true;
    }
    return false;
  };
  
  const [obj, disabled, setObjField,,vresults] = useForm<CoursePriceDto>(
    defaultObject,
    RequiredValidator.getValidators(["idpricepolicy"]),
    validation
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <PricePolicySelect
            value={obj.idpricepolicy}
            idTypeCourse={idTypeCourse}
            setObjectField={setObjField}
          />
        </Box>
        {obj.idpricepolicy && (
          <Box>
            <Box mt={3}>
              <MyCheckboxField
                label={LL("userecommendationprice")}
                field="userecommendationprice"
                setObjectField={setObjField}
                checked={obj.userecommendationprice}
              />
            </Box>
            {obj.userecommendationprice && (
              <Box mt={3}>
                <SelectRecommendationPrice
                  value={obj.idrecommendationprice ?? ""}
                  setObjectField={setObjField}
                  idPricePolicy={obj.idpricepolicy}
                />
              </Box>
            )}
          </Box>
        )}
        {!obj.userecommendationprice && (
          <Box>
            <Box mt={3}>
              {idTypeCourse === Config.ID_TYPE_COURSE_LIVE ? (
                <MyTextField
                  tabIndex={4}
                  fullWidth
                  id="priceperlesson"
                  name="priceperlesson"
                  color="primary"
                  _label={LL("priceperlesson")}
                  value={obj.priceperlesson}
                  setObj={setObjField}
                  _vresults={vresults}
                />
              ) : (
                <MyTextField
                  tabIndex={5}
                  fullWidth
                  id="totalprice"
                  name="totalprice"
                  color="primary"
                  _label={LL("totalprice")}
                  value={obj.totalprice}
                  setObj={setObjField}
                  _vresults={vresults}
                />
              )}
            </Box>
            <Box mt={3}>
              <CurrencySelect
                value={obj.idcurrency}
                setObjectField={setObjField}
              />
            </Box>
          </Box>
        )}

        <Box mt={3}>
          <MyButton
            tabIndex={6}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormCoursePrice };
