import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Types } from "tools/types/types";

export class CourseSettingsDto implements Idto {
  id?: string;
  idtypecourse?: string;
  password?: string;
  hastest?: boolean;

  idcourse?: string;
  test?: any;
  typecourse?: any;

  maxnumberofstudents?: number;

  constructor(
    idcourse?: string,
    idtypecourse?: string,
    password?: string,
    maxnumberofstudents?: number,
    id?: string
  ) {
    this.idcourse = idcourse || "";
    this.idtypecourse = idtypecourse || "";
    this.password = password || "";
    this.maxnumberofstudents = maxnumberofstudents || 0;
    this.id = id || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typecourse",
        value: ["typecourse", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "maxnumberofstudents",
        value: ["maxnumberofstudents"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "hastest",
        value: ["hastest_name"],
      },
    ];
  }
}

export class PostCourseSettingsDto implements Idto {
  idtypecourse?: string;
  idschedule?: string;
  password?: string;
  hastest?: boolean;
  idtest?: string;
  idcourse?: string;
  test?: any;
  typecourse?: any;
  schedule?: any;
  maxnumberofstudents?: number;
}
