import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import FilterTools from "tools/utils/filtertools";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import SelectOptions from "dto/app/selectoptions.dto";
import { CommonTools } from "tools/utils/commontools";

export class UserDto implements Idto {
  id?: string;
  status?: number;
  registerdate?: number;
  email?: string;
  statusemail?: number;
  password?: string;
  statuspassword?: number;
  hash?: string;
  registerdate_name?: any;
  statusemail_name?: any;
  statuspassword_name?: any;
  status_name?: any;
  idusersettings?: string;
  userroles?: any;
  phones?: any;

  constructor(
    id?: string,
    status?: number,
    registerdate?: number,
    email?: string,
    password?: string,
    statuspassword?: number,
    statusemail?: number
  ) {
    this.id = id || "";
    this.status = status || Status.ACTIVE;
    this.registerdate = registerdate || 0;
    this.email = email || "";
    this.password = password || "";
    this.statusemail = statusemail || Status.ACTIVE;
    this.statuspassword = statuspassword || Status.TEMPORARY;
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Email",
        value: ["email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "RegisterDate",
        value: ["registerdate_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "StatusEmail",
        value: ["statusemail_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "StatusPassword",
        value: ["statuspassword_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Name",
        value: ["usersettings", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Surname",
        value: ["usersettings", "surname"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Birthday",
        value: ["birthday_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typegender",
        value: ["usersettings", "typegender", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "language",
        value: ["usersettings", "language", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_IMAGE,
        label: "Avatar",
        value: ["usersettings", "avatar"],
        options: {
          width: 200,
          height: 200,
          withLink: true,
        },
      },
      {
        typeField: Types.FIELD_TYPE_ARRAY,
        label: "roles",
        value: ["userroles"],
        options: {
          fields: ["roleobj", "name"],
          displayArrayType: Types.FIELD_TYPE_TEXT,
          stackOptions: {
            direction: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            spacing: 1,
            divider: ComponentCommonTools.processDivider(),
          },
        },
      },
      {
        typeField: Types.FIELD_TYPE_ARRAY,
        label: "phones",
        value: ["phones"],
        options: {
          fields: ["countrycode"],
          displayArrayType: Types.FIELD_TYPE_MULTI_TEXT,
          secondaryFields: ["phonenumber"],
          stackOptions: {
            direction: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            spacing: 1,
            divider: ComponentCommonTools.processDivider(Types.TYPE_NO_DIVIDER),
          },
        },
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "email"),
      ComponentCommonTools.columns_ConstantColumn(LL, "status"),
      ComponentCommonTools.columns_ConstantColumn(LL, "registerdate"),
      ComponentCommonTools.columns_ConstantColumn(LL, "statusemail"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "userrole", "phone", "social", "address"],
        ["detail"]
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<UserDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(UserDto.parseOption(element));
    });
    return result;
  }

  static parseOption = (obj: UserDto): SelectOptions => {
    return new SelectOptions(
      CommonTools.processObjectField(obj, ["id"]),
      CommonTools.processObjectField(obj, ["email"])
    );
  };
}
