import { HeaderList } from "components/elements/list/HeaderList";
import { useListAdmin } from "hooks/useListAdmin";
import MyTable from "components/elements/tabeltools/MyTable";
import RequestListDTO from "dto/app/requestlist.dto";
import { useResource } from "hooks/useResource";
import React from "react";

import { IPage } from "interfaces/page.interface";
import { TeacherRequestDto } from "dto/user/teacherrequest.dto";
import {TeacherRequestService} from "services/user/teacherrequest.service";

const service = new TeacherRequestService();

const TeacherRequest: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const getListFun = (cb: any, cbParams: any, reqList: any) => {
    service.getList(cb, cbParams, reqList);
  };
  const getRequestListFun = (req: RequestListDTO) => {
    return service.getDefaultRequestList(req);
  };
  const deleteFun = (id: string, cb?: any, params?: any) => {
    service.delete(id, cb, params);
  };
  const {
    addUrl,
    mainUrl,
    setDeleteOneObject,
    rows,
    total,
    totalPage,
    reqList,
    setReqList,
    loading,
  } = useListAdmin<TeacherRequestDto>(
    mainObject,
    currentRoute,
    getListFun,
    getRequestListFun,
    deleteFun
  );
  const { LL } = useResource();
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (!reqList) return null;
  return (
    <React.Fragment>
      <HeaderList
        mainObject={mainObject}
        mainUrl={mainUrl}
        addUrl={addUrl}
        showAddButton={false}
      />
      <MyTable
        _columns={TeacherRequestDto.getColumns(
          LL,
          mainObject,
          setDeleteOneObject
        )}
        _data={rows}
        _total={total}
        _totalPage={totalPage}
        _reqList={reqList}
        _tbFilterList={TeacherRequestDto.getFilters()}
        setReqList={setReqList}
        loading={loading}
        currentRoute={currentRoute}
        mainObject={mainObject}
      />
    </React.Fragment>
  );
};

export { TeacherRequest };
