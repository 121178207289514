
import { GeneralAutocomplete } from "components/elements/autocomplete/newAutocomplete/GeneralAutocomplete";
import RequestListDTO from "dto/app/requestlist.dto";
import SelectOptions from "dto/app/selectoptions.dto";
import { TeacherDto } from "dto/user/teacher.dto";
import { useResource } from "hooks/useResource";

import React from "react";
import  {TeacherService}  from "services/user/teacher.service";

type Props = {
  setObjField: (field: string, value: any) => void;
  defaultObject?: TeacherDto | null;
  value?: string | number;
};

const service = new TeacherService();
const AutocompleteTeacher: React.FC<Props> = ({ setObjField, defaultObject,value }) => {
  const { LL } = useResource();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  let defaultObj: SelectOptions | null = null;
  if (!defaultObject) defaultObj = null;
  else defaultObj = TeacherDto.parseOption(defaultObject);

  return (
    <GeneralAutocomplete
      field="idteacher"
      label={LL("field_teacher")}
      defaultObject={defaultObj}
      setObjectField={setObjField}
      parseOptions={TeacherDto.parseToSelectOptions}
      getListFunction={getList}
      mainValue={value}
      filterField="emailsearch"
    />
  );
};

export { AutocompleteTeacher };
