import { Box } from "@mui/material";

import { StatusSelect } from "components/elements/select/StatusSelect";
import MyTextField from "components/elements/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";
import { AttachmentDto } from "dto/system/attachment.dto";

import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { LanguageSelect } from "components/static/language/newLanguageSelect";
import { MyCheckboxField } from "components/elements/form/MyCheckboxField";
import { MyFileField } from "components/elements/form/MyFileField";

type Props = {
  defaultObject: AttachmentDto;
  onSubmit: (data: AttachmentDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormAttachment: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useResource();
  const validators = forAdd
    ? RequiredValidator.getValidators(["idlanguage", "files"])
    : [];
  const [obj, disabled, setObjField, setObject, vresults, setObjectLanguage] =
    useForm<AttachmentDto>(defaultObject, validators);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            tabIndex={0}
            fullWidth
            id="name"
            name="name"
            variant="outlined"
            color="primary"
            _label={LL("Name")}
            size="small"
            value={obj.name}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <StatusSelect value={obj.status} setObjField={setObjField} />
        </Box>
        <Box mt={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            id="ordercriteria"
            name="ordercriteria"
            variant="outlined"
            color="primary"
            _label={LL("ordercriteria")}
            size="small"
            value={obj.ordercriteria}
            setObj={setObjField}
            _vresults={vresults}
          />
        </Box>
        <Box mt={3}>
          <MyCheckboxField
            field="isdefault"
            label={LL("field_isdefault")}
            setObjectField={setObjField}
            checked={obj.isdefault}
          />
        </Box>
        {forAdd && (
          <Box>
            <Box mt={3}>
              <LanguageSelect
                value={obj.idlanguage ?? ""}
                setValue={setObjectLanguage}
              />
            </Box>
            <Box mt={3}>
              <MyFileField
                field="files"
                label={LL("files")}
                setObj={setObject}
                obj={obj}
              />
            </Box>
          </Box>
        )}

        <Box mt={3}>
          <MyButton
            tabIndex={6}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormAttachment };
