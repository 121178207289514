import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {TeacherRepository} from "repositories/user/teacher.repository";

import { TeacherMediaDto } from "dto/user/teacher.dto";
import RequestListDTO from "dto/app/requestlist.dto";

export class TeacherService
  extends GeneralService<TeacherRepository>
  implements IService
{
  constructor() {
    super(
      new TeacherRepository(),
      [
        { key: "status", type: "teacherStatus" },
        { key: "hasaudience", type: "booleans" },
        { key: "birthday", type: "date" },
      ],
      "email"
    );
  }
  async updateMedia(cb?: any, cbparameters?: any, data?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    this.repository.updateMedia(
      this.handleGet,
      cbparameters,
      this.prepareObjToUpdateMedia(data)
    );
  }
  async addMedia(cb?: any, cbparameters?: any, data?: any): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;

    this.repository.addMedia(
      this.handleGet,
      cbparameters,
      this.prepareObjToAddMedia(data)
    );
  }

  prepareObjToAddMedia = (obj: any): TeacherMediaDto => {
    const rez = new TeacherMediaDto();
    rez.idteacher = obj.idteacher;

    if (
      obj.hasOwnProperty("videolocationlessonexample") &&
      obj.videolocationlessonexample
    )
      rez.videolocationlessonexample = obj.videolocationlessonexample;
    else rez.videolocationlessonexample = "";
    if (
      obj.hasOwnProperty("videolocationpresentation") &&
      obj.videolocationpresentation
    )
      rez.videolocationpresentation = obj.videolocationpresentation;
    else rez.videolocationpresentation = "";
    if (obj.hasOwnProperty("files") && obj.files) rez.files = obj.files;
    else rez.files = null;

    return rez;
  };
  prepareObjToUpdateMedia = (obj: any): TeacherMediaDto => {
    const rez = new TeacherMediaDto();
    rez.idteacher = obj.idteacher;

    if (
      obj.hasOwnProperty("videolocationlessonexample") &&
      obj.videolocationlessonexample
    )
      rez.videolocationlessonexample = obj.videolocationlessonexample;
    else rez.videolocationlessonexample = "";
    if (
      obj.hasOwnProperty("videolocationpresentation") &&
      obj.videolocationpresentation
    )
      rez.videolocationpresentation = obj.videolocationpresentation;
    else rez.videolocationpresentation = "";
    if (obj.hasOwnProperty("files") && obj.files) rez.files = obj.files;
    else rez.files = null;

    if (
      obj.hasOwnProperty("deletevideopresentation") &&
      obj.deletevideopresentation
    )
      rez.deletevideopresentation = obj.deletevideopresentation;
    else rez.deletevideopresentation = false;

    if (obj.hasOwnProperty("deleteattachmentcv") && obj.deleteattachmentcv)
      rez.deleteattachmentcv = obj.deleteattachmentcv;
    else rez.deleteattachmentcv = false;

    if (
      obj.hasOwnProperty("deletevideolessonexample") &&
      obj.deletevideolessonexample
    )
      rez.deletevideolessonexample = obj.deletevideolessonexample;
    else rez.deletevideolessonexample = false;

    return rez;
  };

  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    // defaultRequestSortCriteria.asc = true;
    // defaultRequestSortCriteria.field = 'value';
    // defaultRequestList.sortcriteria = []
    // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { TeacherDto, TeacherMediaDto } from "dto/teacher/teacher.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import TeacherRepository from "repositories/teacher/teacher.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";

// export default class TeacherService extends GeneralService implements IService {
//     teacherRepository: TeacherRepository = new TeacherRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.teacherRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.teacherRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
// { key: 'status', type: 'teacherStatus' },
// { key: 'hasaudience', type: 'booleans' },
// { key: 'birthday', type: 'date' }
//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.teacherRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined) {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'status', type: 'teacherStatus' },
//             { key: 'hasaudience', type: 'booleans' },
//             { key: 'birthday', type: 'date' },

//         ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.teacherRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined) {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.teacherRepository.add(this.handleGetList, cbparameters, data);
//     }

// async addMedia(
//     cb?: any,
//     cbparameters?: any,
//     data?: any
// ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//         this.teacherRepository.addMedia(this.handleGetList, cbparameters, this.prepareObjToAddMedia(data));
//     }

// async updateMedia(
//     cb?: any,
//     cbparameters?: any,
//     data?: any
// ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.teacherRepository.updateMedia(this.handleGetList, cbparameters, this.prepareObjToUpdateMedia(data));
// }

//     prepareObjToAddMedia(obj: any): TeacherMediaDto {
//         const rez = new TeacherMediaDto();
//         rez.idteacher = obj.idteacher;

//         if (obj.hasOwnProperty('videolocationlessonexample') && obj.videolocationlessonexample) rez.videolocationlessonexample = obj.videolocationlessonexample;
//         else rez.videolocationlessonexample = '';
//         if (obj.hasOwnProperty('videolocationpresentation') && obj.videolocationpresentation) rez.videolocationpresentation = obj.videolocationpresentation;
//         else rez.videolocationpresentation = '';
//         if (obj.hasOwnProperty('files') && obj.files) rez.files = obj.files;
//         else rez.files = null;

//         return rez;
//     }

// prepareObjToUpdateMedia(obj: any): TeacherMediaDto {
//     const rez = new TeacherMediaDto();
//     rez.idteacher = obj.idteacher;

//     if (obj.hasOwnProperty('videolocationlessonexample') && obj.videolocationlessonexample) rez.videolocationlessonexample = obj.videolocationlessonexample;
//     else rez.videolocationlessonexample = '';
//     if (obj.hasOwnProperty('videolocationpresentation') && obj.videolocationpresentation) rez.videolocationpresentation = obj.videolocationpresentation;
//     else rez.videolocationpresentation = '';
//     if (obj.hasOwnProperty('files') && obj.files) rez.files = obj.files;
//     else rez.files = null;

//     if (obj.hasOwnProperty('deletevideopresentation') && obj.deletevideopresentation) rez.deletevideopresentation = obj.deletevideopresentation;
//     else rez.deletevideopresentation = false;

//     if (obj.hasOwnProperty('deleteattachmentcv') && obj.deleteattachmentcv) rez.deleteattachmentcv = obj.deleteattachmentcv;
//     else rez.deleteattachmentcv = false;

//     if (obj.hasOwnProperty('deletevideolessonexample') && obj.deletevideolessonexample) rez.deletevideolessonexample = obj.deletevideolessonexample;
//     else rez.deletevideolessonexample = false;

//     return rez;
// }

//     // getDefaultRequestList(onPage?: number): RequestListDTO {
//     //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     //     var defaultRequestList = new RequestListDTO();
//     //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     //     defaultRequestList.onpage = onPage;
//     //     var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//     //     defaultRequestSortCriteria.asc = true;
//     //     defaultRequestSortCriteria.field = 'status';
//     //     defaultRequestList.sortcriteria = []
//     //     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     //     return defaultRequestList;
//     // }

// getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     // var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//     // defaultRequestSortCriteria.asc = true;
//     // defaultRequestSortCriteria.field = 'value';
//     // defaultRequestList.sortcriteria = []
//     // defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
// }

//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "email";

//             obj.sortcriteria = [sobj];
//         }

//         return obj;
//     }
// }
