import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {TransactionRepository} from "repositories/finance/transaction.repository";
export class TransactionService
  extends GeneralService<TransactionRepository>
  implements IService
{
  constructor() {
    super(
      new TransactionRepository(),
      [
        { key: "data", type: "timestamp" },
        { key: "status", type: "status" },
        { key: "typeowner", type: "typeowner" },
        { key: "transactiontype", type: "transactiontype" },
      ],
      "data"
    );
  }

}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// // import { PostTransactionDto } from "dto/finance/transaction.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import TransactionRepository from "repositories/finance/transaction.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";

// export default class TransactionService extends GeneralService implements IService {
//     transactionRepository: TransactionRepository = new TransactionRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.transactionRepository.update(id, this.handleUpdate, data, cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.transactionRepository.delete(id, this.handleDelete, cbparameters);
//     }

//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
//             { key: 'data', type: 'timestamp' },
//             { key: 'status', type: 'status' },
//             { key: 'typeowner', type: 'typeowner' },
//             { key: 'transactiontype', type: 'transactiontype' },
//         ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }

//     async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.transactionRepository.get(id, this.handleGet, cbparameters);
//         if (cb == undefined) {
//             return this.handleGet(t);
//         }
//     }

//     handleGetList(result?: any, cbparameters?: any, data?: any): any {

//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'data', type: 'timestamp' },
//             { key: 'status', type: 'status' },
//             { key: 'typeowner', type: 'typeowner' },
//             { key: 'transactiontype', type: 'transactiontype' },
//         ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.transactionRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined) {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     // prepareObjToAdd(obj: any): PostTransactionDto {
//     //     const rez = new PostTransactionDto();

//     //     rez.date = obj.date;
//     //     rez.fromidcurrency = obj.fromidcurrency;
//     //     rez.toidcurrency = obj.toidcurrency;
//     //     rez.rate = obj.rate;

//     //     return rez;
//     // }

//     // prepareObjToUpdate(obj: any): PostTransactionDto {
//     //     const rez = new PostTransactionDto();

//     //     rez.date = obj.date;
//     //     rez.fromidcurrency = obj.fromidcurrency;
//     //     rez.toidcurrency = obj.toidcurrency;
//     //     rez.rate = obj.rate;

//     //     return rez;
//     // }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.transactionRepository.add(this.handleGetList, cbparameters, data);
//     }

//     // getDefaultRequestList(onPage?: number): RequestListDTO {
//     //     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     //     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     //     var defaultRequestList = new RequestListDTO();
//     //     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     //     defaultRequestList.onpage = onPage;
//     //     var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//     //     defaultRequestSortCriteria.asc = true;
//     //     defaultRequestSortCriteria.field = 'date';
//     //     defaultRequestList.sortcriteria = []
//     //     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     //     return defaultRequestList;
//     // }

//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "data";

//             obj.sortcriteria = [sobj];
//         }

//         return obj;
//     }
// }
