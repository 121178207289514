import { LeftPartUserMenu } from "./LeftPartUserMenu";


type Props = {
  [key: string]: any;
  setOpen: (open: boolean) => void;
};

const LeftPart:React.FC<Props> = ({ setOpen, ...props }) => {
  
  return <LeftPartUserMenu key={"left-part"} setOpen={setOpen} {...props} />;
};

export { LeftPart };
