import React from "react";
import { VideoService } from "services/system/video.service";
import { IPage } from "interfaces/page.interface";
import { VideoDto } from "dto/system/video.dto";
import { FormVideo } from "components/special/video/FormVideo";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useResource } from "hooks/useResource";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useAddSpecialThirdLevel } from "hooks/useAddSpecialThirdLevel";
import { HeaderAddSpecialThirdLevel } from "components/elements/add/HeaderAddSpecialThirdLevel";

const service = new VideoService();
const ThirdLevelSpecialVideoAdd: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const {
    mainUrl,
    loading,
    setLoading,
    parentId,
    parentType,
    firstLevelParentId,
    firstLevelParentType,
  } = useAddSpecialThirdLevel(currentRoute);

  const { _idlanguage } = useResource();
  const onSubmit = (obj: VideoDto) => {
    if (!obj) return;
    obj.idparent = parentId;
    obj.parent = parentType;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecialThirdLevel,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "thirdlevelvideo",
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["idlanguage"]),
        firstLevelParentId,
        firstLevelParentType,
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecialThirdLevel
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="thirdlevelvideo"
        firstLevelParentId={firstLevelParentId}
        firstLevelParentType={firstLevelParentType}
      />
      <DataDisplayBox>
        <FormVideo
          defaultObject={new VideoDto(_idlanguage)}
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { ThirdLevelSpecialVideoAdd };
