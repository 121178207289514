import Idto from "interfaces/idto.interface";
import MessageDto from "dto/app/message.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultMetaDTO from "./resultmeta.dto";
import {RoleDto} from "dto/user/role.dto";

export default class ResultListDTO implements Idto {
  err?: boolean;
  messages?: MessageDto[];
  objects?: Idto[];
  requestinfo?: RequestListDTO;
  total?: number;
  totalpages?: number;
  meta?: ResultMetaDTO;
  roles?: RoleDto[];
  
}
