import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import FilterTools from "tools/utils/filtertools";


export class PostSiteSocialDto implements Idto {
  link?: string;
  idtypesocial?: string;
  typesocial?: any;
  status?: number;
  constructor() {
    this.status = Status.ACTIVE;
  }
}

export class SiteSocialDto implements Idto {
  id?: string;
  link?: string;
  idtypesocial?: string;
  typesocial?: any;
  status?: number;
  constructor(
    idtypesocial?: string,
    link?: string,

  ) {
    this.status = Status.ACTIVE;
    this.idtypesocial = idtypesocial || "";
    this.link = link || "";

  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "typesocial",
        value: ["typesocial", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "link",
        value: ["link"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
    ];
  }

  
  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
    ComponentCommonTools.columns_GetCheck(),
    ComponentCommonTools.columns_StandardColumn(LL, "link"),
    ComponentCommonTools.columns_StandardObjectColumn(LL,"typesocial", "name"),
    ComponentCommonTools.columns_ConstantColumn(LL),
    ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"]
      )
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("searchvalue", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);
    
    return obj;
  }
}
