import React from "react";
import { AddressService } from "services/nomenclature/address.service";

import { IPage } from "interfaces/page.interface";

import { AddressDto } from "dto/nomenclature/address.dto";

import { FormAddress } from "components/special/address/FormAddress";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { HeaderAddSpecial } from "components/elements/add/HeaderAddSpecial";
import { useAddSpecial } from "hooks/useAddSpecial";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

const service = new AddressService();
const SpecialAddressAdd: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, loading, setLoading, parentId, parentType } =
    useAddSpecial(currentRoute);

  const onSubmit = (obj: AddressDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEditSpecial,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "address",
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAddSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        specialType="address"
      />
      <DataDisplayBox>
        <FormAddress
          defaultObject={new AddressDto(parentId)}
          onSubmit={onSubmit}
          loading={loading}
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialAddressAdd };
