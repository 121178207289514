import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";

import RequiredValidator from "validators/required.validator";

import { LinkCourseCategoryDto } from "dto/course/linkcoursecategory.dto";

import { AutocompleteCourseCategory } from "components/course/coursecategory/AutocompleteCourseCategory";

type Props = {
  defaultObject: LinkCourseCategoryDto;
  onSubmit: (data: LinkCourseCategoryDto) => void;
  loading: boolean;
};
const FormLinkCourseCategory: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();

  const [obj, disabled, setObjField] = useForm<LinkCourseCategoryDto>(
    defaultObject,
    RequiredValidator.getValidators(["idcourse", "idcoursecategory"])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <AutocompleteCourseCategory
            setObjField={setObjField}
            value={obj.idcoursecategory}
            defaultObject={obj.coursecategory}
          />
        </Box>

        <Box mt={3}>
          <MyButton
            tabIndex={3}
            disabled={disabled || loading}
            color="primary"
            fullWidth
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormLinkCourseCategory };
